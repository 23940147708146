import { gql, useMutation } from '@apollo/client';

const saveSimpleRule = gql`
  mutation CreateRule(
    $name: String!
    $type: String!
    $description: String
    $customInput: Map
    $staging: ConfDetailInput
    $production: ConfDetailInput
    $action: ActionInput
    $conditions: ConditionsInput
    $dataSetId: String
    $firstCustomInput: String
  ) {
    createRule(
      input: {
        name: $name
        type: $type
        description: $description
        customInput: $customInput
        staging: $staging
        production: $production
        action: $action
        conditions: $conditions
        dataSetId: $dataSetId
        firstCustomInput: $firstCustomInput
      }
    ) {
      id
      name
      type
      staticUrl
      checksum
    }
  }
`;

export function useSaveSimpleRule() {
  return useMutation(saveSimpleRule);
}
