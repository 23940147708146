import { ApolloError } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  DropdownField,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  TextAreaField,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { checksumWarningAtom } from '../../../atom';
import type { User } from '../../../pages/Workspace/component/types';
import { useGetWorkspaceMemberList } from '../../../pages/Workspace/hooks/restApi/useGetWorkspaceMemberList';
import type { CheckSumEntityNames } from '../../../types';
import { handleGetCheckSumByEntityName } from '../../../utils/common';
import type { Option } from '../../Form/types';
import { useRequestReviewForEntity } from '../hooks/graphql/useRequestReviewForEntity';
import { requestReviewFormSchema } from '../schema';
import type { ModalCommonProps } from '../type';
import {
  getChecksumMetaData,
  getEntityTypeForApi,
  getEntityTypeForChecksum,
} from '../utils/common';

type RequestReviewModalFormData = {
  title: string;
  description: string;
  approvers: Option[];
};

type RequestReviewModalProps = ModalCommonProps & {
  defaultFormValues?: RequestReviewModalFormData;
};

export function RequestReviewModal({
  entityType,
  entityId,
  title = 'Request for approval',
  defaultFormValues,
  handleEntityUpdate,
  entityName = '',
  entitySubType = '',
}: RequestReviewModalProps) {
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [approverList, setApproverList] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { close: closeModal } = useCurrentLayer();

  const userUUID = window.localStorage.getItem('userUUID');

  const [requestReviewEntityMutation, { data, error }] =
    useRequestReviewForEntity();

  const { data: memberListData, getWorkspaceMemberList } =
    useGetWorkspaceMemberList();

  const {
    data: selectedApproversList,
    getWorkspaceMemberList: getSelectedApproversList,
  } = useGetWorkspaceMemberList();

  const { control, watch, setValue, handleSubmit } =
    useForm<RequestReviewModalFormData>({
      resolver: zodResolver(requestReviewFormSchema),
    });

  const formData = watch();

  useEffect(() => {
    void getWorkspaceMemberList({
      roles: 'approver,owner,admin',
      approvalFlowEnabled: true,
    });
  }, []);

  const getApproverName = (data: User) => {
    if (!_isEmpty(data.firstName) && !_isEmpty(data.lastName)) {
      return data.firstName + ' ' + data.lastName;
    } else if (!_isEmpty(data.firstName)) {
      return data.firstName;
    } else if (!_isEmpty(data.lastName)) {
      return data.lastName;
    }

    return data.email;
  };

  useEffect(() => {
    if (!_isNil(memberListData) && memberListData.length > 0) {
      const list = memberListData
        .filter((obj) => obj.user.uuid !== userUUID)
        .map((obj) => ({
          label: getApproverName(obj.user),
          value: obj.user.uuid,
        }));

      setApproverList(list);
    }
  }, [memberListData]);

  useEffect(() => {
    if (!_isNil(selectedApproversList) && selectedApproversList.length > 0) {
      const list = selectedApproversList.map((obj) => ({
        label: getApproverName(obj.user),
        value: obj.user.uuid,
      }));

      setValue('approvers', list);
    }
  }, [selectedApproversList]);

  useEffect(() => {
    if (!_isNil(defaultFormValues)) {
      setValue('title', defaultFormValues.title);
      setValue('description', defaultFormValues.description);

      if (
        !_isNil(defaultFormValues?.approvers) &&
        defaultFormValues.approvers.length > 0
      ) {
        void getSelectedApproversList({
          users: defaultFormValues.approvers.join(','),
        });
      }
    }
  }, [defaultFormValues]);

  useEffect(() => {
    if (
      typeof handleEntityUpdate === 'function' &&
      !_isNil(data) &&
      !_isNil(data.requestReview)
    ) {
      const { title = '', description = '', approvers } = formData;

      const requestReviewFormData = {
        title,
        description,
        approvers: approvers.map((obj) => obj.value),
      };

      closeModal();

      toasts.success(
        data.requestReview.message ?? '',
        'entity request reviewd'
      );

      handleEntityUpdate({
        ...data.requestReview,
        formData: {
          requestReview: requestReviewFormData,
        },
      });
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (error instanceof ApolloError) {
      if (error?.graphQLErrors[0]?.extensions?.code === 'checksum_mismatched') {
        setShowChecksumPopup({
          showPopup: true,
          metaData: getChecksumMetaData({
            entityId,
            entityName,
            entityType,
            entitySubType,
          }),
        });
      } else {
        toasts.error(error.message, 'error');
      }
    }
  }, [error]);

  const handleRequestReview = async (data: RequestReviewModalFormData) => {
    const { title = '', description = '', approvers = [] } = data;

    const checksumEntityName = getEntityTypeForChecksum(entityType);

    const payload = {
      id: entityId,
      entityType: getEntityTypeForApi(entityType),
      title,
      description,
      approvers: approvers.map((obj) => obj.value),
      checksum:
        handleGetCheckSumByEntityName(
          checksumEntityName as CheckSumEntityNames
        ) ?? '',
    };

    try {
      setIsLoading(true);

      await requestReviewEntityMutation({
        variables: payload,
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="extraLarge" title={title} overflow="none">
      <form onSubmit={handleSubmit(handleRequestReview)}>
        <ModalContent>
          <Stack as={PadBox} gutter="1.5rem" padding={['1.5rem', 0, '4rem', 0]}>
            <Stack gutter="0.8rem">
              <Typography name="heading3">Title</Typography>
              <TextField
                control={control}
                placeholder="Describe title"
                name="title"
                showErrorIcon={false}
              />
            </Stack>

            <Stack gutter="0.8rem">
              <Typography name="heading3">Describe changes</Typography>

              <TextAreaField
                control={control}
                placeholder="Explain in detail"
                name="description"
                rows={4}
              />
            </Stack>

            <Stack gutter="0.8rem">
              <Typography name="heading3">Add Reviewers</Typography>
              <DropdownField
                control={control}
                placeholder="Add Reviewers"
                name="approvers"
                isMulti={true}
                options={approverList}
              />
            </Stack>
          </Stack>
        </ModalContent>
        <ModalFooter>
          <Button onClick={closeModal} appearance="neutral">
            Cancel
          </Button>

          <Button type="submit">
            {isLoading ? <Spinner size="extraSmall" /> : <>Request Review</>}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
