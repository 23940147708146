import _isEmpty from 'lodash/isEmpty';
import { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { CheckboxField, TextField, TextInput } from 'ui';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  isArrayAsInputValid,
} from '../../../../utils/common';
import {
  FLOATING_NUMBER_REGEX,
  VALID_STRING_REGEX,
} from '../../../../utils/regex';
import {
  isValidDate,
  isValidDateTime,
  isValidDateTimeSecondary,
} from '../../../../utils/validation';
import { JsonNodePill } from '../SimpleRule/Results/JsonNodePill';

type TestNodeComponentProps = Omit<UseControllerProps<any>, 'name'> & {
  index: number;
  nodeKey: string;
  isNullable: boolean;
  disabled?: boolean;
  nodeType?: 'test' | 'cronAttributes';
  setValue?: UseFormSetValue<any>;
  dataType: string;
  from?: string;
};

function TextComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        pattern: {
          value: VALID_STRING_REGEX,
          message: 'Invalid string',
        },
      }}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

// eslint-disable-next-line
function ListComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        pattern: {
          value: VALID_STRING_REGEX,
          message: 'Invalid string',
        },
        validate: {
          require: (value: string) => {
            if (!_isEmpty(value) && !isArrayAsInputValid(value)) {
              return 'Error while parsing input value';
            }

            return undefined;
          },
        },
      }}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function NumericComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        required: {
          value: !disabled,
          message: 'Required Field',
        },
        pattern: {
          value: FLOATING_NUMBER_REGEX,
          message: 'Invalid number',
        },
      }}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function BooleanComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <CheckboxField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      showError
      appearance="switch"
      useId={`test.${index}.${nodeKey}.value`}
      disabled={disabled}
    />
  );
}

function DateComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        required: {
          value: !disabled,
          message: 'Required Field',
        },
        validate: {
          require: (value) => {
            if (typeof value === 'string' && !disabled) {
              return isValidDate(value) ||
                isValidDateTime(value) ||
                isValidDateTimeSecondary(value)
                ? undefined
                : 'Invalid Date Format';
            }

            return undefined;
          },
        },
      }}
      placeholder={DATE_FORMAT}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function DateTimeComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        required: {
          value: !disabled,
          message: 'Required Field',
        },
        validate: {
          require: (value) => {
            if (typeof value === 'string' && !disabled) {
              return isValidDateTime(value) || isValidDateTimeSecondary(value)
                ? undefined
                : 'Invalid Date Format';
            }

            return undefined;
          },
        },
      }}
      placeholder={DATE_TIME_FORMAT}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function JsonComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  setValue,
  dataType,
  from = '',
}: TestNodeComponentProps) {
  return (
    <JsonNodePill
      type={dataType}
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      setOriginalValue={setValue}
      from={from}
    />
  );
}

function RestApiComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  setValue,
}: TestNodeComponentProps) {
  return <TextInput placeholder="Fetched via API" disabled />;
}

export const testNodeComponents = {
  string: TextComponent,
  numeric: NumericComponent,
  boolean: BooleanComponent,
  date: DateComponent,
  dateTime: DateTimeComponent,
  json: JsonComponent,
  restAPI: RestApiComponent,
  list: JsonComponent,
};
