import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

import { Typography } from '../../Typography';

export const NoFileBoxStyled = styled(Stack)`
  border: 1px dashed var(--color-dodgerBlue);
  border-radius: 1rem;
  padding: 3rem 1rem;
  inline-size: 20rem;
  justify-content: center;
  align-items: center;
  position: relative;

  input[type='file'] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
`;

export const FileBoxStyled = styled(Stack)`
  width: 70%;

  progress {
    width: 100%;
    height: 0.75rem;

    -webkit-appearance: none;
    appearance: none;

    &::-webkit-progress-bar {
      background-color: var(--color-aliceBlue);
      border-radius: 0.8rem;
    }

    &::-webkit-progress-value {
      background-color: blue;
      border-radius: 0.8rem;
    }
  }
`;

export const FileContainerStyled = styled(Stack)`
  border: 1px solid var(--color-lightGray);
`;

export const FileItem = styled(Inline)`
  border-top: 1px solid var(--color-lightGray);
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
`;

export const FileStyledHeader = styled(Inline)`
  justify-content: space-between;
  padding: 1rem 2rem;
`;

export const SupportedFileText = styled(Typography)`
  color: var(--color-dodgerBlue);
`;
