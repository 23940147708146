import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button, Spinner } from 'ui';

import {
  createRuleSheetAtom,
  isRulePublishOnlyAtom,
  isRuleReadOnlyAtom,
  isRuleTestOnlyAtom,
  ruleWarningsAtom,
} from '../..';
import { ButtonWithOptions } from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import { buttonOptionIdsObj } from '../../../../components/ButtonWithOptions/constant';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { useUpdateModalStateOnButtonOptionClick } from '../../../../hooks/useUpdateModalStateOnButtonOptionClick';
import { handleSetCheckSumByEntityName } from '../../../../utils/common';
import { ENTITY_ID } from '../../../../utils/constant';
import { useUpdateStateOnRulePublish } from '../../hooks/useUpdateStateOnRulePublish';
import { AutoSaving } from '../AutoSave/AutoSaving';
import { Saved } from '../AutoSave/Saved';
import { ruleSavingErrorAtom } from '../CreateRuleSheet/CreateRuleSheet';
import { approvalInfoRuleAtom, isRuleLiveAtom } from '../atom/atom';
import { ActionButtonsContainer, RuleSaving } from './RuleComponents.styled';
import { RuleDebug } from './RuleDebug/RuleDebug';

type RuleSheetFooterProps = {
  isLive: boolean;
  isRuleCreating: boolean;
  isRuleUpdating: boolean;
  ruleEnvironment: 'staging' | 'production';
  submitAndTestForm: ReturnType<UseFormHandleSubmit<any>>;
  handleEditButton: () => void;
  ruleName: string;
  id?: string;
  isClone?: boolean;
  onPublishFail?: (rules: Record<string, any>) => void;
  status?: string;
  setValue?: UseFormSetValue<any>;
  isTesting?: boolean;
};

export function RuleSheetFooter({
  isLive,
  isRuleCreating,
  isRuleUpdating,
  ruleEnvironment,
  submitAndTestForm,
  id,
  ruleName,
  isClone = false,
  onPublishFail,
  status = '',
  setValue,
  handleEditButton,
  isTesting = false,
}: RuleSheetFooterProps) {
  const [searchParams] = useSearchParams();

  const [ruleType] = useAtom(createRuleSheetAtom);
  const [ruleSavingError] = useAtom(ruleSavingErrorAtom);

  const [isRulePublishOnly] = useAtom(isRulePublishOnlyAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [isRuleTestOnly] = useAtom(isRuleTestOnlyAtom);
  const [ruleWarning] = useAtom(ruleWarningsAtom);

  const [isRuleLive] = useAtom(isRuleLiveAtom);
  const [approvalInfoRule] = useAtom(approvalInfoRuleAtom);

  const { isHide: hideEditBtn } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.workflow],
    statusShouldBe: ['published'],
    entityStatus: status,
  });

  const showEditBtn = isRuleLive && !hideEditBtn;

  const { handleAfterRuleIsPublished } = useUpdateStateOnRulePublish({
    id: id ?? '',
    ruleName,
    isClone,
    setValue,
  });

  const handleEntityUpdate = (data: Record<string, any>) => {
    if (!_isNil(setValue) && !_isNil(data)) {
      Object.keys(data).forEach((key) => {
        if (key === 'checksum') {
          handleSetCheckSumByEntityName('rule', data.checksum);
        } else if (key === 'entityPublished') {
          void handleAfterRuleIsPublished();
        } else {
          setValue(key, data[key]);
        }
      });
    }
  };

  const { selectedOptionId, handleOptionButtonClick } =
    useUpdateModalStateOnButtonOptionClick({
      entityId: id ?? '',
      entityType: 'rules',
      entityName: ruleName,
      entityStatus: status,
      entitySubType: ruleType,
      handleParentStateUpdate: handleEntityUpdate,
      formData: {
        requestReview: approvalInfoRule,
        publishModal: {
          title: approvalInfoRule?.title,
        },
      },
    });

  const showFooterAction = (searchParams.get('type') ?? '') !== 'view';

  return (
    <ActionButtonsContainer padding={5}>
      <Inline justify="end" align="center" gutter={8}>
        {showFooterAction && !isRuleCreating && !isRuleUpdating && (
          <RuleSaving padding={['0rem', '0.8rem']}>
            <Saved />
          </RuleSaving>
        )}

        {showFooterAction && (isRuleCreating || isRuleUpdating) && (
          <AutoSaving />
        )}

        <RuleDebug warnings={ruleWarning} isRuleReadOnly={isRuleReadOnly} />

        {showEditBtn && showFooterAction && (
          <Button onClick={handleEditButton}>Edit</Button>
        )}

        {ruleEnvironment === 'staging' && showFooterAction && (
          <Button
            appearance="filled"
            type="submit"
            onClick={submitAndTestForm}
            disabled={!_isEmpty(ruleSavingError) || !isRuleTestOnly}
          >
            {isTesting ? <Spinner size="extraSmall" /> : 'Test in Staging'}
          </Button>
        )}

        {!isRuleLive && isRulePublishOnly && status === 'published' && (
          <Button
            onClick={() => handleOptionButtonClick(buttonOptionIdsObj.rollback)}
          >
            Rollback
          </Button>
        )}

        {showFooterAction && !['published', 'archived'].includes(status) && (
          <ButtonWithOptions
            id="rule-footer"
            entityType={ENTITY_ID.rules}
            entityStatus={status}
            defaultSelectedOptionId={selectedOptionId}
            onClick={handleOptionButtonClick}
          />
        )}
      </Inline>
    </ActionButtonsContainer>
  );
}
