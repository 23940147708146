import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { UseControllerProps, useWatch } from 'react-hook-form';
import { AiFillCaretDown } from 'react-icons/ai';
import { RulesPopoverField, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../../..';
import { filteredRuleOptionsAtom } from '../../RuleSet';
import { resultByRuleAtom } from '../../RuleSetEditor/RuleSetEditor';
import { RulesLauncherStyled, TableColumnStyled } from './TableColumns.styled';

export type RulesColumnProps = Omit<UseControllerProps, 'name'> & {
  index: number;
};

export function RulesColumn({ control, index }: RulesColumnProps) {
  const id: string = useWatch({
    control,
    name: `ruleList.${index}.ruleId`,
  });

  const [ruleResultById] = useAtom(resultByRuleAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [filteredRuleOptions] = useAtom(filteredRuleOptionsAtom);

  const launcher = (
    <RulesLauncherStyled padding={[4, 8]}>
      <Inline align="center" stretch="start">
        <Typography>
          {!_isNil(ruleResultById[id]) ? ruleResultById[id].name : '-'}
        </Typography>
        <div>
          <AiFillCaretDown size={8} />
        </div>
      </Inline>
    </RulesLauncherStyled>
  );

  return (
    <TableColumnStyled width="30%" padding={[6, 10]}>
      <RulesPopoverField
        launcher={launcher}
        options={filteredRuleOptions}
        control={control}
        name={`ruleList.${index}.ruleId`}
        disabled={isRuleReadOnly}
      />
    </TableColumnStyled>
  );
}
