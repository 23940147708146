import { Inline } from '@bedrock-layout/inline';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { AnimatePresence, motion } from 'framer-motion';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { ReactNode } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { TooltipReact } from '../../TooltipReact';
import { Typography } from '../../Typography';
import { NodeAccordion, NodeStyled } from './Node.styled';

export type NodeProps = {
  depth: number;
  nodeValue: Record<string, any>;
  children: ReactNode;
  expandedNodes: Record<string, boolean>;
  parentNodeClickHandler: (id: string) => void;
  searchInput: string;
  title?: string;
  tooltip?: ReactNode;
};

export const rotationVariants = {
  initial: { rotate: '90deg', transition: { duration: 0 } },
  twist: { rotate: '90deg', transition: { duration: 0.25 } },
  untwist: { rotate: 0 },
};

export const Node = ({
  depth,
  nodeValue,
  title,
  searchInput,
  children,
  expandedNodes,
  tooltip,
  parentNodeClickHandler,
}: NodeProps) => {
  const areChildrenVisible: boolean =
    _isUndefined(expandedNodes[nodeValue.id]) ||
    expandedNodes[nodeValue.id] ||
    searchInput.length > 0;

  return (
    <AnimatePresence>
      <PadBox padding={[0, 5, 0, 8 * depth]}>
        {!_isUndefined(title) && (
          <Inline
            gutter={10}
            align="center"
            onClick={() => {
              parentNodeClickHandler(nodeValue.id);
            }}
            style={{
              marginBottom: '0.5rem',
            }}
          >
            <NodeStyled align="center" gutter={8}>
              <Typography>{title}</Typography>
              {!_isNil(tooltip) && (
                <TooltipReact id={`tree-${nodeValue.id as string}`}>
                  {tooltip}
                </TooltipReact>
              )}
            </NodeStyled>

            <NodeAccordion>
              {areChildrenVisible ? <FaMinus size={7} /> : <FaPlus size={7} />}
            </NodeAccordion>
          </Inline>
        )}

        {!_isNil(nodeValue.footer) && nodeValue.footer}

        {areChildrenVisible && (
          <motion.div>
            <Stack gutter="0px">{children}</Stack>
          </motion.div>
        )}
      </PadBox>
    </AnimatePresence>
  );
};
