import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue } from 'react-hook-form';
import { CheckboxField, Expander, TooltipReact, Typography } from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { getTooltipText } from '../../../../../utils/common';
import {
  isWorkflowReadOnlyAtom,
  workflowEnvironmentAtom,
} from '../../../atoms/atoms';
import { FormContainer } from './WorkflowSettingsSheet.styled';

type WorkflowSettingsSheetProps = {
  setOriginalValue?: UseFormSetValue<any>;
  parentControl?: Control<any>;
};

export function WorkflowSettingsSheet({
  setOriginalValue,
  parentControl,
}: WorkflowSettingsSheetProps) {
  const [workflowEnv] = useAtom(workflowEnvironmentAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [siteConstant] = useAtom(siteConstantsAtom);

  return (
    <FormContainer gutter="2rem">
      <Expander title="Settings">
        <>
          <PadBox padding="1rem">
            <Inline align="center" gutter={12}>
              <Typography>Enable Workflow</Typography>

              <TooltipReact id="enable-workflow" placement="bottom-start">
                <Typography>
                  {getTooltipText(siteConstant, 'workflow', 'enableWorkflow')}
                </Typography>
              </TooltipReact>

              <CheckboxField
                control={parentControl}
                name="isEnabled"
                useId="isEnabled"
                appearance="switch"
                disabled={isWorkflowReadOnly}
              />
            </Inline>
          </PadBox>

          <PadBox padding="1rem">
            <Inline align="center" gutter={12}>
              <Typography>Auditing</Typography>

              <TooltipReact id="enable-auditIO" placement="bottom-start">
                <Typography>
                  {getTooltipText(siteConstant, 'rules', 'auditing')}
                </Typography>
              </TooltipReact>

              <CheckboxField
                control={parentControl}
                name={`${workflowEnv}.auditIO`}
                useId={`${workflowEnv}.auditIO`}
                appearance="switch"
                disabled={isWorkflowReadOnly}
              />
            </Inline>
          </PadBox>
        </>
      </Expander>
      {/* <Typography name="heading3">Settings</Typography> */}
    </FormContainer>
  );
}
