import { gql, useMutation } from '@apollo/client';

import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updateConditions = gql`
  mutation updateRule(
    $id: String!
    $conditions: ConditionsInput
    $decisionTable: Map
    $checksum: String!
    $editMode: Boolean
  ) {
    updateRule(
      input: {
        id: $id
        conditions: $conditions
        decisionTable: $decisionTable
        editMode: $editMode
        checksum: $checksum
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateConditions() {
  return useMutation<RuleUpdateResponseModel>(updateConditions);
}
