import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { createRuleSheetAtom } from '..';
import { checksumWarningAtom } from '../../../atom';
import { useAxiosPrivate } from '../../../hooks';
import { getUserState } from '../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import {
  handleSetCheckSumByEntityName,
  isOnboardingCompleted,
  showGraphQlErrorToast,
} from '../../../utils/common';
import { newRuleCreated, testAndPublishRule } from '../../../utils/constant';
import { updateWidgetState } from '../../Home/components/sub-components/UpdateWidgetState';
import { ruleEnvironmentAtom } from '../components/CreateRuleSheet/CreateRuleSheet';
import {
  cronInfoAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
} from '../components/atom/atom';
import { useGetRuleByIdOnPublish } from './graphql/useGetRuleByIdOnPublish';

type UseUpdateStateOnRulePublishProps = {
  id: string;
  ruleName: string;
  isClone: boolean;
  setValue?: UseFormSetValue<any>;
};

export function useUpdateStateOnRulePublish({
  id,
  ruleName,
  isClone,
  setValue,
}: UseUpdateStateOnRulePublishProps) {
  const navigate = useNavigate();
  const { axiosPrivate } = useAxiosPrivate();

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [cronInfo, setCronInfo] = useAtom(cronInfoAtom);

  const [ruleType] = useAtom(createRuleSheetAtom);
  const [ruleSetEnvironment] = useAtom(ruleEnvironmentAtom);

  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);

  const [getRuleByIdQuery, { data: ruleDataToUpdateOnPublish }] =
    useGetRuleByIdOnPublish(ruleType);

  const { sendEventToGTM } = useSendEventToGTM();

  useEffect(() => {
    if (!_isNil(ruleDataToUpdateOnPublish)) {
      const data = ruleDataToUpdateOnPublish.getRule.data[0];

      if (!_isNil(data)) {
        if (typeof setValue === 'function') {
          setValue('publishedAt', data.publishedAt);
          setValue('status', data.status);
        }

        handleSetCheckSumByEntityName('rule', data.checksum);

        setVersionInfoRule(data.versionInfo);
        setIsRuleLive(data.isLive ?? false);
      }
    }
  }, [ruleDataToUpdateOnPublish]);

  const handleSheetCloseOnCreate = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');

    if (type === 'create' || isClone) {
      close();
      navigate(
        `/rules/${
          id ?? ''
        }?type=edit&stage=${ruleSetEnvironment}&ruleType=${ruleType}&wsid=${
          sessionStorage.getItem('workspaceUUID') as string
        }`
      );
    }
  };

  const getStatusOfCron = async () => {
    try {
      const response = await axiosPrivate.get('/rule/schedule', {
        params: {
          id,
        },
      });

      if (!_isNil(response.data?.data)) {
        setCronInfo({
          ...cronInfo,
          ...response.data.data,
        });
      }
    } catch (error) {}
  };

  const handleAfterRuleIsPublished = async () => {
    sendEventToGTM({
      event: 'rule',
      ruleId: id,
      ruleName,
      type: ruleType,
      action: 'publish',
      nec_source: '',
    });

    sendEventToGTM({
      event: 'rule_published',
      ruleId: id,
      ruleName,
      type: ruleType,
      action: 'publish',
      nec_source: '',
    });

    try {
      if (!isOnboardingCompleted(testAndPublishRule)) {
        updateWidgetState(testAndPublishRule)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }

      if (!isOnboardingCompleted(newRuleCreated)) {
        updateWidgetState(newRuleCreated)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }

      await getRuleByIdQuery({
        variables: {
          id,
          fetchPolicy: 'no-cache',
        },
      });

      void getStatusOfCron();
      handleSheetCloseOnCreate();
    } catch (error: any) {
      if ((error?.message as string).includes('checksum')) {
        setShowChecksumPopup({
          showPopup: true,
          metaData: { ruleId: id, ruleName, type: ruleType },
        });
      } else {
        showGraphQlErrorToast(error);
      }
    }
  };

  return {
    handleAfterRuleIsPublished,
  };
}
