import { gql, useMutation } from '@apollo/client';

const createWorkflowQuery = gql`
  mutation CreateWorkflow(
    $name: String!
    $description: String
    $nodes: [NodeInput!]
    $edges: [EdgeInput!]
    $isEnabled: Boolean
    $startNode: String
    $trigger: TriggerInput
    $staging: SettingsInput
    $production: SettingsInput
  ) {
    createWorkflow(
      input: {
        name: $name
        description: $description
        nodes: $nodes
        edges: $edges
        isEnabled: $isEnabled
        startNode: $startNode
        trigger: $trigger
        staging: $staging
        production: $production
      }
    ) {
      id
      name
      checksum
      staticUrl
      status
      isLive
      createdAt
    }
  }
`;

export function useCreateWorkflow() {
  return useMutation(createWorkflowQuery);
}
