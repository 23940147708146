import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { stopPropagate } from '../../../../../utils/form';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  workflowEdgesAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useGetDataset } from '../../../hooks/useGetDataset';
import { WorkflowNodeType } from '../../../hooks/useOpenWorkflow';
import { DelayNodeForm } from '../../../models/models';
import {
  checkIfNameExists,
  formatDelayInWorkflow,
  getAllPredecessorsSkippingType,
  transformDelayNodeData,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateDelayNode,
} from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { DelayMapping } from './DelayMapping/DelayMapping';
import { DelaySettings } from './DelaySettings';

type DelayNodeSheetProps = {
  data?: any;
  id?: string;
};

export function DelayNodeSheet({
  data: localData,
  id = '',
}: DelayNodeSheetProps) {
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodeSaving] = useAtom(workflowNodeSavingAtom);

  const [counter, setCounter] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );
  const [isSaving, setIsSaving] = useState(false);

  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);

  const { close } = useCurrentLayer();

  const { open: openSheetCloseModal } = useLayer(
    <RuleSheetCloseModal onClose={close} title="Close delay sheet" />
  );

  const [currentTab, setCurrentTab] = useState(0);

  const [, setIsTesting] = useState(false);

  useEffect(() => {
    const directParents = getAllPredecessorsSkippingType(
      id,
      workflowNodes,
      workflowEdges,
      'addNode'
    );

    setParentNodes(directParents);
  }, []);

  // eslint-disable-next-line
  const { updatedDataSet } = useGetDataset({ parentNodes });

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { control, clearErrors, watch, handleSubmit, setError, setValue } =
    useForm<DelayNodeForm>({
      defaultValues: {
        action: {
          label: 'Immediately',
          value: 'immed',
        },
        dateTime: new Date(),
        unit: null,
        amount: {
          value: 0,
        },
        settings: localData.settings ?? {
          timeout: 60,
        },
        name: localData.name,
      },
    });

  // eslint-disable-next-line
  const onSubmit = async (dt: DelayNodeForm, test: boolean = false) => {
    setIsSaving(true);
    clearErrors();
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    if (test) {
      setIsTesting(true);
    }

    const isNameValid = nodeNameValidationBeforeSave(dt.name, setError);

    if (!isNameValid) {
      setIsTesting(false);
      setIsSaving(false);

      return;
    }

    const isValid = validateDelayNode(dt, setError);

    if (!_isNil(workflowNode) && isValid) {
      const sheetData = transformDelayNodeData(dt);
      const newWorkflowNode = workflowNode;
      newWorkflowNode.data.status = currentStatus;

      if (
        dt.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          dt.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: dt.name,
        });
      }

      newWorkflowNode.data.name = sheetData.name;

      newWorkflowNode.data.settings = sheetData.settings;

      newWorkflowNode.data.input = {
        action: sheetData.action,
        unit: sheetData.unit,
        amount: sheetData.amount,
        timezone: {
          value: 'utc',
        },
        dateTime: sheetData.dateTime,
      };

      localData.onWorkflowNodeChange(newWorkflowNode);

      setTimeout(() => {
        setChangedNodeIds([id]);
      }, 100);

      close();
    }

    setIsSaving(false);

    return null;
  };

  useEffect(() => {
    const value = formatDelayInWorkflow(localData);

    setValue('action', value.action);
    setValue('name', value.name);
    setValue('settings', value.settings);
    setValue('unit', value.unit);
    setValue('amount', value.amount);
    setValue('dateTime', value.dateTime);
  }, []);

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const formValues = watch();

  useEffect(() => {
    setCounter((count) => count + 1);
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  const isLoading = workflowNodeSaving || isSaving;

  return (
    <Sheet size="small" onClose={counter > 2 ? openSheetCloseModal : close}>
      <WorkflowSheetFormStyled>
        <Inline stretch="start">
          <Stack as={PadBox} gutter={48} padding={[16, 24]}>
            <Inline stretch="start">
              <Stack gutter={8}>
                <Inline align="center" gutter="1.6rem" justify="start">
                  <Typography name="heading2">
                    <ExpandingTextField
                      control={control}
                      name="name"
                      disabled={isWorkflowReadOnly}
                    />
                  </Typography>
                </Inline>
              </Stack>
            </Inline>
          </Stack>
        </Inline>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PadBox padding="2rem">
                <DelayMapping
                  control={control}
                  setValue={setValue}
                  dataset={updatedDataSet}
                />
              </PadBox>
            </TabPanel>
            <TabPanel>
              <DelaySettings control={control} name="settings" />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <SheetFooterStyled>
          <Button
            disabled={isWorkflowReadOnly || isLoading}
            appearance="contained"
            onClick={handleSaveData}
          >
            {isLoading ? <Spinner size="extraSmall" /> : <Inline>Save</Inline>}
          </Button>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
