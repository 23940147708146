import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';
import { Pagination } from 'ui';

export type TableProps = {
  columns: Array<ColumnDef<any>>;
  data: Array<Record<string, any>>;
  totalPages: number;
  currentPage: number;
  onPageChange?: (pageSize: number, pageNumber: number) => void;
  rowsPerPage?: number;
};

export const Table = ({
  columns,
  data,
  totalPages,
  currentPage = 0,
  rowsPerPage = 5,
  onPageChange,
}: TableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: currentPage,
    pageSize: rowsPerPage,
  });

  const [state, setState] = useState(table.initialState);

  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState,
    manualPagination: true,
    pageCount: totalPages,
    onPaginationChange: setPagination,
  }));

  const isPaginationEnabled = typeof onPageChange === 'function';

  useEffect(() => {
    if (isPaginationEnabled) {
      onPageChange(pageSize, pageIndex);
    }
  }, [pageIndex, pageSize]);

  const { rows } = table.getRowModel();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
    estimateSize: useCallback((i: number) => 35, []),
  });
  const { virtualItems: virtualRows } = rowVirtualizer;

  return (
    <Stack gutter={15} ref={tableContainerRef}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.id === 'name' ? 2 : header.colSpan}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];

            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    colSpan={cell.column.columnDef.header === 'name' ? 2 : 1}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {isPaginationEnabled && (
        <Inline justify="end">
          <Pagination
            canNextPage={table.getCanNextPage()}
            canPreviousPage={table.getCanPreviousPage()}
            nextPage={table.nextPage}
            previousPage={table.previousPage}
            pageCount={table.getPageCount()}
            pageIndex={currentPage}
            goToPage={table.setPageIndex}
          />
        </Inline>
      )}
    </Stack>
  );
};
