import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { Control, useWatch } from 'react-hook-form';
import { NectedEditor, TextInput, TooltipReact, Typography } from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import {
  getTooltipText,
  handleChangeApiToWebhookUrl,
} from '../../../../../utils/common';
import { envMap } from '../../../../../utils/constant';
import { ruleEnvironmentAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { TriggerApi } from '../Trigger API/TriggerApi';
import { JsonContainerStyled } from './TriggerSheet.styled';

type WorkflowTriggerFormProps = {
  bodyExample: string;
  control?: Control<any>;
  nameByType: string;
  credentialKey: string;
  triggerUrl: string;
  value: any;
};

export function WorkflowTriggerForm({
  bodyExample,
  credentialKey,
  nameByType,
  control,
  triggerUrl,
  value,
}: WorkflowTriggerFormProps) {
  const [siteConstant] = useAtom(siteConstantsAtom);

  const [ruleSetEnvironment] = useAtom(ruleEnvironmentAtom);

  const execValue = useWatch({
    control,
    name: `${nameByType}.staticUrl`,
  });

  return (
    <>
      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Base URL</Typography>
          <TooltipReact id="baseurl-rule-id">
            {getTooltipText(siteConstant, 'rules', 'triggerBaseURL')}
          </TooltipReact>
        </Inline>

        <TextInput
          value={handleChangeApiToWebhookUrl(execValue, ruleSetEnvironment)}
          disabled
        />
      </Stack>

      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Body Params</Typography>
          <TooltipReact id="body-params-rule-id">
            {getTooltipText(siteConstant, 'rules', 'triggerBodyParams')}
          </TooltipReact>
        </Inline>

        <Typography>
          <span>
            There is no need to provide an environment; the default environment
            for testing is staging.
          </span>
        </Typography>

        <JsonContainerStyled>
          {bodyExample.length > 0 && (
            <NectedEditor
              mode="json"
              readOnly
              defaultValue={bodyExample}
              domain={envMap.VITE_EDITOR_URL}
            />
          )}
        </JsonContainerStyled>
      </Stack>

      <TriggerApi
        authType={_isNil(value) ? '' : value}
        credentialKey={credentialKey}
        triggerUrl={triggerUrl}
      />
    </>
  );
}
