import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { customAttributesAtom } from '../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  getTooltipText,
  handleSetCheckSumByEntityName,
  unionStringArrays,
} from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import type {
  RuleChainPayloadModel,
  RulePolicyDropdownModel,
  RuleSetNodesModel,
  RuleSetResponse,
} from '../components/RuleSet/models';
import type {
  ProductionConfigModel,
  StagingConfigModel,
  TriggerModelKeys,
} from '../components/SimpleRule/models';
import {
  approvalInfoRuleAtom,
  datasetDetailsInRuleAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
} from '../components/atom/atom';
import { isRuleReadOnlyAtom, ruleWarningsAtom } from '../index';
import {
  formatTriggersDataFromResponse,
  transformPayloadCustomInputToTableData,
} from '../utils/common';
import { useDiscardRuleSet } from './graphql/useDiscardRuleSet';
import { useGetRuleSetById } from './graphql/useGetRuleSetById';

type UsePrefillRuleSetProps = {
  isClone: boolean;
  isLive: boolean;
  ruleId?: string;
  commitId?: string;
  ruleIdExist?: boolean;
  isLiveNodeSheetClosed: boolean;
};

function getAuthType(
  editRuleData: RuleSetResponse,
  environment: TriggerModelKeys
) {
  return editRuleData[environment].api.isPrivate
    ? { label: 'Private', value: 'private' }
    : { label: 'None', value: 'none' };
}

function getRuleListFromRuleChain(
  startId: string,
  ruleChain: Record<string, RuleChainPayloadModel>
) {
  const ruleList: RuleSetNodesModel[] = [];
  getRuleDataRecursion(startId, ruleChain, ruleList);

  return ruleList;
}

function getRuleDataRecursion(
  ruleId: string,
  ruleChain: Record<string, RuleChainPayloadModel>,
  ruleList: RuleSetNodesModel[]
): void {
  if (!_isNil(ruleChain[ruleId])) {
    ruleList.push({
      ruleId,
      isEnabled: ruleChain[ruleId].isEnabled,
    });

    if (
      !_isNil(ruleChain[ruleId].nextRuleId) &&
      !_isEmpty(ruleChain[ruleId].nextRuleId)
    ) {
      getRuleDataRecursion(ruleChain[ruleId].nextRuleId, ruleChain, ruleList);
    }
  }
}

export function useEditRuleSet({
  ruleId,
  isClone,
  isLive,
  ruleIdExist = false,
  isLiveNodeSheetClosed = false,
  commitId,
}: UsePrefillRuleSetProps) {
  const [searchParams] = useSearchParams();

  const [getRuleById, { data: editRuleData, loading: ruleLoading }] =
    useGetRuleSetById();

  const [discardRuleById, { data: discardRule, error: discardError }] =
    useDiscardRuleSet();

  const [nodesStartId, setFirstRuleChain] = useState('');
  const [stagingConfig, setStagingConfig] = useState<StagingConfigModel>();
  const [isMounted, setIsMounted] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [dataClone, setDataClone] = useState(false);

  const [ruleList, setRuleList] = useState<RuleSetNodesModel[]>([]);
  const [ruleSetName, setRuleSetName] = useState('Untitled');
  const [ruleSetDescription, setRuleSetDescription] = useState('');
  const [ruleSetPolicy, setRuleSetPolicy] =
    useState<RulePolicyDropdownModel | null>(null);

  const [productionConfig, setProductionConfig] =
    useState<ProductionConfigModel>();

  const [currentRuleData, setCurrentRuleData] = useState<any>();

  const [, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setRuleWarnings] = useAtom(ruleWarningsAtom);
  const [, setCustomAttributes] = useAtom(customAttributesAtom);

  const [siteConstant] = useAtom(siteConstantsAtom);
  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);
  const [, setDatasetDetailsInRule] = useAtom(datasetDetailsInRuleAtom);

  const [ruleVersion, setRuleVersion] = useState('draft');

  const [sheetMode, setSheetMode] = useState('');

  const { isHide: editDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
  });

  const isEditSheetDisable = sheetMode === 'view' || editDisable;

  useEffect(() => {
    setDataClone(isClone);
  }, [isClone]);

  const handleGetRuleById = async () => {
    try {
      const filters: Record<string, any> = {};

      if (!_isNil(commitId) && !_isEmpty(commitId)) {
        filters.eq = { commitId };
      }

      const response = await getRuleById({
        variables: { id: ruleId, live: isLive, filters },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  const handleGetRuleAfterStateTransition = async () => {
    try {
      const response = await getRuleById({
        variables: { id: ruleId },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  useEffect(() => {
    setSheetMode(searchParams.get('type') ?? '');
  }, []);

  useEffect(() => {
    if (_isNil(ruleId)) {
      setFirstRuleChain('');
    } else if (ruleIdExist) {
      void handleGetRuleById();
    }
  }, [ruleId, isLive, ruleIdExist, isLiveNodeSheetClosed]);

  const initialize = (ruleData?: RuleSetResponse) => {
    if (!_isNil(ruleData)) {
      setLoadingData(true);
      const startRuleId = ruleData.firstRuleChain;
      setFirstRuleChain(startRuleId);

      handleSetCheckSumByEntityName('rule', ruleData.checksum);

      setRuleSetName(`${ruleData.name}${isClone ? '_copy' : ''}`);
      setRuleSetDescription(ruleData.description);

      setIsRuleLive(ruleData.isLive ?? false);
      setApprovalInfoRule(ruleData.approvalInfo);
      setVersionInfoRule(ruleData.versionInfo);
      setDatasetDetailsInRule(ruleData.datasetDetail);

      setCurrentRuleData({
        createdAt: ruleData.createdAt,
        publishedAt: ruleData.publishedAt,
        status: ruleData.status,
      });

      if (!_isNil(ruleData.version) && !dataClone) {
        setRuleVersion(ruleData.version);
      }

      if (!_isNil(ruleData.customInput)) {
        setCustomAttributes(
          transformPayloadCustomInputToTableData(ruleData.customInput)
        );
      }

      if (!_isNil(ruleData.production)) {
        const authType = getAuthType(ruleData, 'production');

        setProductionConfig(
          formatTriggersDataFromResponse(
            ruleData.production,
            ruleData.staticUrl,
            isClone,
            authType
          )
        );
      }

      if (!_isNil(ruleData.staging)) {
        const authType = getAuthType(ruleData, 'staging');

        setStagingConfig(
          formatTriggersDataFromResponse(
            ruleData.staging,
            ruleData.staticUrl,
            isClone,
            authType
          )
        );
      }

      if (!_isNil(ruleData.ruleChain)) {
        setRuleList(getRuleListFromRuleChain(startRuleId, ruleData.ruleChain));
      }

      if (!_isNil(ruleData.warnings)) {
        setRuleWarnings((prev) =>
          unionStringArrays(prev, ruleData.warnings ?? [])
        );
      }

      if (!_isNil(ruleData.ruleSetPolicy)) {
        setRuleSetPolicy({
          label: _startCase(ruleData.ruleSetPolicy),
          value: ruleData.ruleSetPolicy,
        });
      }

      if (dataClone) {
        setDataClone(false);
      }
    }

    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
  };

  useEffect(() => {
    if (isLiveNodeSheetClosed) {
      initialize(editRuleData?.getRule.data[0]);
    }
  }, [editRuleData, isLive, isLiveNodeSheetClosed]);

  useEffect(() => {
    if (!_isNil(ruleId) && !_isNil(discardRule)) {
      initialize(discardRule.discardRule);
    }
  }, [discardRule]);

  useEffect(() => {
    if (!_isNil(discardError)) {
      toasts.error(discardError.message, 'success');
    }
  }, [discardError]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      if (isLive && isMounted && !isEditSheetDisable) {
        setIsRuleReadOnly(false);
      }
    };
  }, [isMounted, isEditSheetDisable]);

  return {
    nodesStartId,
    stagingConfig,
    productionConfig,
    ruleList,
    ruleSetPolicy,
    ruleSetName,
    ruleSetDescription,
    discardRuleById,
    ruleLoading,
    ruleVersion,
    loadingData,
    currentRuleData,
    handleGetRuleAfterStateTransition,
  };
}
