import type { RoleDetails } from '../types';

export const ROLES_DETAILS: RoleDetails[] = [
  {
    description:
      'Admin are the users with the authority to create, edit, view, and delete permissions for all entities within the workspace.',
    label: 'Admin',
    value: 'admin',
  },
  {
    description:
      'All access like Editor plus permission to review/approve any changes across rules , data source & workflows within workspace.',
    label: 'Approver',
    value: 'approver',
  },
  {
    description:
      'Editor are the users with the authority to create, edit, view for all entities within the workspace except for integrations and workspace settings.',
    label: 'Editor',
    value: 'editor',
  },
  {
    description:
      'Viewer are the users with the authority to view for all entities within the workspace except for integrations, dataset, credentials, global variables and workspace settings.',
    label: 'Viewer',
    value: 'viewer',
  },
  {
    description:
      'Tech are the users with the authority to create, edit, view and delete for all entities within the workspace except for workspace settings.',
    label: 'Tech',
    value: 'tech',
  },
];

export const FILTER_ROLES: Array<Pick<RoleDetails, 'label' | 'value'>> =
  ROLES_DETAILS.map(({ label, value }) => ({ label, value }))
    .concat({ label: 'Owner', value: 'owner' })
    .sort(
      (role1, role2) => role1.label.charCodeAt(0) - role2.label.charCodeAt(0)
    );
