import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import type { Control } from 'react-hook-form';
import { CheckboxField, DropdownField, TextField, Typography } from 'ui';

import { timeToExpireUnits } from '../../../../DataSets/utils';
import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';

// import { timeToExpireUnits } from '../../../../DataSets/utils';

type ConnectorSettingsProps = {
  control?: Control<any>;
  name: string;
};

export function ConnectorSettings({ control, name }: ConnectorSettingsProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  return (
    <Stack as={PadBox} padding="2rem" gutter="1.6rem">
      <Inline gutter="1.6rem">
        <Typography fontWeight={700}>Timeout after (s)</Typography>

        <TextField
          type="number"
          size="small"
          control={control}
          name={`${name}.timeout`}
          showErrorIcon={false}
          disabled={isWorkflowReadOnly}
        />
      </Inline>

      <Inline gutter="1.6rem">
        <Typography fontWeight={700}>Row Limit</Typography>

        <TextField
          type="number"
          size="small"
          control={control}
          name={`${name}.rowLimit`}
          showErrorIcon={false}
          disabled={isWorkflowReadOnly}
        />
      </Inline>

      <Inline gutter="1.6rem" align="center">
        <Typography fontWeight={700}>Cache</Typography>
        <CheckboxField
          control={control}
          name={`${name}.cacheEnabled`}
          appearance="switch"
          disabled={isWorkflowReadOnly}
        />
      </Inline>

      <Inline gutter="1.6rem">
        <Inline gutter={8}>
          <Typography fontWeight={700}>Time to expire</Typography>
        </Inline>

        <TextField
          type="number"
          size="medium"
          control={control}
          name={`${name}.durationValue`}
          showErrorIcon={false}
          disabled={isWorkflowReadOnly}
        />
        <DropdownField
          control={control}
          name={`${name}.durationUnit`}
          options={timeToExpireUnits}
          disabled={isWorkflowReadOnly}
        />
      </Inline>
    </Stack>
  );
}
