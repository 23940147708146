import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import type { Attributes, Dataset, NectedSuggestionModel } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { CalenderIconPicker } from '../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { FieldByDataType } from '../../../../../components/FieldByDataType/FieldByDataType';
import {
  createResultDataset,
  formatNectedDate,
  isArrayNotPresent,
} from '../../../../../utils/common';
import {
  getEvaluatedExValueForResult,
  getRequiredKey,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ResultRhs } from '../../RestltRhs/ResultRhs';
import '../../SimpleRule/Results/DataParams';
import { ResultHeader } from './ResultHeader';

type RowResultSelectionProps = {
  control?: Control<any>;
  nodeName: string;
  dataType: string;
  setValue: UseFormSetValue<any>;
  resIndex: number;
  keyName: string;
  rowIndex: number;
  rowKey: string;
  currentKey: string;
  hideOptionalCustomAttributes: boolean;
  suggestions: string[];
  suggestionsObjs: NectedSuggestionModel[];
};

export function RowResultSelection({
  control,
  nodeName,
  dataType,
  setValue,
  resIndex,
  keyName,
  rowIndex,
  rowKey,
  currentKey,
  hideOptionalCustomAttributes,
  suggestions,
  suggestionsObjs,
}: RowResultSelectionProps) {
  const [dataset] = useAtom(dataSetParamsAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const rows = useWatch({ control, name: 'rows' });

  const results = useWatch({ name: 'results', control });

  const updatedDataset = useMemo(() => {
    const newDs = createResultDataset({ ...dataset });

    const suggestionName = 'resultData';

    if (
      !_isNil(newDs.dataSet) &&
      !_isNil(newDs.dataSet.attributes) &&
      _isNil(newDs.dataSet.attributes[suggestionName])
    ) {
      const suggestionPayload: Attributes = {
        name: suggestionName,
        dataType: 'list',
        executedValue: [
          Object.keys(newDs.dataSet.attributes).reduce(
            (acc: Record<string, any>, key: string) => {
              acc[key] = newDs.dataSet.attributes[key].executedValue;

              return acc;
            },
            {}
          ),
        ],
      };
      newDs.dataSet.attributes = {
        ...newDs.dataSet.attributes,
        [suggestionName]: {
          ...suggestionPayload,
        },
      };
    }

    const rowKey = getRequiredKey(rows[0], ['id']);

    const rowRes = rows[0][rowKey].ruleResult;

    const attributes = rowRes.reduce((acc: any, curr: any, i: number) => {
      const currKey = getRequiredKey(curr, ['id']);
      const resultKey = getRequiredKey(results[i], ['id']);

      if (i >= resIndex) {
        return acc;
      }

      const source = curr[currKey].source ?? null;
      const attribute = curr[currKey].attribute ?? null;
      let executedValue = curr[currKey].executedValue;

      if (
        _isNil(source) &&
        _isNil(attribute) &&
        !['jsFormula', 'json', 'excelFormula', 'list'].includes(
          curr[currKey].dataType ?? ''
        )
      ) {
        executedValue = getEvaluatedExValueForResult(
          curr[currKey].value,
          curr[currKey].dataType
        );
      }

      return {
        ...acc,
        [results[i][resultKey].keyName]: {
          // eslint-disable-next-line
          dataType: !!curr[currKey].returnType
            ? curr[currKey].returnType === 'number'
              ? 'numeric'
              : curr[currKey].returnType
            : curr[currKey].dataType,
          executedValue,
          name: results[i][resultKey].keyName,
        },
      };
    }, {});

    const outputData: Dataset = {
      name: 'Output Data',
      id: 'outputData',
      attributes,
    };

    return {
      ...newDs,
      outputData,
    };
  }, [
    JSON.stringify(results),
    JSON.stringify(rows[rowIndex][rowKey].ruleResult),
    JSON.stringify(dataset),
  ]);

  if (['jsFormula', 'excelFormula'].includes(dataType)) {
    return (
      <FieldByDataType
        name={`rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.value`}
        returnTypeName={`rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.returnType`}
        executedValueName={`rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.executedValue`}
        setValue={setValue}
        dataType={dataType}
        control={control}
        showError
        index={resIndex}
        section="thenDataParams"
        disabled={isRuleReadOnly}
        suggestions={suggestions}
        isSmallInput
        hideOptionalCustomAttributes={hideOptionalCustomAttributes}
        suggestionObjs={suggestionsObjs}
        dataSet={updatedDataset}
      />
    );
  }

  const currentResult = useWatch({
    control,
    name: `rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}`,
  });

  return (
    <ResultRhs
      control={control}
      nodeName={nodeName}
      dataType={dataType}
      setValue={setValue}
      keyName={keyName}
      isSmall
      typesToAllow={[dataType, 'generic']}
      allowList
      showTooltip={true}
      updatedDataSet={updatedDataset}
      readOnly={['list', 'json', 'date', 'dateTime'].includes(dataType)}
      options={{
        updateDataType: ['jsFormula', 'excelFormula'].includes(dataType),
      }}
      rightIcon={
        ['dateTime', 'date'].includes(dataType) ? (
          <CalenderIconPicker
            value={currentResult.value}
            dataType={dataType}
            disabled={['list', 'json'].includes(dataType)}
            onPick={(val) => {
              if (typeof setValue === 'function') {
                setValue(
                  `rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.value`,
                  formatNectedDate(val, dataType)
                );
                setValue(
                  `rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.attribute`,
                  null
                );
                setValue(
                  `rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.source`,
                  null
                );
              }
            }}
          />
        ) : undefined
      }
      header={
        ['list', 'json'].includes(dataType) ? (
          <ResultHeader
            dataSet={dataset}
            // eslint-disable-next-line
            isAdd={isArrayNotPresent(currentResult?.value)}
            nodeName={`rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}`}
            resIndex={resIndex}
            suggestionsObjs={suggestionsObjs}
            control={control}
            setOriginalValue={setValue}
            disabled={isRuleReadOnly}
            returnTypeName={`rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.returnType`}
            executedValueName={`rows.${rowIndex}.${rowKey}.ruleResult.${resIndex}.${currentKey}.executedValue`}
            dataType={dataType}
            editorType={dataType as unknown as 'list' | 'json'}
          />
        ) : undefined
      }
    />
  );
}
