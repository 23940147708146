import { gql, useMutation } from '@apollo/client';

import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updateActionMutation = gql`
  mutation updateRule(
    $id: String!
    $action: ActionInput
    $decisionTable: Map
    $checksum: String!
    $editMode: Boolean
  ) {
    updateRule(
      input: {
        id: $id
        action: $action
        decisionTable: $decisionTable
        checksum: $checksum
        editMode: $editMode
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateAction() {
  return useMutation<RuleUpdateResponseModel>(updateActionMutation);
}
