import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { NectedSuggestionModel, toasts } from 'ui';

import { HeaderFieldsArray } from '../../pages/Integrations/components/connector/form/fields/HeaderFieldsArray';
import { PluginTextField } from '../../pages/Integrations/components/connector/form/fields/PluginTextField';
import {
  convertObjecttoStringCSS,
  getAllVariablesFromExpression,
  handleSetCheckSumByEntityName,
} from '../../utils/common';
import { TextEditor } from './components/TextEditor';
import { WebAsyncDropDownField } from './components/WebAsyncDropdownField';
import { WebBooleanField } from './components/WebBooleanField';
import { WebButtonField } from './components/WebButtonField';
import { WebDropdownField } from './components/WebDropdownField';
import { WebGSheetColumnField } from './components/WebGSheetColumnField';
import { WebGSheetPickerField } from './components/WebGSheetPickerField';
import { useGetDependentEntityData } from './components/hook/useGetDepenedentEntityData';
import { CHECKSUM_MAPPING } from './constant';
import { FieldContainer } from './form.styled';
import type { FormFields } from './types';
import { extractKeyDataFromObject, parseExpression } from './utlis';

type FieldConfigWrapperProps = {
  field: FormFields;
  control: Control | undefined;
  isPermissionDisabled: boolean;
  formKeyPrefix: string;
  watch: UseFormWatch<Record<string, any>>;
  getDropdownOptions: (obj?: any) => Promise<void>;
  parentFormData: Record<string, any>;
  formJson: Record<string, any>;
  setValue?: UseFormSetValue<any>;
  newCustomSuggestions?: NectedSuggestionModel[];
  footerComponent?: (id: string) => JSX.Element | undefined;
  handleGetExecutionValues?: (data: Record<string, any>) => void;
  execValues?: Record<string, any>;
};

type FormComponentsProps = {
  type: string;
  api: boolean;
};

export const formComponents = ({ type, api }: FormComponentsProps) => {
  switch (type) {
    case 'dropdown':
      return api ? WebAsyncDropDownField : WebDropdownField;
    case 'map':
      return HeaderFieldsArray;
    case 'number':
    case 'password':
    case 'text':
    case 'url':
      return PluginTextField;
    case 'boolean':
    case 'ssl':
      return WebBooleanField;
    case 'textEditor':
      return TextEditor;
    case 'button':
      return WebButtonField;
    case 'googlePicker':
      return WebGSheetPickerField;
    case 'gSheetColumns':
      return WebGSheetColumnField;
    default:
      return null;
  }
};

type dependencyConfigType = {
  visible: string[];
  required: string[];
  disabled: string[];
};

export function FieldConfigWrapper({
  field,
  isPermissionDisabled,
  watch,
  control,
  footerComponent,
  getDropdownOptions = async () => {},
  parentFormData,
  formJson,
  setValue,
  formKeyPrefix,
  newCustomSuggestions,
  handleGetExecutionValues,
  execValues,
}: FieldConfigWrapperProps) {
  const {
    key,
    type,
    placeholder,
    regex,
    width = '100%',
    height,
    styles,
    selectionType,
    api = false,
    hidden,
    isDisabled,
    required = false,
    isRequired,
    dependencies = [],
    ...rest
  } = field;
  const [config, setConfig] = useState({
    isVisible: false,
    isDisabled: true,
    isRequired: required,
  });

  const [dependencyConfig, setDependencyConfig] =
    useState<dependencyConfigType>({
      visible: [],
      required: [],
      disabled: [],
    });

  const formData = watch();

  const [dependentEntityDataQuery] = useGetDependentEntityData(
    dependencies.length > 0 ? dependencies[0] : ''
  );

  useEffect(() => {
    if (!_isNil(formData[key]) && !_isEmpty(formData[key])) {
      void makeGraphQLRequest();
    }
  }, [formData[key]]);

  useEffect(() => {
    if (!_isNil(formData) && !_isEmpty(formData)) {
      // Check if there is any change in variables used to show Component when formData is updated
      const visibleVariableList = getAllVariablesFromExpression(hidden);

      const updatedVisibleVariableList = visibleVariableList.map((variable) => {
        // variable (key) can be dot separated, will handle this use case later
        if (variable in parentFormData) {
          return parentFormData[variable];
        }

        return extractKeyDataFromObject(formData, variable);
      });

      const updateVisibleConfig =
        JSON.stringify(updatedVisibleVariableList) !==
        JSON.stringify(dependencyConfig.visible);

      // Check if there is any change in variables used to check if Component is mandatory to fill when formData is updated
      const requiredVariableList = getAllVariablesFromExpression(isRequired);

      const updatedRequiredVariableList = requiredVariableList.map(
        (variable) => {
          // variable (key) can be dot separated, will handle this use case later

          if (variable in parentFormData) {
            return parentFormData[variable];
          }

          return extractKeyDataFromObject(formData, variable);
        }
      );

      const updateRequiedConfig =
        JSON.stringify(updatedRequiredVariableList) !==
        JSON.stringify(dependencyConfig.required);

      // Check if there is any change in variables used to check if Component is Disabled to fill when formData is updated
      const disableVariableList = getAllVariablesFromExpression(isDisabled);

      const updatedDisabledVariableList = disableVariableList.map(
        (variable) => {
          // variable (key) can be dot separated, will handle this use case later

          if (variable in parentFormData) {
            return parentFormData[variable];
          }

          return extractKeyDataFromObject(formData, variable);
        }
      );

      const updateDisableConfig =
        JSON.stringify(updatedDisabledVariableList) !==
        JSON.stringify(dependencyConfig.disabled);

      if (updateVisibleConfig || updateRequiedConfig || updateDisableConfig) {
        setDependencyConfig({
          ...dependencyConfig,
          visible: updatedVisibleVariableList,
          required: updatedRequiredVariableList,
          disabled: updatedDisabledVariableList,
        });
      }
    }
  }, [JSON.stringify(formData), JSON.stringify(parentFormData)]);

  useEffect(() => {
    // If there is change in dependency config of visible variables, then calulate the updated component visibility.
    const isComponentHidden = parseExpression(formData, parentFormData, hidden);

    // If there is change in dependency config of required variables, then calulate the updated component is mandatory to fill or not.
    const isComponentMandatory: boolean = parseExpression(
      formData,
      parentFormData,
      isRequired
    );

    // If there is change in dependency config of disable variable list, then calulate the updated component is disabled or not.
    const isComponentDisabled = parseExpression(
      formData,
      parentFormData,
      isDisabled
    );

    setConfig({
      ...config,
      isVisible: !(isComponentHidden as boolean),
      isRequired: required || isComponentMandatory,
      isDisabled: isComponentDisabled,
    });
  }, [
    dependencyConfig.visible,
    dependencyConfig.required,
    dependencyConfig.disabled,
  ]);

  const makeGraphQLRequest = async () => {
    // get data of dependent entitied and then update checksum in session storage.
    // will implement for n number of dependencies later on

    try {
      if (dependencies.length > 0) {
        const payload = {
          id: formData[key],
        };

        const response = await dependentEntityDataQuery({
          variables: payload,
          fetchPolicy: 'no-cache',
        });

        if (!_isNil(response.data)) {
          const dependentEntityData = response.data[dependencies[0]].data;

          if (!_isNil(dependentEntityData) && dependentEntityData.length > 0) {
            const checksumValue = dependentEntityData[0]?.checksum ?? undefined;

            const checksumObj = CHECKSUM_MAPPING[dependencies[0]];

            if (!_isNil(checksumObj) && !checksumObj.sendChecksum) {
              handleSetCheckSumByEntityName(checksumObj.module, checksumValue);
            }
          }
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        toasts.error(error.message, 'error');
      }
    }
  };
  const Component = formComponents({ type, api });

  if (_isNil(Component) || !config.isVisible) {
    return null;
  }

  return (
    <FieldContainer
      key={key}
      width={`calc(${width} - 0.4rem)`}
      height={height}
      styles={convertObjecttoStringCSS(styles)}
    >
      <Component
        control={control}
        name={key}
        placeholder={placeholder}
        regex={new RegExp(regex)}
        disabled={isPermissionDisabled || config.isDisabled}
        formKeyPrefix={formKeyPrefix}
        type={type}
        footer={
          typeof footerComponent === 'function'
            ? footerComponent(key)
            : undefined
        }
        isMulti={selectionType === 'multi'}
        required={config.isRequired}
        customSuggestions={newCustomSuggestions}
        newCustomSuggestions={newCustomSuggestions}
        getDropdownOptions={getDropdownOptions}
        parentFormData={parentFormData}
        formJson={formJson}
        setValue={setValue}
        handleGetExecutionValues={handleGetExecutionValues}
        execValues={execValues}
        {...rest}
      />
    </FieldContainer>
  );
}
