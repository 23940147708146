import { atom } from 'jotai';
import type { Edge } from 'reactflow';

import type {
  ApprovalInfoType,
  PanelType,
  VersionInfoType,
} from '../../../types';
import type { WorkflowNodeType } from '../hooks/useOpenWorkflow';
import type {
  TriggerInputTypeById,
  WorkflowErrorByNode,
  WorkflowNodeStatus,
} from '../models/models';

export const workflowEnvironmentAtom = atom<'staging' | 'production'>(
  'staging'
);

export const workflowNodesAtom = atom<WorkflowNodeType[]>([]);
export const oldWorkflowNodesAtom = atom<WorkflowNodeType[]>([]);
export const workflowEdgesAtom = atom<Array<Edge<any>>>([]);
export const inputParamsByTriggerIdAtom = atom<TriggerInputTypeById>({});
export const workflowIdAtom = atom<string | null>(null);
export const workflowStatusAtom = atom<
  'draft' | 'published' | 'tested' | 'inreview'
>('draft');
export const workflowStaticUrlAtom = atom<string>('');

export const workflowStatusByNode = atom<WorkflowNodeStatus>({});

export const workflowErrorByNodeAtom = atom<WorkflowErrorByNode>({});

export const changedNodeIdsAtom = atom<string[]>([]);
export const isWorkflowReadOnlyAtom = atom(false);
export const isWorkflowTestOnlyAtom = atom(false);

export const workflowNodeSavingAtom = atom<boolean>(true);
export const isWorkflowLiveAtom = atom<boolean>(false);

export const approvalInfoWorkflowAtom = atom<ApprovalInfoType | undefined>(
  undefined
);

export const versionInfoWorkflowAtom = atom<VersionInfoType | undefined>(
  undefined
);

export const activePanelWorkflowAtom = atom<PanelType | undefined>(undefined);
export const vcListTabIndexWorkflowAtom = atom<number>(0);

export const ruleListWorkflowAtom = atom<any[]>([]);
