import { PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const CreateRuleContainer = styled(PadBox)`
  height: 100vh;

  .srTabListStyle {
    border-block-end: none;
  }
`;
