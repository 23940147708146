import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { Button, PopoverMethods, PopoverPanel, Typography } from 'ui';

import { openSchedularAtom } from '../TriggerForm';
import { SchedulerForm } from './SchedulerForm';

type SchedulerProps = {
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  launcher?: ReactElement;
  cronIndex?: number;
};

export function Scheduler({
  control,
  setValue,
  launcher,
  cronIndex = 0,
}: SchedulerProps) {
  const ref = useRef<PopoverMethods>(null);
  const [isVisible, setIsVisible] = useState(false);

  const [autoOpenScheduler, setAutoOpenScheduler] = useAtom(openSchedularAtom);

  const { fieldState } = useController({
    name: `productionConfig.schedule`,
    control,
  });

  useEffect(() => {
    if (autoOpenScheduler) {
      ref.current?.show();
      setAutoOpenScheduler(false);
    }
  }, [ref.current, autoOpenScheduler]);

  return (
    <Stack>
      <PopoverPanel
        arrow
        padding="1.6rem"
        trigger="click"
        launcher={
          launcher != null ? (
            <Stack>
              {launcher}
              {!_isNil(fieldState.error) &&
                !_isNil(fieldState.error?.message) && (
                  <Typography name="error">
                    {fieldState.error.message}
                  </Typography>
                )}
            </Stack>
          ) : (
            <Stack>
              <Button appearance="filled">Create Schedule</Button>
              {!_isNil(fieldState.error) &&
                !_isNil(fieldState.error?.message) && (
                  <Typography name="error">
                    {fieldState.error.message}
                  </Typography>
                )}
            </Stack>
          )
        }
        ref={ref}
        setIsTippyVisible={setIsVisible}
      >
        <SchedulerForm
          index={cronIndex}
          control={control}
          onClose={() => {
            ref.current?.hide();
          }}
          setValue={setValue}
          isVisible={isVisible}
        />
      </PopoverPanel>
    </Stack>
  );
}
