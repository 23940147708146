import { gql, useLazyQuery } from '@apollo/client';

import { TableDataItemModel } from '../../components/RulesTable/models';

type filtersType = {
  in: Record<string, any>;
  eq: Record<string, any>;
  nin: Record<string, any>;
  ne: Record<string, any>;
  gt: Record<string, any>;
  gte: Record<string, any>;
  lt: Record<string, any>;
  lte: Record<string, any>;
};

const getTableDataQuery = gql`
  query getRule(
    $page: Int
    $perPage: Int
    $sort: Map
    $filters: QueryOperators
  ) {
    getRule(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      data {
        id
        name
        version
        status
        isLive
        createdAt
        createdBy
        publishedAt
        publishedBy
        updatedAt
        updatedBy
        workspaceId
        type
        checksum
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

export type TableResponseModel = {
  getRule: {
    data: TableDataItemModel[];
    paginationInfo: {
      perPage: number;
      totalRecord: number;
      totalPage: number;
    };
  };
};

export type TablePayloadVariables = {
  page: number;
  perPage: number;
  live?: boolean;
  sort?: Record<string, any>;
  filters?: filtersType;
};

export function useGetTableData() {
  return useLazyQuery<TableResponseModel, TablePayloadVariables>(
    getTableDataQuery
  );
}
