import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const DiscardButtonContainer = styled(PadBox)`
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 2rem;
  max-width: max-content;
`;

export const SyntaxErrorContainer = styled(PadBox)`
  min-block-size: 5rem;
  background-color: var(--color-snow);
  border-radius: 4px;
  color: var(--color-fireEngineRed);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ActionButtonsContainer = styled(PadBox)`
  border-block-start: 1px solid var(--color-darkGray);
  border-block-end: 1px solid var(--color-darkGray);
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: white;
`;

export const RuleSaving = styled(PadBox)`
  color: var(--color-charcoal);
`;

export const MissingLiveButtonContainer = styled.span`
  flex-basis: 11.7rem;
`;
