import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ChangeEvent, Ref, forwardRef, useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  useController,
  useWatch,
} from 'react-hook-form';
import { BiExpandAlt } from 'react-icons/bi';
import {
  Dataset,
  ExecutedValueTooltip,
  TextInputInModal,
  Typography,
  useLayer,
} from 'ui';

import {
  convertCaSampleValues,
  formatNectedDate,
  getPropertyIfExists,
  isFieldReadOnly,
} from '../../../../utils/common';
import { LIST_KEY_REGEX } from '../../../../utils/regex';
import type { sendEventToGTMType } from '../../types';
import { getOutputPlaceholder } from '../../utils/common';
import { ErrorPopoverPositioned } from '../SimpleRule/Error/ErrorPopoverPositioned';
import type { ResultConditionType } from './ResultRhs';
import {
  ExpandIconContainer,
  InputBrackets,
  InputContainer,
  InputStyled,
  RightIconContainer,
} from './ResultRhs.styled';

type RhsLauncherProps = {
  nodeName: string;
  panelVisible: boolean;
  text: string | number;
  dataType: string;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  nodeType?: string;
  isSmall?: boolean;
  isFull?: boolean;
  type: ResultConditionType;
  disabled: boolean;
  onlyJson?: boolean;
  showTooltip?: boolean;
  dataset?: Record<string, Dataset>;
  showExpandIconIfList?: boolean;
  sourceKey?: string;
  attributeKey?: string;
  nodeTypeName?: string;
  rightIcon?: any;
  forceExpandListIcon?: boolean;
  readOnly?: boolean;
};

export const RhsLauncher = forwardRef(
  (
    {
      text,
      nodeName,
      panelVisible = false,
      dataType,
      handleSendEventToGTM,
      control,
      setValue,
      nodeType,
      isSmall = false,
      isFull = false,
      type = '',
      disabled,
      onlyJson = false,
      showTooltip = false,
      dataset = {},
      showExpandIconIfList = true,
      sourceKey = 'source',
      attributeKey = 'attribute',
      nodeTypeName,
      rightIcon,
      forceExpandListIcon = false,
      readOnly = false,
    }: RhsLauncherProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [nType, setNodeType] = useState(nodeType);
    const [value, setLocalValue] = useState<string | number>(text);
    const [showTooltipLocal, setShowTooltipLocal] = useState(
      showTooltip && nodeType !== 'constant'
    );

    const { openWithProps: openTextInputModal } = useLayer(
      <TextInputInModal value={value} />
    );

    const { fieldState } = useController({
      name: `${nodeName}.value`,
      control,
    });

    const nodeVal = useWatch({
      control,
      name: nodeName,
    });

    const { source, attribute } = nodeVal;

    const tooltipMessage =
      !_isNil(source) && !_isEmpty(source) && !_isNil(dataset[source])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[source].attributes).reduce((acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      dataset[source ?? ''].attributes[`${curr}`].executedValue,
                  };
                }, {})
              )
            ) ?? {},
            attribute ?? ''
          )
        : attribute;

    const handleClick = () => {
      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: _isEmpty(text) ? 'add' : 'edit',
          element: 'rhs_value',
          actionName: dataType ?? '',
        });
      }
    };

    useEffect(() => {
      setLocalValue(text.toString());
    }, [text]);

    useEffect(() => {
      setNodeType(nodeType);
    }, [nodeType]);

    useEffect(() => {
      setShowTooltipLocal(showTooltip && nType !== 'constant');
    }, [nType]);

    const error = fieldState.error?.message;

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const val = e.target.value.toString();
      // eslint-disable-next-line
      const isList = !!val.match(LIST_KEY_REGEX)?.length;
      setLocalValue(e.target.value);
      const localNodeType = isList ? 'token' : 'constant';

      setNodeType(localNodeType);

      setShowTooltipLocal(false);
    };

    const handleOnBlur = () => {
      if (nType === 'constant' && !panelVisible) {
        if (typeof setValue === 'function') {
          setValue(`${nodeName}.value`, value);
          setValue(
            `${nodeName}.executedValue`,
            convertCaSampleValues(dataType, value)
          );
          setValue(`${nodeName}.source`, null);
          setValue(`${nodeName}.attribute`, null);
        }
      } else if (nType !== 'constant') {
        if (type === 'workflowDT' || type === '') {
          if (LIST_KEY_REGEX.test(value.toString())) {
            const values = value.toString().split('.');

            if (typeof setValue === 'function') {
              setValue(`${nodeName}.value`, null);
              setValue(`${nodeName}.source`, values[0]);
              setValue(
                `${nodeName}.attribute`,
                values.filter((x, i) => i !== 0).join('.')
              );
            }
          }
        }
      }

      setShowTooltipLocal(showTooltip && nType !== 'constant');
    };

    const showExpandIcon = showExpandIconIfList
      ? nType === 'constant' && dataType === 'list'
      : false;

    const formatValue = (input: any) => {
      if (dataType === 'date' && nType === 'constant') {
        try {
          return formatNectedDate(input, 'date');
        } catch (err) {
          return input;
        }
      }

      if (dataType === 'dateTime' && nType === 'constant') {
        try {
          return formatNectedDate(input, 'dateTime');
        } catch (err) {
          return input;
        }
      }

      return input;
    };

    return (
      <InputContainer align="center" gutter={0} onClick={handleClick}>
        {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

        {nType !== 'constant' && (
          <InputBrackets direction="left">
            <Typography>{'{{'}</Typography>
          </InputBrackets>
        )}

        <ExecutedValueTooltip
          attribute={attribute}
          value={tooltipMessage}
          dataType={dataType}
          id={nodeName}
          placement="top-end"
          isVisible={showTooltipLocal}
        >
          <InputStyled
            ref={ref}
            value={formatValue(value)}
            $isSmall={isSmall}
            $hasError={!_isNil(error)}
            $showExpandIcon={showExpandIcon}
            onChange={onInputChange}
            onBlur={handleOnBlur}
            placeholder={getOutputPlaceholder(dataType)}
            $isFull={isFull}
            disabled={disabled}
            readOnly={readOnly || isFieldReadOnly(dataType)}
          />
        </ExecutedValueTooltip>

        {nType !== 'constant' && (
          <InputBrackets direction="right" $showExpandIcon={showExpandIcon}>
            <Typography>{'}}'}</Typography>
          </InputBrackets>
        )}

        {forceExpandListIcon && (
          <ExpandIconContainer
            onClick={() => {
              openTextInputModal({
                onChange: onInputChange,
                value,
                disabled,
              });
            }}
          >
            <BiExpandAlt size={12} />
          </ExpandIconContainer>
        )}

        {!_isNil(rightIcon) && (
          <RightIconContainer>{rightIcon}</RightIconContainer>
        )}
      </InputContainer>
    );
  }
);

RhsLauncher.displayName = 'RhsLauncher';
