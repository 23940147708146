import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ChangeEvent, Ref, forwardRef, useState } from 'react';
import { BsCloudUpload } from 'react-icons/bs';
import { FaRegFileAlt } from 'react-icons/fa';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { MdOutlineUploadFile } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';

import { Button, IconButton } from '../../Button';
import { Spinner } from '../../Spinner';
import { Typography } from '../../Typography';
import {
  FileBoxStyled,
  FileContainerStyled,
  FileItem,
  FileStyledHeader,
  NoFileBoxStyled,
  SupportedFileText,
} from './FileUpload.styled';

export type FileUploadProps = {
  name: string;
  value?: string[] | string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFileUpload?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile?: (value: string) => void;
  disabled?: boolean;
  size?: string;
  type?: string;
  label?: string;
  accept?: string;
  showError?: boolean;
  hasError?: boolean;
  multiple?: boolean;
  isLoading?: boolean;
  entityName?: string;
  fileProgress?: number;
};

export const FileUpload = forwardRef(
  (
    {
      value,
      type = 'file',
      accept = '.json',
      name,
      onChange,
      label,
      size = 'medium',
      disabled = false,
      multiple = true,
      onFileUpload,
      handleDeleteFile,
      isLoading = false,
      entityName,
      fileProgress,
    }: FileUploadProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [isExpanded, setIsExpanded] = useState(_isNil(entityName));

    const hasUploadImage =
      !_isNil(value) &&
      (Array.isArray(value) ? value.length > 0 : !_isEmpty(value));

    return (
      <Stack style={{ alignItems: 'center' }}>
        {!hasUploadImage && (
          <NoFileBoxStyled gutter="1rem">
            <BsCloudUpload size={44} color="var(--color-aliceBlue)" />
            <SupportedFileText>{`Supported File types: ${accept}`}</SupportedFileText>
            <Button
              leadingIcon={
                <MdOutlineUploadFile color="var(--color-white)" size={18} />
              }
            >
              Upload file
            </Button>
            <input
              disabled={disabled}
              type={type}
              multiple={multiple}
              accept={accept}
              onInput={onFileUpload}
            />
          </NoFileBoxStyled>
        )}
        {hasUploadImage && (
          <FileBoxStyled gutter="1rem">
            {!_isNil(fileProgress) && (
              <Stack style={{ alignItems: 'center' }}>
                <Typography
                  name="heading1"
                  fontWeight={700}
                >{`${fileProgress}%`}</Typography>
                <progress value={fileProgress} max="100"></progress>
              </Stack>
            )}

            <FileContainerStyled gutter="0rem">
              {!_isNil(entityName) && (
                <FileStyledHeader align="center">
                  <Typography>
                    {`Uploaded ${
                      entityName[0].toUpperCase() + entityName.substring(1)
                    }`}
                  </Typography>
                  <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? (
                      <IoMdArrowDropdown />
                    ) : (
                      <IoMdArrowDropright />
                    )}
                  </IconButton>
                </FileStyledHeader>
              )}
              {(isExpanded || _isNil(entityName)) && (
                <>
                  {Array.isArray(value) &&
                    value?.map((file, index) => {
                      const fileName = file.split('\\').slice(-1)[0];

                      return (
                        <FileItem key={index}>
                          <Inline align="center" justify="center" gutter="1rem">
                            <FaRegFileAlt />
                            <Typography>{fileName}</Typography>
                          </Inline>
                          <IconButton
                            onClick={() => {
                              if (typeof handleDeleteFile === 'function') {
                                handleDeleteFile(file);
                              }
                            }}
                          >
                            <RxCross2 size={12} />
                          </IconButton>
                        </FileItem>
                      );
                    })}

                  {!Array.isArray(value) && (
                    <FileItem>
                      <Inline align="center" justify="center" gutter="1rem">
                        <FaRegFileAlt />
                        <Typography>
                          {value.split('\\').slice(-1)[0]}
                        </Typography>
                      </Inline>
                      <IconButton
                        onClick={() => {
                          if (typeof handleDeleteFile === 'function') {
                            handleDeleteFile(value);
                          }
                        }}
                      >
                        <RxCross2 size={12} />
                      </IconButton>
                    </FileItem>
                  )}
                </>
              )}
            </FileContainerStyled>
          </FileBoxStyled>
        )}
        {isLoading && <Spinner />}
      </Stack>
    );
  }
);

FileUpload.displayName = 'FileUpload';
