import { gql, useMutation } from '@apollo/client';

const updateNameDescMutation = gql`
  mutation UpdateWorkflow(
    $id: String!
    $name: String!
    $description: String
    $checksum: String!
    $editMode: Boolean
  ) {
    updateWorkflow(
      input: {
        id: $id
        name: $name
        description: $description
        checksum: $checksum
        editMode: $editMode
      }
    ) {
      id
      name
      checksum
      status
      staticUrl
      isLive
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateNameDesc() {
  return useMutation(updateNameDescMutation);
}
