import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import type {
  Control,
  UseFormGetValues,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { TextButton, TextField } from 'ui';

import { PermissionWrapper } from '../../../components/PermissionComponent';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import {
  formatNectedDate,
  handleSetCheckSumByEntityName,
} from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { dataTypes } from '../constant';
import type {
  AddConfigValueType,
  GetRemoteConfigResult,
  RemoteConfigFieldsDataTypes,
  SavedConfigModel,
} from '../models';
import type { RemoteConfigFormValues } from './RemoteConfig';
import { FieldContainer, StackStyled } from './RemoteConfig.styled';
import { RemoteConfigDeleteButton } from './RemoteConfigDeleteButton';
import { RemoteConfigField } from './form/RemoteConfigComponents';

type HandleUpdateConfig = {
  id: string;
  value: AddConfigValueType;
  checksum: string;
};

type TableDataProps = {
  control: Control<RemoteConfigFormValues>;
  getValues: UseFormGetValues<RemoteConfigFormValues>;
  trigger: UseFormTrigger<RemoteConfigFormValues>;
  savedConfigList: GetRemoteConfigResult;
  watch: UseFormWatch<Record<string, any>>;
  handleUpdateConfig: ({ id, value, checksum }: HandleUpdateConfig) => void;
  handleDeleteConfig: (id: string, checksum: string) => void;
  isLoading: boolean;
};

export function TableData({
  savedConfigList,
  control,
  handleUpdateConfig,
  handleDeleteConfig,
  getValues,
  watch,
  trigger,
  isLoading,
}: TableDataProps) {
  const { isHide: disabledValueField } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.remoteConfig],
  });
  const [savedConfigs, setSavedConfigs] = useState<SavedConfigModel[]>([]);

  useEffect(() => {
    if (
      !_isNil(savedConfigList) &&
      savedConfigList.savedConfig.data.length > 0
    ) {
      const updatedConfigList = structuredClone(
        savedConfigList.savedConfig.data
      );
      // updatedConfigList.map((i) => {
      //   if (i.dataType === 'list') {
      //     i.value = convertArrayToString(i.value as any[]);
      //   }

      //   return i;
      // });
      setSavedConfigs(updatedConfigList);
    }
  }, [savedConfigList]);

  const formData = watch();

  const handleUpdate = async (
    index: number,
    id: string,
    dataType: RemoteConfigFieldsDataTypes,
    checksum: string
  ) => {
    let fieldValue = formData.savedConfig.data[index].value;
    handleSetCheckSumByEntityName('remote-config', checksum);

    if (dataType === dataTypes.numeric && !_isEmpty(fieldValue)) {
      fieldValue = parseInt(fieldValue as string);
    }

    if (dataType === dataTypes.date && !_isNil(fieldValue)) {
      fieldValue = formatNectedDate(fieldValue as Date, 'date');
    }

    if (dataType === dataTypes.dateTime && !_isNil(fieldValue)) {
      fieldValue = formatNectedDate(fieldValue as Date, 'dateTime');
    }

    const requestValues = {
      id,
      value: fieldValue,
      checksum,
    };

    if (
      dataType === dataTypes.boolean ||
      dataType === dataTypes.dateTime ||
      dataType === dataTypes.date
    ) {
      handleUpdateConfig({
        ...requestValues,
      });
    } else if (dataType === dataTypes.json) {
      handleUpdateConfig({
        ...requestValues,
        value: JSON.parse(requestValues.value),
      });
    } else if (
      (dataType === dataTypes.numeric || dataType === dataTypes.string) &&
      !_isUndefined(fieldValue) &&
      !_isEmpty(fieldValue.toString())
    ) {
      handleUpdateConfig({
        ...requestValues,
      });
    } else if (dataType === dataTypes.list) {
      handleUpdateConfig({
        ...requestValues,
        value: JSON.parse(requestValues.value),
      });
    } else {
      await trigger(`savedConfig.data.${index}.value`);
    }
  };

  return (
    <StackStyled gutter="1.6rem">
      {savedConfigs.map(({ id, name, value, dataType, checksum }, index) => {
        return (
          <Inline as="li" key={id} align="start">
            <FieldContainer>
              <TextField
                control={control}
                name={`savedConfig.data.${index}.name`}
                defaultValue={name}
                disabled
              />
            </FieldContainer>

            <FieldContainer>
              <RemoteConfigField
                type={dataType}
                control={control}
                name={`savedConfig.data.${index}.value`}
                value={value}
                disabled={disabledValueField}
              />
            </FieldContainer>

            <Inline as={FieldContainer} align="center">
              <PermissionWrapper
                allowedPermission={[permissionObj.delete]}
                entityList={[ENTITY_ID.remoteConfig]}
              >
                <RemoteConfigDeleteButton
                  title="Delete Attribute"
                  deleteConfig={(id) => handleDeleteConfig(id, checksum)}
                  deletingIndex={id}
                />
              </PermissionWrapper>

              <PermissionWrapper
                allowedPermission={[permissionObj.create, permissionObj.edit]}
                entityList={[ENTITY_ID.remoteConfig]}
              >
                <TextButton
                  disabled={isLoading}
                  onClick={async () =>
                    await handleUpdate(index, id, dataType, checksum)
                  }
                >
                  Update
                </TextButton>
              </PermissionWrapper>
            </Inline>
          </Inline>
        );
      })}
    </StackStyled>
  );
}
