import styled from 'styled-components';

export const ProfileActionButton = styled.button`
  all: unset;
  border: transparent;
  text-align: left;
  background: var(--color-white);
  cursor: pointer;
  padding: 0.3rem 0.7rem;

  &:hover {
    background: var(--color-whiteSmoke);
  }

  &.btn-disabled-interceptor {
    background: var(--color-white) !important;
    color: var(--color-black) !important;
    cursor: progress !important;
  }
`;

export const InviteButton = styled.button`
  background: var(--color-white);
  border-radius: 6px;
  border: 1px solid var(--color-whiteSmoke);
  padding: 0.5rem 1rem;
  cursor: pointer;

  &.btn-disabled-interceptor {
    background: var(--color-white) !important;
    color: var(--color-black) !important;
    cursor: progress !important;
  }
`;
