import { PadBox } from '@bedrock-layout/padbox';
import type { CellContext } from '@tanstack/react-table';
import type { AxiosError } from 'axios';
import _isNil from 'lodash/isNil';
import { useMemo } from 'react';
import type { RefObject } from 'react';
import { OutputPanel, ToolTip, Typography } from 'ui';

import { TableContainer } from '../../../components/table/TableContainer';
import { getResponseErrorMessage } from '../../../utils/common';
import type { CustomAxiosError } from '../../../utils/response/types';
import type {
  ColumnValues,
  TestDataSetOutputValues,
} from '../hooks/useTestDataSet';
import { getCellValue, nullAsStringCellValue } from '../utils';
import { OutputResultContainer } from './DataSetForm.styled';

export type DataSetTestOutputResultProps = {
  data?: TestDataSetOutputValues;
  error?: AxiosError<CustomAxiosError>;
  outputRef: RefObject<HTMLDivElement>;
};

export function DataSetTestOutputResult({
  data,
  error,
  outputRef,
}: DataSetTestOutputResultProps) {
  const { fields = {}, rows = [], executionTime = '' } = data ?? {};

  const columns = useMemo(() => {
    return Object.keys(fields)
      .sort((a, b) => fields[a].order - fields[b].order)
      .map((key) => {
        return {
          header: key,
          accessorKey: key,
          cell: (props: CellContext<ColumnValues, any>) => {
            const { cellValue, tooltipValue } = getCellValue(
              props.row.original[key]
            );

            return (
              <ToolTip message={tooltipValue} placement="right" size="large">
                {cellValue === nullAsStringCellValue ? (
                  <Typography element="i">{cellValue}</Typography>
                ) : (
                  <Typography>{cellValue}</Typography>
                )}
              </ToolTip>
            );
          },
        };
      });
  }, [fields]);

  const isSuccess = !_isNil(data);

  return (
    <OutputPanel
      executionTime={executionTime}
      headingName="Output"
      isSuccess={isSuccess}
      outputRef={outputRef}
    >
      <OutputResultContainer>
        {!_isNil(data) ? (
          <TableContainer
            columns={columns}
            currentPage={1}
            tableData={rows}
            totalPages={1}
            isMarginReqired={false}
          />
        ) : (
          <PadBox padding={12}>
            <Typography>
              {_isNil(error) ? '' : getResponseErrorMessage(error)}
            </Typography>
          </PadBox>
        )}
      </OutputResultContainer>
    </OutputPanel>
  );
}
