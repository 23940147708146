import * as Sentry from '@sentry/react';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useLayer } from 'ui';
import { createUUID } from 'ui/src/Layer/utils';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { axiosPrivate } from './api/axios';
import {
  allWorkspaceAtom,
  checksumWarningAtom,
  enableGetAllWorkspaceApiAtom,
  enablePermissionApiAtom,
  isFetchedUserStateAtom,
  siteConstantsAtom,
  subscriptionPlanAtom,
  userProfileAtom,
} from './atom';
import { ChecksumWarning } from './components/ChecksumWarning';
import { FAQMenu } from './components/FAQMenu';
import {
  AuthenticatedAlready,
  AuthenticationRequired,
  RememberMe,
  useAuth,
} from './components/authentication';
import { Authentication } from './components/layouts/Authentication';
import { CoveredLogo } from './components/layouts/CoveredLogo';
import { Dashboard } from './components/layouts/Dashboard';
import { getUserState } from './hooks/getUserState';
import { useAllWorkspaceData } from './hooks/useAllWorkspaceData';
import { useFeatureFlag } from './hooks/useFeatureFlag';
import { useLogEvent } from './hooks/useLogEvent';
import { useProfile } from './hooks/useProfile';
import { useSendEventToGTM } from './hooks/useSendEventToGTM';
import { EmailNotVerified } from './pages/EmailNotVerified/EmailNotVerified';
import { isUerAllowedToAccessProtectedRoutes } from './pages/EmailNotVerified/utils';
import { Error404 } from './pages/Errors/Error404';
import { ForgotPassword } from './pages/ForgotPassword';
import { Home } from './pages/Home/components/Home';
import { OAuth2Redirect } from './pages/OAuth2Redirect';
import { Questionnaire } from './pages/Questionnaire';
import { ResetPassword } from './pages/ResetPassword';
import { Signin } from './pages/Signin';
import { Signup } from './pages/Signup';
import { SwitchToDesktop } from './pages/SwitchToDesktop';
import { VerifyEmail } from './pages/VerifyEmail/components/VerifyEmail';
import { currentWorkspaceDetailAtom } from './pages/Workspace/atom';
import { VerifyWorkspaceInvite } from './pages/Workspace/component/verifyWsInvite';
import { ComponentRoleWrapper } from './routes';
import { ALL_ROUTE } from './routes/constant';
import type { SubscriptionPlanType, WorkSpaceDetailsModel } from './types';
import {
  CookieConsentConfig,
  constructEventPayload,
  generateCaptchaToken,
  handleGetConstantData,
  installClarity,
  installGA,
  installGTM,
} from './utils/common';
import { envMap } from './utils/constant';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
// todo : will shift to env after signoff from product - @subhanshu
const GA_MID = envMap.VITE_GA_MID;
const CLARITY_ID = envMap.VITE_CLARITY_ID;
const GTM_ID = envMap.VITE_GTM_ID;
const isCookieConsentEnabled = envMap.VITE_COOKIE_CONSENT_ENABLED === 'true';

function App() {
  const location = useLocation();
  const currentRoutePath = location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const paramsWsid = urlParams.get('wsid');

  if (!_isNil(paramsWsid) && !_isEmpty(paramsWsid)) {
    window.sessionStorage.setItem('workspaceUUID', paramsWsid);
  }
  const { logEvent } = useLogEvent();
  const [siteConstants, setSiteConstants] = useAtom(siteConstantsAtom);
  const [, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [allWorkspaceList, setAllWorkspaceAtom] = useAtom(allWorkspaceAtom);
  const [checksumWarningData] = useAtom(checksumWarningAtom);
  const [enablePermissionApi, setEnablePermisisonApi] = useAtom(
    enablePermissionApiAtom
  );
  const { openWithProps: openChecksumWarning } = useLayer(
    <ChecksumWarning redirectTo="" />
  );
  const [, setCurrWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const [, setIsFetchedUserState] = useAtom(isFetchedUserStateAtom);
  const [enableAllWorkspaceApi, setEnableAllWorkspaceApi] = useAtom(
    enableGetAllWorkspaceApiAtom
  );

  const { sendEventToGTM } = useSendEventToGTM();

  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  const { auth, isLoggedIn } = useAuth();

  const { data, isFetching } = useProfile(
    (_isEmpty(userProfile) || _isNil(userProfile)) &&
      !_isEmpty(auth.accessToken)
  );

  const { data: allWorkspaceData, isFetching: isWorkspaceDataFetching } =
    useAllWorkspaceData(enableAllWorkspaceApi);
  const isEmailVerified = isUerAllowedToAccessProtectedRoutes(userProfile);

  useFeatureFlag({ email, enablePermissionApi });

  useEffect(() => {
    /* eslint-disable */
    if (_isNil(window.localStorage.getItem('nec_fid'))) {
      window.localStorage.setItem('nec_fid', createUUID());
    }
    if (envMap.VITE_ENVIRONMENT === 'production') {
      if (!_isNil(CLARITY_ID) && !_isEmpty(CLARITY_ID)) {
        installClarity(CLARITY_ID);
      }
      if (!_isNil(GTM_ID) && !_isEmpty(GTM_ID)) {
        installGTM(GTM_ID);
      }
      sendEventToGTM({
        event: 'site_access',
      });
      if (!_isNil(GA_MID) && !_isEmpty(GA_MID)) {
        installGA(GA_MID);
      }
    }
    if (isCookieConsentEnabled) {
      void CookieConsentConfig();
    }
  }, []);

  const getAccountUsageDetails = async () => {
    const response = await axiosPrivate.get<{
      data: SubscriptionPlanType;
    }>(`/plan/usage`);
    if (!_isNil(response.data.data)) {
      setSubscriptionPlan({
        ...response.data.data,
        planName: response.data.data.plan.name,
      });
    }
  };

  const logAppVisitor = () => {
    const eventName = 'app_visitor';
    const entity = 'views';
    const entityId = '';
    const appVisitorCookie = Cookies.get(eventName);
    const payload = {
      value: 'visited',
    };
    const apiPayload = constructEventPayload(
      eventName,
      'internal',
      entity,
      entityId,
      payload
    );

    if (
      _isNil(appVisitorCookie) &&
      _isEmpty(appVisitorCookie) &&
      appVisitorCookie !== 'visited'
    ) {
      generateCaptchaToken(logEvent, apiPayload);
    }
  };

  useEffect(() => {
    const envEmailConfig: string[] = envMap.VITE_ADMIN_EMAIL_CONFIG.split(',');

    if (!_isEmpty(email)) {
      void getAccountUsageDetails();

      if (
        envEmailConfig.some((currEmail) => currEmail === email.toLowerCase())
      ) {
        setEnablePermisisonApi(false);
      } else {
        setEnablePermisisonApi(true);
      }

      void (async () => {
        const result = await getUserState();
        setIsFetchedUserState(result);
      })();

      sendEventToGTM({
        event: 'config',
        trackingId: GA_MID,
        user_id: window.localStorage.getItem('userUUID'),
        email,
        createdAt: userProfile?.createdAt ?? '',
      });

      sendEventToGTM({
        event: 'userdata',
        userid: window.localStorage.getItem('userUUID'),
        user_id: window.localStorage.getItem('userUUID'),
        email,
        createdAt: userProfile?.createdAt ?? '',
      });

      setTimeout(() => {
        if (!_isNil(window.clarity) && !_isEmpty(window.clarity)) {
          window.clarity('set', 'userId', email);
        }
      }, 2000);

      logAppVisitor();
    }
  }, [email]);

  useEffect(() => {
    if (_isNil(allWorkspaceList) && isLoggedIn) {
      setEnableAllWorkspaceApi(true);
    } else {
      setEnableAllWorkspaceApi(false);
    }
  }, [allWorkspaceList, isLoggedIn]);

  useEffect(() => {
    if (!isFetching && !_isNil(data)) {
      setUserProfile(data);
    }
  }, [isFetching, data]);

  const id = window.sessionStorage.getItem('workspaceUUID');

  useEffect(() => {
    if (
      !isWorkspaceDataFetching &&
      !_isNil(allWorkspaceData) &&
      !_isEmpty(allWorkspaceData)
    ) {
      const allWorkspaceList = _reduce(
        allWorkspaceData,
        (accum: WorkSpaceDetailsModel[], currWorkspace) => {
          return [...accum, currWorkspace];
        },
        []
      );

      setAllWorkspaceAtom(allWorkspaceList);

      const currWorkspace = allWorkspaceList.find((workspace) =>
        !_isEmpty(id) ? workspace.uuid === id : workspace.isDefault
      );

      const defaultWorkspace = allWorkspaceList.find(
        (workspace) => workspace.isDefault
      );

      setCurrWorkspace(currWorkspace ?? null);

      if (!_isNil(paramsWsid) && !_isEmpty(paramsWsid)) {
        window.sessionStorage.setItem('workspaceUUID', paramsWsid);
      } else {
        window.sessionStorage.setItem(
          'workspaceUUID',
          currWorkspace?.uuid ?? ''
        );
      }

      window.localStorage.setItem(
        'workspaceUUID',
        defaultWorkspace?.uuid ?? ''
      );
    }
  }, [isWorkspaceDataFetching, JSON.stringify(allWorkspaceData)]);

  useEffect(() => {
    if (!_isNil(location.pathname)) {
      handleGetConstantData(
        location.pathname.split('/')[1],
        setSiteConstants,
        siteConstants
      );
    }
  }, [location]);

  useEffect(() => {
    if (checksumWarningData.showPopup) {
      openChecksumWarning({
        redirectTo: location.pathname.split('/')[1],
      });
    }
  }, [checksumWarningData]);

  return (
    <>
      <SentryRoutes>
        <Route element={<Authentication />}>
          <Route path="/verify/signup/email" element={<VerifyEmail />} />
        </Route>
        <Route
          path="/verify/workspace/invitation/:inviteToken"
          element={<VerifyWorkspaceInvite />}
        />
        <Route element={<AuthenticatedAlready />}>
          <Route element={<Authentication />}>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
          </Route>

          <Route element={<CoveredLogo />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Route>

        <Route element={<RememberMe />}>
          <Route element={<AuthenticationRequired />}>
            <Route element={<Authentication />}>
              <Route path="/switch-to-desktop" element={<SwitchToDesktop />} />
            </Route>

            <Route element={<CoveredLogo />}>
              <Route path="/questionnaire" element={<Questionnaire />} />
            </Route>

            <Route path="/:type/redirect" element={<OAuth2Redirect />} />

            <Route element={<Dashboard />}>
              <Route
                index
                element={ComponentRoleWrapper(
                  Home,
                  'home',
                  isEmailVerified,
                  currentRoutePath
                )}
              />

              <Route
                path="/email-not-verified"
                element={<EmailNotVerified />}
              />

              {ALL_ROUTE.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  element={ComponentRoleWrapper(
                    route.element,
                    route.id,
                    isEmailVerified,
                    currentRoutePath
                  )}
                />
              ))}
            </Route>
          </Route>
        </Route>
      </SentryRoutes>
      <FAQMenu />
    </>
  );
}

export default Sentry.withProfiler(App);
