import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const InputContainer = styled(Inline)<{ $hasError: boolean }>`
  position: relative;
`;

export const InputStyled = styled.input<{
  $hasError: boolean;
  $isLarge?: boolean;
  $showExpandIcon?: boolean;
}>`
  padding: 0.2rem 1.8rem;
  max-inline-size: 14rem;
  outline: none;
  border: none;
  border-radius: 4px;
  text-overflow: ellipsis;

  ${({ $showExpandIcon = false }) =>
    $showExpandIcon && 'padding-right: 3.8rem;'}

  ${({ $isLarge = false }) => $isLarge && 'max-inline-size: 22rem;'}

  outline: ${({ $hasError }) =>
    `1px solid ${
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-white)'
    }`};

  background-color: ${({ $hasError }) =>
    $hasError ? 'var( --color-snow)' : 'var(--color-solitudeSecondary)'};
`;

export const InputBrackets = styled.div<{
  direction: 'left' | 'right';
  $showExpandIcon?: boolean;
}>`
  position: absolute;
  ${({ direction = 'left', $showExpandIcon = false }) =>
    direction === 'left'
      ? 'left: 0.5rem;'
      : `right: ${$showExpandIcon ? '3rem' : ' 0.5rem'}`};
  color: var(--color-lightGray);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ExpandIconContainer = styled.div`
  inline-size: 1.8rem;
  block-size: 1.8rem;
  background-color: var(--color-gainsboro);
  border-radius: 0.4rem;
  position: absolute;
  inset-inline-end: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoundIcon = styled.button`
  border-radius: 0.4rem;
  padding: 0.15rem 0.1rem 0.1rem 0.1rem;
  background-color: var(--color-lightGray2);
  border: none;
  cursor: pointer;
  line-height: 1;
  height: 2.8rem;
  width: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
