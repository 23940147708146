import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import _transform from 'lodash/transform';
import { UseFormSetError } from 'react-hook-form';

import {
  evaluateExpression,
  getAllVariablesFromExpression,
  removeCurlyBracesFromExpression,
  removeDoubleDollarFromExpression,
} from '../../utils/common';
import {
  CURLY_BRACES_PRESENT_REGEX,
  DOUBLE_DOLLAR_PRESENT_REGEX,
} from '../../utils/regex';
import { KEY } from './constant';
import { FormFields, MapType, Option } from './types';

// data is nth level nested object, key is dot separated keys representing nth level nested object
export const extractKeyDataFromObject = (data: any, key: string) => {
  if (_isEmpty(key)) {
    return null;
  }

  const keysList = key.split('.');

  for (const currentKey of keysList) {
    if (_isNil(data[currentKey])) {
      return null;
    } else {
      data = data[currentKey];
    }
  }

  return data;
};

export const setDataAtKeyInNestedObject = (
  data: any,
  key: string,
  value: any
) => {
  if (_isEmpty(key)) {
    return data;
  }

  let schema = data;
  const keysList = key.split('.');

  for (let i = 0; i < keysList.length - 1; i++) {
    const currentKey = keysList[i];

    if (_isNil(schema[currentKey])) {
      return data;
    } else {
      schema = schema[currentKey];
    }
  }

  const lastKey = keysList[keysList.length - 1];
  schema[lastKey] = value;

  return data;
};

// Parse string expression and update variables with values present in formData
export const parseExpression = (
  formData: Record<string, any>,
  parentFormData: Record<string, any>,
  expression?: string,
  formKeyPrefix: string = ''
) => {
  const valueList: any = [];

  if (_isUndefined(expression)) return false;

  const variableList = getAllVariablesFromExpression(expression);

  variableList.forEach((value) => {
    if (value in parentFormData) {
      // value(key) can be dot seperated, will handle this case later
      valueList.push(extractKeyDataFromObject(formData, value));
    } else {
      valueList.push(extractKeyDataFromObject(formData, formKeyPrefix + value));
    }
  });
  let updatedExpression = removeCurlyBracesFromExpression(expression);

  variableList.forEach((variable: string, index) => {
    const value = valueList[index];
    updatedExpression = updatedExpression.replaceAll(
      variable,
      typeof value === 'object'
        ? `'${(value?.value ?? '') as string}'`
        : typeof value === 'boolean'
        ? `${value as unknown as string}`
        : `'${value as string}'`
    );
  });

  return evaluateExpression(updatedExpression);
};

function getTransformedParamsKeys(paramKeys: FormFields) {
  const clonedParamKeys = { ...paramKeys };
  const { type, defaultValue, options, value, api = false } = clonedParamKeys;

  if (type === 'map') {
    const { keyDef } = paramKeys;
    const { regex } = keyDef ?? {};

    if (!_isNil(regex)) {
      clonedParamKeys.regex = regex;
    }
  } else if (type === 'boolean') {
    if (_isNil(value)) {
      clonedParamKeys.value = defaultValue === 'true';
    } else {
      clonedParamKeys.value = value;
    }
  } else if (type === 'dropdown') {
    if (api) {
      clonedParamKeys.value = defaultValue;
    } else {
      const defaultValueObj = {
        label: options?.find((option: Option) => option.value === defaultValue)
          ?.label,
        value: defaultValue,
      };

      clonedParamKeys.value = defaultValueObj;
    }
  } else if (type === 'multi-select') {
    const defaultValueObj =
      options?.filter(
        (option: Option) =>
          value?.find(
            (currentValue: string) => currentValue === option.value
          ) ?? false
      ) ?? [];

    clonedParamKeys.value = defaultValueObj;
  } else if (type === 'text') {
    clonedParamKeys.value = defaultValue;
  }

  if (type === 'text') {
    clonedParamKeys.value = defaultValue;
  }

  return clonedParamKeys;
}

export function getTransformedSortedFormFields(
  params: Record<string, any>,
  keySuffix = ''
) {
  const transformedParams = _transform(
    params,
    (result: Record<string, FormFields>, paramKeys, key) => {
      const fieldKeysCloned = getTransformedParamsKeys({
        ...paramKeys,
        key: keySuffix + key,
        order: paramKeys.order ?? 1,
      });

      result[keySuffix + key] = {
        ...fieldKeysCloned,
      };

      return result;
    }
  );

  return _sortBy(transformedParams, 'order');
}

export function convertFieldArrayValueToMap(arrayData?: MapType[]) {
  if (_isNil(arrayData) || !Array.isArray(arrayData)) {
    return undefined;
  }

  return arrayData;
}

export function parseFormData(
  formConfig: FormFields[],
  formValues: Record<string, any>
) {
  const formValuesClone = structuredClone(formValues);

  formConfig.forEach((config) => {
    const { type, key } = config;

    if (type === 'number') {
      formValuesClone[key] = parseInt(formValues[key], 10);
    } else if (type === 'map') {
      formValuesClone[key] = convertFieldArrayValueToMap(formValues[key]);
    } else if (type === 'dropdown') {
      if (_isNil(formValuesClone[key])) {
        formValuesClone[key] = '';

        return;
      }

      formValuesClone[key] = formValuesClone[key].value;
    } else if (type === 'multi-select') {
      if (_isNil(formValuesClone[key])) {
        formValuesClone[key] = [];

        return;
      }

      formValuesClone[key] = formValuesClone[key].map(
        (valueObject: MapType) => valueObject.value
      );
    }
  });

  return formValuesClone;
}

export const getColumnValueBasedOnDataType = (
  columnData: Record<string, any>,
  key: string,
  currColumnValue: string | number
) => {
  const dataType = columnData[key].dataType;

  if (dataType === 'numeric') {
    if (typeof currColumnValue === 'number') {
      return currColumnValue;
    }

    // Explicitly returning undefined in case of null or empty string to ignore key while sending data to API
    return _isNil(currColumnValue) || currColumnValue === ''
      ? undefined
      : currColumnValue.includes('{{')
      ? currColumnValue
      : +currColumnValue;
  }

  if (_isNil(currColumnValue) || currColumnValue === '') {
    return undefined;
  }

  return currColumnValue;
};

export function parseFormDataForNestedData(
  formConfig: FormFields[],
  formValues: Record<string, any>
) {
  let formValuesClone = structuredClone(formValues);

  formConfig.forEach((config) => {
    const { type, key, api = false, sendValueAsObject = false } = config;

    const value = extractKeyDataFromObject(formValues, key);

    if (type === 'number') {
      formValuesClone = setDataAtKeyInNestedObject(
        formValuesClone,
        key,
        parseInt(value, 10)
      );
    } else if (type === 'map') {
      formValuesClone = setDataAtKeyInNestedObject(
        formValuesClone,
        key,
        convertFieldArrayValueToMap(value)
      );
    } else if (type === 'dropdown') {
      if (_isNil(value)) {
        formValuesClone = setDataAtKeyInNestedObject(formValuesClone, key, '');

        return;
      }

      formValuesClone = setDataAtKeyInNestedObject(
        formValuesClone,
        key,
        api || sendValueAsObject ? value : value.value
      );
    } else if (type === 'multi-select') {
      if (_isNil(value)) {
        formValuesClone = setDataAtKeyInNestedObject(formValuesClone, key, []);

        return;
      }

      const valueToSet = value.map((valueObject: MapType) => valueObject.value);
      formValuesClone = setDataAtKeyInNestedObject(
        formValuesClone,
        key,
        valueToSet
      );
    } else if (type === 'gSheetColumns') {
      const data =
        extractKeyDataFromObject(formValues, `action.config.data`) ?? [];

      const valueToSet = data.map((rowData: Record<string, any>) => {
        return _reduce(
          rowData,
          (columnData, currColumnValue, key) => {
            return {
              ...columnData,
              [key]: getColumnValueBasedOnDataType(value, key, currColumnValue),
            };
          },
          {}
        );
      });

      formValuesClone = setDataAtKeyInNestedObject(
        formValuesClone,
        `action.config.data`,
        valueToSet
      );
    }
  });

  return formValuesClone;
}

export const getUpdatedFormConfigBasedOnApiData = (
  formConfig: FormFields[],
  apiData: Record<string, any>
) => {
  const updatedFormFields = formConfig.map((field) => {
    const { key, type, options } = field;

    if (type === 'dropdown') {
      const filteredValue =
        options?.filter((option) => option.value === apiData.value[key]) ?? [];

      field.value = filteredValue.length > 0 ? filteredValue[0] : {};
    } else {
      field.value = apiData.value[key];
    }

    return field;
  });

  return updatedFormFields;
};

export const convertBodyParams = (
  bodyParams: Record<string, any>,
  fetchParent: Record<string, any>,
  formValues: Record<string, any>,
  formJson: Record<string, any>,
  parentFormData: Record<string, any>,
  formKeyPrefix = ''
) => {
  const updatedBodyParams = _reduce(
    bodyParams,
    (acc: Record<string, any>, currentValue, key) => {
      if (typeof currentValue === 'string') {
        if (CURLY_BRACES_PRESENT_REGEX.test(currentValue)) {
          //  curly braces pattern is used to check for dynamic keys and find its value from object
          const variable = removeCurlyBracesFromExpression(currentValue);

          // Check if current key exists in fetchParent and its value is true
          // then updated body params with data from parent form data
          // else update body params with data present in form Values

          if (
            !_isEmpty(fetchParent) &&
            key in fetchParent &&
            (fetchParent[key] as boolean)
          ) {
            acc[key] = parentFormData[variable];
          } else {
            // variable key is dot separated and assuming the value before first dot is actual key of formJson
            // Also formKeyPrefix is used if the formValues have nested data
            const fieldValue = getValueBasedOnFieldType(
              {
                ...formJson[variable.split('.')[0]],
                key: formKeyPrefix + variable,
              },
              false,
              formValues
            );
            acc[key] = fieldValue ?? null;
          }
        } else if (DOUBLE_DOLLAR_PRESENT_REGEX.test(currentValue)) {
          //  double dollar pattern is used to check for dynamic keys and find its value from object
          // In this case the actual value to fill will be key value + current timestamp

          const variable = removeDoubleDollarFromExpression(currentValue);

          // Check if current key exists in fetchParent and its value is true
          // then updated body params with data from parent form data
          // else update body params with data present in form Values

          if (
            !_isEmpty(fetchParent) &&
            key in fetchParent &&
            (fetchParent[key] as boolean)
          ) {
            acc[key] =
              (parentFormData[variable] as string) +
              new Date().getTime().toString();
          } else {
            const fieldValue = getValueBasedOnFieldType(
              { ...formJson[variable], key: variable },
              false,
              formValues
            );
            acc[key] =
              ((fieldValue ?? null) as string) +
              new Date().getTime().toString();
          }
        } else {
          acc[key] = currentValue;
        }
      } else if (typeof currentValue === 'boolean') {
        acc[key] = currentValue;
      } else if (typeof currentValue === 'object') {
        const updatedCurrentValue = convertBodyParams(
          currentValue,
          fetchParent,
          formValues,
          formJson,
          parentFormData,
          formKeyPrefix
        );

        acc[key] = updatedCurrentValue;
      }

      return acc;
    },
    {}
  );

  return updatedBodyParams;
};

export const getValueBasedOnFieldType = (
  field: Record<string, any>,
  isReset = true,
  formValues?: Record<string, any>
) => {
  if (_isNil(field)) {
    return null;
  }

  const {
    type,
    selectionType = 'single',
    key,
    sendValueAsObject = false,
  } = field;

  if (type === 'boolean') {
    return !_isUndefined(formValues) && !isReset
      ? extractKeyDataFromObject(formValues, key)
      : false;
  } else if (type === 'dropdown') {
    if (selectionType === 'single') {
      return !_isUndefined(formValues) && !isReset
        ? (sendValueAsObject as boolean)
          ? extractKeyDataFromObject(formValues, key)
          : extractKeyDataFromObject(formValues, key)?.value
        : {};
    } else if (selectionType === 'multi') {
      return [];
    }
  } else if (type === 'googlePicker') {
    if (selectionType === 'single') {
      return !_isUndefined(formValues) && !isReset
        ? extractKeyDataFromObject(formValues, key)
        : {};
    }
  }

  // Assuming text type
  return !_isUndefined(formValues) && !isReset
    ? extractKeyDataFromObject(formValues, key)
    : '';
};

export const getInitialFormValuesBasedOnFields = (
  formConfig: Array<Record<string, any>>
) => {
  const defaultData: Record<string, any> = {};

  formConfig.forEach((field) => {
    const { key, value } = field;

    defaultData[key] = value;
  });

  return defaultData;
};

export const getUpdatedApiDataBasedOnFormConfigForNestedObject = (
  formConfig: FormFields[],
  apiData: Record<string, any>
) => {
  let updatedApiData = structuredClone(apiData);

  formConfig.forEach((field) => {
    const {
      key,
      type,
      options,
      api = false,
      sendValueAsObject = false,
    } = field;

    const valueFromApi = extractKeyDataFromObject(apiData, key);

    if (type === 'dropdown') {
      if (api) {
        updatedApiData = setDataAtKeyInNestedObject(
          updatedApiData,
          key,
          valueFromApi
        );
      } else {
        const filteredValue = sendValueAsObject
          ? valueFromApi
          : options?.find((option) => option.value === valueFromApi);

        updatedApiData = setDataAtKeyInNestedObject(
          updatedApiData,
          key,
          !_isUndefined(filteredValue) ? filteredValue : {}
        );
      }
    } else {
      updatedApiData = setDataAtKeyInNestedObject(
        updatedApiData,
        key,
        valueFromApi
      );
    }

    return field;
  });

  return updatedApiData;
};

// Used to Validate the form values before sending the data to API.
export const validateFormData = (
  formValues: Record<string, any>,
  formFields: FormFields[],
  setError: UseFormSetError<Record<string, any>>,
  parentFormData: Record<string, any> = {}
) => {
  let isFormValid = true;
  const errorMessage = 'This field is required';

  formFields.forEach((field) => {
    const {
      type,
      key,
      required: isFieldRequired = false,
      isRequired,
      hidden,
    } = field;
    const formValueAgainstAKey = extractKeyDataFromObject(formValues, key);

    const isFieldHidden = parseExpression(formValues, parentFormData, hidden);
    const required: boolean =
      isFieldRequired ||
      parseExpression(formValues, parentFormData, isRequired);

    if (!(isFieldHidden as boolean)) {
      if (
        required &&
        (type === 'text' || type === 'password') &&
        (_isEmpty(formValueAgainstAKey) || _isNil(formValueAgainstAKey))
      ) {
        isFormValid = false;
        setError(key, {
          type: 'empty',
          message: errorMessage,
        });
      } else if (
        required &&
        type === 'number' &&
        ((typeof formValueAgainstAKey === 'number' &&
          isNaN(formValueAgainstAKey)) ||
          (typeof formValueAgainstAKey === 'string' &&
            _isEmpty(formValueAgainstAKey)) ||
          _isNil(formValueAgainstAKey))
      ) {
        isFormValid = false;
        setError(key, {
          type: 'empty',
          message: errorMessage,
        });
      } else if (type === 'map') {
        const mapTypeValues: MapType[] = formValueAgainstAKey;

        if (!_isNil(mapTypeValues) && Array.isArray(mapTypeValues)) {
          mapTypeValues.forEach((keys, index) => {
            const keyName = `${key}.${index}.${KEY}`;

            if (_isEmpty(keys.key)) {
              isFormValid = false;
              setError(keyName, {
                type: 'empty',
                message: errorMessage,
              });
            }
          });
        }
      } else if (
        required &&
        type === 'dropdown' &&
        (_isEmpty(formValueAgainstAKey) ||
          _isNil(formValueAgainstAKey) ||
          _isNil(formValueAgainstAKey.value))
      ) {
        isFormValid = false;
        setError(key, {
          type: 'empty',
          message: errorMessage,
        });
      } else if (
        required &&
        type === 'googlePicker' &&
        (_isEmpty(formValueAgainstAKey) || _isNil(formValueAgainstAKey))
      ) {
        isFormValid = false;
        setError(key, {
          type: 'empty',
          message: errorMessage,
        });
      }
    }
  });

  return isFormValid;
};

export const filterDataBasedOnFormFields = (
  formValues: Record<string, any>,
  formFields: FormFields[],
  parentFormData: Record<string, any> = {}
) => {
  let updatedFormValues: Record<string, any> = {};

  formFields.forEach((field) => {
    const isHidden = parseExpression(formValues, parentFormData, field.hidden);

    if (!(isHidden as boolean)) {
      updatedFormValues = {
        ...updatedFormValues,
        [field.key]: formValues[field.key],
      };
    }
  });

  return updatedFormValues;
};
