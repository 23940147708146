import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import _truncate from 'lodash/truncate';
import type { Attributes, NectedSuggestionModel } from 'ui';

import { generateUid } from '../../utils/common';
import { MONGO_ACTION_REGEX, MONGO_QUERY_REGEX } from '../../utils/regex';
import type { Plugin } from '../Integrations/types';
import type { GetDataSetSchemas } from './hooks/useGetDataSetSchema';
import { ColumnValues } from './hooks/useTestDataSet';
import type {
  CellValue,
  CreateDataSetFormValues,
  DataSetPublishedStatus,
  DataSetSchema,
  DatabaseName,
  DurationUnit,
  DurationUnitValue,
  EditorConfigurationDetails,
  SchemasValue,
  SettingsValues,
  TableColumnValues,
} from './types';

export const timeToExpireUnits: DurationUnit[] = [
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Hours',
    value: 'h',
  },
  {
    label: 'Minutes',
    value: 'm',
  },
];

const languageDetails: Record<DatabaseName, EditorConfigurationDetails> = {
  mysql: {
    databaseName: 'mysql',
    databaseLabel: 'MySQL',
  },
  sqlserver: {
    databaseName: 'sqlserver',
    databaseLabel: 'MS SQL Server',
  },
  mongodb: {
    databaseName: 'mongodb',
    databaseLabel: 'MongoDB',
  },
  pgsql: {
    databaseName: 'pgsql',
    databaseLabel: 'PostgreSQL',
  },
  redshift: {
    databaseName: 'pgsql',
    databaseLabel: 'PostgreSQL',
  },
  snowflake: {
    databaseName: 'snowflake',
    databaseLabel: 'Snowflake',
  },
  oracle: {
    databaseName: 'oracle',
    databaseLabel: 'OracleDB',
  },
  gsheet: {
    databaseName: 'gsheet',
    databaseLabel: 'G-Sheet',
  },
};

export function getEditorDetailsByPlugin(
  plugin?: Plugin
): EditorConfigurationDetails | null {
  const { name: pluginName = '' } = plugin ?? {};

  switch (pluginName) {
    case 'mysql':
      return languageDetails.mysql;
    case 'sqlserver':
      return languageDetails.sqlserver;
    case 'mongodb':
      return languageDetails.mongodb;
    case 'postgres':
      return languageDetails.pgsql;
    case 'gsheet':
      return languageDetails.gsheet;
    case 'redshift':
      return languageDetails.redshift;
    case 'snowflake':
      return languageDetails.snowflake;
    case 'oracle':
      return languageDetails.oracle;
    default:
      return null;
  }
}

const settingsFormInitialValues: SettingsValues = {
  cacheEnabled: true,
  durationUnit: timeToExpireUnits[2],
  durationValue: 5,
  rowLimit: 1000,
};

export const dataSetInitialFormValues: CreateDataSetFormValues = {
  name: 'Untitled',
  query: '',
  stagingSettings: settingsFormInitialValues,
  productionSettings: settingsFormInitialValues,
  params: {},
  editable: true,
  status: 'draft',
};

export const mongoSuggestions = [
  'aggregate',
  'count',
  'find',
  'findOne',
  'limit',
  'skip',
  '"$addToSet"',
  '"$all"',
  '"$allElementsTrue"',
  '"$and"',
  '"$bit"',
  '"$collStats"',
  '"$comment"',
  '"$currentDate"',
  '"$currentOp"',
  '"$each"',
  '"$elemMatch"',
  '"$elemMatch"',
  '"$eq"',
  '"$exists"',
  '"$group"',
  '"$gt"',
  '"$gte"',
  '"$hint"',
  '"$in"',
  '"$inc"',
  '"$includeArrayIndex"',
  '"$indexes"',
  '"$indexStats"',
  '"$isolated"',
  '"$isRoot"',
  '"$lt"',
  '"$lte"',
  '"$max"',
  '"$maxScan"',
  '"$merge"',
  '"$meta"',
  '"$min"',
  '"$mul"',
  '"$ne"',
  '"$nin"',
  '"$nor"',
  '"$not"',
  '"$or"',
  '"$out"',
  '"$pop"',
  '"$position"',
  '"$pull"',
  '"$pullAll"',
  '"$push"',
  '"$push"',
  '"$pushAll"',
  '"$redact"',
  '"$regex"',
  '"$rename"',
  '"$set"',
  '"$setOnInsert"',
  '"$size"',
  '"$skip"',
  '"$split"',
  '"$slice"',
  '"$slice"',
  '"$sort"',
  '"$stdDevPop"',
  '"$stdDevSamp"',
  '"$type"',
  '"$unset"',
];

export function parseEnvironmentSetting(settingsValues: SettingsValues) {
  const { rowLimit, durationValue, durationUnit } = settingsValues;

  return {
    ...settingsValues,
    rowLimit: parseInt(rowLimit as unknown as string, 10),
    durationValue: parseInt(durationValue as unknown as string, 10),
    durationUnit: (durationUnit as DurationUnit).value,
  };
}

export function getDurationUnitByValue(value: DurationUnitValue) {
  const durationUnit = timeToExpireUnits.find((unit) => unit.value === value);

  return _isUndefined(durationUnit) ? null : durationUnit;
}

export function convertTableColumnsToMap(columns: TableColumnValues[]) {
  return _reduce(
    columns,
    (acc: Record<string, Attributes>, column) => {
      const { name, type } = column;

      acc[name] = {
        name,
        dataType: type,
      };

      return acc;
    },
    {}
  );
}

export function convertResponseObjectToSchemas(schemas?: GetDataSetSchemas) {
  if (schemas == null) return {};

  return _reduce(
    Object.keys(schemas),
    (acc: Record<string, DataSetSchema>, tableName) => {
      const { columns } = schemas[tableName];

      acc[tableName] = {
        name: tableName,
        id: generateUid('param_'),
        attributes: {
          ...convertTableColumnsToMap(columns),
        },
      };

      return acc;
    },
    {}
  );
}

export function getDataSetStatus({
  isPublished,
  isTested,
}: DataSetPublishedStatus) {
  if (isPublished) {
    return 'published';
  }

  if (isTested) {
    return 'tested';
  }

  return 'draft';
}

export function getSuggestionsKeywords(schema: SchemasValue, Plugin?: Plugin) {
  const tables = Object.keys(schema);
  const suggestions: NectedSuggestionModel[] = [];
  tables.forEach((table) => {
    suggestions.push({
      value: `${table}`,
      score: 999,
      meta: `table`,
    });

    const { attributes } = schema[table];

    Object.keys(attributes).forEach((attribute) => {
      suggestions.push({
        value: `${table}.${attributes[attribute].name}`,
        name: `${table}.${attributes[attribute].name}`,
        score: 998,
        meta: `${attributes[attribute].dataType}`,
      });
    });
  });

  return suggestions;
}

export function getSuggestionsKeywordsNew(
  schema: SchemasValue,
  Plugin?: Plugin,
  databaseName?: DatabaseName
) {
  const tables = Object.keys(schema);
  const suggestions: NectedSuggestionModel[] = [];
  tables.forEach((table) => {
    suggestions.push({
      value: `${table}`,
      name: `${table}`,
      score: 999,
      meta: databaseName === 'mongodb' ? `collection` : `table`,
      version: '0.0.0',
    });

    const { attributes } = schema[table];

    Object.keys(attributes).forEach((attribute) => {
      suggestions.push({
        value:
          databaseName === 'mongodb'
            ? `${attributes[attribute].name}`
            : `${table}.${attributes[attribute].name}`,
        name:
          databaseName === 'mongodb'
            ? `${attributes[attribute].name}`
            : `${table}.${attributes[attribute].name}`,
        score: 998,
        meta: `${attributes[attribute].dataType}`,
        version: '0.0.0',
      });
    });
  });

  return suggestions;
}

export async function nodeSqlParser(editorDatabaseProvider: DatabaseName) {
  switch (editorDatabaseProvider) {
    case 'mysql':
      return await import('node-sql-parser/umd/mysql.umd');
    case 'pgsql':
      return await import('node-sql-parser/umd/postgresql.umd');
  }

  throw new Error('node query parse is not available');
}

export function isMongoQueryValid(query: string) {
  return MONGO_QUERY_REGEX.test(query.replaceAll('\n', ''));
}

export function isMongoActionValid(query: string = '') {
  return MONGO_ACTION_REGEX.test(query.replaceAll('\n', ''));
}

export const nullAsStringCellValue = '[NULL]';

export function getCellValue(value: unknown): CellValue {
  if (_isNil(value)) {
    return {
      cellValue: nullAsStringCellValue,
      tooltipValue: nullAsStringCellValue,
    };
  }

  const stringifyValue = JSON.stringify(value as string);
  const formattedValue = JSON.stringify(value as string, null, 2);

  if (stringifyValue.length > 15) {
    return {
      cellValue: _truncate(stringifyValue, { length: 15, omission: '...' }),
      tooltipValue: formattedValue,
    };
  }

  return {
    cellValue: stringifyValue,
    tooltipValue: formattedValue,
  };
}

export const modifyTestDataResponse = (fields: any, rows: ColumnValues[]) => {
  const updatedRows = _reduce(
    rows,
    (finalRows: ColumnValues[], currentRowValue) => {
      const currentRow = _reduce(
        fields,
        (currentRowData, currentColumnValue, columnKey) => {
          const cell: Record<string, any> = {};

          if (columnKey in currentRowValue) {
            cell[columnKey] = currentRowValue[columnKey];
          } else {
            cell[columnKey] = '';
          }

          return { ...currentRowData, ...cell };
        },
        {}
      );

      return [...finalRows, currentRow];
    },
    []
  );

  return updatedRows;
};

export const transformDatasetData = (formValues: CreateDataSetFormValues) => {
  return {
    ...formValues,
  };
};
