import { translations } from '../translations/en-US';

type MaxCharactersValidationRule = {
  value: number;
  message: string;
};

export const maxFiftyCharactersRule: MaxCharactersValidationRule = {
  value: 50,
  message: translations['validation.max_characters'],
};

export const isValidDate = (date: string): boolean => {
  return isValidDateorDateTime(date).isValid;
};

export const isValidDateTime = (dateTime: string): boolean => {
  return (
    isValidDateorDateTime(dateTime).isValid &&
    isValidDateorDateTime(dateTime).type === 'dateTime'
  );
};

export const isValidDateorDateTime = (inputString: string) => {
  const dateFormat1 = /^(\d{2})\/(\d{2})\/(\d{4})$/; // dd/mm/yyyy
  const dateFormat2 = /^(\d{4})\/(\d{2})\/(\d{2})$/; // yyyy/mm/dd
  const dateFormat3 = /^(\d{2})-(\d{2})-(\d{4})$/; // dd-mm-yyyy
  const dateFormat4 = /^(\d{4})-(\d{2})-(\d{2})$/; // yyyy-mm-dd

  const dateTimeFormat1 =
    // eslint-disable-next-line
    /^(\d{2})[\/-](\d{2})[\/-](\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd/MM/yyyy HH:mm:ss
  const dateTimeFormat2 =
    // eslint-disable-next-line
    /^(\d{4})[\/-](\d{2})[\/-](\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/MM/dd HH:mm:ss
  // eslint-disable-next-line
  const dateTimeFormat3 = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-MM-dd HH:mm:ss
  // eslint-disable-next-line
  const dateTimeFormat4 = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd-MM-yyyy HH:mm:ss
  // eslint-disable-next-line
  const dateTimeTZFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ
  // eslint-disable-next-line
  const rfc3339Format =
    /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional milliseconds

  if (dateFormat1.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateFormat2.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateFormat3.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateFormat4.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateTimeFormat1.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeFormat2.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeFormat3.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeFormat4.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeTZFormat.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (rfc3339Format.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else {
    return { isValid: true, type: 'string' };
  }
};

export const isValidDateTimeSecondary = (
  dateTimeSecondary: string
): boolean => {
  return (
    isValidDateorDateTime(dateTimeSecondary).isValid &&
    isValidDateorDateTime(dateTimeSecondary).type === 'dateTime'
  );
};
