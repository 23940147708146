import { Inline } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { BiSolidError } from 'react-icons/bi';
import {
  Attributes,
  Dataset,
  Image,
  NectedSuggestionModel,
  TextButton,
  TooltipReact,
  Typography,
} from 'ui';

import {
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../../../atom';
import { ActionSheetPill } from '../../../../../components/ActionComponents/ActionSheetPill';
import { getTooltipText } from '../../../../../utils/common';
import { TokenScores, envMap } from '../../../../../utils/constant';
import { isRuleReadOnlyAtom } from '../../../index';
import { sendEventToGTMType } from '../../../types';
import { getDtOutputRows } from '../../../utils/decisionTable';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import type { ResultActionType } from '../../SimpleRule/Results';
import { DecisionTableResultRow } from '../types';
import { ErrorIcon, PillContainer } from './decisionTableActions.styled';

export const additionalDatasetAtom = atom<Record<string, Dataset>>({});

type DecisionTableActionsProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  isLast: boolean;
  section: ResultActionType;
  key: string;
  handleDeleteElement: (index: number) => void;
  handleAddElement: (connectorId: string, key: string) => void;
  connectorId: string;
  field: Record<string, any>;
  open: boolean;
  setOpen: (value: boolean) => void;
  setValue?: UseFormSetValue<any>;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export default function DecisionTableActions({
  control,
  index,
  isLast,
  section,
  key,
  handleDeleteElement,
  connectorId,
  field,
  open,
  setOpen,
  setValue,
  handleSendEventToGTM,
}: DecisionTableActionsProps) {
  const additionalData = useWatch({ name: 'additionalData', control });
  const [dataset] = useAtom(dataSetParamsAtom);

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsObjs, setSuggestionsObjs] = useState<
    NectedSuggestionModel[]
  >([]);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setAdditionalDataset] = useAtom(additionalDatasetAtom);
  const [usedConnectorMapping] = useAtom(usedConnectorMappingAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const results: DecisionTableResultRow[] = useWatch({
    name: 'results',
    control,
  });

  const rows = useWatch({ control, name: 'rows' });

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionObjList: NectedSuggestionModel[] = [];

    const additionalDataAttributes: Record<string, Attributes> = {};

    additionalData?.forEach((data: any) => {
      suggestionList.push(`{{.additionalData.${data.name as string}}}`);
      suggestionObjList.push({
        name: `{{.additionalData.${data.name as string}}}`,
        value: `{{.additionalData.${data.name as string}}}`,
        score: TokenScores.additionalData,
        meta: data.returnType,
        executedValue: data.executedValue ?? undefined,
      });

      additionalDataAttributes[data.name] = {
        name: data.name,
        dataType: data.returnType,
        executedValue: data.executedValue ?? undefined,
      };
    });

    setSuggestions(suggestionList);
    setSuggestionsObjs(suggestionObjList);

    const finalRows = getDtOutputRows(results, rows, dataset);

    setAdditionalDataset({
      additionalData: {
        attributes: additionalDataAttributes,
        name: 'Additional Data Attributes',
        id: 'additional_data_attributes',
      },
      outputData: {
        attributes: {
          output: {
            dataType: 'list',
            name: 'output',
            executedValue: finalRows,
          },
        },
        name: 'Output Data Attributes',
        id: 'output_data_list_attributes',
      },
    });
  }, [additionalData]);

  const handleDeleteClick = () => {
    handleDeleteElement(index);

    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'delete',
        element: 'action',
        actionName: '',
      });
    }
  };

  const hasConnectorError = !_isNil(usedConnectorMapping)
    ? !usedConnectorMapping[connectorId]
    : false;

  return (
    <PillContainer align="center" key={key} gutter="0.4rem">
      <ActionSheetPill
        connectorId={connectorId}
        control={control}
        section={section}
        field={field}
        index={index}
        suggestions={suggestions}
        suggestionsObjs={suggestionsObjs}
        hasError={true}
        setValue={setValue}
        open={false}
        setOpen={setOpen}
        handleSendEventToGTM={handleSendEventToGTM}
        ruleType="decisionTable"
      />
      <Inline align="center">
        {!isRuleReadOnly && (
          <TextButton onClick={handleDeleteClick}>
            <Image
              src={`${envMap.VITE_ASSETS_URL}website/icons/Cross.svg`}
              alt="minus"
              size="small"
            />
          </TextButton>
        )}
      </Inline>

      {hasConnectorError && (
        <ErrorIcon>
          <TooltipReact
            id={`dt_connector_error_${index}`}
            launcher={
              <BiSolidError size={18} color="var(--color-fireEngineRed)" />
            }
          >
            <Typography>
              {getTooltipText(
                siteConstants,
                'integrations',
                'integrationNotConnected'
              )}
            </Typography>
          </TooltipReact>
        </ErrorIcon>
      )}
    </PillContainer>
  );
}
