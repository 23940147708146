import { PadBox } from '@bedrock-layout/padbox';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import { type Attributes, TextButton, useLayer } from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { HowToLink } from '../../../components/HowToLink/HowToLink';
import {
  CustomAttributeSheet,
  customAttributesAtom,
} from '../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { generateUid, getTooltipText } from '../../../utils/common';
import { dataSetParamsAtom } from '../components/CreateRuleSheet/CreateRuleSheet';
import {
  decisionTableNodesAtom,
  decisionTableOutputAtom,
} from '../components/DecisionTable/DecisionTable';
import type {
  AdditionalActionFormData,
  DecisionTableNodesModel,
  DecisionTableRow,
  PropertiesNodeStructure,
} from '../components/DecisionTable/models';
import type { DecisionTableResultRow } from '../components/DecisionTable/types';
import type { RulePolicyDropdownModel } from '../components/RuleSet/models';
import { tokensAtom } from '../components/SimpleRule';
import {
  ProductionConfigModel,
  StagingConfigModel,
} from '../components/SimpleRule/models';
import {
  dataSetFieldsByIdAtom,
  isRuleReadOnlyAtom,
  selectedDataSetAtom,
} from '../index';
import type { AttributeModel } from '../models';
import { ResultAction } from '../types';
import { getDefaultValueByDataTypeV2, getUpdatedTokens } from '../utils/common';
import { decisionTablePolicies } from '../utils/constant';
import { useGetVariables } from './graphql/useGetVariables';
import type { VariablesResult } from './graphql/useGetVariables';
import { useInitializeDataSets } from './useInitializeDataSets';

type UseInitializeDecisionTableArguments = {
  customDataParams: Record<string, AttributeModel>;
  setValue: UseFormSetValue<any>;
  properties: PropertiesNodeStructure[];
  nodeList: Record<string, DecisionTableNodesModel>;
  outputData: Record<string, any>;
  result: DecisionTableResultRow[];
  decisionTableRow: Array<Record<string, DecisionTableRow>>;
  decisionTablePolicy: RulePolicyDropdownModel | null;
  decisionTableName: string;
  decesionTableDescription: string;
  stagingConfig?: StagingConfigModel;
  productionConfig?: ProductionConfigModel;
  selectedDataSets?: string[];
  thenActionResponse?: ResultAction[];
  additionalData?: AdditionalActionFormData[];
  isDemo?: boolean;
  currentRuleData?: any; // For now only new keys are added, Need to add previous keys of Rule Data to update form.
};

export function useInitializeDecisionTable({
  customDataParams,
  setValue,
  properties,
  nodeList,
  outputData,
  result,
  decisionTableRow,
  stagingConfig,
  productionConfig,
  decisionTablePolicy,
  decisionTableName,
  selectedDataSets,
  decesionTableDescription,
  thenActionResponse,
  additionalData,
  isDemo = false,
  currentRuleData,
}: UseInitializeDecisionTableArguments) {
  const [dataSetParams, setDatasetParams] = useAtom(dataSetParamsAtom);
  const [customAttributes, setCustomAttributes] = useAtom(customAttributesAtom);
  const [, setTokens] = useAtom(tokensAtom);
  const [getVariables, { loading }] = useGetVariables();
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setDecisionTableNodes] = useAtom(decisionTableNodesAtom);
  const [, setOutput] = useAtom(decisionTableOutputAtom);
  const [dataSetSelected, setSelectedDataSets] = useAtom(selectedDataSetAtom);
  const [dataSetFieldsById] = useAtom(dataSetFieldsByIdAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { openWithProps: openCustomAttributeSheet } = useLayer(
    <CustomAttributeSheet isReadOnly={isRuleReadOnly} />
  );

  const setGlobalCustomToDataSet = (data?: VariablesResult) => {
    if (_isNil(data)) {
      return;
    }

    if (!_isNil(data.getGlobalVars) && data.getGlobalVars.data.length > 0) {
      setDatasetParams((prev) => ({
        ...prev,
        [data.getGlobalVars.data[0].category]: {
          name: 'Global Attributes',
          order: 2,
          tooltip: (
            <span>
              {getTooltipText(siteConstants, 'rules', 'condGlobalAttributes')}{' '}
              <HowToLink
                variant="link"
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'condGlobalAttributeHowTo',
                  'howToLinks'
                )}
              />
            </span>
          ),
          id: generateUid('global_'),
          attributes: data.getGlobalVars.data.reduce<
            Record<string, Attributes>
          >((acc, curr) => {
            return {
              ...acc,
              [curr.name]: {
                name: curr.name,
                dataType: curr.dataType,
                executedValue:
                  curr.dataType === 'json' && typeof curr.value === 'string'
                    ? JSON.parse(curr.value)
                    : curr.value,
              },
            };
          }, {}),
        },
      }));
    }

    if (!_isNil(data.getSystemVars) && data.getSystemVars.data.length > 0) {
      setDatasetParams((prev) => ({
        ...prev,
        [data.getSystemVars.data[0].category]: {
          name: 'System Attributes',
          id: generateUid('system_'),
          order: 5,
          tooltip: (
            <span>
              {getTooltipText(siteConstants, 'rules', 'condSystemAttributes')}{' '}
              <HowToLink
                variant="link"
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'condSystemAttributeHowTo',
                  'howToLinks'
                )}
              />
            </span>
          ),
          attributes: data.getSystemVars.data.reduce<
            Record<string, Attributes>
          >((acc, curr) => {
            return {
              ...acc,
              [curr.name]: {
                name: curr.name,
                dataType: curr.dataType,
                executedValue: getDefaultValueByDataTypeV2(curr.dataType),
              },
            };
          }, {}),
        },
      }));
    }
  };

  useEffect(() => {
    const getGlobalSystemVars = async () => {
      try {
        const { data } = await getVariables();

        setGlobalCustomToDataSet(data);
      } catch {}
    };

    void getGlobalSystemVars();
  }, []);

  useEffect(() => {
    if (Object.keys(customDataParams).length > 0) {
      setCustomAttributes(customDataParams);
    }
  }, [customDataParams]);

  useEffect(() => {
    if (!_isNil(customAttributes)) {
      setDatasetParams((prev) => ({
        ...prev,
        customInput: {
          name: 'Input Attributes',
          id: generateUid('constant_'),
          order: 1,
          tooltip: (
            <span>
              {getTooltipText(siteConstants, 'rules', 'condInputAttributes')}{' '}
              <HowToLink
                variant="link"
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'condInputAttributeHowTo',
                  'howToLinks'
                )}
              />
            </span>
          ),
          footer:
            Object.keys(customAttributes).length === 0 ? (
              <PadBox padding={['0.4rem', '0.4rem', '0.4rem', '2rem']}>
                <TextButton
                  onClick={() =>
                    openCustomAttributeSheet({ isReadOnly: isRuleReadOnly })
                  }
                >
                  Add Input Attribute
                </TextButton>
              </PadBox>
            ) : null,
          attributes: Object.keys(customAttributes)
            .filter((key) => key !== 'CI0')
            .reduce((acc, key) => {
              return {
                ...acc,
                [key]: {
                  name: customAttributes[key].name,
                  dataType: customAttributes[key].dataType?.value,
                  sampleValue: customAttributes[key].sampleValue,
                  executedValue: customAttributes[key].executedValue,
                  config: customAttributes[key].config,
                },
              };
            }, {}),
        },
      }));
    }
  }, [customAttributes, siteConstants]);

  useEffect(() => {
    setDatasetParams((prev) => ({
      ...prev,
      custom: {
        name: 'Custom Functions',
        id: generateUid('param_'),
        order: 4,
        tooltip: (
          <span>
            {getTooltipText(siteConstants, 'rules', 'customFunctionRule')}{' '}
            <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'rules',
                'condCustomFunction',
                'howToLinks'
              )}
            />
          </span>
        ),
        attributes: {
          sql: {
            name: 'JS Code',
            dataType: 'jsCondition',
          },
          excel: {
            name: 'Formula',
            dataType: 'excelCondition',
          },
        },
      },
    }));
  }, [siteConstants]);

  useEffect(() => {
    setTokens(getUpdatedTokens(dataSetParams));
  }, [dataSetParams]);

  useEffect(() => {
    if (!_isNil(properties)) {
      setValue('properties', properties);
    }
  }, [properties]);

  useEffect(() => {
    if (!_isNil(result)) {
      setValue('results', result);
    }
  }, [result]);

  useEffect(() => {
    if (!_isNil(decisionTableRow)) {
      setValue('rows', decisionTableRow);
    }
  }, [decisionTableRow]);

  useEffect(() => {
    if (!_isNil(stagingConfig)) {
      setValue('stagingConfig', stagingConfig);
    }
  }, [stagingConfig]);

  useEffect(() => {
    if (!_isNil(productionConfig)) {
      setValue('productionConfig', productionConfig);
    }
  }, [productionConfig]);

  useEffect(() => {
    if (!_isNil(additionalData)) {
      setValue('additionalData', additionalData);
    }
  }, [additionalData]);

  useEffect(() => {
    setValue('rulePolicy', decisionTablePolicy ?? decisionTablePolicies[0]);
  }, [decisionTablePolicy]);

  useEffect(() => {
    setValue('ruleName', decisionTableName);
  }, [decisionTableName]);

  useEffect(() => {
    setValue('ruleDescription', decesionTableDescription);
  }, [decesionTableDescription]);

  useEffect(() => {
    setValue('thenActionParams', thenActionResponse);
  }, [thenActionResponse]);

  useEffect(() => {
    if (!_isNil(currentRuleData)) {
      Object.keys(currentRuleData).forEach((key) => {
        setValue(key, currentRuleData[key]);
      });
    }
  }, [JSON.stringify(currentRuleData)]);

  useEffect(() => {
    setDecisionTableNodes(structuredClone(nodeList));
  }, [nodeList]);

  useEffect(() => {
    setOutput(structuredClone(outputData));
  }, [outputData]);

  useEffect(() => {
    if (!_isNil(selectedDataSets) && selectedDataSets.length > 0) {
      setSelectedDataSets(selectedDataSets);
    }
  }, [selectedDataSets]);

  useInitializeDataSets({ dataSetSelected, dataSetFieldsById });

  return {
    loading,
  };
}
