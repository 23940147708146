import _isNil from 'lodash/isNil';
import type { ChangeEvent, ReactElement } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Typography } from 'ui';

import { CreateButtonContainer } from './CreateButton.styled';

type CreateButtonProps = {
  icon?: ReactElement;
  buttonLabel: string;
  onClick: (e: ChangeEvent<HTMLButtonElement>) => void;
  margin?: string;
};

export function CreateButton({
  icon,
  buttonLabel = '',
  onClick,
  margin,
}: CreateButtonProps) {
  return (
    <CreateButtonContainer
      align="center"
      justify="center"
      onClick={onClick}
      margin={margin}
    >
      {_isNil(icon) ? <BiPlus size={18} /> : icon}

      <Typography>{buttonLabel}</Typography>
    </CreateButtonContainer>
  );
}
