import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { SetStateAction } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { IconButton, Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import { generateUid } from '../../utils/common';
import { FromType } from '../Modals/ImportEntityModal/ImportEntityModal';
import { StackAsItem } from '../layouts/Stack.styled';
import { ActionMenuLauncher } from './ActionMenuLauncher';
import { DependencyConfig } from './EntityDependencyView';
import { ChildNodeContainer, Container } from './EntityDependencyView.styled';
import { Node } from './Node';
import './Node.styled';
import { entityActionOptions } from './constant';

type RenderNestedNodesProps = {
  rootId: string;
  id: string;
  data: Record<string, DependencyConfig>;
  expandedNodeIds: string[];
  setExpandedNodeIds: Dispatch<SetStateAction<string[]>>;
  isSummaryView: boolean;
  parentId?: string;
  setValue?: UseFormSetValue<any>;
  control?: Control<any>;
  from?: FromType;
  iconConfig?: Record<string, string>;
  defaultDepenedencyData?: Record<string, any>;
};

export const RenderNestedNodes = React.memo(
  ({
    rootId,
    id,
    data,
    parentId,
    expandedNodeIds,
    setExpandedNodeIds,
    setValue,
    control,
    isSummaryView,
    from = 'import_sheet',
    iconConfig,
    defaultDepenedencyData,
  }: RenderNestedNodesProps) => {
    const ref = useRef<PopoverMethods>(null);
    const [nodeId, setNodeId] = useState('');

    if (_isNil(data) || _isNil(data[id])) {
      return null;
    }

    const { entityId, isNameConflict, isCategoryConflict } = data[id];

    const hasChildNodes = !_isNil(data[id].dependencies);

    const currenNodeAction = !_isNil(control)
      ? useWatch({
          name: `dependencyJson.ref.${id}.action`,
          control,
        })
      : '';

    useEffect(() => {
      const uuid = generateUid('entityDependency_');
      setNodeId(uuid);
      setExpandedNodeIds((prevIds) => [...prevIds, uuid]);
    }, []);

    useEffect(() => {
      void updateActionsForChildDependencies(id);
    }, [currenNodeAction]);

    const updateActionsForChildDependencies = (id: string) => {
      if (
        _isNil(data[id].dependencies) ||
        data[id].dependencies?.length === 0
      ) {
        return;
      }

      if (['useExisting'].includes(currenNodeAction) && !_isNil(setValue)) {
        (data[id].dependencies ?? [])
          .filter((key) => key !== id)
          .forEach((key) => {
            setValue(`dependencyJson.ref.${key}.action`, currenNodeAction);

            const childDependencies = data[key].dependencies ?? [];

            if (childDependencies.length > 0) {
              childDependencies
                .filter((childKey) => childKey !== id)
                .forEach((childKey) =>
                  updateActionsForChildDependencies(childKey)
                );
            }
          });
      } else if (
        ['create', 'replace'].includes(currenNodeAction) &&
        !_isNil(setValue) &&
        !_isNil(defaultDepenedencyData)
      ) {
        (data[id].dependencies ?? [])
          .filter((key) => key !== id)
          .forEach((key) => {
            const currAction = defaultDepenedencyData.ref[key].action;
            setValue(`dependencyJson.ref.${key}.action`, currAction);

            const childDependencies = data[key].dependencies ?? [];

            if (childDependencies.length > 0) {
              childDependencies
                .filter((childKey) => childKey !== id)
                .forEach((childKey) =>
                  updateActionsForChildDependencies(childKey)
                );
            }
          });
      }
    };

    const handleShowChildNodes = (id: string) => {
      if (expandedNodeIds.includes(id)) {
        setExpandedNodeIds(expandedNodeIds.filter((nodeId) => nodeId !== id));
      } else {
        setExpandedNodeIds([...expandedNodeIds, id]);
      }
    };

    const handleMenuItemClick = (value: string) => {
      if (typeof setValue === 'function') {
        setValue(`dependencyJson.ref.${id}.action`, value);
      }
      ref.current?.hide();
    };

    /*
  // NC | CC | Action
  // T  | T  | Create(with dummyName)
  // T  | F  | Create(with dummyName)/Replace/UseExisting
  // F  | T  | NA
  // F  | F  | Create(without dummyName)
  // exclude: Variable entity can only be used if !NC && !CC
  */
    const filterActionOptions = (currAction: {
      label: string;
      value: string;
    }) => {
      const { entity, isNameConflict, isCategoryConflict } = data[id];

      if (entity === 'variable') {
        if (currAction.value === 'replace') {
          return false;
        }

        if (isNameConflict && !isCategoryConflict) {
          if (['none', 'create'].includes(currAction.value)) {
            return false;
          }
        }
      }

      if (entity === 'connector' && ['replace'].includes(currAction.value)) {
        return false;
      }

      if (rootId === id && ['useExisting'].includes(currAction.value)) {
        return false;
      }

      if (
        rootId === id &&
        from === 'import_sheet' &&
        currAction.value === 'create'
      ) {
        return false;
      }

      if (currAction.value === 'none') {
        return false;
      }

      return true;
    };

    const disabledDueToParentAction = useMemo(() => {
      if (!_isNil(parentId)) {
        const action = data[parentId].action ?? '';

        if (['useExisting'].includes(action)) {
          return true;
        }
      }

      return false;
    }, [parentId, JSON.stringify(data)]);

    const isLauncherDisabled =
      (!isNameConflict && !isCategoryConflict) ||
      (isNameConflict && isCategoryConflict) ||
      disabledDueToParentAction;

    return (
      <Stack>
        {!disabledDueToParentAction && (
          <Container>
            <Inline gutter="0.5rem">
              <Node
                rootId={rootId}
                id={id}
                nodeId={nodeId}
                data={data[id]}
                parentId={parentId}
                isExpanded={expandedNodeIds.includes(nodeId)}
                handleShowChildNodes={handleShowChildNodes}
                from={from}
                iconUrl={
                  !_isNil(iconConfig)
                    ? iconConfig[data[id].category] ??
                      iconConfig[data[id].entity]
                    : 'entityIcon'
                }
              />
            </Inline>
            {['import_sheet', 'import_list'].includes(from) &&
              (_isNil(entityId) || _isEmpty(entityId) ? (
                <Menu
                  launcher={
                    <IconButton>
                      <ActionMenuLauncher
                        id={id}
                        parentId={parentId}
                        entityType={data[id].entity}
                        config={data[id]}
                        control={control}
                        isSummaryView={isSummaryView}
                        nodeId={nodeId}
                      />
                    </IconButton>
                  }
                  ref={ref}
                  disabled={isLauncherDisabled || isSummaryView}
                  onMenuItemClick={handleMenuItemClick}
                >
                  {entityActionOptions
                    .filter(filterActionOptions)
                    .map((actionObj) => (
                      <MenuItem key={actionObj.value} value={actionObj.value}>
                        <Typography>{actionObj.label}</Typography>
                      </MenuItem>
                    ))}
                </Menu>
              ) : (
                <ActionMenuLauncher
                  id={id}
                  parentId={parentId}
                  entityType={data[id].entity}
                  config={data[id]}
                  control={control}
                  isSummaryView={isSummaryView}
                  nodeId={nodeId}
                />
              ))}
          </Container>
        )}

        {hasChildNodes && currenNodeAction === 'useExisting' && (
          <div style={{ marginLeft: '5rem' }}>
            <Typography>{`No child Dependencies would be imported and existing one would be used.`}</Typography>
          </div>
        )}
        {!disabledDueToParentAction &&
          currenNodeAction !== 'useExisting' &&
          expandedNodeIds.includes(nodeId) &&
          hasChildNodes && (
            <ChildNodeContainer
              marginLeft={
                rootId === id && _isNil(parentId)
                  ? '1rem'
                  : currenNodeAction !== 'useExisting'
                  ? '3.5rem'
                  : '5rem'
              }
              hasBorder={currenNodeAction !== 'useExisting'}
            >
              <PadBox as={StackAsItem} grow={1} padding={[0, 0, 0, 0]}>
                {data[id].dependencies
                  ?.filter((childId) => childId !== parentId)
                  .map((childNodeId) => (
                    <RenderNestedNodes
                      rootId={rootId}
                      key={childNodeId}
                      id={childNodeId}
                      parentId={id}
                      data={data}
                      expandedNodeIds={expandedNodeIds}
                      setExpandedNodeIds={setExpandedNodeIds}
                      setValue={setValue}
                      control={control}
                      isSummaryView={isSummaryView}
                      from={from}
                      iconConfig={iconConfig}
                      defaultDepenedencyData={defaultDepenedencyData}
                    />
                  ))}
              </PadBox>
            </ChildNodeContainer>
          )}
      </Stack>
    );
  }
);

RenderNestedNodes.displayName = 'RenderNestedNodes';
