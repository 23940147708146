import { Inline } from '@bedrock-layout/primitives';
import { UseControllerProps } from 'react-hook-form';

import { TableRowContainerStyled } from './RuleSetRow.styled';
import { MenuColumn } from './TableColumns/MenuColumn';
import { ResultColumn } from './TableColumns/ResultColumn';
import { RulesColumn } from './TableColumns/RulesColumn';

export type RuleSetRowProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  handleInsertRow: (index: number) => void;
  handleDeleteRow: (index: number) => void;
};

export function RuleSetRow({
  control,
  index,
  handleInsertRow,
  handleDeleteRow,
}: RuleSetRowProps) {
  return (
    <TableRowContainerStyled>
      <Inline align="center">
        <RulesColumn control={control} index={index} />
        <ResultColumn index={index} control={control} />
        <MenuColumn
          handleInsertRow={handleInsertRow}
          handleDeleteRow={handleDeleteRow}
          control={control}
          index={index}
        />
      </Inline>
    </TableRowContainerStyled>
  );
}
