import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { SimpleDropDownModel, ToolTip, Typography } from 'ui';

import {
  DynamicPanelContainer,
  EditorPanelContainer,
  SidePanelContainer,
} from '../../../../../components/CommonStyles/CommonStyles.styled';
import { EntityUnderReview } from '../../../../../components/EntityUnderReview/EntityUnderReview';
import { FAQMenu } from '../../../../../components/FAQMenu';
import { Panel } from '../../../../../components/Panel/Panel';
import { VersionControl } from '../../../../../components/VersionControl/VersionControl';
import { tabList } from '../../../../../components/VersionControl/constant';
import { StackAsItem } from '../../../../../components/layouts/Stack.styled';
import { handleSetCheckSumByEntityName } from '../../../../../utils/common';
import { ENTITY_ID } from '../../../../../utils/constant';
import { useGetRuleList } from '../../../hooks/graphql/useGetRuleList';
import { useUpdateStateOnRulePublish } from '../../../hooks/useUpdateStateOnRulePublish';
import { ruleResultAndList } from '../../../utils/common';
import { PolicyDropdown } from '../../RuleComponents/PolicyDropdown';
import { Triggers } from '../../Triggers/Triggers';
import {
  activePanelAtom,
  approvalInfoRuleAtom,
  vcListTabIndexRuleAtom,
} from '../../atom/atom';
import { RuleSetTable } from '../RuleSetTable/RuleSetTable';
import { CustomAttributeByRuleId, RuleResultMappedModel } from '../models';
import {
  RuleEditorContainer,
  RulesContainerStyled,
} from './RuleSetEditor.styled';

export type RuleSetEditorProps = Omit<UseControllerProps, 'name'> & {
  discardRule: () => void;
  isReadOnly: boolean;
  setValue: UseFormSetValue<any>;
  isClone: boolean;
  handleFetchRule: () => void;
  ruleSetId?: string;
};

export const rulesListAtom = atom<SimpleDropDownModel[]>([]);
export const resultByRuleAtom = atom<Record<string, RuleResultMappedModel>>({});
export const customAttributesByIdAtom = atom<
  Record<string, CustomAttributeByRuleId[]>
>({});

export function RuleSetEditor({
  control,
  ruleSetId,
  discardRule,
  isReadOnly,
  setValue,
  isClone,
  handleFetchRule,
}: RuleSetEditorProps) {
  const [getRuleList] = useGetRuleList();

  const [activePanel, setActivePanel] = useAtom(activePanelAtom);
  const [vcListTabIndex, setVcListTabIndex] = useAtom(vcListTabIndexRuleAtom);
  const [approvalInfoRule, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);

  const [, setResultByRule] = useAtom(resultByRuleAtom);
  const [, setRuleList] = useAtom(rulesListAtom);
  const [, setCustomAttributesById] = useAtom(customAttributesByIdAtom);

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const status = useWatch({
    control,
    name: 'status',
  });

  const { handleAfterRuleIsPublished } = useUpdateStateOnRulePublish({
    id: ruleSetId ?? '',
    ruleName: ruleName ?? '',
    isClone,
    setValue,
  });

  const handleGetRuleList = async () => {
    try {
      const { data } = await getRuleList({
        variables: {
          page: 1,
          perPage: 100,
        },
        fetchPolicy: 'no-cache',
      });

      if (!_isNil(data)) {
        const { ruleOptions, ruleResultAndNameById, customAttributePerRule } =
          ruleResultAndList(data.getRule.data);

        setCustomAttributesById(customAttributePerRule);
        setRuleList(ruleOptions);
        setResultByRule(ruleResultAndNameById);
      }
    } catch {}
  };

  useEffect(() => {
    void handleGetRuleList();

    return () => {
      if (!isReadOnly) {
        setResultByRule({});
        setRuleList([]);
      }
    };
  }, []);

  useEffect(() => {
    const updatedVcListTabIndex = tabList.findIndex(
      (tabObj) => tabObj.value === status
    );
    setVcListTabIndex(updatedVcListTabIndex !== -1 ? updatedVcListTabIndex : 0);
  }, [status]);

  const handleEntityUpdate = (data: Record<string, any>) => {
    if (!_isNil(setValue) && !_isNil(data)) {
      Object.keys(data).forEach((key) => {
        if (key === 'checksum') {
          handleSetCheckSumByEntityName('rule', data.checksum);
        } else if (key === 'entityPublished') {
          void handleAfterRuleIsPublished();
        } else if (key === 'discardEntity') {
          void discardRule();
        } else if (key === 'formData') {
          const reviewFormData = data.formData.requestReview;

          if (!_isNil(reviewFormData)) {
            setApprovalInfoRule(reviewFormData);
          }
        } else if (key === 'fetchEntity') {
          void handleFetchRule();
        } else {
          setValue(key, data[key]);
        }
      });
    }
  };

  return (
    <RuleEditorContainer gutter={0} className="rule-set-editor-container">
      <EditorPanelContainer>
        <RulesContainerStyled className="rule-set-editor-content">
          <Stack gutter="1.6rem">
            <Inline align="center">
              <ToolTip message="The policy in which the rules will be processed and output to be shown">
                <Typography>Rule Policy:</Typography>
              </ToolTip>

              <PolicyDropdown
                name="rulePolicy"
                rules={{ required: 'Required' }}
                control={control}
                placeholder="Policy"
                showError={false}
                isDisabled={isReadOnly}
              />
            </Inline>
            <RuleSetTable control={control} />
          </Stack>

          <PadBox padding={[0, 0, '2rem', '1rem']}>
            <EntityUnderReview entityType="Rule" entityStatus={status} />
          </PadBox>
        </RulesContainerStyled>
      </EditorPanelContainer>

      {activePanel === 'settings' && (
        <DynamicPanelContainer className="rule-set-trigger-container">
          <StackAsItem grow={1} gutter={0} className="rule-set-trigger-content">
            <Triggers control={control} setValue={setValue} />
          </StackAsItem>
        </DynamicPanelContainer>
      )}

      {activePanel === 'versionControl' && (
        <DynamicPanelContainer activePanel={activePanel}>
          <VersionControl
            entityInfo={{
              type: ENTITY_ID.rules,
              status,
              name: ruleName ?? '',
              id: ruleSetId ?? '',
              subType: 'ruleSet',
            }}
            handleEntityUpdate={handleEntityUpdate}
            currentTab={vcListTabIndex}
            modalFormdata={{
              requestReview: approvalInfoRule,
              publishModal: {
                title: approvalInfoRule?.title,
              },
            }}
            updateTabIndex={setVcListTabIndex}
          />
        </DynamicPanelContainer>
      )}

      {activePanel === 'faqMenu' && (
        <DynamicPanelContainer>
          <FAQMenu />
        </DynamicPanelContainer>
      )}

      <SidePanelContainer>
        <Panel
          defaultActivePanel={activePanel}
          onPanelItemClick={setActivePanel}
        />
      </SidePanelContainer>
    </RuleEditorContainer>
  );
}
