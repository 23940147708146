import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { BiSolidError } from 'react-icons/bi';
import { MdModeEdit } from 'react-icons/md';
import {
  Dataset,
  NectedSuggestionModel,
  TooltipReact,
  Typography,
  toasts,
  useLayer,
} from 'ui';

import {
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../../../atom';
import { publishedConnectorsAtom } from '../../../../../components/CreateAction/CreateAction';
import type { TokensSetProps } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { RestInCISheet } from '../../../../../components/rules/forms/CustomAttributeSheet/RestInCISheet';
import {
  getTooltipText,
  transformCustomInputs,
} from '../../../../../utils/common';
import { useGetPublishedConnectors } from '../../../../DataSets/hooks/useGetPublishedConnectors';
import { AttributeModel } from '../../../models';
import { SelectedType } from '../../../types';
import { ErrorIcon, RestNodePillBox } from './RestNodePill.styled';
import type { ResultType } from './Results';

export type RestAPIPillProps = UseControllerProps & {
  name: string;
  index?: number;
  suggestions?: string[];
  section?: ResultType;
  suggestionObjs?: NectedSuggestionModel[];
  setOriginalValue?: UseFormSetValue<any>;
  returnTypeName?: string;
  executedValueName?: string;
  updatedLocalCI?: Record<string, any>;
  tokensSet?: TokensSetProps[];
  customAttrArray?: AttributeModel[];
  updatedDataset?: Record<string, Dataset>;
};

export const suggestionsAtom = atom<string[]>([]);

export function RestNodePill({
  name,
  control,
  index = 0,
  suggestions = [],
  section = 'thenDataParams',
  suggestionObjs = [],
  executedValueName,
  returnTypeName,
  setOriginalValue,
  tokensSet,
  customAttrArray,
  updatedDataset = {},
}: RestAPIPillProps) {
  const { openWithProps: openRestInCISheet } = useLayer(
    <RestInCISheet tokensSet={tokensSet} updatedDataset={updatedDataset} />
  );
  const [updatedLocalCI, setUpdatedLocalCI] = useState<Record<string, any>>([]);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [usedConnectorMapping] = useAtom(usedConnectorMappingAtom);
  const [publishConnectors] = useAtom(publishedConnectorsAtom);

  const { fieldState } = useController({
    name,
    control,
  });

  const errorMessage = fieldState.error?.message;

  const selectedType: SelectedType | null = useWatch({
    name: `attributes.${index}.selectedType`,
    control,
  });

  const connectorId = selectedType?.value ?? '';

  const hasError =
    !_isNil(usedConnectorMapping) &&
    !_isEmpty(usedConnectorMapping) &&
    !_isEmpty(connectorId)
      ? !usedConnectorMapping[connectorId]
      : false;

  const { data, refetch } = useGetPublishedConnectors(false);

  useEffect(() => {
    if (!_isNil(customAttrArray) && customAttrArray.length > 0) {
      const filteredAttributesData: AttributeModel[] = [];

      for (const attr of customAttrArray) {
        if (attr.dataType?.value !== 'restAPI') {
          filteredAttributesData.push(attr);
        }
      }

      setUpdatedLocalCI(
        transformCustomInputs({
          attributes: filteredAttributesData,
        })
      );
    }
  }, [JSON.stringify(customAttrArray)]);

  if (_isNil(selectedType)) {
    return null;
  }

  return (
    <Stack>
      <Inline
        onClick={() => {
          const tabIndex = 1;

          if (
            !_isNil(selectedType?.value) &&
            selectedType?.value in publishConnectors
          ) {
            openRestInCISheet({
              connectorId: selectedType?.value,
              control,
              index,
              updatedLocalCI,
              executedValueName,
              returnTypeName,
              setOriginalValue,
              tabIndex,
              tokensSet,
              updatedDataset,
              publishConnectorList: data,
              refetchPublishedConnectorList: refetch,
            });
          } else {
            toasts.error(
              'Some issue occurred with this integration, Please check',
              'connector-not-found'
            );
          }
        }}
        as={RestNodePillBox}
        align="center"
        justify="center"
        padding={[2, 12]}
        $hasError={!_isEmpty(errorMessage)}
      >
        <MdModeEdit />
        <Typography>Edit</Typography>

        {hasError && (
          <ErrorIcon>
            <TooltipReact
              id={`connector_error_${index}`}
              launcher={<BiSolidError color="var(--color-fireEngineRed)" />}
            >
              <Typography>
                {getTooltipText(
                  siteConstants,
                  'integrations',
                  'integrationNotConnected'
                )}
              </Typography>
            </TooltipReact>
          </ErrorIcon>
        )}
      </Inline>

      {!_isEmpty(errorMessage) && (
        <Typography name="error">{errorMessage}</Typography>
      )}
    </Stack>
  );
}
