import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import useDrivePicker from 'react-google-drive-picker';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { GSheetPickerField } from 'ui';

import { useAxiosPrivate } from '../../../hooks';
import { PluginFieldContainer } from '../../../pages/Integrations/components/common/PluginFieldContainer';
import type { PluginFieldCommonProps } from '../../../pages/Integrations/types';
import { envMap } from '../../../utils/constant';

type WebGSheetPickerFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    setValue?: UseFormSetValue<any>;
    formJson?: Record<string, any>;
    parentFormData?: Record<string, any>;
  };

export const WebGSheetPickerField = ({
  control,
  label = '',
  name,
  tooltipText,
  disabled = false,
  required = false,
  setValue,
  formJson = {},
  parentFormData = {},
}: WebGSheetPickerFieldProps) => {
  const [openPicker] = useDrivePicker();
  const { axiosPrivate } = useAxiosPrivate();
  const selectedFile = useWatch({
    name,
    control,
  });

  const { connectorId, currentEnvironment = 'staging' } = parentFormData;

  const getGooglePickerInfo = async () => {
    if (!_isNil(connectorId)) {
      try {
        const response = await axiosPrivate.get(
          `credential/token?id=${connectorId as string}&env=${
            currentEnvironment as string
          }`
        );

        if (response?.data.code === 'success') {
          const { clientId, accessToken, apiKey } = response.data.data;

          return {
            clientId,
            token: accessToken,
            developerKey: apiKey,
          };
        }
      } catch (err) {}
    }

    return null;
  };

  const handleOpenPicker = async () => {
    const pickerInfo = await getGooglePickerInfo();

    if (!_isNil(pickerInfo)) {
      openPicker({
        clientId: pickerInfo.clientId,
        developerKey: pickerInfo.developerKey,
        token: pickerInfo.token,
        showUploadView: false,
        showUploadFolders: true,
        supportDrives: false,
        multiselect: false,
        viewMimeTypes: 'application/vnd.google-apps.spreadsheet',
        callbackFunction: (data) => {
          if (data.action === 'cancel') {
            /*eslint-disable */
          }
          if (!_isUndefined(setValue) && !_isNil(data) && !_isNil(data.docs)) {
            setValue(name, {
              label: data.docs[0].name,
              value: data.docs[0].id,
            });

            setValue('action.config.isShared', data.docs[0].isShared ?? false);

            clearDependentValues();
          }
        },
      });
    }
  };

  const handleDeleteFile = () => {
    if (!_isUndefined(setValue)) {
      setValue(name, null);
      setValue('action.config.isShared', null);

      clearDependentValues();
    }
  };

  const clearDependentValues = () => {
    if (!_isUndefined(setValue)) {
      setValue('action.config.sheet', null);
      setValue('action.config.hasHeaders', null);
      setValue('action.config.columnData', null);
      setValue('action.config.data', null);
      setValue('action.config.columnList', null);
    }
  };

  return (
    <PluginFieldContainer
      label={label}
      required={required}
      tooltipText={tooltipText}
    >
      <GSheetPickerField
        name={name}
        rules={{
          required: {
            value: required,
            message: 'Please select a file',
          },
        }}
        disabled={disabled}
        control={control}
        value={selectedFile}
        fileIconUrl={`${envMap.VITE_ASSETS_URL}website/icons/file-icon.svg`}
        crossIconUrl={`${envMap.VITE_ASSETS_URL}website/icons/Cross.svg`}
        handleOpenPicker={handleOpenPicker}
        handleDeleteFile={handleDeleteFile}
      />
    </PluginFieldContainer>
  );
};
