import { DecisionTableModel, DecisionTableResponse } from '../models';

export const decisionTableInitialNodes = {
  rule_01: {
    operator: 'and',
    parent: '',
    nodeType: 'group',
    children: ['rule_02'],
    siblingIndex: 1,
  },
  rule_02: {
    operator: '',
    parent: 'rule_01',
    nodeType: 'condition',
    siblingIndex: 1,
    leftNode: ['rule_03'],
    rightNode: ['rule_04'],
  },
  rule_03: {
    nodeType: 'params',
    parent: '',
    multiParent: ['rule_02'],
    siblingIndex: 1,
    sourceType: '',
    attribute: '',
  },
  rule_04: {
    siblingIndex: 1,
    nodeType: 'constant',
    value: '',
    parent: 'rule_02',
    dataType: '',
  },
};

export const decisionTableInitialData: DecisionTableModel = {
  firstRow: 'rule_01',
  firstProperty: 'rule_03',
  firstResult: '',
  firstAggOutputData: '',
  rows: {
    rule_01: {
      firstOutputData: '',
      isEnabled: true,
      nextId: '',
      prevId: '',
    },
  },
  properties: {
    rule_03: {
      nextId: '',
      prevId: '',
      type: 'simpleCondition',
      multiParent: ['rule_02'],
    },
  },
  results: {
    result_01: {
      nextId: '',
      prevId: '',
      keyName: 'keyName',
      dataType: 'string',
      children: [],
    },
  },
};

/**
 * @construct here we'll see how to add a parameter column
 * 1. When adding a column, we need to first go to the nodes object and add a node of
 * type "param" with ID @param 'l1'.
 * 2. Then we have to iterate through all the rows and add conditions to each row
 * namely @param 'c1' , @param 'c2' etc also we update the row/group's corresponding
 * id in the nodes array with the above conditions as children.
 * Then for each of these conditions we need to
 * create it's corresponding entry in the nodes object with lhs as @param 'l1'
 * and simultaneously create it's corresponding rhs with type as constant @param 'r1'.
 * 3. We'll have to repeat this for all the rows which we can get by watching the
 * @field "rows" in the hook form.
 */

/**
 * @construct here we'll see how to add a result column
 * 1. When adding a result column, we need to first iterate through the rows and add
 * result ids such as @param 'output_01', @param 'o2' etc also their corresponding declaration
 * in the output object which will have the following properties:
 * @property dataType -> string | boolean | number | jsFormula | date | json ;
 * @property value
 */

/**
 * @construct here we'll see how to add a row
 * 1. When adding a row, we need to first iterate through the @field properties and
 * @field results and after that we create node in the nodes object with type as group
 * 2. After doing that we create condition nodes and rhs nodes equal to the length
 * of the properties field also keeping in mind if we have formula selected at the
 * properties index we create a formulaType condition having no RHS.
 * 3. After this we create output nodes corresponding to the output object as per the
 * length of the @field results. We then add the nodes assuming @param 'o2' ,
 * @param 'o3' to the results part of the added group.
 * 4. Finally we add the newly created group object
 * {
 *  name: 'g1',
 *  isEnabled: true,
 *  conditions: [{
 *   condition: 'c1',
 *   rhs: ['r1']
 *  },
 *  {
 *   condition: 'c2',
 *   rhs: []
 *  }],
 *  results: ['o2', 'o3']
 * }
 */

export const exampleRow = {
  rule_01: {
    isEnabled: false,
    condition: [
      {
        rule_03: {},
        rhs: [
          {
            rule_04: {},
          },
        ],
      },
    ],
    ruleResults: [],
  },
};

export const exampleDecisionTableResponse: DecisionTableResponse = {
  conditions: {
    startNodeId: '',
    nodes: decisionTableInitialNodes,
  },
  firstCustomInput: '',
  checksum: '',
  decisionTable: decisionTableInitialData,
  type: 'decisionTable',
  action: {
    then: {
      firstOutputData: '',
      outputData: {
        output_01: {
          name: 'keyName',
          dataType: 'string',
          value: 'value',
          next: '',
        },
      },
    },
  },
  staticUrl: '',
  policy: '',
  name: 'Untitled',
  description: '',
  staging: {
    isEnabled: true,
    startAt: '',
    endAt: '',
    api: {
      isEnabled: true,
      isPrivate: false,
      auth: {},
    },
    salience: 0,
  },
  production: {
    isEnabled: true,
    startAt: '',
    endAt: '',
    api: {
      isEnabled: true,
      isPrivate: false,
      auth: {},
    },
    salience: 0,
  },
};
