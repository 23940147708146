/* eslint-disable @typescript-eslint/no-unused-expressions */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Typography } from 'ui';

import { roleJsonAtom } from '../../../components/authentication/router/AuthProvider';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import { getSubTasks } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import type { OnboardingDataProps, SubTasks } from '../models';
import {
  StepsContainer,
  StepsContainerLeft,
  StepsContainerRight,
  SubStepsContainer,
  SubStepsContainerLeft,
  SubStepsContainerRight,
  SubTaskContainer,
  SubTaskNumbering,
  TodoContainer,
  TypographyContainer,
} from './Home.Styled';

type OnboardingStepsProps = {
  isQuestionnaireInProgress: boolean;
  isRuleDataInProgress: boolean;
  onboardingData: OnboardingDataProps;
  closeMenu: () => void;
};

export function OnboardingSteps({
  isQuestionnaireInProgress,
  isRuleDataInProgress,
  onboardingData,
  closeMenu,
}: OnboardingStepsProps) {
  const navigate = useNavigate();
  const [roleJson] = useAtom(roleJsonAtom);
  const { sendEventToGTM } = useSendEventToGTM();
  const [selectedAccordian, setSelectedAccordian] = useState<number | null>(
    null
  );
  const [selectedSubStep, setSelectedSubStep] = useState<string>('');

  const isOnboardingStepCompleted =
    getSubTasks(
      onboardingData.advancedFeatures.subTasks,
      'complete_onboarding_questionnaire'
    )?.isCompleted ?? false;

  useEffect(() => {
    setSelectedAccordian(getDefaultAccordianOpen());
  }, [JSON.stringify(onboardingData)]);

  useEffect(() => {
    if (isOnboardingStepCompleted) {
      sendEventToGTM({
        event: 'onboarding',
        status: 'success',
        action: 'submit',
      });
    }
  }, [isOnboardingStepCompleted]);

  const handleAccordianClick = (index: number) => {
    if (selectedAccordian === index) {
      setSelectedAccordian(null);
    } else {
      setSelectedAccordian(index);
    }
  };

  const handleSubTaskClick = (
    e: React.MouseEvent<HTMLElement>,
    stepIndex: number,
    subStepIndex: number,
    id: string,
    isCompleted: boolean,
    path: string
  ) => {
    e.stopPropagation();
    e.preventDefault();

    sendEventToGTM({
      event: 'stepper_steps_clicked',
      taskName: id,
      step: `${stepIndex}_${subStepIndex}`,
    });

    if (!isCompleted) {
      navigate(`${path}${path.includes('?') ? '&' : '?'}step=${id}`);
    }
  };

  const filteredOnboardingTask = _filter(onboardingData, ({ id }) => {
    if (
      !_isNil(roleJson) &&
      !_isNil(roleJson.flags) &&
      !_isUndefined(roleJson.flags[id])
    ) {
      return roleJson.flags[id];
    }

    return true;
  });

  const calculateCompletedTask = (subTasks: SubTasks[]) => {
    return subTasks.filter((task) => task.isCompleted).length;
  };

  const isSubTasksCompleted = (subTasks: Record<string, any>) => {
    return (
      subTasks.filter((task: Record<string, any>) => task.isCompleted)
        .length === subTasks.length
    );
  };

  const getDefaultAccordianOpen = () => {
    const defaultAccordianIndex = filteredOnboardingTask.findIndex((task) => {
      return !isSubTasksCompleted(task.subTasks);
    });

    return defaultAccordianIndex;
  };

  // function to make default setSelectedSubStep. Find the first subtask which is not completed and set it as selectedSubStep parentIndex_subIndex.
  // function will accept subtask array and parent index and return the selectedSubStep
  const getDefaultSelectedSubStep = (
    subTasks: SubTasks[],
    parentIndex: string
  ) => {
    const defaultSubStepIndex = subTasks.findIndex((task) => {
      return !task.isCompleted;
    });

    return `${parentIndex}_${defaultSubStepIndex}`;
  };

  return (
    <>
      {isQuestionnaireInProgress || isRuleDataInProgress ? (
        <Typography>Loading</Typography>
      ) : (
        <Stack gutter="1rem">
          {_map(
            filteredOnboardingTask,
            (
              { heading, isCompleted, path, subHeading, id, subTasks },
              stepIndex
            ) => (
              <TodoContainer
                key={stepIndex}
                onClick={() => {
                  handleAccordianClick(stepIndex);
                }}
                isOpened={selectedAccordian === stepIndex}
              >
                <StepsContainer>
                  <StepsContainerLeft>
                    {isSubTasksCompleted(subTasks) ? (
                      <Image
                        size="self"
                        alt="completed-icon"
                        src={`${envMap.VITE_ASSETS_URL}home/home_completed.svg`}
                      />
                    ) : (
                      <Image
                        alt={`${id}-icon`}
                        src={`${envMap.VITE_ASSETS_URL}home/${id}.svg`}
                      />
                    )}
                    <TypographyContainer gutter=".2rem">
                      <Typography
                        fontWeight={400}
                        name={
                          isSubTasksCompleted(subTasks)
                            ? 'secondarySmall'
                            : 'heading4'
                        }
                      >
                        {heading}
                      </Typography>
                      <Typography name="secondary2">{subHeading}</Typography>
                    </TypographyContainer>
                  </StepsContainerLeft>
                  <StepsContainerRight>
                    <Typography>
                      {' '}
                      {calculateCompletedTask(subTasks)} / {subTasks.length}
                    </Typography>
                    <Typography>
                      {selectedAccordian === stepIndex ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronUp />
                      )}
                    </Typography>
                  </StepsContainerRight>
                </StepsContainer>
                {selectedAccordian === stepIndex && (
                  <PadBox as={SubTaskContainer}>
                    {subTasks.map((item: SubTasks, subStepIndex: number) => {
                      return (
                        <SubStepsContainer
                          key={subStepIndex}
                          isCompleted={item.isCompleted}
                          onClick={(e: React.MouseEvent<HTMLElement>) =>
                            handleSubTaskClick(
                              e,
                              stepIndex,
                              subStepIndex,
                              item.id,
                              item.isCompleted,
                              item.path
                            )
                          }
                          onMouseEnter={() => {
                            !item.isCompleted
                              ? setSelectedSubStep(
                                  `${stepIndex}_${subStepIndex}`
                                )
                              : null;
                          }}
                          onMouseLeave={() => {
                            setSelectedSubStep('');
                          }}
                        >
                          <SubStepsContainerLeft>
                            {item.isCompleted ? (
                              <Image
                                size="self"
                                alt="completed-icon"
                                src={`${envMap.VITE_ASSETS_URL}home/home_completed.svg`}
                              />
                            ) : (
                              <SubTaskNumbering>
                                {subStepIndex + 1}
                              </SubTaskNumbering>
                            )}
                            <TypographyContainer
                              gutter=".2rem"
                              isCompleted={item.isCompleted}
                            >
                              <Typography
                                fontWeight={400}
                                name={
                                  item.isCompleted
                                    ? 'secondarySmall'
                                    : 'heading4'
                                }
                              >
                                {item.heading}
                              </Typography>
                            </TypographyContainer>
                          </SubStepsContainerLeft>
                          <SubStepsContainerRight>
                            {(selectedSubStep ===
                              `${stepIndex}_${subStepIndex}` ||
                              getDefaultSelectedSubStep(
                                subTasks,
                                stepIndex.toString()
                              ) === `${stepIndex}_${subStepIndex}`) && (
                              <Button size={'small'}>Try Now</Button>
                            )}
                          </SubStepsContainerRight>
                        </SubStepsContainer>
                      );
                    })}
                  </PadBox>
                )}
              </TodoContainer>
            )
          )}
        </Stack>
      )}
    </>
  );
}
