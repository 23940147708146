import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  DataTypes,
  Dataset,
  ExpandingTextField,
  Link,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  getDataTypeNected,
  getObjectUnion,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { getPropertyIfExists } from '../../../../../utils/common';
import { stopPropagate } from '../../../../../utils/form';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useGetIdsToBeNotRendered } from '../../../hooks/useGetIdsToBeNotRendered';
import { useTestWorkflowNode } from '../../../hooks/useTestWorkflowNode';
import { RunInLoopType } from '../../../models/models';
import {
  checkIfNameExists,
  getExecutedValueAndStatus,
  transformRuleAttributes,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateRuleParams,
} from '../../../utils/validations';
import {
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { WorkflowMapping } from './WorkflowMapping';
import { WorkflowNodeSettings } from './WorkflowSettings';
import { WorkflowTest } from './WorkflowTest/WorkflowTest';

type WorkflowNodeSheetProps = {
  data?: any;
  id?: string;
};

export function WorkflowNodeSheet({
  data: localData,
  id = '',
}: WorkflowNodeSheetProps) {
  const [isResultDisabled] = useState(false);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodeSaving, setWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );

  const [counter, setCounter] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const { close } = useCurrentLayer();

  const { open: openRuleSheetModal } = useLayer(
    <RuleSheetCloseModal onClose={close} title="Close workflow sheet" />
  );

  const {
    updatedDataSet,
    currentCustomInputs,
    setIsTesting,
    attributes: localAttributes,
    currentTab,
    parentNodes,
    isTesting,
    workflowNode,
    currentStatus,
    currentWorkflow,
    setCurrentTab,
    workflowData,
    workflowError,
    testWorkflowData,
    setCurrentStatus,
    setAttributes,
    directParents,
  } = useTestWorkflowNode({ localData, id, type: 'workflowNode' });

  const { control, handleSubmit, setValue, watch, setError, clearErrors } =
    useForm<any>({
      defaultValues: {
        attributes: [],
        runInLoop: localData.runInLoop ?? {
          status: false,
          source: '',
          attribute: '',
        },
        settings: localData.settings ?? {
          timeout: 60,
        },
        name: localData.name,
      },
    });

  const runInLoop: RunInLoopType | undefined = watch('runInLoop');

  const finalDataSet: Record<string, Dataset> = useMemo(() => {
    if (!_isNil(runInLoop) && runInLoop.status) {
      const parentData = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            parentNodes.find((p) => p.data.name === runInLoop.source)?.data
              ?.executedValue ?? {}
          )
        ),
        runInLoop.attribute
      );

      return {
        ...updatedDataSet,
        loop: {
          name: 'Loop',
          attributes: {
            currentItem: {
              dataType: getDataTypeNected(
                getObjectUnion(parentData ?? [])
              ) as DataTypes,
              name: 'currentItem',
              executedValue: getObjectUnion(parentData ?? []),
            },
          },
          id: 'currentItem',
        },
      };
    }

    return updatedDataSet;
  }, [updatedDataSet, JSON.stringify(runInLoop), parentNodes.length]);

  const onSubmit = async (dt: any, test: boolean = false) => {
    setIsSaving(true);
    clearErrors();

    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    const isValid = await validateRuleParams(
      dt,
      updatedDataSet,
      test ? setError : undefined,
      false
    );

    const isNameValid = nodeNameValidationBeforeSave(dt.name, setError);

    if (!isNameValid) {
      return;
    }

    if (dt.runInLoop.status as boolean) {
      if (
        // eslint-disable-next-line
        _isNil(dt.runInLoop.source as string | null) ||
        // eslint-disable-next-line
        _isNil(dt.runInLoop.attribute as string | null) ||
        // eslint-disable-next-line
        dt.runInLoop.source === '' ||
        // eslint-disable-next-line
        dt.runInLoop.attribute === ''
      ) {
        setError('runInLoop.value', {
          message: 'Please map a valid list',
        });

        return;
      }
    }

    if (
      (dt.runInLoop.status as boolean) &&
      // eslint-disable-next-line
      !dt.attributes.find((attr: any) => attr.source === 'loop')
    ) {
      setIsTesting(false);
      setIsSaving(false);

      return toasts.error(
        'You must map at least a single value!',
        'value-map-error'
      );
    }

    if (test) {
      setIsTesting(true);
    }

    if (!isValid) {
      setIsTesting(false);
      setIsSaving(false);

      return null;
    }

    if (!_isNil(workflowNode) && !_isNil(localData.onWorkflowNodeChange)) {
      const newWorkflowNode = workflowNode;

      if (
        dt.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          dt.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: dt.name,
        });
      }

      newWorkflowNode.data.name = dt.name;
      newWorkflowNode.data.settings = dt.settings;
      newWorkflowNode.data.input = transformRuleAttributes(dt.attributes);
      newWorkflowNode.data.executedValue =
        workflowData?.data?.data?.output ?? null;
      newWorkflowNode.data.status = currentStatus;

      if (
        !_isNil(localData.runInLoop) &&
        !_isNil(workflowData?.data?.data?.output)
      ) {
        if (
          runInLoop?.status !== localData.runInLoop?.status ||
          runInLoop?.attribute !== localData.runInLoop?.attribute
        ) {
          newWorkflowNode.data.status = '';
          newWorkflowNode.data.executedValue = null;
        }
      }

      newWorkflowNode.data.runInLoop = (dt.runInLoop.status as boolean)
        ? {
            status: dt.runInLoop.status,
            source: dt.runInLoop.source,
            attribute: dt.runInLoop.attribute,
          }
        : { status: false, source: '', attribute: '' };

      newWorkflowNode.data.executedValue =
        currentStatus === ''
          ? null
          : getExecutedValueAndStatus(workflowData ?? {}).executedValue ??
            workflowNode.data.executedValue ??
            null;

      localData.onWorkflowNodeChange(newWorkflowNode);

      setChangedNodeIds([id]);
      setWorkflowNodeSaving(true);

      if (!test) {
        close();
      }
    }

    if (test) {
      setAttributes(dt.attributes);
      setCounter(1);
    }

    setIsSaving(false);

    return null;
  };

  useEffect(() => {
    if (localAttributes.length > 0) {
      setValue('attributes', localAttributes);
    }
  }, [JSON.stringify(localAttributes.length)]);

  const attributes: any[] = watch('attributes');
  const settings: Record<string, any> = watch('settings');

  useEffect(() => {
    if (attributes.length > 0) {
      setCounter((count) => count + 1);
    }
  }, [JSON.stringify(attributes), JSON.stringify(settings)]);

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  const handleTestNode = async () => {
    await testWorkflowData(attributes);

    setIsTesting(false);
    setIsSaving(false);
  };

  const isLoading = isTesting || workflowNodeSaving || isSaving;

  const { idsToNotExpand } = useGetIdsToBeNotRendered({
    directParents,
    dataSet: updatedDataSet,
  });

  return (
    <Sheet size="small" onClose={counter > 1 ? openRuleSheetModal : close}>
      <WorkflowSheetFormStyled>
        <PadBox padding={0}>
          <Inline stretch="start">
            <Stack as={PadBox} gutter={48} padding={[16, 24]}>
              <Inline stretch="start">
                <Stack gutter={8}>
                  <Inline align="center" gutter="1.6rem" justify="start">
                    <Typography name="heading2">
                      <ExpandingTextField
                        control={control}
                        name="name"
                        disabled={isWorkflowReadOnly}
                      />
                    </Typography>
                  </Inline>
                  <PadBox
                    padding={{
                      left: 8,
                    }}
                  >
                    <Link to={currentWorkflow.link} target="_blank">
                      {currentWorkflow.name}
                    </Link>
                  </PadBox>
                </Stack>
              </Inline>
            </Stack>
          </Inline>
        </PadBox>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab disabled={isResultDisabled}>
              <Typography
                fontWeight={700}
                name={isResultDisabled ? 'secondarySmall' : 'paragraph'}
              >
                Test Results
              </Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled className="workflow-node-input-container">
                <PadBox padding="2rem">
                  <WorkflowMapping
                    control={control}
                    setValue={setValue}
                    dataSet={finalDataSet}
                    watch={watch}
                    customInputs={currentCustomInputs}
                    localData={localData}
                    idsToNotExpand={idsToNotExpand}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>
            <TabPanel>
              <PadBox padding="2rem">
                <WorkflowTest
                  parentNodes={parentNodes}
                  watch={watch}
                  mappedValues={attributes}
                  outputValue={
                    !_isNil(workflowData?.data?.data)
                      ? getExecutedValueAndStatus(workflowData).executedValue
                      : localData.executedValue
                  }
                  error={workflowError ?? {}}
                  ruleData={workflowData}
                  isTesting={isTesting}
                  dataSet={finalDataSet}
                />
              </PadBox>
            </TabPanel>
            <TabPanel>
              <WorkflowSheetTabContentStyled className="workflow-node-settings-container">
                <WorkflowNodeSettings control={control} name="settings" />
              </WorkflowSheetTabContentStyled>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Inline
          style={{
            marginTop: 'auto',
            padding: '0.8rem',
          }}
          justify="end"
          gutter="1rem"
        >
          <Button
            appearance="filled"
            disabled={isLoading || !isWorkflowTestOnly}
            onClick={handleSaveDataAndTest}
          >
            {isLoading ? <Spinner size="extraSmall" /> : <Inline>Test</Inline>}
          </Button>

          <Button
            disabled={isWorkflowReadOnly || isLoading}
            appearance="contained"
            onClick={handleSaveData}
          >
            {isLoading && !isTesting ? (
              <Spinner size="extraSmall" />
            ) : (
              <Inline>Save</Inline>
            )}
          </Button>
        </Inline>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
