import { PadBox } from '@bedrock-layout/padbox';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const publishedStatusAppearance = theme('status', {
  new: css`
    background: var(--color-amour);
    border: 1px solid var(--color--jazzberryJam);
    color: var(--color-jazzberryJam);
  `,
  draft: css`
    background: var(--color-earlyDawn);
    border: 1px solid var(--color-tangerine);
    color: var(--color-tangerine);
  `,
  tested: css`
    background: var(--color-aliceBlue);
    border: 1px solid var(--color-dodgerBlue);
    color: var(--color-dodgerBlue);
  `,
  published: css`
    background: var(--color-honeydew);
    border: 1px solid var(--color-malachite);
    color: var(--color-malachite);
  `,
  live: css`
    background: var(--color-lightCyan);
    border: 1px solid var(--color-green);
    color: var(--color-green);
  `,
  inreview: css`
    background: var(--color-dodgerBlue);
    border: 1px solid var(--color-dodgerBlue);
    color: var(--color-white);
  `,
  archived: css`
    background: var(--color-lightSlateBlue);
    border: 1px solid var(--color-lightSlateBlue);
    color: var(--color-white);
  `,
});

export const PublishedStatusContainer = styled(PadBox)`
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  ${publishedStatusAppearance};
`;
