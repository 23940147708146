import { Inline, Stack } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { DataListInputField, NectedSuggestionModel, Typography } from 'ui';

import { PluginFieldContainer } from '../../../pages/Integrations/components/common/PluginFieldContainer';
import type { PluginFieldCommonProps } from '../../../pages/Integrations/types';
import { extractKeyDataFromObject } from '../utlis';

type WebGSheetColumnFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    setValue?: UseFormSetValue<any>;
    formJson?: Record<string, any>;
    parentFormData?: Record<string, any>;
    newCustomSuggestions?: NectedSuggestionModel[];
  };

export const WebGSheetColumnField = ({
  control,
  name,
  disabled,
  required = false,
  tooltipText,
  regex,
  newCustomSuggestions = [],
  setValue,
  formJson,
  parentFormData = {},
  formKeyPrefix = '',
}: WebGSheetColumnFieldProps) => {
  const [customSuggestions, setCustomSuggestions] = useState<
    Array<{
      suggestion: string;
      dataType: string;
    }>
  >([]);

  const fieldValue = useWatch({
    name,
    control,
  });

  const formValues = useWatch({
    control,
  });

  const [columnFields, setColumnFields] = useState<Array<Record<string, any>>>(
    []
  );

  useEffect(() => {
    const hasHeaders = extractKeyDataFromObject(
      formValues,
      `${formKeyPrefix}hasHeaders`
    );

    if (
      _isNil(hasHeaders) ||
      (!_isNil(hasHeaders) && _isNil(hasHeaders.value))
    ) {
      setColumnFields([]);
    } else if (!_isNil(fieldValue)) {
      const updatedColumnFields = _reduce(
        fieldValue,
        (columnArray: Array<Record<string, any>>, field, key) => {
          return [
            ...columnArray,
            {
              ...field,
              key,
              columnName:
                hasHeaders.value === 'yes'
                  ? _isEmpty(field.columnName)
                    ? key
                    : field.columnName
                  : key,
            },
          ];
        },
        []
      );
      const sortedColumnFields = _sortBy(updatedColumnFields, 'order');

      if (!_isUndefined(setValue)) {
        setValue(
          `${formKeyPrefix}columnList`,
          sortedColumnFields.map((column) => column.key)
        );
      }

      setColumnFields(sortedColumnFields);
    }
  }, [fieldValue, JSON.stringify(formValues)]);

  useEffect(() => {
    const suggestions = newCustomSuggestions.map((token) => {
      if (token.meta === 'string') {
        return {
          suggestion: token.value.slice(1, token.value.length - 1),
          dataType: token.meta,
        };
      }

      return {
        suggestion: token.value,
        dataType: token.meta,
      };
    });

    setCustomSuggestions([
      ...suggestions,
      {
        suggestion: '<<NECTED_EMPTY_VALUE_TOKEN>>',
        dataType: '',
      },
    ]);
  }, [newCustomSuggestions]);

  const getSuggestionsBasedOnDataType = (dataType: string) => {
    // For Numeric datatype also considering number data type because of additional data tokens
    return customSuggestions
      .filter(
        (suggestionObj) =>
          suggestionObj.dataType === '' ||
          suggestionObj.dataType === 'generic' ||
          (dataType === 'numeric'
            ? suggestionObj.dataType === dataType ||
              suggestionObj.dataType === 'number'
            : suggestionObj.dataType === dataType)
      )
      .map((suggestionObj) => suggestionObj.suggestion);
  };

  if (columnFields.length === 0) {
    return null;
  }

  return (
    <Stack gutter="0.4rem">
      <PluginFieldContainer
        label="COLUMNS"
        required={required}
        tooltipText={tooltipText}
      >
        <Stack gutter="1rem">
          {columnFields.map((field, index) => (
            <div key={index}>
              <Inline gutter={8}>
                <Typography fontWeight={700}>{field.columnName}</Typography>
              </Inline>

              <DataListInputField
                control={control}
                name={`${formKeyPrefix}data.0.${field.key as string}`}
                options={getSuggestionsBasedOnDataType(field.dataType)}
                readOnly={disabled}
              />
            </div>
          ))}
        </Stack>
      </PluginFieldContainer>
    </Stack>
  );
};
