import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import { TESTABLE_NODES } from '../../../utils/constant';
import {
  changedNodeIdsAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import type { WorkflowAttribute } from '../models/models';
import {
  getAllPredecessorsSkippingType,
  getDirectParentsSkippingType,
  getExecutedValueAndStatus,
  getExecutedValueOfUsedAttributes,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { validateEmptyCheckNode } from '../utils/validations';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGenerateDataset } from './useGenerateDataset';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestSetVarNodeProps = {
  localData: any;
  id: string;
};

export function useTestSetVarNode({ localData, id }: UseTestSetVarNodeProps) {
  const [workflowId] = useAtom(workflowIdAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);
  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);

  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );
  const [directParents, setDirectParents] = useState<WorkflowNodeType[]>([]);

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { testWorkflow, data: codeData, error: codeError } = useTestWorkflow();

  const { updatedDataSet } = useGetDataset({
    parentNodes,
  });

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  useEffect(() => {
    if (!_isNil(codeData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(codeData);
        newWorkflowNode.data.status = exec.status;

        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);
        setCurrentStatus(exec.status);

        if (exec.status !== 'error') {
          toasts.success('Node tested successfully', 'node-test-success');
        } else {
          toasts.error('Node test failed', 'node-test-fail');
        }

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
      setIsTesting(false);
    }
  }, [codeData]);

  useEffect(() => {
    if (!_isNil(codeError)) {
      setIsTesting(false);
    }
  }, [codeError]);

  const testData = async (
    attributes: Record<string, WorkflowAttribute>,
    onSuccess?: () => void
  ) => {
    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    if (Object.keys(attributes).length === 0) {
      setIsTesting(false);

      return toasts.error('No attributes to test', 'node-test');
    }

    const isValid = validateEmptyCheckNode(attributes);

    if (!isValid) {
      setIsTesting(false);
      // eslint-disable-next-line
      return;
    }

    const params = getExecutedValueOfUsedAttributes(attributes, updatedDataSet);
    try {
      testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params,
      });
    } catch (error) {}

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  useEffect(() => {
    setParentNodes(
      getAllPredecessorsSkippingType(
        id,
        workflowNodes,
        workflowEdges,
        'addNode'
      )
    );

    setDirectParents(
      getDirectParentsSkippingType(workflowNodes, workflowEdges, id, 'addNode')
    );
  }, [JSON.stringify(workflowNodes)]);

  return {
    testData,
    codeData,
    codeError,
    updatedDataSet,
    tokens,
    parentNodes,
    currentStatus,
    isTesting,
    setCurrentStatus,
    setIsTesting,
    directParents,
  };
}
