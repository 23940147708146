import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { Button, Sheet, Typography, useCurrentLayer } from 'ui';

import { createRuleSheetAtom } from '../../..';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import type { CronCAObjectModel } from '../../../models';
import {
  getDataTypeByKey,
  getDefaultValuesForTest,
  handleSetCronValues,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { customAttributesInFieldsAtom } from '../../RuleSet/RuleSetTable/RuleSetTable';
import { getPropertiesByKeyName } from '../../RuleSet/TestNodeSheet/TestNodeSheet';
import { CustomAttributeByRuleId } from '../../RuleSet/models';
import { RuleCronScheduleModel } from '../../SimpleRule/models';
import { TestNodeField } from '../../TestNodeComponents/TestNodeField';
import { TestNodeFooter } from '../../TestNodeComponents/TestNodeFooter';
import {
  EmptyInputText,
  Form,
  HeaderItemStyled,
  HeaderStyled,
  RowItemStyled,
  RowStyled,
  SaveNodesContainerStyled,
} from './SchedulerCASheet.styled';

type TestNodesHeaderModel = {
  label: string;
  dataType: string;
};

export type CronCADataModel = {
  cronAttributes: Array<Record<string, CronCAObjectModel>>;
};

type SchedulerCASheetProps = {
  cronIndex: number;
  parentControl?: Control<any>;
  setValue?: UseFormSetValue<any>;
};

const processValue = (
  k: CustomAttributeByRuleId,
  sendText: boolean,
  oldValue?: RuleCronScheduleModel
) => {
  if (sendText && !_isNil(k.isDataset) && k.isDataset) {
    return 'Mapped to DB Field';
  } else if (
    !_isNil(oldValue) &&
    !_isNil(oldValue.inputParam) &&
    !_isNil(oldValue.inputParam[k.key])
  ) {
    return oldValue.inputParam[k.key].value;
  } else {
    return getDefaultValuesForTest(k.dataType);
  }
};

export function getDefaultValues(
  customAttributesInFields: Record<string, CustomAttributeByRuleId[]>,
  oldValue?: RuleCronScheduleModel,
  sendText: boolean = false
) {
  return _reduce(
    customAttributesInFields,
    (result: Record<string, CronCAObjectModel>, value, key) => {
      const keyByValue = {
        ...customAttributesInFields[key].reduce<
          Record<string, CronCAObjectModel>
        >((acc, k) => {
          return {
            ...acc,
            [k.key]: {
              value: processValue(k, sendText, oldValue),
              isNullable:
                !_isNil(oldValue) &&
                !_isNil(oldValue.inputParam) &&
                !_isNil(oldValue.inputParam[k.key])
                  ? oldValue.inputParam[k.key].sendNull
                  : false,
              isOptional:
                !_isNil(oldValue) &&
                !_isNil(oldValue.inputParam) &&
                !_isNil(oldValue.inputParam[k.key])
                  ? oldValue.inputParam[k.key].notSend
                  : false,
              isDataset: !_isNil(k.isDataset) ? k.isDataset : false,
            },
          };
        }, {}),
      };

      return {
        ...result,
        ...keyByValue,
      };
    },
    {}
  );
}

export function SchedulerCASheet({
  cronIndex = 0,
  parentControl,
  setValue,
}: SchedulerCASheetProps) {
  const [dataset] = useAtom(dataSetParamsAtom);
  const { attributes } = dataset.customInput;
  const [customAttributes] = useAtom(customAttributesAtom);
  const [customAttributesInFields] = useAtom(customAttributesInFieldsAtom);
  const [ruleType] = useAtom(createRuleSheetAtom);
  const dataTypeByKey = getDataTypeByKey(customAttributesInFields);

  const oldValue: RuleCronScheduleModel | undefined = useWatch({
    name: `productionConfig.schedule.${cronIndex}`,
    control: parentControl,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue: setLocalValue,
  } = useForm<CronCADataModel>({
    defaultValues: {
      cronAttributes: [
        ruleType === 'ruleSet'
          ? getDefaultValues(customAttributesInFields, undefined, true)
          : _reduce(
              customAttributes,
              (result: Record<string, CronCAObjectModel>, value, key) => {
                if (key !== 'CI0') {
                  return {
                    ...result,
                    [key]: {
                      // eslint-disable-next-line
                      value: customAttributes[key].sourceType
                        ? 'Mapped to DB Field'
                        : null,
                      isNullable: false,
                      isOptional: false,
                      isDataset: customAttributes[key].sourceType === 'dataSet',
                    },
                  };
                }

                return result;
              },
              {}
            ),
      ],
    },
  });

  const { close } = useCurrentLayer();

  const { fields } = useFieldArray({
    control,
    name: 'cronAttributes',
  });

  const [headerList, setHeaderList] = useState<TestNodesHeaderModel[]>([]);

  useEffect(() => {
    if (!_isEmpty(attributes)) {
      setHeaderList(
        _reduce(
          attributes,
          (result: TestNodesHeaderModel[], value, key) => {
            if (key !== 'CI0') {
              result.push({ label: key, dataType: attributes[key].dataType });
            }

            return result;
          },
          []
        )
      );
    }

    return () => {
      setHeaderList([]);
    };
  }, [attributes]);

  useEffect(() => {
    if (!_isNil(customAttributesInFields) && ruleType === 'ruleSet') {
      const headers: TestNodesHeaderModel[] = [];

      _forEach(customAttributesInFields, (value, key) => {
        if (!_isNil(customAttributesInFields[key])) {
          customAttributesInFields[key].forEach((attribute) => {
            if (
              _isNil(headers.find((header) => header.label === attribute.key))
            ) {
              headers.push({
                dataType: attribute.dataType,
                label: attribute.key,
              });
            }
          });
        }
      });

      setHeaderList(headers.filter((header) => header.label !== 'CI0'));
    }
  }, [customAttributesInFields]);

  const onSubmit = async (data: CronCADataModel) => {
    if (typeof setValue === 'function') {
      setValue(
        `productionConfig.schedule.${cronIndex}.inputParam`,
        handleSetCronValues(
          ruleType,
          data.cronAttributes[0],
          dataTypeByKey,
          customAttributes
        )
      );
    }

    close();
  };

  useEffect(() => {
    if (!_isNil(oldValue)) {
      reset({
        cronAttributes: [
          ruleType === 'ruleSet'
            ? getDefaultValues(customAttributesInFields, oldValue, true)
            : _reduce(
                customAttributes,
                (result: Record<string, CronCAObjectModel>, value, key) => {
                  if (key !== 'CI0') {
                    return {
                      ...result,
                      [key]: {
                        value:
                          customAttributes[key].sourceType === 'dataSet'
                            ? 'Mapped to DB Field'
                            : !_isNil(oldValue?.inputParam?.[key])
                            ? oldValue?.inputParam?.[key].value
                            : null,
                        isNullable:
                          oldValue?.inputParam?.[key]?.sendNull ?? false,
                        isOptional:
                          oldValue?.inputParam?.[key]?.notSend ?? false,
                        isDataset:
                          customAttributes[key].sourceType === 'dataSet',
                      },
                    };
                  }

                  return result;
                },
                {}
              ),
        ],
      });
    }
  }, [oldValue, ruleType]);

  return (
    <Sheet size="large">
      <PadBox padding="2rem">
        <Typography name="heading2">Custom Attributes</Typography>
      </PadBox>
      <Stack gutter="1.6rem" as={Form} onSubmit={handleSubmit(onSubmit)}>
        {headerList.length > 0 ? (
          <>
            <Typography fontWeight={700}>Input Atrributes</Typography>
            <SaveNodesContainerStyled as="ul">
              <HeaderStyled gutter={0}>
                {headerList.map((header, index) => (
                  <HeaderItemStyled key={`attributeHeader_${index}`}>
                    <PadBox padding={[5, 10]}>
                      <Inline stretch="start" align="end">
                        <Typography>{header.label}</Typography>
                        <Typography name="secondaryXs">
                          {header.dataType}
                        </Typography>
                      </Inline>
                    </PadBox>
                  </HeaderItemStyled>
                ))}
              </HeaderStyled>

              {fields.map((field, index) => (
                <RowStyled
                  gutter={0}
                  key={`testRowItem_${field.id}`}
                  align="stretch"
                >
                  {Object.keys(field)
                    .filter((key) => key !== 'id')
                    .map((key, fieldIndex) => (
                      <RowItemStyled key={`testRowItem_${index}_${fieldIndex}`}>
                        <PadBox padding={[5, 10]} as={Inline} align="center">
                          <TestNodeField
                            nodeKey={key}
                            index={index}
                            setValue={setLocalValue}
                            dataType={
                              // eslint-disable-next-line
                              !!field[key].isDataset
                                ? 'string'
                                : ruleType === 'ruleSet'
                                ? dataTypeByKey[key]
                                : customAttributes[key].sourceType === 'dataSet'
                                ? 'string'
                                : attributes[key].dataType
                            }
                            control={control}
                            isNullable={
                              ruleType === 'ruleSet'
                                ? getPropertiesByKeyName(
                                    customAttributesInFields
                                  )[key].isNullable
                                : customAttributes[key].isNullable
                            }
                            isOptional={
                              ruleType === 'ruleSet'
                                ? getPropertiesByKeyName(
                                    customAttributesInFields
                                  )[key].isNullable
                                : customAttributes[key].isOptional
                            }
                            isDisabled={field[key].isDataset}
                            nodeType="cronAttributes"
                          />
                        </PadBox>
                      </RowItemStyled>
                    ))}
                </RowStyled>
              ))}
            </SaveNodesContainerStyled>{' '}
          </>
        ) : (
          <EmptyInputText name="secondarySmall">
            You do not have any input attributes configured. Please go ahead and
            click on {`"Test Now"`} to test the rule.
          </EmptyInputText>
        )}

        <TestNodeFooter>
          <Button type="submit">Save</Button>
        </TestNodeFooter>
      </Stack>
    </Sheet>
  );
}
