import { gql, useMutation } from '@apollo/client';

import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updateRuleSetItemsMutation = gql`
  mutation updateRule(
    $id: String!
    $ruleChain: Map
    $ruleSetPolicy: String
    $firstRuleChain: String
    $ruleSet: [String!]
    $checksum: String!
    $customInput: Map
    $editMode: Boolean
  ) {
    updateRule(
      input: {
        id: $id
        ruleChain: $ruleChain
        ruleSetPolicy: $ruleSetPolicy
        firstRuleChain: $firstRuleChain
        ruleSet: $ruleSet
        checksum: $checksum
        customInput: $customInput
        editMode: $editMode
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateRuleSetItems() {
  return useMutation<RuleUpdateResponseModel>(updateRuleSetItemsMutation);
}
