import { z } from 'zod';

import { translations } from '../../../../translations/en-US';
import { KEY_REGEX } from '../../../../utils/regex';

export const thenElseDataSchema = z
  .object({
    keyName: z
      .string()
      .min(1, 'Key name cannot be empty')
      .max(50, translations['validation.max_characters'])
      .regex(
        KEY_REGEX,
        'Attribute name can contain alphabets, numbers, and underscore'
      ),
    dataType: z.string().min(1, 'Must have at least one character'),
    returnType: z.any(),
    executedValue: z.any(),
    value: z.any(),
    source: z.any(),
    attribute: z.any(),
  })
  .array();

export const thenElseActionSchema = z.array(
  z.object({
    name: z.string(),
    connectorId: z.string(),
    config: z.any(),
  })
);

export const resultDataSchema = z
  .object({
    ruleName: z
      .string()
      .min(1, `Rule name cannot be empty`)
      .max(100, 'Rule name cannot be more than 100 characters'),
    ruleDescription: z
      .string()
      .max(1000, 'Rule description cannot be more than 1000 characters'),
    thenDataParams: thenElseDataSchema,
    elseDataParams: thenElseDataSchema,

    thenActionParams: thenElseActionSchema,
    elseActionParams: thenElseActionSchema,

    stagingConfig: z.object({
      order: z.number().or(z.string().regex(/^-?\d+\.?\d*$/)),
      startDate: z.nullable(z.date()),
      endDate: z.nullable(z.date()),
      api: z.string(),
      isEnabled: z.boolean(),
      auditIO: z.boolean(),
      authType: z.nullable(
        z.object({
          label: z.string(),
          value: z.string(),
        })
      ),
      isApiEnabled: z.boolean(),
    }),

    productionConfig: z.object({
      order: z.number().or(z.string().regex(/^-?\d+\.?\d*$/)),
      startDate: z.nullable(z.date()),
      endDate: z.nullable(z.date()),
      api: z.string(),
      isEnabled: z.boolean(),
      auditIO: z.boolean(),
      authType: z.nullable(
        z.object({
          label: z.string(),
          value: z.string(),
        })
      ),
      isApiEnabled: z.boolean(),
      schedule: z.any(),
    }),
  })
  .refine(
    (data) => {
      if (
        data.stagingConfig.endDate != null &&
        data.stagingConfig.startDate != null
      ) {
        const startDate = new Date(data.stagingConfig.startDate);
        const endDate = new Date(data.stagingConfig.endDate);

        return startDate <= endDate;
      }

      return true;
    },
    {
      path: ['stagingConfig.endDate'],
      message: 'End Date must be greater than or equal to start date',
    }
  )
  .refine(
    (data) => {
      if (
        data.productionConfig.endDate != null &&
        data.productionConfig.startDate != null
      ) {
        const startDate = new Date(data.productionConfig.startDate);
        const endDate = new Date(data.productionConfig.endDate);

        return startDate <= endDate;
      }

      return true;
    },
    {
      path: ['productionConfig.endDate'],
      message: 'End Date must be greater than or equal to start date',
    }
  );

export const sqlNodeScheme = z.object({
  sqlNodeName: z
    .string()
    .min(1, 'Node name cannot be empty')
    .max(50, 'Node name cannot be more than 50 characters'),
  query: z.string(),
});
