import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { OperatorStyled } from './PathBlock.styled';

type PathOperatorProps = {
  setValue: UseFormSetValue<any>;
  operator: string;
  groupName: string;
};

export function PathOperator({
  groupName,
  operator,
  setValue,
}: PathOperatorProps) {
  const ref = useRef<PopoverMethods>(null);

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  return (
    <Menu
      onMenuItemClick={(value) => {
        setValue(`${groupName}.operator`, value);
        ref.current?.hide();
      }}
      ref={ref}
      disabled={isWorkflowReadOnly}
      placement="bottom-start"
      launcher={
        <Inline>
          <OperatorStyled operator={operator}>
            <Typography>{operator === 'and' ? 'And' : 'Or'}</Typography>
          </OperatorStyled>
        </Inline>
      }
    >
      <MenuItem value="and">
        <Typography>And</Typography>
      </MenuItem>
      <MenuItem value="or">
        <Typography>Or</Typography>
      </MenuItem>
    </Menu>
  );
}
