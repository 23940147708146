import { gql, useMutation } from '@apollo/client';

import { RuleSetUpdateModel } from '../../components/RuleSet/models';
import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updateRuleSet = gql`
  mutation updateRule(
    $id: String!
    $name: String!
    $description: String
    $staging: ConfDetailInput
    $production: ConfDetailInput
    $ruleChain: Map
    $ruleSetPolicy: String
    $firstRuleChain: String
    $ruleSet: [String!]
    $checksum: String!
    $customInput: Map
    $editMode: Boolean
  ) {
    updateRule(
      input: {
        id: $id
        name: $name
        description: $description
        staging: $staging
        production: $production
        ruleChain: $ruleChain
        ruleSetPolicy: $ruleSetPolicy
        firstRuleChain: $firstRuleChain
        ruleSet: $ruleSet
        checksum: $checksum
        customInput: $customInput
        editMode: $editMode
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateRuleSet() {
  return useMutation<RuleUpdateResponseModel, RuleSetUpdateModel>(
    updateRuleSet
  );
}
