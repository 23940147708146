import { PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const PillStyled = styled(PadBox)`
  border-radius: 2.5rem;
  background-color: var(--color-darkGray);
`;

export const PillStyledList = styled(PadBox)`
  border-radius: 2.5rem;
  background-color: var(--color-darkGray);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #fff;
`;

export const SourceContainerStyled = styled(PadBox)`
  background-color: var(--color-solitude);
`;

export const SaveButtonContainer = styled(PadBox)`
  border-block-start: 1px solid var(--color-darkGray);
  border-block-end: 1px solid var(--color-darkGray);
`;

export const RuleName = styled(PadBox)`
  &:focus {
    outline: 2px solid var(--color-dodgerBlue);
    border-radius: 4px;
  }
`;

export const RuleNodesContainer = styled(PadBox)`
  display: inline-block;
  overflow-x: auto;
`;

export const CreateRuleContainer = styled(PadBox)`
  height: 100vh;
  overflow-y: auto;

  .rsTabListStyle {
    border-block-end: none;
  }
`;
