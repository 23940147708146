import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import type { ReactNode } from 'react';
import { createRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image, PopoverMethods, PopoverPanel, Typography, useLayer } from 'ui';

import { siteConstantsAtom, userProfileAtom } from '../../../atom';
import { getTooltipText } from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { HowToLink } from '../../HowToLink/HowToLink';
import { roleJsonAtom } from '../../authentication/router/AuthProvider';
import { InviteButton, ProfileActionButton } from './Header.styled';
import { LogoutModal } from './LogoutModal';
import { ProfileDetailsMini } from './profile/ProfileDetailsMini';
import { ProfileIcon } from './profile/ProfileIcon';
import { ProfileIconSkeleton } from './profile/ProfileIconSkeleton';

/* eslint-disable-next-line import/no-absolute-path */
import inviteUser from '/assets/konark/icons/invite.svg';

export type HeaderProps = { content: ReactNode };

export function Header({ content }: HeaderProps) {
  const { open: openModal } = useLayer(<LogoutModal />);
  const popoverMethods = createRef<PopoverMethods>();
  const navigate = useNavigate();
  const location = useLocation();

  const [userProfile] = useAtom(userProfileAtom);
  const [roleJson] = useAtom(roleJsonAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const howToLinkOnHeader: string | undefined = useMemo(() => {
    switch (location.pathname) {
      case '/integrations':
        return getTooltipText(
          siteConstants,
          'integrations',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/rules':
        return getTooltipText(
          siteConstants,
          'rules',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/datasets':
        return getTooltipText(
          siteConstants,
          'datasets',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/auditlogs':
        return getTooltipText(
          siteConstants,
          'auditlogs',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/credentials':
        return getTooltipText(
          siteConstants,
          'credentials',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/remoteconfig':
        return getTooltipText(
          siteConstants,
          'remoteconfig',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/workspace/settings':
        return getTooltipText(
          siteConstants,
          'workspace',
          'howToLinkHeader',
          'howToLinks'
        );

      case '/workspace/team':
        return getTooltipText(
          siteConstants,
          'workspace',
          'howToLinkHeader',
          'howToLinks'
        );
    }

    return undefined;
  }, [location.pathname]);

  const logoutClickHandler = () => {
    popoverMethods.current?.hide();

    openModal();
  };

  const showInviteButton = () => {
    if (
      !_isNil(roleJson) &&
      !_isNil(roleJson.flags) &&
      !!roleJson.flags[ENTITY_ID.workspace] &&
      !location.pathname.includes('/workspace/team')
    ) {
      return true;
    }

    return false;
  };

  if (_isNil(userProfile)) {
    return (
      <Inline stretch="start">
        {content}

        <ProfileIconSkeleton />
      </Inline>
    );
  }

  return (
    <Inline stretch="start" gutter="1.6rem">
      {content}

      <Inline align="center" gutter="0.8rem">
        {showInviteButton() && (
          <InviteButton onClick={() => navigate('/workspace/team')}>
            <Inline align="center" gutter=".6rem">
              <Image src={inviteUser} alt="invite" size="self" />

              <Typography> Invite Team </Typography>
            </Inline>
          </InviteButton>
        )}

        {!_isNil(howToLinkOnHeader) && !_isEmpty(howToLinkOnHeader) && (
          <HowToLink link={howToLinkOnHeader} />
        )}
      </Inline>

      <PopoverPanel
        padding={5}
        trigger="click"
        launcher={
          <span>
            <ProfileIcon profile={userProfile} />
          </span>
        }
        ref={popoverMethods}
      >
        <Stack gutter={10}>
          <ProfileDetailsMini profile={userProfile} />

          <ProfileActionButton onClick={logoutClickHandler} value="Logout">
            <Typography>Logout</Typography>
          </ProfileActionButton>
        </Stack>
      </PopoverPanel>
    </Inline>
  );
}
