import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { PopoverMethods, PopoverPanel, TextButton } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';

type SwitchActionProps = {
  id: string;
  data: Record<string, any>;
};

export function SwitchAction({ id, data }: SwitchActionProps) {
  const ref = useRef<PopoverMethods>(null);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const onDeleteClick = () => {
    data.deleteNode({
      id,
      type: data.nodeType ?? 'dtNode',
    });

    ref.current?.hide();
  };

  return (
    <PopoverPanel
      ref={ref}
      trigger="click"
      launcher={
        <Inline>
          <HiDotsVertical />
        </Inline>
      }
    >
      <TextButton disabled={isWorkflowReadOnly} onClick={onDeleteClick}>
        Delete
      </TextButton>
    </PopoverPanel>
  );
}
