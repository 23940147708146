import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';

import { currentWorkspaceDetailAtom } from '../../../pages/Workspace/atom';
import { roleJsonAtom } from '../../authentication/router/AuthProvider';
import {
  type PermissionType,
  entityListHavingApprovalFlow,
  permissionObj,
} from '../constant';

type ShowEditComponentProps = {
  allowedPermission: string[];
  entityList: string[];
  entityStatus?: string;
  statusShouldBe?: string[];
};

// allowedPermission -> permission values to check if user is allowed to perform actions
// entityList -> entity Type
// entityStatus -> current status of the entity
// statusShouldBe -> current status should be one of statusShouldBe to perform action if user has permission.
export function useCheckPermissions({
  allowedPermission,
  entityList,
  entityStatus = '',
  statusShouldBe = [],
}: ShowEditComponentProps) {
  const [roleJson] = useAtom(roleJsonAtom);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const approvalFlowEnabled = currentWorkspace?.approvalFlowEnabled ?? false;

  const [isHide, setIsHide] = useState(true);
  const [permissions, setPermissions] = useState<Record<
    PermissionType,
    boolean
  > | null>(null);

  useEffect(() => {
    if (
      !_isNil(roleJson) &&
      !_isNil(roleJson.internals) &&
      !_isEmpty(entityList)
    ) {
      let updatedPermissions = null;
      let internalObj = roleJson.internals;

      let i = 0;

      while (!_isUndefined(internalObj[entityList[i]])) {
        internalObj = internalObj[entityList[i]];
        updatedPermissions = internalObj?.permissions ?? null;
        i++;
      }

      setPermissions(updatedPermissions);
    }
  }, [JSON.stringify(roleJson)]);

  useEffect(() => {
    if (!_isEmpty(permissions)) {
      const isEnabled = checkPermissionsToEnable(
        allowedPermission,
        entityStatus,
        statusShouldBe
      );

      setIsHide(!isEnabled);
    }
  }, [
    JSON.stringify(permissions),
    JSON.stringify(allowedPermission),
    JSON.stringify(statusShouldBe),
    entityStatus,
  ]);

  const checkPermissionsToEnable = (
    allowedPermission: string[],
    entityStatus: string,
    statusShouldBe: string[] = []
  ) => {
    let isEnabled = false;

    if (!_isEmpty(permissions)) {
      allowedPermission.forEach((currPermission) => {
        if (entityListHavingApprovalFlow.includes(entityList[0])) {
          const permFlag = permissions[currPermission as PermissionType];

          if (permFlag) {
            if (
              currPermission === permissionObj.create ||
              currPermission === permissionObj.edit
            ) {
              if (statusShouldBe.length > 0) {
                isEnabled = isEnabled || statusShouldBe.includes(entityStatus);
              } else {
                isEnabled =
                  isEnabled ||
                  entityStatus === '' ||
                  entityStatus === 'draft' ||
                  entityStatus === 'tested';
              }
            } else if (currPermission === permissionObj.delete) {
              if (statusShouldBe.length > 0) {
                isEnabled = isEnabled || statusShouldBe.includes(entityStatus);
              } else {
                isEnabled =
                  isEnabled ||
                  entityStatus === '' ||
                  entityStatus === 'draft' ||
                  entityStatus === 'tested';
              }
            } else if (currPermission === permissionObj.clone) {
              isEnabled = true;
            } else if (currPermission === permissionObj.test) {
              if (statusShouldBe.length > 0) {
                isEnabled = isEnabled || statusShouldBe.includes(entityStatus);
              } else {
                isEnabled = true;
              }
            } else if (
              currPermission === permissionObj.publish ||
              currPermission === permissionObj.approve
            ) {
              isEnabled =
                isEnabled ||
                (approvalFlowEnabled
                  ? entityStatus === 'inreview'
                  : entityStatus === 'tested' || entityStatus === 'inreview');
            }
          } else {
            isEnabled = isEnabled || false;
          }
        } else {
          isEnabled =
            isEnabled || permissions[currPermission as PermissionType];
        }
      });
    }

    return isEnabled;
  };

  return { isHide, checkPermissionsToEnable };
}
