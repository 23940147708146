import { useWatch } from 'react-hook-form';
import type { Control } from 'react-hook-form';

import type { CreateDataSetFormValues } from '../types';

export function useWatchFields(control: Control<CreateDataSetFormValues>) {
  const dataSetName = useWatch({
    control,
    name: 'name',
  });

  const description = useWatch({
    control,
    name: 'description',
  });

  const query = useWatch({
    control,
    name: 'query',
  });

  const params = useWatch({
    control,
    name: 'params',
  });

  const stagingSettings = useWatch({
    control,
    name: 'stagingSettings',
  });

  const productionSettings = useWatch({
    control,
    name: 'productionSettings',
  });

  const isEditableValue =
    useWatch({
      control,
      name: 'editable',
    }) ?? true;

  const statusValue = useWatch({
    control,
    name: 'status',
  });

  const approvalInfo = useWatch({
    name: 'approvalInfo',
    control,
  });

  const versionInfo = useWatch({
    name: 'versionInfo',
    control,
  });

  const publishedAt = useWatch({
    name: 'publishedAt',
    control,
  });

  const createdAt = useWatch({
    name: 'createdAt',
    control,
  });

  const isLive = useWatch({
    name: 'isLive',
    control,
  });

  return {
    dataSetName,
    query,
    params,
    stagingSettings,
    productionSettings,
    isEditableValue,
    statusValue,
    approvalInfo,
    isLive,
    versionInfo,
    createdAt,
    publishedAt,
    description,
  };
}
