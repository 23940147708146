import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import styled, { css } from 'styled-components';

export const ConditionBlockContainer = styled.div<{ padding?: string }>`
  min-block-size: 5rem;
  min-inline-size: 32rem;
  border: 1px solid var(--color-whiteSmoke);
  position: relative;

  ${({ padding = null }) => !_isNil(padding) && `padding: ${padding};`}
`;

export const ResultBlockContainer = styled(Inline)<{ dataType: string }>`
  min-block-size: 5rem;
  inline-size: 16rem;
  border: 1px solid var(--color-whiteSmoke);
  position: relative;

  ${({ dataType }) => dataType !== 'boolean' && 'display:block'}
`;

export const RowActionsContainer = styled(Inline)`
  min-inline-size: 12rem;
`;

export const AddRow = styled(PadBox)`
  border: 1px solid var(--color-whiteSmoke);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex: 1;
`;

export const AddRowStyled = styled(Inline)<{ $disabled: boolean }>`
  cursor: pointer;
  color: var(--color-dodgerBlue);
  background-color: var(--color-white);

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      cursor: default;
      color: var(--color-darkGray);
    `}
`;

export const RowNumContainer = styled(Inline)<{ isError: boolean }>`
  min-inline-size: 4rem;
  padding: 12px 8px;
  border: 1px solid var(--color-whiteSmoke);
  border-top: none;

  ${({ isError }) => isError && `background-color: var(--color-snow);`}
`;
