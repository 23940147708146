import { Inline } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import {
  Attributes,
  Dataset,
  Image,
  NectedSuggestionModel,
  TextButton,
  toasts,
  useLayer,
} from 'ui';

import { usedConnectorMappingAtom } from '../../../../../atom';
import ActionSheet from '../../../../../components/ActionComponents/ActionSheet';
import { ActionSheetPill } from '../../../../../components/ActionComponents/ActionSheetPill';
import { useGetConnectorById } from '../../../../../hooks/graphql/useGetConnectorById';
import { TokenScores, envMap } from '../../../../../utils/constant';
import { getEditorDetailsByPlugin } from '../../../../DataSets/utils';
import {
  isRuleReadOnlyAtom,
  ruleLimitsConfigAtom,
  simpleRuleNodeIdAtom,
} from '../../../index';
import { getResultKeyName } from '../../../utils/common';
import { DataActionsType } from '../../DataAddAction/DataActionsType';
import type { ResultAddDataModel } from '../models';
import type { ResultActionType, sendEventToGTMType } from './Results';

// This atom contains all the output data attributes which is then used to generate tokens
export const outputDatasetAtom = atom<Record<string, Dataset>>({});

type DataParamsProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  isLast: boolean;
  section: ResultActionType;
  key: string;
  handleDeleteElement: (index: number) => void;
  handleAddElement: (connectorId: string) => void;
  connectorId: string;
  field: Record<string, any>;
  setValue?: UseFormSetValue<any>;
  openFirst?: boolean;
  total?: number;
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
};

export default function DataActions({
  control,
  index,
  isLast,
  section,
  key,
  handleDeleteElement,
  handleAddElement,
  connectorId,
  field,
  setValue,
  openFirst = false,
  total = 0,
  handleSendEventToGTM,
}: DataParamsProps) {
  const fields = useWatch({
    control,
    name: section === 'thenActionParams' ? 'thenDataParams' : 'elseDataParams',
  });
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsObjs, setSuggestionsObjs] = useState<
    NectedSuggestionModel[]
  >([]);
  const [open, setOpen] = useState(false);
  const [selectedConnectorId, setSelectedConnectorId] = useState(connectorId);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [limitConfig] = useAtom(ruleLimitsConfigAtom);
  const [id] = useAtom(simpleRuleNodeIdAtom);
  const [usedConnectorMapping] = useAtom(usedConnectorMappingAtom);
  const [, setOutputDataset] = useAtom(outputDatasetAtom);

  const [getConnectorById] = useGetConnectorById();

  const actionFields = useWatch({
    name: section,
    control,
  });

  const { openWithProps: openActionSheet } = useLayer(
    <ActionSheet
      section={section}
      control={control}
      index={index}
      setValue={setValue}
      sheetMode=""
    />
  );

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionListObjList: NectedSuggestionModel[] = [];

    const functionFieldsExcluded = [''];

    const outputDataAttributes: Record<string, Attributes> = {};

    fields.forEach((field: ResultAddDataModel) => {
      if (functionFieldsExcluded.includes(field.dataType)) {
        // Pushing empty string here as it is required for the Field index tracking.
        // Moreover it will not be visible in the suggestions list
        suggestionList.push('');
      } else if (field.dataType === 'string' || field.dataType === 'date') {
        suggestionList.push(`"{{.outputData.${field.keyName}}}"`);
        suggestionListObjList.push({
          name: `{{.outputData.${field.keyName}}}`,
          value: `{{.outputData.${field.keyName}}}`,
          score: TokenScores.outputData,
          meta: field.dataType,
          executedValue: field.value,
        });
      } else {
        suggestionList.push(`{{.outputData.${field.keyName}}}`);
        suggestionListObjList.push({
          name: `{{.outputData.${field.keyName}}}`,
          value: `{{.outputData.${field.keyName}}}`,
          score: TokenScores.outputData,
          meta: ['jsFormula', 'excelFormula'].includes(field.dataType)
            ? // eslint-disable-next-line
              (!!field.returnType as boolean)
              ? field.returnType
              : 'unknown'
            : field.dataType,
          executedValue: ['jsFormula', 'excelFormula'].includes(field.dataType)
            ? field.executedValue
            : field.value,
        });
      }

      outputDataAttributes[field.keyName] = {
        name: field.keyName,
        dataType: ['jsFormula', 'excelFormula'].includes(field.dataType)
          ? // eslint-disable-next-line
            (!!field.returnType as boolean)
            ? field.returnType
            : 'unknown'
          : field.dataType,
        executedValue: field.executedValue,
      };
    });

    setOutputDataset({
      outputData: {
        attributes: outputDataAttributes,
        name: 'Output Data Attributes',
        id: 'output_data_attributes',
      },
    });

    setSuggestionsObjs(suggestionListObjList);
    setSuggestions(suggestionList);
  }, [fields]);

  useEffect(() => {
    if (!open) {
      setSelectedConnectorId(connectorId);
    }
  }, [open]);

  useEffect(() => {
    if (openFirst && total === 1) {
      setSelectedConnectorId(connectorId);
      setOpen(true);
    }
  }, [openFirst]);

  const handleAddAction = async (id: string) => {
    if (!_isEmpty(limitConfig) && total < limitConfig.maxActions.value) {
      handleAddElement(id);
      setSelectedConnectorId(id);
      // setOpen(true);

      const data = await getConnectorById({
        variables: {
          connectorId: id,
        },
      });

      const name = data.data?.getConnector.data[0].plugin.name;

      const editorDetails = getEditorDetailsByPlugin(
        data.data?.getConnector.data[0].plugin
      );

      if (typeof handleSendEventToGTM === 'function' && !open) {
        handleSendEventToGTM({
          action: 'edit',
          element: 'action',
          actionName: name ?? '',
        });
      }

      const sheetIndex = index + 1;

      const currField = {
        name: getResultKeyName(actionFields, 'action_'),
        connectorId: id,
        config: {
          query: '',
        },
      };

      openActionSheet({
        section,
        connectorId: id,
        field: currField,
        suggestions,
        suggestionsObjs,
        type: editorDetails?.databaseName ?? name ?? 'mysql',
        editorDetails,
        index: sheetIndex,
        connector: data.data?.getConnector.data[0],
        sheetMode: 'create',
      });
    } else if (!_isEmpty(limitConfig.maxActions.message)) {
      toasts.info(limitConfig.maxActions.message, 'info');
    }
  };

  const handleDeleteClick = () => {
    handleDeleteElement(index);

    handleSendEventToGTM({
      action: 'delete',
      element: 'action',
      actionName: '',
    });
  };

  const indexOfActionPill =
    openFirst && total === 1 && open ? index - 1 : index;

  return (
    <Inline align="start" key={key}>
      <ActionSheetPill
        connectorId={selectedConnectorId}
        hasConnectorError={
          !_isNil(usedConnectorMapping) &&
          !_isEmpty(usedConnectorMapping) &&
          !_isEmpty(connectorId)
            ? !usedConnectorMapping[connectorId]
            : false
        }
        control={control}
        section={section}
        field={field}
        index={indexOfActionPill}
        suggestions={suggestions}
        setValue={setValue}
        open={open}
        setOpen={setOpen}
        handleSendEventToGTM={handleSendEventToGTM}
        suggestionsObjs={suggestionsObjs}
      />
      <Inline align="center">
        <TextButton disabled={isRuleReadOnly} onClick={handleDeleteClick}>
          <Image
            src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
            alt="minus"
            size="small"
          />
        </TextButton>

        {isLast && !isRuleReadOnly && (
          <Inline>
            <DataActionsType
              ruleId={id}
              control={control}
              ruleType="simpleRule"
              addAction={async (value, id) => await handleAddAction(id)}
            />
          </Inline>
        )}
      </Inline>
    </Inline>
  );
}
