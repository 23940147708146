import { ReactElement, useRef } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { IoExitOutline } from 'react-icons/io5';
import { MdExitToApp } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import {
  MenuItemBlock,
  RuleMenuItem,
  RuleMenuLauncher,
} from './RuleMenu.styled';

type RuleMenuProps = {
  showDelete: boolean;
  convertIntoGroup: boolean;
  unGroupItem?: boolean;
  handleDelete: () => void;
  handleConvertIntoGroup: () => void;
  handleUnConvert?: () => void;
};

export function RuleMenu({
  convertIntoGroup,
  showDelete,
  unGroupItem = false,
  handleConvertIntoGroup,
  handleDelete,
  handleUnConvert,
}: RuleMenuProps) {
  const items: ReactElement[] = [];
  const ref = useRef<PopoverMethods>(null);

  if (showDelete) {
    items.push(
      <MenuItem key={'on-delete'} value={'on-delete'}>
        <RuleMenuItem align="center" stretch={1}>
          <MenuItemBlock $width="15%">
            <RiDeleteBin6Line />
          </MenuItemBlock>

          <MenuItemBlock $width="80%">
            <Typography>Remove</Typography>
          </MenuItemBlock>
        </RuleMenuItem>
      </MenuItem>
    );
  }

  if (convertIntoGroup) {
    items.push(
      <MenuItem key={'on-group'} value={'on-group'}>
        <RuleMenuItem align="center" stretch={1}>
          <MenuItemBlock $width="15%">
            <MdExitToApp />
          </MenuItemBlock>

          <MenuItemBlock $width="80%">
            <Typography>Convert into group</Typography>
            <Typography name="secondaryXs">To move inside a group</Typography>
          </MenuItemBlock>
        </RuleMenuItem>
      </MenuItem>
    );
  }

  if (unGroupItem) {
    items.push(
      <MenuItem key={'on-ungroup'} value={'on-ungroup'}>
        <RuleMenuItem align="center" stretch={1}>
          <MenuItemBlock $width="15%">
            <IoExitOutline />
          </MenuItemBlock>

          <MenuItemBlock $width="80%">
            <Typography>Leave group</Typography>
            <Typography name="secondaryXs">
              To move outside the group
            </Typography>
          </MenuItemBlock>
        </RuleMenuItem>
      </MenuItem>
    );
  }

  return (
    <Menu
      onMenuItemClick={(v) => {
        if (v === 'on-delete') {
          handleDelete();
        } else if (v === 'on-group') {
          handleConvertIntoGroup();
        } else if (
          v === 'on-ungroup' &&
          typeof handleUnConvert === 'function'
        ) {
          handleUnConvert();
        }
        ref.current?.hide();
      }}
      ref={ref}
      launcher={
        <RuleMenuLauncher>
          <HiDotsVertical />
        </RuleMenuLauncher>
      }
    >
      {items}
    </Menu>
  );
}
