import { Inline } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useRef } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { BsThreeDots } from 'react-icons/bs';
import { TbDownload, TbUpload } from 'react-icons/tb';
import { useSearchParams } from 'react-router-dom';
import {
  EnvironmentActionsContainer,
  IconButton,
  Menu,
  MenuItem,
  PopoverMethods,
  Typography,
  WorkflowNodeSheetHeader,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { EntityHeader } from '../../../../components/EntityHeader/EntityHeader';
import { ExportEntityModal } from '../../../../components/Modals/ExportEntityModal/ExportEntityModal';
import { ImportEntityModal } from '../../../../components/Modals/ImportEntityModal/ImportEntityModal';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { getTooltipText } from '../../../../utils/common';
import { ENTITY_ID, RuleEnvironment } from '../../../../utils/constant';
import { ruleEnvironmentAtom } from '../CreateRuleSheet/CreateRuleSheet';
import {
  activePanelAtom,
  isRuleLiveAtom,
  vcListTabIndexRuleAtom,
  versionInfoRuleAtom,
} from '../atom/atom';

export type RuleSheetHeaderProps = Omit<UseControllerProps, 'name'> & {
  title: 'simpleRule' | 'ruleset' | 'decisionTable';
  onLiveButtonClick?: () => void;
  ruleVersion: string;
  isReadOnly: boolean;
  showLiveButton?: boolean;
  id?: string;
  setValue?: UseFormSetValue<any>;
  handleFetchRule?: () => void;
};

export const ruleStatusAtom = atom<string>('');

export function RuleSheetHeader({
  title,
  control,
  ruleVersion,
  onLiveButtonClick,
  showLiveButton = true,
  isReadOnly,
  id,
  setValue,
  handleFetchRule,
}: RuleSheetHeaderProps) {
  const menuRef = useRef<PopoverMethods>(null);

  const [ruleSetEnvironment, setRuleSetEnvironment] =
    useAtom(ruleEnvironmentAtom);
  const [versionInfoRule] = useAtom(versionInfoRuleAtom);
  const [isRuleLive] = useAtom(isRuleLiveAtom);

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [, setActivePanel] = useAtom(activePanelAtom);
  const [, setVcListTabIndex] = useAtom(vcListTabIndexRuleAtom);
  const [searchParams] = useSearchParams();

  const { isHide: isImportDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
  });

  const { openWithProps: openImportModal } = useLayer(
    <ImportEntityModal entityType="rule" />
  );

  const { openWithProps: openExportModal } = useLayer(
    <ExportEntityModal entityId={id ?? ''} entityType="rule" />
  );

  const status = useWatch({
    control,
    name: 'status',
  });

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const isMenuDisabled = !isRuleLive && status === 'published';

  useEffect(() => {
    const stage = searchParams.get('stage') ?? 'staging';
    setRuleSetEnvironment(stage as RuleEnvironment);
  }, []);

  const openPublishedVersions = () => {
    setActivePanel('versionControl');
    setVcListTabIndex(2);
  };

  const handleMenuItemClick = (value: string) => {
    if (value === 'export' && !_isNil(id)) {
      openExportModal({
        isLive: isRuleLive,
      });
    } else if (value === 'import') {
      openImportModal({
        handleFetchEntity: handleFetchRule,
        entityName: ruleName,
      });
    }

    menuRef.current?.hide();
  };

  return (
    <div>
      <WorkflowNodeSheetHeader>
        <EntityHeader
          nameFieldKey={'ruleName'}
          descriptionFieldKey={'ruleDescription'}
          createdAtFieldKey="createdAt"
          publishedAtFieldKey="publishedAt"
          entityName="Rule"
          status={status}
          versionInfo={versionInfoRule}
          type={title}
          control={control}
          setValue={setValue}
          isReadOnly={isReadOnly}
          openPublishedVersions={openPublishedVersions}
        />

        <EnvironmentActionsContainer
          active={ruleSetEnvironment}
          getActive={(environment) => {
            setRuleSetEnvironment(environment);
          }}
          stageTooltip={getTooltipText(
            siteConstants,
            'rules',
            'ruleEnvStaging'
          )}
          prodTooltip={getTooltipText(
            siteConstants,
            'rules',
            'ruleEnvProduction'
          )}
        />

        <Menu
          launcher={
            <IconButton>
              <BsThreeDots />
            </IconButton>
          }
          ref={menuRef}
          onMenuItemClick={handleMenuItemClick}
        >
          <MenuItem
            disabled={isMenuDisabled}
            tooltipText="Only draft and live versions can be exported, as of now."
            value="export"
            key="export"
          >
            <Inline align="center" justify="center" gutter="0.5rem">
              <TbUpload />
              <Typography>Export</Typography>
            </Inline>
          </MenuItem>
          <MenuItem
            disabled={isImportDisable || isMenuDisabled}
            value="import"
            key="import"
            tooltipText={
              isMenuDisabled
                ? 'Import is enabled on draft and live versions.'
                : isImportDisable
                ? `You don't have permission to import rule`
                : ''
            }
          >
            <Inline align="center" justify="center" gutter="0.5rem">
              <TbDownload />
              <Typography>Import</Typography>
            </Inline>
          </MenuItem>
        </Menu>
      </WorkflowNodeSheetHeader>
    </div>
  );
}
