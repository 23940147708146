import { ENTITY_ID } from '../../utils/constant';

export const permissionObj = {
  read: 'read',
  create: 'create',
  edit: 'edit',
  delete: 'delete',
  test: 'test',
  publish: 'publish',
  clone: 'clone',
  viewLive: 'viewLive',
  share: 'share',
  approve: 'approve',
};

export type PermissionType = keyof typeof permissionObj;

export const entityListHavingApprovalFlow: string[] = [
  ENTITY_ID.datasets,
  ENTITY_ID.rules,
  ENTITY_ID.workflow,
];
