import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  Attributes,
  Button,
  DataTypes,
  Dataset,
  EditorLanguages,
  NectedEditorField,
  NectedSuggestionModel,
  Sheet,
  SheetSize,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { HowToLink } from '../../../../../components/HowToLink/HowToLink';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  convertCaSampleValues,
  getDataTypeByReturnType,
  getOutputDataByExecutedValue,
  getPropertyIfExists,
  getTooltipText,
  isValidJson,
} from '../../../../../utils/common';
import {
  TokenDataTypeByDataType,
  TokenDataTypeByDataTypeSingle,
  TokenScores,
  editorDomain,
} from '../../../../../utils/constant';
import { useGenerateDataset } from '../../../../Workflow/hooks/useGenerateDataset';
import { sanitizedStringV2 } from '../../../../Workflow/utils/common';
import { useUpdateExecutedValueRules } from '../../../hooks/useUpdateExecutedValueRules';
import {
  createRuleSheetAtom,
  isRuleReadOnlyAtom,
  selectedDataSetAtom,
} from '../../../index';
import { jsNodeResultQuery } from '../../../schema';
import {
  filterDataSetSuggestionObjBySection,
  filterDataSetSuggestionsBySection,
  getEvaluatedExValueForResult,
  getFilteredDataSetObjWithoutOptional,
  getFilteredDataSetWithoutOptional,
  getRequiredKey,
  updateDataSetOnChange,
} from '../../../utils/common';
import {
  dataSetParamsAtom,
  sectionAtom,
} from '../../CreateRuleSheet/CreateRuleSheet';
import { DecisionTableResultRow } from '../../DecisionTable/types';
import { SyntaxErrorContainer } from '../../RuleComponents/RuleComponents.styled';
import { ResultAddDataModel } from '../models';
import { suggestionsObjAtom } from './JsNodePill';
import { suggestionsAtom } from './JsonNodePill';
import {
  JsonBlockContainer,
  JsonFieldContainer,
  SaveButtonContainer,
} from './JsonNodeSheet.styled';
import { OnCloseNodeModal } from './OnCloseNodeModal';
import type { ResultType } from './Results';
import { SaveNodeModal } from './SaveNodeModal';

export type JsonNodeSheetProps = UseControllerProps & {
  index: number;
  section: ResultType;
  hideOptionalCustomAttributes?: boolean;
  suggestionObjs?: NectedSuggestionModel[];
  setOriginalValue?: UseFormSetValue<any>;
  onChangeSpecial?: (value: any) => void;
  returnTypeName?: string;
  executedValueName?: string;
  hideSuggestions?: boolean;
  size?: SheetSize;
  isJsonInCustomInput?: boolean;
  onClose?: () => void;
  overrideValue?: any;
  dataSet?: Record<string, Dataset>;
  type: string;
  disabled?: boolean;
  from?: string;
};

type JsonNodeSheetResult = {
  nodeQuery: string;
};

export function JsonNodeSheet({
  type,
  name,
  control,
  index,
  section,
  hideOptionalCustomAttributes = false,
  suggestionObjs = [],
  setOriginalValue,
  onChangeSpecial,
  returnTypeName,
  executedValueName,
  hideSuggestions = false,
  size = 'medium',
  isJsonInCustomInput = false,
  onClose,
  dataSet = {},
  overrideValue,
  disabled = false,
  from,
}: JsonNodeSheetProps) {
  const [dataSetVar] = useAtom(dataSetParamsAtom);
  const dataSetVariables = !_isEmpty(dataSet) ? dataSet : dataSetVar;
  const [suggestions, setSuggestions] = useAtom(suggestionsAtom);
  const [, setSuggestionsObj] = useAtom(suggestionsObjAtom);
  const [isReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [currentSection] = useAtom(sectionAtom);
  const [ruleType] = useAtom(createRuleSheetAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const { openWithProps: openSaveNodeModal } = useLayer(<SaveNodeModal />);
  const { close } = useCurrentLayer();

  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => close()} />
  );

  const [, setDataSetTokens] = useState<string[]>([]);
  const [datasetTokensObj, setDataSetTokensObjs] = useState<
    NectedSuggestionModel[]
  >([]);
  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [, setDataSetResults] = useState<ResultAddDataModel[]>([]);
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [executedValue, setExecutedValue] = useState<any | null>(null);
  const [, setReturnType] = useState<string | null>(null);
  const [hasChanged, setHasChanged] = useState(false);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const fields = useWatch({
    name: currentSection,
    control,
  });

  const results: DecisionTableResultRow[] = useWatch({
    name: 'results',
    control,
  });

  const rows = useWatch({ control, name: 'rows' });

  const nodeQuery = useWatch({ control, name });

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const {
    control: localControl,
    handleSubmit,
    watch,
  } = useForm<JsonNodeSheetResult>({
    resolver: zodResolver(jsNodeResultQuery),
    defaultValues: {
      nodeQuery:
        !_isNil(overrideValue) && overrideValue !== ''
          ? JSON.stringify(overrideValue)
          : _isEmpty(nodeQuery) || _isNil(nodeQuery)
          ? getTooltipText(
              siteConstants,
              'rules',
              isJsonInCustomInput ? `${type}InCustomInput` : `${type}InResult`,
              'otherText'
            )
          : typeof nodeQuery === 'string'
          ? nodeQuery
          : JSON.stringify(nodeQuery),
    },
    mode: 'onSubmit',
  });

  const localQuery = watch('nodeQuery');

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionListObj: NectedSuggestionModel[] = [];

    const outputDataAttributes: Record<string, Attributes> = {};

    fields?.forEach((field: ResultAddDataModel, currIndex: number) => {
      const source = field.source ?? null;
      const attribute = field.attribute ?? null;
      let executedValue = field.executedValue;

      if (
        _isNil(source) &&
        _isNil(attribute) &&
        !['jsFormula', 'json', 'excelFormula', 'list'].includes(
          field.dataType ?? ''
        )
      ) {
        executedValue = getEvaluatedExValueForResult(
          field.value,
          field.dataType
        );
      }

      if (
        field.dataType === 'string' ||
        field.dataType === 'date' ||
        (['jsFormula', 'excelFormula'].includes(field.dataType) &&
          (field.returnType === 'string' ||
            field.returnType === 'date' ||
            field.returnType === 'datetime'))
      ) {
        suggestionList.push(`"<<outputData.${field.keyName}>>"`);

        suggestionListObj.push({
          name: `"<<outputData.${field.keyName}>>"`,
          value: `"<<outputData.${field.keyName}>>"`,
          score: TokenScores.outputData,
          meta: ['jsFormula', 'excelFormula'].includes(field.dataType)
            ? // eslint-disable-next-line
              !!field.returnType
              ? field.returnType
              : 'unknown'
            : field.dataType,
          executedValue,
        });

        if (currIndex < index) {
          outputDataAttributes[field.keyName] = {
            name: field.keyName,
            dataType: ['jsFormula', 'excelFormula'].includes(field.dataType)
              ? // eslint-disable-next-line
                !!field.returnType
                ? field.returnType
                : 'unknown'
              : field.dataType,
            executedValue,
          };
        }
      } else {
        suggestionList.push(`<<outputData.${field.keyName}>>`);
        suggestionListObj.push({
          name: `<<outputData.${field.keyName}>>`,
          value: `<<outputData.${field.keyName}>>`,
          meta: field.dataType,
          score: TokenScores.outputData,
          executedValue,
        });

        if (currIndex < index) {
          outputDataAttributes[field.keyName] = {
            name: field.keyName,
            dataType: field.dataType as DataTypes,
            executedValue,
          };
        }
      }
    });

    if (ruleType === 'simpleRule') {
      setSuggestionsObj(suggestionListObj);
      setDataSetResults(fields);
      setSuggestions(suggestionList);

      setUpdatedDataset((prev) => ({
        ...prev,
        outputData: {
          attributes: outputDataAttributes,
          name: 'Output Data Attributes',
          id: 'output_data_attributes',
        },
      }));
    }
  }, [JSON.stringify(fields), ruleType]);

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionListObjs: NectedSuggestionModel[] = [];

    const resultAddDataModels: ResultAddDataModel[] = [];

    const outputDataAttributes: Record<string, Attributes> = {};

    results?.forEach((field, currIndex) => {
      const fieldKey = getRequiredKey(field, ['id']);
      const currentResult = field[fieldKey];

      const rowKey = getRequiredKey(rows[0], ['id']);
      const firstRow = rows[0][rowKey];

      const ruleResultKey = getRequiredKey(firstRow.ruleResult[currIndex], [
        'id',
      ]);

      const firstOutput = firstRow.ruleResult[currIndex]?.[ruleResultKey] ?? {};

      const { source, attribute } = firstOutput;

      const mappedValue =
        !_isNil(source) &&
        !_isNil(attribute) &&
        !_isNil(dataSetVariables[source])
          ? getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataSetVariables[source].attributes).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataSetVariables[source].attributes[`${curr}`]
                            .executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              attribute
            )
          : firstOutput.value;

      if (
        currentResult.dataType !== 'json' &&
        currentResult.dataType !== 'jsFormula' &&
        currentResult.dataType !== 'excelFormula' &&
        currentResult.dataType !== 'list'
      ) {
        suggestionList.push(`"<<outputData.${currentResult.keyName}>>"`);
        suggestionListObjs.push({
          name: `"<<outputData.${currentResult.keyName}>>"`,
          value: `"<<outputData.${currentResult.keyName}>>"`,
          meta: currentResult.dataType,
          score: TokenScores.outputData,
          executedValue: firstOutput.value,
        });

        if (currIndex < index) {
          outputDataAttributes[currentResult.keyName] = {
            name: currentResult.keyName,
            dataType: currentResult.dataType as DataTypes,
            executedValue: convertCaSampleValues(
              currentResult.dataType,
              mappedValue
            ),
          };
        }
      } else {
        suggestionList.push(`<<outputData.${currentResult.keyName}>>`);

        suggestionListObjs.push({
          name: `<<outputData.${currentResult.keyName}>>`,
          value: `<<outputData.${currentResult.keyName}>>`,
          meta: TokenDataTypeByDataTypeSingle[currentResult.dataType],
          score: TokenScores.outputData,
          executedValue: getOutputDataByExecutedValue(
            currentResult,
            false,
            firstOutput
          ),
        });

        if (currIndex < index) {
          // TODO: Might need to change dataType and add all executed values depending on editor support
          outputDataAttributes[currentResult.keyName] = {
            name: currentResult.keyName,
            dataType: (['jsFormula', 'excelFormula'].includes(
              currentResult.dataType
            )
              ? (getDataTypeByReturnType(firstOutput) as string)
              : TokenDataTypeByDataType[currentResult.dataType]) as DataTypes,
            executedValue: getOutputDataByExecutedValue(
              currentResult,
              false,
              firstOutput
            ),
          };
        }
      }

      resultAddDataModels.push({
        keyName: currentResult.keyName,
        dataType: currentResult.dataType,
        value: '',
      });
    });

    if (ruleType === 'decisionTable') {
      setSuggestionsObj(suggestionListObjs);
      setDataSetResults(resultAddDataModels);
      setSuggestions(suggestionList);

      setUpdatedDataset((prev) => ({
        ...prev,
        outputData: {
          attributes: outputDataAttributes,
          name: 'Output Data Attributes',
          id: 'output_data_attributes',
        },
      }));
    }
  }, [JSON.stringify(results), ruleType, JSON.stringify(dataSetVariables)]);

  const comment = getTooltipText(
    siteConstants,
    'rules',
    isJsonInCustomInput ? `${type}InCustomInput` : `${type}InResult`,
    'otherText'
  );

  const handleSave = () => {
    if (typeof onClose === 'function') {
      onClose();
    }

    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    if (_isEmpty(localQuery)) {
      toasts.warning('Put your JSON comment here', 'warning');
    }

    if (_isNil(executedValueName)) {
      if (typeof setOriginalValue === 'function') {
        setOriginalValue(name, localQuery);
      }

      if (typeof onChangeSpecial === 'function') {
        onChangeSpecial(localQuery);
      }

      close();
    }

    setIsReturnTypeRequired(true);
  };

  const onSetReturnType = (type: any) => {
    if (type === 'undefined' || type === null) {
      setReturnType('undefined');

      toasts.error(
        'The type of the last statement cannot be null or undefined',
        'type-null-undefined'
      );
    }

    setReturnType(type);
    setIsReturnTypeRequired(false);
  };

  const { tokens: dataSetSuggestionsObj } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  useEffect(() => {
    let filteredDataSetVariablesObj = filterDataSetSuggestionObjBySection(
      dataSetSuggestionsObj,
      section
    );

    if (hideOptionalCustomAttributes) {
      filteredDataSetVariablesObj = getFilteredDataSetObjWithoutOptional(
        filteredDataSetVariablesObj,
        customAttributes
      );
    }

    setDataSetTokensObjs([...filteredDataSetVariablesObj, ...suggestionObjs]);
  }, [JSON.stringify(dataSetSuggestionsObj)]);

  useEffect(() => {
    setUpdatedDataset((prev) => ({
      ...prev,
      ...dataSet,
    }));
  }, [JSON.stringify(dataSet)]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const dataSetSuggestions = _reduce(
        dataSetVariables,
        (result: string[], value, key) => {
          if (!_isNil(value.attributes)) {
            return [
              ...result,
              ..._map(value.attributes, (attributeValue, attributeKey) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return `"<<${key}.${attributeKey}>>"`;
                }

                return `<<${key}.${attributeKey}>>`;
              }),
            ];
          }

          return result;
        },
        []
      );

      setUpdatedDataset((prev) => ({
        ...prev,
        ...updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected,
          false,
          true
        ),
      }));

      let tokenSuggestions = suggestions.reduce<string[]>(
        (result, curr, currentIndex) => {
          if (index > currentIndex) {
            return [...result, curr];
          }

          return result;
        },
        []
      );

      let filteredDataSetVariables = filterDataSetSuggestionsBySection(
        dataSetSuggestions,
        section
      );

      if (hideOptionalCustomAttributes) {
        filteredDataSetVariables = getFilteredDataSetWithoutOptional(
          filteredDataSetVariables,
          customAttributes
        );

        tokenSuggestions = getFilteredDataSetWithoutOptional(
          tokenSuggestions,
          customAttributes
        );
      }

      setDataSetTokens([...filteredDataSetVariables, ...tokenSuggestions]);
    }
  }, [
    JSON.stringify(dataSetVariables),
    JSON.stringify(suggestions),
    JSON.stringify(suggestionObjs),
    index,
    currentSection,
    JSON.stringify(customAttributes),
  ]);

  useEffect(() => {
    if (
      !_isEmpty(localQuery) &&
      isMounted &&
      typeof setOriginalValue === 'function'
    ) {
      if (
        !_isNil(executedValueName) &&
        !_isEmpty(executedValueName) &&
        !_isNil(executedValue)
      ) {
        setOriginalValue(executedValueName, executedValue);
        setOriginalValue(name, localQuery);
        close();
      }
    }
  }, [executedValue]);

  const onExecutionValueSave = (execValue: any) => {
    if (!_isUndefined(execValue)) {
      setExecutedValue(execValue);
    } else if (!_isNil(executedValueName) || !_isNil(returnTypeName)) {
      setExecutedValue(undefined);
      openSaveNodeModal({
        onSave: () => {
          if (!_isNil(setOriginalValue)) {
            if (!_isNil(returnTypeName) && !_isEmpty(returnTypeName)) {
              setOriginalValue(returnTypeName, undefined);
            }

            if (!_isNil(executedValueName) && !_isEmpty(executedValueName)) {
              setOriginalValue(executedValueName, undefined);
            }

            setOriginalValue(name, watch('nodeQuery'));
          }

          close();
        },
      });
    }
  };

  const stringWithReplacedToken = !_isEmpty(localQuery)
    ? sanitizedStringV2(localQuery ?? '', updatedDataset)
    : localQuery;

  return (
    <Sheet
      onClose={() => {
        if (hasChanged) {
          onCloseModal();
        } else {
          close();
        }
      }}
      size={size}
    >
      <JsonBlockContainer as="form" onSubmit={handleSubmit(handleSave)}>
        <PadBox padding="1rem">
          <Stack gutter="2rem">
            <Inline align="center" stretch="start">
              <Typography name="heading1">
                {type === 'json' ? 'JSON' : 'List'} Editor
              </Typography>
              <HowToLink
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'jsonEditorHowTo',
                  'howToLinks'
                )}
              />
            </Inline>
            <JsonFieldContainer>
              <NectedEditorField
                name="nodeQuery"
                control={localControl}
                showError={false}
                customSuggestions={hideSuggestions ? [] : datasetTokensObj}
                defaultValue={comment}
                mode={type as EditorLanguages}
                setReturnType={onSetReturnType}
                onSetEditorValidity={setIsQueryValidNected}
                setExecutedValue={onExecutionValueSave}
                sendReturnType={isReturnTypeRequired}
                setHasEditorChanged={setHasChanged}
                domain={editorDomain}
                execValues={execValues}
                handleGetExecData={handleGetExecutionValues}
                readOnly={(isReadOnly && from !== 'test-sheet') || disabled}
              />
            </JsonFieldContainer>

            {!_isEmpty(localQuery) &&
              !isValidJson(stringWithReplacedToken) &&
              localQuery !== comment && (
                <SyntaxErrorContainer padding="1rem">
                  <Typography>
                    The statement written here is syntactically incorrect
                  </Typography>
                </SyntaxErrorContainer>
              )}
          </Stack>
        </PadBox>
        <SaveButtonContainer padding={[4, 8]}>
          <Inline justify="end">
            <Button
              appearance="contained"
              type="submit"
              disabled={(isReadOnly && from !== 'test-sheet') || disabled}
            >
              Save
            </Button>
          </Inline>
        </SaveButtonContainer>
      </JsonBlockContainer>
    </Sheet>
  );
}
