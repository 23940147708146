import { gql, useMutation } from '@apollo/client';

const discardWorkflowQuery = gql`
  mutation DiscardWorkflow($id: String!, $checksum: String!) {
    discardWorkflow(input: { id: $id, checksum: $checksum }) {
      data {
        id
        name
        version
        description
        checksum
        status
        isLive
        approvalInfo {
          title
          description
          approvers
        }
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
        staticUrl
        createdAt
        publishedAt
        nodes {
          draggable
          id
          position
          style
          sourcePosition
          targetPosition
          type
          data {
            input
            output
            settings
            executedValue
            nodeType
            entity
            entityId
            name
            runInLoop {
              status
              attribute
              source
            }
            sourceIds
            isMergeNode
            rootId
            status
          }
        }
        edges {
          style
          id
          markerEnd
          label
          source
          target
          animated
          sourceHandle
          type
          data {
            edgeType
          }
        }
      }
    }
  }
`;

export function useDiscardWorkflow() {
  return useMutation(discardWorkflowQuery);
}
