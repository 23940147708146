import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ChangeEvent, Ref, forwardRef, useEffect, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { ExecutedValueTooltip, TooltipReact, Typography } from 'ui';

import { CalenderIconPicker } from '../../../../../components/CalenderIconPicker/CalenderIconPicker';
import {
  DATE_TIME_FORMAT,
  convertArrayAsInput,
  convertArrayToString,
  formatNectedDate,
  getPropertyIfExists,
  isArrayAsInputValid,
  isFieldReadOnly,
} from '../../../../../utils/common';
import { LIST_KEY_REGEX } from '../../../../../utils/regex';
import { sendEventToGTMType } from '../../../types';
import {
  getPlaceholderByDataType,
  primitiveDataTypesList,
  validateRhsValue,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ErrorPopoverPositioned } from '../../SimpleRule/Error/ErrorPopoverPositioned';
import { decisionTableNodesAtom } from '../DecisionTable';
import {
  InputBrackets,
  InputContainer,
  InputStyled,
  RoundIcon,
} from './RhsNode.styled';

type RhsLauncherProps = Omit<UseControllerProps<any>, 'name'> & {
  nodeId: string;
  nodeName: string;
  conditionKey: string;
  setValue: UseFormSetValue<any>;
  text?: string;
  panelVisible?: boolean;
  error?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  dataType?: string;
  selectedOperator?: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RhsLauncher = forwardRef(
  (
    {
      text,
      nodeId,
      panelVisible = false,
      control,
      setValue,
      nodeName,
      conditionKey,
      error,
      onFocus,
      onBlur,
      disabled,
      dataType,
      selectedOperator,
      handleSendEventToGTM,
    }: RhsLauncherProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
    const [dataset] = useAtom(dataSetParamsAtom);
    const [localSourceType, setLocalSourceType] = useState<string>();

    const nodeType = !_isNil(nodes[nodeId])
      ? nodes[nodeId].nodeType
      : 'constant';

    const sourceType = nodes[nodeId]?.sourceType;
    const attribute = nodes[nodeId]?.attribute;
    const rhsDataType = nodes[nodeId]?.dataType;

    const value = useWatch({
      control,
      name: `${nodeName}.value`,
    });

    const [localValue, setLocalValue] = useState(value ?? '');

    useEffect(() => {
      if (nodeType !== 'constant') {
        setLocalSourceType(sourceType);
      }
    }, [sourceType]);

    const toolTipMessage =
      nodeType !== 'constant' &&
      !_isNil(localSourceType) &&
      !_isEmpty(localSourceType) &&
      !_isNil(dataset[localSourceType])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[localSourceType].attributes).reduce(
                  (acc, curr) => {
                    return {
                      ...acc,
                      [curr]:
                        dataset[localSourceType ?? ''].attributes[`${curr}`]
                          .executedValue,
                    };
                  },
                  {}
                )
              )
            ) ?? {},
            attribute ?? ''
          )
        : JSON.stringify(value);

    const returnDataType = (datatype?: string) => {
      const type = datatype ?? dataType;

      if (
        type === 'list' &&
        !['contains', 'notContains'].includes(selectedOperator ?? '')
      ) {
        return type;
      }

      const isValidOperator = [
        'notContainsIn',
        'containsIn',
        'in',
        'nin',
      ].includes(selectedOperator ?? '');

      if (primitiveDataTypesList.includes(type ?? '') && isValidOperator) {
        if (isArrayAsInputValid(value) && type === 'string') {
          return 'list';
        }

        if (type !== 'string') {
          return 'list';
        }
      }

      return dataType;
    };

    const transformListValue = (type: string, value: any) => {
      if (type === 'LIST_TO_STRING') {
        if (Array.isArray(value)) {
          const convertedValue = convertArrayToString(value);

          return _isEmpty(convertedValue) ? '' : `${convertedValue}`;
        } else {
          return value;
        }
      }

      if (type === 'STRING_TO_LIST') {
        if (typeof value === 'string') {
          return convertArrayAsInput(value);
        } else {
          return [value] ?? [];
        }
      }

      return value;
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const localNodeType = LIST_KEY_REGEX.test(localValue)
        ? 'params'
        : 'constant';

      setLocalSourceType(localNodeType);

      setLocalValue(e.target.value);
    };

    useEffect(() => {
      if (!_isNil(sourceType) && sourceType !== '') {
        setLocalValue(`${attribute ?? ''}`);
      }
    }, [JSON.stringify(sourceType), JSON.stringify(attribute)]);

    useEffect(() => {
      if (
        (_isNil(sourceType) || sourceType === '') &&
        value !== localValue &&
        !_isNil(value) &&
        value !== ''
      ) {
        setLocalValue(value);
      }
    }, [JSON.stringify(value)]);

    const showExpandIcon =
      nodeType === 'constant' && returnDataType() === 'list';

    const formatValue = (input: any) => {
      if (returnDataType() === 'list') {
        return transformListValue('LIST_TO_STRING', input);
      }

      if (returnDataType() === 'date') {
        try {
          return formatNectedDate(input, 'date');
        } catch (err) {
          return input;
        }
      }

      if (returnDataType() === 'dateTime') {
        try {
          return formatNectedDate(input, 'dateTime');
        } catch (err) {
          return input;
        }
      }

      return input;
    };

    return (
      <Inline>
        <InputContainer align="center" gutter={0}>
          {nodeType === 'params' && (
            <InputBrackets direction="left">
              <Typography>{'{{'}</Typography>
            </InputBrackets>
          )}

          {nodeType === 'constant' && returnDataType() === 'list' && (
            <InputBrackets direction="left">
              <Typography>{'['}</Typography>
            </InputBrackets>
          )}

          {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

          <ExecutedValueTooltip
            attribute={attribute}
            value={
              returnDataType() === 'list'
                ? transformListValue('STRING_TO_LIST', toolTipMessage)
                : toolTipMessage
            }
            isVisible={nodeType !== 'constant'}
            dataType={rhsDataType}
            id={nodeId}
          >
            <InputStyled
              value={formatValue(localValue)}
              ref={ref}
              $hasError={false}
              $showExpandIcon={showExpandIcon}
              placeholder={getPlaceholderByDataType(dataType)}
              disabled={disabled}
              onChange={onChange}
              onFocus={onFocus}
              readOnly={isFieldReadOnly(returnDataType() ?? '')}
              onBlur={(e) => {
                if (!_isNil(onBlur)) {
                  onBlur();
                }

                const localNodeType = LIST_KEY_REGEX.test(e.target.value)
                  ? 'params'
                  : 'constant';

                if (localNodeType === 'constant') {
                  const result = validateRhsValue(
                    localValue,
                    returnDataType(),
                    localNodeType
                  );

                  if (result.isCorrect) {
                    setNodes((prev) => ({
                      ...prev,
                      [nodeId]: {
                        ...prev[nodeId],
                        nodeType: 'constant',
                        value:
                          returnDataType() === 'list'
                            ? transformListValue('STRING_TO_LIST', result.value)
                            : result.value,
                        sourceType: '',
                        attribute: '',
                      },
                    }));
                    setValue(`${nodeName}.value`, localValue);
                  }
                } else if (localNodeType === 'params') {
                  setNodes((prev) => ({
                    ...prev,
                    [nodeId]: {
                      ...prev[nodeId],
                      nodeType: 'params',
                      sourceType,
                      attribute: localValue,
                      value: '',
                    },
                  }));
                }
              }}
            />
          </ExecutedValueTooltip>

          {nodeType === 'params' && (
            <InputBrackets direction="right">
              <Typography>{'}}'}</Typography>
            </InputBrackets>
          )}

          {nodeType === 'constant' && returnDataType() === 'list' && (
            <InputBrackets direction="right">
              <Typography>{']'}</Typography>
            </InputBrackets>
          )}

          {value === '' && dataType === 'dateTime' && (
            <InputBrackets direction="right">
              <TooltipReact id={nodeId}>
                Enter Date in {DATE_TIME_FORMAT} format
              </TooltipReact>
            </InputBrackets>
          )}
        </InputContainer>

        {['dateTime', 'date'].includes(rhsDataType ?? '') &&
          returnDataType() !== 'list' && (
            <RoundIcon type="button">
              <CalenderIconPicker
                value={value}
                dataType={rhsDataType ?? ''}
                disabled={disabled}
                onPick={(val) => {
                  const fValue = formatNectedDate(
                    val,
                    rhsDataType ?? 'date'
                  ) as unknown as string;

                  setNodes((prev) => ({
                    ...prev,
                    [nodeId]: {
                      ...prev[nodeId],
                      value: fValue,
                      sourceType: undefined,
                      attribute: undefined,
                      nodeType: 'constant',
                    },
                  }));

                  setValue(`${nodeName}.value`, fValue);
                  setValue(`${nodeName}.key`, '');
                }}
              />
            </RoundIcon>
          )}
      </Inline>
    );
  }
);

RhsLauncher.displayName = 'RhsLauncher';
