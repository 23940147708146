import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

import { Typography } from '../../Typography';

export const LeafNodeStyled = styled(PadBox)<{ $version?: 'v1' | 'v2' }>`
  cursor: pointer;
  ${({ $version = 'v1' }) =>
    $version === 'v1' ? '' : 'border-left:2px solid var(--color-dodgerBlue);'}

  &:hover {
    background-color: var(--color-aliceBlue);
    cursor: pointer;
  }
`;

export const SymbolText = styled(Typography)`
  background-color: var(--color-cyprus);
  color: var(--color-british-racing-greencolor-cyprus);
  border-radius: 4px;
  border: 1px solid var(--color-cyprus);
  text-align: center;
  padding: 0.4rem;
  margin-right: 0.8rem;
`;

export const StyledText = styled(Typography)<{ $version?: 'v1' | 'v2' }>`
  ${({ $version = 'v1' }) =>
    $version === 'v1' ? '' : 'color: var(--color-paleDodgerBlue);'}
`;
