/* eslint-disable no-extra-boolean-cast */
import type { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { parse } from 'date-fns';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isString from 'lodash/isString';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import { UseFormSetError } from 'react-hook-form';
import {
  Attributes,
  DataTypes,
  Dataset,
  NectedSuggestionModel,
  OperatorsProps,
  RestAPIEditorPayload,
  SimpleDropDownModel,
  flattenKeysAndTypes,
  getDataTypeNected,
} from 'ui';

import {
  DataTypeDropdownResult,
  dataTypes,
} from '../../../components/rules/DataTypeDropdown/DataTypeDropdown';
import { TokensSetProps } from '../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { AllowedDatatypesProps, SiteConstantsModel } from '../../../types';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  capitalizeHeaderKey,
  convertArrayAsInput,
  convertArrayToString,
  convertCaSampleValues,
  formatNectedDate,
  generateUid,
  getOutputValueParsed,
  getPropertyIfExists,
  getTimeZone,
  getTooltipText,
  isArrayAsInputValid,
  isDateStringValid,
  isValidJS,
  isValidJson,
  transformSampleValue,
} from '../../../utils/common';
import {
  CRON_UNITS,
  POST_REQUESTS,
  SQL_COMMANDS,
  bodyParamTypes,
  datatypesToIgnoreOnValidation,
  defaultSchedulerValues,
} from '../../../utils/constant';
import {
  ACTION_KEY_REGEX,
  FLOATING_NUMBER_REGEX,
  KEY_REGEX,
  REMOVE_JAVASCRIPT_COMMENTS_REGEX,
  TOKEN_REGEX,
} from '../../../utils/regex';
import { StatusCode } from '../../../utils/response/statusCode';
import { isValidDate, isValidDateTime } from '../../../utils/validation';
import { nodeSqlParserSupportedDatabases } from '../../DataSets/components/DataSetForm';
import { EditorConfigurationDetails } from '../../DataSets/types';
import {
  getEditorDetailsByPlugin,
  isMongoActionValid,
  nodeSqlParser,
} from '../../DataSets/utils';
import { ConnectorAndPluginModel } from '../../Integrations/types';
import {
  checkAttributeIsValid,
  checkUsedTokensAreValid,
  sanitizedStringV2,
} from '../../Workflow/utils/common';
import { RuleTypes } from '../components/CreateRuleSheet/CreateRuleSheet';
import { AdditionalActionFormData } from '../components/DecisionTable/models';
import { PublishedConnectors } from '../components/DecisionTable/types';
import type {
  CustomAttributeByRuleId,
  RuleChainPayloadModel,
  RuleListDataModel,
  RuleResultMappedModel,
  RuleSetNodesModel,
  RuleSetPayloadModel,
  RulesetModel,
} from '../components/RuleSet/models';
import { ResultActionType, ResultType } from '../components/SimpleRule/Results';
import { TestNodesDataModel } from '../components/SimpleRule/TestNodeSheet/TestNodeSheet';
import {
  ActionThenModel,
  AuthenticationDropdownModel,
  CustomInputModel,
  ErrorByNodeId,
  NodeErrorModel,
  ProductionConfigModel,
  ResultAddDataModel,
  RuleCronScheduleModel,
  SimpleRuleModel,
  SimpleRuleNodesModel,
  SimpleRulePayloadModel,
  SimpleRuleTestDataModel,
  StagingConfigModel,
  TriggerEnvironmentModel,
} from '../components/SimpleRule/models';
import { dataTypeRules } from '../fixtures/DataTypeRules';
import { operators } from '../fixtures/operators';
import type {
  AttributeModel,
  CronCAObjectModel,
  LocalCronRuleModel,
} from '../models';
import type {
  ActionConfig,
  ActionConfigFE,
  ActionInfoObject,
  DuplicateKeyIndexes,
  FieldsByID,
  ResultAction,
  RuleTestActionObject,
  RuleTestActionResponse,
  RuleType,
} from '../types';

const numericTypes = ['numeric', 'float'];
const stringTypes = ['string'];
const dateTypes = ['date'];
const dateTimeTypes = ['datetime', 'dateTime'];
const booleanTypes = ['boolean'];
const listTypes = ['list'];
const genericTypes = ['generic'];

export const primitiveDataTypesList = [
  'string',
  'numeric',
  'date',
  'dateTime',
  'boolean',
  'json',
  'restAPI',
  'list',
];

export const allConditionTypes = [
  'string',
  'numeric',
  'date',
  'dateTime',
  'boolean',
  'restAPI',
  'list',
  'jsCondition',
  'excelCondition',
  'generic',
];

export const handleAddChildByGroup = (
  parentId: string,
  type: 'group' | 'condition',
  rules: Record<string, SimpleRuleNodesModel>,
  operator?: string
) => {
  const newRules = { ...rules };

  if (!_isNil(operator)) {
    newRules[parentId].operator = operator;
  }

  const id = generateUid('rule_');
  const leftNodeId = generateUid('rule_');
  const rightNodeId = generateUid('rule_');

  const signingsByOrder = getNodeIdsSortedBySiblingIndex(rules, parentId);

  const lastChildSiblingIndex = !_isNil(signingsByOrder)
    ? rules[signingsByOrder[signingsByOrder.length - 1]].siblingIndex
    : 0;

  if (type === 'condition') {
    newRules[id] = {
      parent: parentId,
      nodeType: 'condition',
      operator: '',
      dataType: '',
      siblingIndex: lastChildSiblingIndex + 1,
      leftNode: [leftNodeId],
      rightNode: [rightNodeId],
    };

    newRules[leftNodeId] = getParamNode(id);
    newRules[rightNodeId] = getConstantNode(id, '');
  } else {
    const conditionNodeId = generateUid('rule_');

    newRules[id] = {
      parent: parentId,
      nodeType: 'group',
      operator: 'and',
      children: [conditionNodeId],
      siblingIndex: lastChildSiblingIndex + 1,
    };

    newRules[conditionNodeId] = {
      parent: id,
      nodeType: 'condition',
      operator: '',
      siblingIndex: 1,
      leftNode: [leftNodeId],
      rightNode: [rightNodeId],
    };

    newRules[leftNodeId] = getParamNode(conditionNodeId);
    newRules[rightNodeId] = getConstantNode(conditionNodeId, '');
  }

  if (!_isNil(newRules[parentId].children)) {
    const children = !_isNil(newRules[parentId].children)
      ? newRules[parentId].children
      : [];

    children?.push(id);

    newRules[parentId].children = children;
  }

  return newRules;
};

export const getParamNode = (parent: string = '', siblingIndex?: number) => {
  return {
    siblingIndex: siblingIndex ?? 1,
    nodeType: 'params',
    sourceType: '',
    attribute: '',
    parent,
  };
};

export const getConstantNode = (
  parent: string,
  type: string,
  siblingIndex?: number
) => {
  return {
    siblingIndex: siblingIndex ?? 1,
    nodeType: 'constant',
    value: type === 'list' ? [] : '',
    parent,
    dataType: type === 'list' ? 'list' : '',
  };
};

export const getGroupNode = (
  parent: string,
  children: string[],
  operator: string = ''
) => {
  return {
    nodeType: 'group',
    operator,
    parent,
    children,
  };
};

// countToPublish is the count which prevents the autosave to run editRule
export const countToPublish = 0;

export const handleDeleteElement = (
  ruleId: string,
  rules: Record<string, SimpleRuleNodesModel>
) => {
  const elementsToBeDeletedIds: string[] = [];
  getElementIds(rules[ruleId], ruleId, elementsToBeDeletedIds, rules);

  const newRules = { ...rules };
  const updatedRules: Record<string, SimpleRuleNodesModel> = {};

  const ruleChildren = newRules[rules[ruleId].parent].children;

  if (!_isNil(rules[ruleId].parent) && !_isNil(ruleChildren)) {
    const children = ruleChildren.filter((child) => child !== ruleId);

    newRules[rules[ruleId].parent].children = children;

    if (!_isNil(children)) {
      let isIndex = false;

      const siblingIndexById: Record<string, number> = {};

      rules[ruleId]?.children?.forEach((child: string) => {
        const siblingIndex = rules[child].siblingIndex;
        const value = !_isNil(siblingIndex) ? siblingIndex : -1;

        if (!_isNil(value) && value !== -1) {
          siblingIndexById[child] = value;
        }
      });

      const sortedSiblingIndexById = Object.entries(siblingIndexById)
        .sort(
          ([, siblingOneIndex], [, siblingTwoIndex]) =>
            siblingOneIndex - siblingTwoIndex
        )
        .map(([siblingRuleId]) => siblingRuleId);

      if (sortedSiblingIndexById.length > 0) {
        sortedSiblingIndexById.forEach((key) => {
          let sibIndex = 1;
          const siblingIndex = newRules[key].siblingIndex;

          if (!_isNil(siblingIndex)) {
            sibIndex = siblingIndex;
          }

          if (isIndex && !_isNil(siblingIndex)) {
            newRules[key].siblingIndex = !_isNil(sibIndex) ? sibIndex - 1 : 1;
          }

          if (key === ruleId) {
            isIndex = true;
          }
        });
      }
    }
  }

  if (elementsToBeDeletedIds.length > 0) {
    Object.keys(newRules).forEach((id) => {
      if (!elementsToBeDeletedIds.includes(id)) {
        updatedRules[id] = newRules[id];
      }
    });
  }

  return updatedRules;
};

const getElementIds = (
  rule: SimpleRuleNodesModel,
  ruleId: string,
  idList: string[],
  rules: Record<string, SimpleRuleNodesModel>
) => {
  if (!idList.includes(ruleId)) {
    idList.push(ruleId);

    if (!_isNil(rule.leftNode)) {
      idList.push(...rule.leftNode);
    }

    if (!_isNil(rule.rightNode)) {
      idList.push(...rule.rightNode);
    }

    if (
      rule.nodeType === 'group' &&
      !_isNil(rule.parent) &&
      !_isNil(rule.children) &&
      rule.children.length > 0
    ) {
      rule.children.map((child) =>
        getElementIds(rules[child], child, idList, rules)
      );
    }
  }

  return null;
};

export const handleDatatypeComparison = (
  dataType: string,
  nodeDatatype?: string
) => {
  if (_isUndefined(nodeDatatype) || _isEmpty(nodeDatatype)) {
    return false;
  }

  const isNumeric =
    nodeDatatype === 'numeric' && numericTypes.includes(dataType);

  const isString = nodeDatatype === 'string' && stringTypes.includes(dataType);

  const isBoolean =
    nodeDatatype === 'boolean' && booleanTypes.includes(dataType);

  const isDate = nodeDatatype === 'date' && dateTypes.includes(dataType);

  const isDateTime =
    nodeDatatype === 'dateTime' && dateTimeTypes.includes(dataType);

  const isArray = nodeDatatype === 'list' && listTypes.includes(dataType);

  return isNumeric || isString || isBoolean || isDate || isDateTime || isArray;
};

export const getDataTypeByParamType = (dataType: string) => {
  if (numericTypes.includes(dataType)) {
    return 'numeric';
  } else if (stringTypes.includes(dataType)) {
    return 'string';
  } else if (booleanTypes.includes(dataType)) {
    return 'boolean';
  } else if (dateTypes.includes(dataType)) {
    return 'date';
  } else if (dateTimeTypes.includes(dataType)) {
    return 'dateTime';
  } else if (listTypes.includes(dataType)) {
    return 'list';
  } else if (genericTypes.includes(dataType)) {
    return 'generic';
  }

  return 'numeric';
};

export const getOperatorTextById = (
  operators: OperatorsProps,
  operator?: string,
  dataType?: string
) => {
  if (
    !_isUndefined(dataType) &&
    !_isUndefined(operator) &&
    !_isEmpty(dataType) &&
    !_isEmpty(operator)
  ) {
    return !_isNil(operators[dataType]?.ops[operator])
      ? operators[dataType].ops[operator].symbol
      : operators.generic.ops[operator]?.symbol;
  }

  return undefined;
};

export const getNodeIdsSortedBySiblingIndex = (
  rules: Record<string, SimpleRuleNodesModel>,
  ruleId: string
) => {
  if (_isUndefined(rules[ruleId].children)) return [];

  const siblingIndexById: Record<string, number> = {};

  rules[ruleId]?.children?.forEach((child: string) => {
    const value = rules[child]?.siblingIndex;

    if (!_isNil(value) && value !== -1) {
      siblingIndexById[child] = value;
    }
  });

  const siblingIndexes = Object.entries(siblingIndexById)
    .sort(
      ([, siblingOneIndex], [, siblingTwoIndex]) =>
        siblingOneIndex - siblingTwoIndex
    )
    .map(([siblingRuleId]) => siblingRuleId);

  // eslint-disable-next-line
  return rules[ruleId]?.children?.length ?? 0 > siblingIndexes.length
    ? rules[ruleId]?.children ?? []
    : siblingIndexes;
};

export const getRhsNodeTitle = (
  rules: Record<string, any>,
  nodeId: string,
  dataset: Record<string, Dataset>
) => {
  if (!_isNil(rules[nodeId]) && rules[nodeId].nodeType === 'params') {
    const attribute = rules[nodeId].attribute?.split('.')[0];
    const source = rules[nodeId].sourceType;

    return !_isUndefined(source) &&
      !_isUndefined(attribute) &&
      !_isEmpty(source) &&
      !_isEmpty(attribute) &&
      !_isNil(dataset[source]) &&
      !_isUndefined(dataset[source].attributes[attribute])
      ? rules[nodeId].attribute
      : undefined;
  }

  return !_isNil(rules[nodeId]) && !_isUndefined(rules[nodeId].value)
    ? rules[nodeId].value
    : undefined;
};

export const getRhsNodeTitleV2 = (
  rules: Record<string, SimpleRuleNodesModel>,
  nodeId: string
) => {
  const node = rules[nodeId];

  return !_isNil(node)
    ? !_isNil(node.value) && node.value !== ''
      ? node.value
      : node.attribute
    : '';
};

export const getDataSetByType = (
  dataset: Record<string, Dataset>,
  type: string,
  dataSetSelected: string[] = [],
  selectedOperator: string = '',
  allowList: boolean = false
) => {
  const dataSetByType: Record<string, Dataset> = {};
  const allowedDatatypes = dataTypeRules;

  Object.keys(dataset).forEach((key) => {
    const attributes = dataset[key].attributes;
    const filteredAttributesMap = filterAttributeObj(
      allowedDatatypes,
      attributes,
      selectedOperator,
      type,
      allowList
    );

    if (!_isEmpty(filteredAttributesMap)) {
      dataSetByType[key] = {
        name: dataset[key].name,
        id: dataset[key].id,
        attributes: filteredAttributesMap,
        tooltip: dataset[key].tooltip,
      };
    }
  });

  return dataSetByType;
};

const filterAttributeObj = (
  allowedDatatypes: AllowedDatatypesProps[],
  attributes: Record<string, Attributes>,
  selectedOperator: string,
  type: string,
  allowList: boolean = true
) => {
  const updatedAttributes: Record<string, Attributes> = {};
  const filteredAllowedDataTypes: AllowedDatatypesProps[] = [];

  allowedDatatypes.forEach((i) => {
    if (i.type.includes(type) && i.operators.includes(selectedOperator)) {
      filteredAllowedDataTypes.push(i);
    }
  });

  if (filteredAllowedDataTypes.length === 0) {
    filteredAllowedDataTypes.push({
      type: [`${type}`],
      operators: ['*'],
      includes: [`${type}`],
    });
  }

  Object.keys(attributes).forEach((attribute) => {
    filteredAllowedDataTypes.forEach((element) => {
      if (
        (element.operators.includes(selectedOperator) ||
          element.operators[0] === '*') &&
        element.includes.includes(attributes[attribute].dataType)
      ) {
        updatedAttributes[attribute] = attributes[attribute];
      }

      if (
        (element.operators.includes(selectedOperator) ||
          element.operators[0] === '*') &&
        ['restAPI', 'json', 'list'].includes(attributes[attribute].dataType)
      ) {
        const dataType =
          ['numeric', 'boolean', 'date', 'dateTime'].includes(type) &&
          ['in', 'nin'].includes(selectedOperator)
            ? 'list'
            : type;

        const includeList =
          ['in', 'nin'].includes(selectedOperator) && type === 'string';

        const allowedtypes = [dataType];

        if (includeList) {
          allowedtypes.push('list');
        }

        updatedAttributes[attribute] = getAttributesDropDown(
          attributes[attribute],
          !allowList,
          allowedtypes
        );
      }
    });
  });

  return updatedAttributes;
};

export const getTypesToAllowForConditionNodes = (
  dataType?: string,
  selectedOperator?: string
) => {
  if (_isUndefined(dataType)) {
    return [];
  }

  for (const currentTypeRule of dataTypeRules) {
    const { type, operators, includes } = currentTypeRule;

    if (type.includes(dataType) && operators.includes(selectedOperator ?? '')) {
      return includes;
    }
  }

  return [dataType, 'generic'];
};

export const getAttributesDropDown = (
  attribute: Attributes,
  filterNeeded: boolean,
  conditionType: string[]
) => {
  if (['restAPI', 'json'].includes(attribute.dataType)) {
    return {
      ...attribute,
      executedValue: filterNeeded
        ? filterObjectByType(attribute.executedValue ?? {}, conditionType)
        : attribute.executedValue,
    };
  }

  return attribute;
};

// function filterObjectByType(
//   obj: Record<string, any>,
//   targetType: string,
//   includeList = false
// ): Record<string, any> {
//   const result: Record<string, any> = {};

//   for (const key in obj) {
//     if (!_isNil(obj[key])) {
//       const value = obj[key];

//       if (
//         // eslint-disable-next-line
//         typeof value === targetType ||
//         (typeof value === 'number' && targetType === 'numeric')
//       ) {
//         result[key] = value;
//       } else if (
//         // eslint-disable-next-line
//         typeof value === 'object' &&
//         !Array.isArray(value)
//       ) {
//         const filteredSubObject = filterObjectByType(
//           value,
//           targetType,
//           includeList
//         );

//         if (Object.keys(filteredSubObject).length > 0) {
//           result[key] = filteredSubObject;
//         }
//       } else if (targetType === 'list' && Array.isArray(value)) {
//         result[key] = value;
//       }
//     }
//   }

//   return result;
// }

function filterObjectByType(
  obj: Record<string, any>,
  targetTypes: string[]
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (!_isNil(obj[key])) {
      const value = obj[key];
      const dataType = getDataTypeNected(value);

      if (
        targetTypes.includes(dataType) ||
        (targetTypes.includes('numeric') && dataType === 'number')
      ) {
        result[key] = value;
      } else if (dataType === 'object' && !Array.isArray(value)) {
        const filteredSubObject = filterObjectByType(value, targetTypes);

        if (Object.keys(filteredSubObject).length > 0) {
          result[key] = filteredSubObject;
        }
      } else if (targetTypes.includes('list') && Array.isArray(value)) {
        result[key] = value;
      }
    }
  }

  return result;
}

export const getFreshRulesRhs = (
  rules: Record<string, SimpleRuleNodesModel>,
  ruleId: string,
  rightNodes: string[],
  rightOperands: number,
  type: string
) => {
  const updatedRules: Record<string, SimpleRuleNodesModel> = {};
  Object.keys(rules).forEach((key) => {
    if (!rightNodes.includes(key)) {
      updatedRules[key] = rules[key];
    }
  });

  updatedRules[ruleId].rightNode = [];

  for (let i = 0; i < rightOperands; i++) {
    const newId = generateUid('rule_');

    updatedRules[ruleId].rightNode?.push(newId);
    updatedRules[newId] = getConstantNode(ruleId, type, i + 1);
  }

  return updatedRules;
};

export const customAttributesToPayloadObject = (
  customAttributes: Record<string, AttributeModel>
): Record<string, CustomInputModel> => {
  return Object.keys(customAttributes).reduce((customObject, key) => {
    return {
      ...customObject,
      [key]: {
        name: customAttributes[key].name,
        dataType: customAttributes[key].dataType?.value,
        isList: customAttributes[key].isList,
        isOptional: customAttributes[key].isOptional,
        isNullable: customAttributes[key].isNullable,
        isCaseSensitive: customAttributes[key].isCaseSensitive,
        sourceType: customAttributes[key].sourceType,
        attribute: customAttributes[key].attribute,
        executedValue: customAttributes[key].executedValue,
        config: customAttributes[key].config,
        next: customAttributes[key].next,
        previous: customAttributes[key].previous,
      },
    };
  }, {});
};

export const getResultDataRhsValue = (
  result: ResultAddDataModel,
  resultParams: ResultAddDataModel[],
  index: number
) => {
  const resultObject = {
    name: result.keyName,
    dataType: result.dataType,
    returnType: result.returnType ?? '',
    executedValue: result.executedValue ?? '',
    value: null,
    next: !_isNil(resultParams[index + 1])
      ? resultParams[index + 1].keyName
      : null,
    source: null,
    attribute: null,
  };

  if (
    !_isNil(result.source) &&
    !_isEmpty(result.source) &&
    !_isNil(result.attribute) &&
    !_isEmpty(result.attribute)
  ) {
    return {
      ...resultObject,
      value: null,
      source: result.source,
      attribute: result.attribute,
      executedValue: getDefaultValueByDataType(result.dataType),
    };
  }

  const value = getEvaluatedValueForResult(result.value, result.dataType);

  return {
    ...resultObject,
    value,
    executedValue: ['jsFormula', 'excelFormula', 'json', 'list'].includes(
      result.dataType
    )
      ? result.executedValue
      : getEvaluatedExValueForResult(result.value, result.dataType),
  };
};

export const getEvaluatedValueForResult = (
  value: any,
  dataType: string,
  isSource: boolean = false
) => {
  if (isSource) {
    return null;
  }

  if (dataType === 'numeric') {
    if (isNaN(value)) {
      return value.toString();
    }

    return parseFloat(value as string);
  } else if (dataType === 'boolean') {
    if (!['true', 'false'].includes(value)) {
      return value.toString();
    }

    return value === 'true';
  }

  return value;
};

export const getEvaluatedExValueForResult = (
  value: any,
  dataType: string,
  isSource: boolean = false
) => {
  if (isSource) {
    return null;
  }

  if (dataType === 'numeric') {
    if (isNaN(value)) {
      return value.toString();
    }

    return parseFloat(value as string);
  } else if (dataType === 'boolean') {
    if (!['true', 'false'].includes(value) && !_isNil(value)) {
      return value.toString();
    }

    return value === 'true';
  }

  if (dataType === 'json') {
    try {
      const json = typeof value === 'string' ? JSON.parse(value) : value;

      return json;
    } catch {
      return null;
    }
  }

  return value;
};

export const createResultParams = (
  resultParams: ResultAddDataModel[],
  actionParams: ResultAction[],
  type: 'then' | 'else'
) => {
  const outputData: Record<string, any> = {};
  let actionNode: Record<string, any> = {};

  resultParams.forEach((result, index) => {
    outputData[result.keyName] = getResultDataRhsValue(
      result,
      resultParams,
      index
    );
  });

  actionNode = handleSetActionParams(actionParams);

  return {
    firstOutputData: !_isNil(resultParams[0]) ? resultParams[0].keyName : '',
    firstActionNode: actionParams.length > 0 ? 'actionData_1' : '',
    outputData,
    actionNode,
  };
};

export const handleSetActionParams = (actionParams: ResultAction[]) => {
  const actionNode: Record<string, any> = {};

  actionParams.forEach((action, index) => {
    const currentId = `actionData_${index + 1}`;
    const nextId = `actionData_${index + 2}`;
    const next = !_isNil(actionParams[index + 1]) ? nextId : '';

    const config: ActionConfig = {};

    for (const key in action.config) {
      if (!_isNil(action.config[key])) {
        if (
          key === 'method' &&
          !_isNil(action.config.method) &&
          !_isEmpty(action.config.method)
        ) {
          config.method = action.config.method.value;
        } else if (
          key === 'contentType' &&
          !_isNil(action.config.contentType) &&
          !_isEmpty(action.config.contentType)
        ) {
          config.contentType = action.config.contentType.value;
        } else if (
          (key === 'body' &&
            !_isNil(action.config.method) &&
            !_isEmpty(action.config.method) &&
            POST_REQUESTS.includes(action.config.method.value)) ||
          key !== 'body'
        ) {
          config[key] = action.config[key];
        }
      }
    }
    actionNode[currentId] = {
      connectorId: action.connectorId,
      name: action.name,
      config,
      next,
    };
  });

  return actionNode;
};

export const transformSimpleRuleToRequestPayload = (
  data: SimpleRuleModel,
  customAttributes: Record<string, AttributeModel>,
  ruleList: Record<string, SimpleRuleNodesModel>,
  startNodeId: string,
  ruleId?: string,
  firstCustomInput: string = ''
) => {
  const name = data.ruleName;
  const description = data.ruleDescription;
  const customInput: Record<string, CustomInputModel> =
    customAttributesToPayloadObject(customAttributes);
  const startNode = startNodeId;
  const nodes = ruleList;
  const thenData = createResultParams(
    data.thenDataParams,
    data.thenActionParams,
    'then'
  );
  const elseData = createResultParams(
    data.elseDataParams,
    data.elseActionParams,
    'else'
  );

  const editMode = data.editMode;

  const trigger = getTrigger(data.stagingConfig, data.productionConfig);
  const type = 'simpleRule';
  const dataSet = {};

  const ruleJson: SimpleRulePayloadModel = {
    name,
    description,
    type,
    dataSet,
    editMode,
    customInput,
    firstCustomInput,
    rule: {
      conditions: {
        startNode,
        nodes,
      },
      action: {
        then: thenData,
        else: elseData,
      },
    },
    trigger,
  };

  if (!_isNil(ruleId)) {
    ruleJson.id = ruleId;
  }

  return ruleJson;
};

export const validateOutput = (
  name: string,
  value: any,
  dataType: string,
  source?: string | null,
  attribute?: string | null,
  tokens: string[] = [],
  setError?: UseFormSetError<any>,
  dataset?: Record<string, Dataset>
) => {
  let isValid = true;

  const isSetError = typeof setError === 'function';

  if (!_isNil(source) && !_isEmpty(source) && _isNil(dataset)) {
    return isValid;
  } else if (!_isNil(source) && !_isEmpty(source) && !_isNil(dataset)) {
    const mappedValue = getPropertyIfExists(
      JSON.parse(
        JSON.stringify(
          Object.keys(dataset[source]?.attributes ?? {}).reduce((acc, curr) => {
            return {
              ...acc,
              [curr]: dataset[source].attributes[`${curr}`].executedValue,
            };
          }, {})
        )
      ) ?? {},
      attribute ?? ''
    );

    const dType = getDataTypeNected(mappedValue);
    const typesToInclude = ['date', 'dateTime'].includes(dataType)
      ? ['date', 'dateTime']
      : [dataType];

    if (
      ![...typesToInclude, 'generic'].includes(dType) &&
      source !== 'systemVar' &&
      source !== 'dataSet'
    ) {
      if (isSetError) {
        setError(name, {
          message: 'Invalid value datatype',
          type: 'validate',
        });
      }

      return false;
    } else {
      return true;
    }
  }

  if (dataType === 'boolean') {
    if (!['true', 'false'].includes(value)) {
      if (isSetError) {
        setError(name, {
          message: 'Invalid boolean value',
          type: 'validate',
        });
      }

      isValid = false;
    }
  } else if (dataType === 'numeric') {
    if (isNaN(value) || value === '') {
      if (isSetError) {
        setError(name, {
          message: 'Invalid numeric value',
          type: 'validate',
        });
      }

      isValid = false;
    }
  } else if (dataType === 'date') {
    if (!isDateStringValid(value.toString())) {
      if (isSetError) {
        setError(name, {
          message: 'Invalid date value',
          type: 'validate',
        });
      }

      isValid = false;
    }
  } else if (dataType === 'dateTime') {
    if (!isValidDateTime(value)) {
      if (isSetError) {
        setError(name, {
          message: 'Invalid date time value',
          type: 'validate',
        });
      }

      isValid = false;
    }
  }

  return isValid;
};

export const getTrigger = (
  stagingData: StagingConfigModel,
  productionData: ProductionConfigModel
) => {
  const staging: TriggerEnvironmentModel = {
    isEnabled: stagingData.isEnabled,
    salience: stagingData.order,
    auditIO: stagingData.auditIO,
    api: {
      isEnabled: stagingData.isApiEnabled,
      isPrivate: stagingData.authType?.value !== 'none',
    },
  };

  const production: TriggerEnvironmentModel = {
    isEnabled: productionData.isEnabled,
    salience: productionData.order,
    auditIO: productionData.auditIO,
    api: {
      isEnabled: productionData.isApiEnabled,
      isPrivate: productionData.authType?.value !== 'none',
    },
    schedule: productionData.schedule,
  };

  if (!_isNil(stagingData.startDate)) {
    staging.startAt = stagingData.startDate.toISOString();
  }

  if (!_isNil(stagingData.endDate)) {
    staging.endAt = stagingData.endDate.toISOString();
  }

  if (!_isNil(productionData.startDate)) {
    production.startAt = productionData.startDate.toISOString();
  }

  if (!_isNil(productionData.endDate)) {
    production.endAt = productionData.endDate.toISOString();
  }

  return {
    staging,
    production,
  };
};

export const getDataTypeByValue = (
  value: string
): DataTypeDropdownResult | null => {
  const dataType = dataTypes.find((selectItem) => selectItem.value === value);

  return !_isUndefined(dataType) ? dataType : null;
};

export const transformSelectedType = (
  customInput: Record<string, any>,
  key: string,
  currentDataSet?: string,
  dataType?: string
) => {
  if (customInput[key].sourceType === 'dataSet') {
    return {
      value: currentDataSet ?? '',
      key: 'dataSet',
      dataType: 'dataSet',
    };
  }

  if (customInput[key].sourceType === 'restAPI') {
    return {
      value: customInput[key].attribute,
      key: 'restAPI',
      dataType: 'restAPI',
    };
  }

  return {
    value: dataType ?? customInput[key].dataType,
    key: 'primitive',
    dataType: dataType ?? customInput[key].dataType,
  };
};

export const getSelectedType = (
  dataType: string,
  attribute?: string,
  sourceType?: string
) => {
  if (sourceType === 'dataSet') {
    return {
      value: '',
      key: 'dataSet',
      dataType: 'dataSet',
    };
  }

  if (sourceType === 'restAPI') {
    return {
      value: attribute,
      key: 'restAPI',
      dataType: 'restAPI',
    };
  }

  return {
    value: dataType,
    key: 'primitive',
    dataType,
  };
};

export const transformPayloadCustomInputToTableData = (
  customInput: Record<string, CustomInputModel>
) => {
  return Object.keys(customInput).reduce<Record<string, AttributeModel>>(
    (prev, key) => {
      return {
        ...prev,
        [key]: {
          name: key,
          isOptional: customInput[key].isOptional ?? false,
          isList: customInput[key].isList ?? false,
          isNullable: customInput[key].isNullable ?? false,
          dataType: getDataTypeByValue(customInput[key].dataType),
          isCaseSensitive: customInput[key].isCaseSensitive ?? false,
          sourceType: customInput[key].sourceType ?? '',
          attribute: customInput[key].attribute ?? '',
          selectedType: transformSelectedType(customInput, key, ''),
          executedValue: customInput[key].executedValue ?? null,
          config: customInput[key].config ?? {},
          next: customInput[key].next,
          previous: customInput[key].previous,
        },
      };
    },
    {}
  );
};

export const getResultNextOutputDataRecursion = (
  data: Record<string, any> | null,
  nodeId: string,
  finalResult: ResultAddDataModel[]
) => {
  if (!_isNil(data) && !_isNil(data[nodeId])) {
    finalResult.push({
      keyName: data[nodeId].name,
      dataType: data[nodeId].dataType,
      returnType: data[nodeId].returnType ?? '',
      executedValue: data[nodeId].executedValue ?? '',
      value: !_isNil(data[nodeId].value) ? data[nodeId].value.toString() : '',
      source: data[nodeId].source,
      attribute: data[nodeId].attribute,
    });

    if (!_isNil(data[nodeId].next)) {
      getResultNextOutputDataRecursion(data, data[nodeId].next, finalResult);
    }
  }
};

export const transformPayloadToResultDataType = (
  ruleOutputData: Record<string, any> | null,
  startNode?: string | null
): ResultAddDataModel[] => {
  if (!_isNil(startNode) && !_isEmpty(startNode)) {
    const finalResult: ResultAddDataModel[] = [];
    getResultNextOutputDataRecursion(ruleOutputData, startNode, finalResult);

    return finalResult;
  }

  return !_isNil(ruleOutputData)
    ? Object.keys(ruleOutputData)
        .filter((key) => key !== 'defaultOutputData')
        .map((key) => ({
          keyName: ruleOutputData[key].name,
          dataType: ruleOutputData[key].dataType,
          value: getOutputValueParsed(
            ruleOutputData[key].dataType ?? 'string',
            ruleOutputData[key].value
          ),
          executedValue: ruleOutputData[key].executedValue,
          returnType: ruleOutputData[key].returnType,
        }))
    : [];
};

export const transformConfig = (config: ActionConfig) => {
  const newConfig: ActionConfigFE = {};

  for (const key in config) {
    if (
      key === 'contentType' &&
      !_isNil(config.contentType) &&
      bodyParamTypes.some((param) => param.value === config.contentType)
    ) {
      newConfig.contentType = {
        value: config.contentType,
        label:
          bodyParamTypes.find((param) => param.value === config.contentType)
            ?.label ?? '',
      };
    } else if (key === 'method' && !_isNil(config.method)) {
      newConfig.method = {
        label: config.method,
        value: config.method,
      };
    } else if (!_isNil(config[key])) {
      newConfig[key] = config[key];
    }
  }

  return newConfig;
};

export const transformActionNode = (
  ruleActionData?: Record<string, any>,
  startNode?: string | null
) => {
  if (!_isNil(ruleActionData) && (_isNil(startNode) || _isEmpty(startNode))) {
    return Object.keys(ruleActionData).map((key) => {
      return {
        name: ruleActionData[key].name,
        connectorId: ruleActionData[key].connectorId,
        config: transformConfig(ruleActionData[key].config),
      };
    });
  } else if (
    !_isNil(ruleActionData) &&
    !_isNil(startNode) &&
    !_isEmpty(startNode)
  ) {
    let current = startNode;
    const array = [];

    while (!_isEmpty(current) && !_isNil(current)) {
      if (!_isNil(ruleActionData[current])) {
        array.push({
          name: ruleActionData[current].name,
          connectorId: ruleActionData[current].connectorId,
          config: transformConfig(ruleActionData[current].config),
        });

        current = ruleActionData[current].next;
      }
    }

    return array;
  }

  return [];
};

export function selectedMenuItemIsRuleTypes(value: string): value is RuleTypes {
  return ['simpleRule', 'ruleSet', 'decisionTable'].includes(value);
}

export function ruleResultToString(result: ActionThenModel) {
  const filteredOutputData = _reduce(
    result.outputData,
    (result: string[], value, key) => {
      if (key === 'defaultOutputData') {
        return result;
      }

      return [...result, key];
    },
    []
  );

  return filteredOutputData
    .map((key) => {
      const keyName: string = result.outputData[key].name;
      const value: string = result.outputData[key].value;

      return `${keyName} = ${value}`;
    })
    .join(', ');
}

export function ruleResultAndList(rules: RuleListDataModel[]) {
  const excludedRuleTypes = ['ruleSet'];

  const ruleOptions = rules

    .filter((rule) => !excludedRuleTypes.includes(rule.type))
    .map((rule) => ({
      name: rule.name,
      id: rule.id,
      type: rule.type,
    }));

  const customAttributePerRule: Record<string, CustomAttributeByRuleId[]> =
    rules
      .filter((rule) => !excludedRuleTypes.includes(rule.type))
      .reduce<Record<string, CustomAttributeByRuleId[]>>((acc, rule) => {
        return {
          ...acc,
          [rule.id]: Object.keys(rule.customInput ?? {}).map((key) => ({
            key,
            dataType: rule.customInput[key].dataType,
            isOptional: rule.customInput[key].isOptional ?? false,
            isNullable: rule.customInput[key].isNullable ?? false,
            isDataset: rule.customInput[key].sourceType === 'dataSet',
            testValue: rule.customInput[key].testValue ?? '',
            sampleValue: rule.customInput[key].sampleValue ?? '',
            executedValue: rule.customInput[key].executedValue ?? '',
            isCaseSensitive: rule.customInput[key].isCaseSensitive ?? '',
            config: rule.customInput[key].config ?? {},
            sourceType: rule.customInput[key].sourceType,
            attribute: rule.customInput[key].attribute,
          })),
        };
      }, {});

  const ruleResultAndNameById: Record<string, RuleResultMappedModel> = {};

  rules
    .filter((rule) => !excludedRuleTypes.includes(rule.type))
    .forEach((rule) => {
      const res = ruleResultToString(rule.action.then);
      ruleResultAndNameById[rule.id] = {
        name: rule.name,
        result: res,
        type: rule.type,
      };
    });

  return { ruleOptions, ruleResultAndNameById, customAttributePerRule };
}

export const getSampleValuesByDataType = (dataType: string) => {
  switch (dataType) {
    case 'string':
      return 'sample-value';
    case 'boolean':
      return false;
    case 'numeric':
      return 0;
    case 'date':
      return DATE_FORMAT;
    case 'list':
      return [];
    case 'json':
      return {};
    case 'dateTime':
      return DATE_TIME_FORMAT;
    default:
      return 'sample-value';
  }
};

export function convertAttributesToJson(
  attributes: Record<string, AttributeModel>,
  type: 'staging' | 'production',
  ruleType: RuleTypes
) {
  const attributeJson = {
    environment: type,
    params: _reduce(
      attributes,
      (result: Record<string, any>, value, key) => {
        if (key !== 'CI0' && value?.dataType?.value !== 'restAPI') {
          result[key] = getSampleValuesByDataType(
            !_isNil(value.dataType) ? value.dataType.value : 'string'
          );
        }

        return result;
      },
      {}
    ),
  };

  return JSON.stringify(attributeJson, null, 2);
}

export function convertAttributesToJsonWebhook(
  attributes: Record<string, AttributeModel>,
  type: 'staging' | 'production',
  ruleType: RuleTypes
) {
  const attributeJson = _reduce(
    attributes,
    (result: Record<string, any>, value, key) => {
      if (key !== 'CI0' && value?.dataType?.value !== 'restAPI') {
        result[key] = getSampleValuesByDataType(
          !_isNil(value.dataType) ? value.dataType.value : 'string'
        );
      }

      return result;
    },
    {}
  );

  return JSON.stringify(attributeJson, null, 2);
}

export const transformRulesetToRequestPayload = (
  rulesetData: RulesetModel,
  rulesetId?: string,
  startNodeId: string = ''
) => {
  const name = rulesetData.ruleName;
  const description = rulesetData.ruleDescription;
  const editMode = rulesetData.editMode;
  const firstRuleChain = startNodeId;
  const trigger = getTrigger(
    rulesetData.stagingConfig,
    rulesetData.productionConfig
  );
  const type = 'ruleSet';
  const ruleSetPolicy = rulesetData.rulePolicy?.value;
  const ruleChain = getRuleChain(rulesetData.ruleList);
  const ruleSet = rulesetData.ruleList.map(({ ruleId }) => ruleId);

  const rulesetJson: RuleSetPayloadModel = {
    name,
    type,
    description,
    editMode,
    trigger,
    firstRuleChain,
    ruleSetPolicy,
    ruleChain,
    ruleSet,
  };

  if (!_isNil(rulesetId)) {
    rulesetJson.id = rulesetId;
  }

  return rulesetJson;
};

export const getRuleChain = (rulesetList: RuleSetNodesModel[]) => {
  return rulesetList.reduce<Record<string, RuleChainPayloadModel>>(
    (acc, rule, currentIndex) => {
      return {
        ...acc,
        [rule.ruleId]: {
          isEnabled: rule.isEnabled,
          prevRuleId: !_isNil(rulesetList[currentIndex - 1])
            ? rulesetList[currentIndex - 1].ruleId
            : '',
          nextRuleId: !_isNil(rulesetList[currentIndex + 1])
            ? rulesetList[currentIndex + 1].ruleId
            : '',
        },
      };
    },
    {}
  );
};

export type CorrectValues = {
  value: number | string;
  isCorrect: boolean;
  message?: string;
};

export const validateRhsValue = (
  value: any,
  dataType: string = 'string',
  nodeType: string = 'constant'
): CorrectValues => {
  if (_isEmpty(value)) {
    return { value, isCorrect: true };
  }

  if (dataType === 'string') {
    return { value, isCorrect: true };
  }

  if (dataType === 'numeric' && nodeType === 'constant') {
    if (FLOATING_NUMBER_REGEX.test(value)) {
      return { value: parseFloat(value), isCorrect: true };
    }

    return { value, isCorrect: false, message: 'Invalid numeric value' };
  }

  if (dataType === 'numeric' && nodeType === 'params') {
    return { value, isCorrect: true };
  }

  if (dataType === 'date' && nodeType === 'constant') {
    if (isValidDate(value)) {
      return { value, isCorrect: true };
    }

    return { value, isCorrect: false, message: `only ${DATE_FORMAT} allowed` };
  }

  if (dataType === 'dateTime' && nodeType === 'constant') {
    if (isValidDateTime(value)) {
      return { value, isCorrect: true };
    }

    return {
      value,
      isCorrect: false,
      message: `only ${DATE_TIME_FORMAT} allowed`,
    };
  }

  if (dataType === 'list' && nodeType === 'constant') {
    if (Array.isArray(value)) {
      return { value: convertArrayToString(value), isCorrect: true };
    } else if (isArrayAsInputValid(value)) {
      return { value, isCorrect: true };
    } else {
      return {
        value,
        isCorrect: false,
        message: 'Error in parsing input value',
      };
    }
  }

  return { value, isCorrect: true };
};

export const getDefaultValueByDataTypeV2 = (dataType: string) => {
  switch (dataType) {
    case 'string':
      return 'Value';
    case 'boolean':
      return false;
    case 'number':
    case 'numeric':
      return 0;
    case 'date':
      return formatNectedDate('NOW', 'date');
    case 'dateTime':
      return formatNectedDate('NOW', 'dateTime');
    case 'generic':
      return null;
    default:
      return null;
  }
};

export const getDefaultValueByDataType = (dataType: string) => {
  switch (dataType) {
    case 'string':
      return 'Value';
    case 'boolean':
      return false;
    case 'number':
    case 'numeric':
      return 0;
    case 'date':
      return formatNectedDate('NOW', 'date');
    case 'dateTime':
      return formatNectedDate('NOW', 'dateTime');
    case 'generic':
      return null;
    default:
      return null;
  }
};

export const getDefaultValueByDataTypeForRhsOutput = (dataType: string) => {
  switch (dataType) {
    case 'string':
      return 'Value';
    case 'boolean':
      return 'false';
    case 'number':
    case 'numeric':
      return '0';
    case 'date':
      return formatNectedDate('NOW', 'date');
    case 'dateTime':
      return formatNectedDate('NOW', 'dateTime');
    case 'list':
      return [];
    default:
      return null;
  }
};

const getDefaultDate = (date: Date): string => {
  if (!(date instanceof Date)) {
    throw new Error('Input is not a valid Date object');
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const monthStr = month < 10 ? `0${month}` : month.toString();
  const dayStr = day < 10 ? `0${day}` : day.toString();

  return `${year}-${monthStr}-${dayStr}`;
};

export const getDefaultValueForTokensByDataType = (dataType: string) => {
  switch (dataType) {
    case 'string':
      return 'Value';
    case 'boolean':
      return false;
    case 'number':
    case 'numeric':
      return 0;
    case 'date':
      return `${getDefaultDate(new Date())}`;
    case 'dateTime':
      return `${new Date().toISOString()}`;
    case 'json':
      return JSON.stringify({
        keyName: 'Testing',
      });
    default:
      return null;
  }
};

export const getDefaultValuesForTest = (dataType: string) => {
  switch (dataType) {
    case 'string':
      return '';
    case 'boolean':
      return false;
    case 'number':
    case 'numeric':
      return null;
    case 'date':
    case 'dateTime':
      return '';
    default:
      return null;
  }
};

export const checkIfResultNullOrOptional = (
  isOptional: boolean,
  isNullable: boolean,
  result: Record<string, any>,
  key: string,
  dataType: string,
  value: any,
  isDataset: boolean = false
) => {
  if (isOptional || isDataset || dataType === 'restAPI') {
    return result;
  }

  if (isNullable) {
    return { ...result, [key]: null };
  }

  if (dataType === 'numeric' && !_isNil(value)) {
    return { ...result, [key]: parseFloat(value.toString()) };
  }

  if (dataType === 'boolean') {
    return { ...result, [key]: value === true || value === 'true' };
  }

  if (dataType === 'json') {
    return {
      ...result,
      [key]: JSON.parse(
        `${
          // eslint-disable-next-line
          typeof value === 'object'
            ? // eslint-disable-next-line
              JSON.stringify(value as any)
            : // eslint-disable-next-line
              value ?? '{}'
        }`
      ),
    };
  }

  if (dataType === 'list') {
    // eslint-disable-next-line
    return { ...result, [key]: convertArrayAsInput(value ?? '') };
  }

  return { ...result, [key]: (value ?? '').toString().trim() };
};

export const convertDataToTestableData = (
  data: SimpleRuleTestDataModel,
  attributes: Record<string, Attributes>
) => {
  return _reduce(
    data,
    (
      result: Record<string, string | number | boolean | null | Date | any[]>,
      val,
      key
    ) => {
      const { value, isOptional, isNullable } = val;

      return checkIfResultNullOrOptional(
        isOptional,
        isNullable,
        result,
        key,
        attributes[key].dataType,
        value
      );
    },
    {}
  );
};

export const convertDataToTestableDataCron = (
  data: Record<string, CronCAObjectModel>,
  attributes: Record<string, Attributes>
) => {
  return _reduce(
    data,
    (result: Record<string, any>, val, key) => {
      const {
        value,
        isOptional = false,
        isNullable = false,
        isDataset = false,
      } = val;

      return checkIfResultNullOrOptional(
        isOptional,
        isNullable,
        result,
        key,
        attributes[key].dataType,
        value,
        isDataset
      );
    },
    {}
  );
};

export const convertRuleSetDataToTestableData = (
  data: SimpleRuleTestDataModel,
  dataTypeByKey: Record<string, string>
) => {
  return _reduce(
    data,
    (result: Record<string, any>, val, key) => {
      const { value, isNullable = false, isOptional = false } = val;

      return checkIfResultNullOrOptional(
        isOptional,
        isNullable,
        result,
        key,
        dataTypeByKey[key],
        value
      );
    },
    {}
  );
};

export const convertRuleSetDataToTestableDataCron = (
  data: Record<string, CronCAObjectModel>,
  dataTypeByKey: Record<string, string>
) => {
  return _reduce(
    data,
    (result: Record<string, any>, val, key) => {
      const {
        value,
        isNullable = false,
        isOptional = false,
        isDataset = false,
      } = val;

      return checkIfResultNullOrOptional(
        isOptional,
        isNullable,
        result,
        key,
        dataTypeByKey[key],
        value,
        isDataset
      );
    },
    {}
  );
};

export function getDataTypeByKey(
  customAttributesInFields: Record<string, CustomAttributeByRuleId[]>
) {
  return _reduce(
    customAttributesInFields,
    (result: Record<string, string>, value, key) => {
      const keyByValue = {
        ...customAttributesInFields[key].reduce<Record<string, string>>(
          (acc, k) => {
            return {
              ...acc,
              [k.key]: k.dataType,
            };
          },
          {}
        ),
      };

      return {
        ...result,
        ...keyByValue,
      };
    },
    {}
  );
}

export function convertAttributesToRuleSetJson(
  attributes: Record<string, string>,
  type: 'staging' | 'production',
  ruleType: RuleTypes
) {
  const attributeJson = {
    environment: type,
    params: _reduce(
      attributes,
      (result: Record<string, any>, value, key) => {
        if (key !== 'CI0') {
          result[key] = getSampleValuesByDataType(value);
        }

        return result;
      },
      {}
    ),
  };

  return JSON.stringify(attributeJson, null, 2);
}

export function convertAttributesToRuleSetJsonWebhook(
  attributes: Record<string, string>,
  type: 'staging' | 'production',
  ruleType: RuleTypes
) {
  const attributeJson = _reduce(
    attributes,
    (result: Record<string, any>, value, key) => {
      if (key !== 'CI0') {
        result[key] = getSampleValuesByDataType(value);
      }

      return result;
    },
    {}
  );

  return JSON.stringify(attributeJson, null, 2);
}

export function validateSimpleRuleBeforeSaving(
  nodes: Record<string, SimpleRuleNodesModel>,
  startId: string,
  dataset: Record<string, Dataset>
) {
  const ruleError: ErrorByNodeId = {};

  getErrorRecursion(nodes, nodes[startId], ruleError, startId, dataset);

  return ruleError;
}

export const validateRhsParamType = (
  parentNodeType: string,
  operatorType: string,
  rhsType: string,
  dataTypeMatched: boolean
) => {
  for (const currentDataRule of dataTypeRules) {
    const { type, operators, includes } = currentDataRule;

    if (type.includes(parentNodeType) && operators.includes(operatorType)) {
      return includes.includes(rhsType);
    }
  }

  return dataTypeMatched;
};

function getErrorRecursion(
  nodes: Record<string, SimpleRuleNodesModel>,
  node: SimpleRuleNodesModel,
  ruleError: ErrorByNodeId,
  startNode: string,
  dataset: Record<string, Dataset>
) {
  if (node?.nodeType === 'group') {
    if (!_isNil(node.children)) {
      node.children.map((key) =>
        getErrorRecursion(nodes, nodes[key], ruleError, key, dataset)
      );
    }
  } else if (node?.nodeType === 'condition') {
    if (
      _isNil(node.operator) ||
      _isEmpty(node.operator) ||
      _isNil(getOperatorTextById(operators, node.operator, node.dataType))
    ) {
      ruleError[startNode] = {
        message: 'Please select operator',
        code: 500,
      };
    }

    if (!_isNil(node?.leftNode)) {
      node?.leftNode.map((key) =>
        getErrorRecursion(nodes, nodes[key], ruleError, key, dataset)
      );
    }

    if (!_isNil(node?.rightNode)) {
      node?.rightNode.map((key) =>
        getErrorRecursion(nodes, nodes[key], ruleError, key, dataset)
      );
    }

    const validated = validateConditionRule(node, nodes);

    if (!_isNil(node?.rightNode) && !_isNil(validated)) {
      const lastRightNode = node.rightNode[node.rightNode.length - 1];
      ruleError[lastRightNode] = validated;
    }
  } else if (node?.nodeType === 'constant') {
    const value = !_isNil(node?.value)
      ? node.dataType === 'list' || Array.isArray(node.value)
        ? node.value ?? []
        : node.value.toString()
      : '';
    const inputValid = validateRhsValue(value, nodes[startNode].dataType);

    if (_isEmpty(value) && !Array.isArray(value)) {
      ruleError[startNode] = {
        message: 'Field cannot be empty',
        code: 500,
      };
    } else if (!inputValid.isCorrect) {
      ruleError[startNode] = {
        message: !_isNil(inputValid.message)
          ? inputValid.message
          : 'Incorrect datatype',
        code: 500,
      };
    }
  } else if (node?.nodeType === 'params') {
    const ruleNodeAttribute = node?.attribute ?? '';
    const sourceType = node?.sourceType;

    const propertyValue =
      !_isNil(sourceType) && !_isEmpty(sourceType)
        ? `${sourceType}.${ruleNodeAttribute}`
        : ruleNodeAttribute;

    if (
      _isNil(node.attribute) ||
      _isEmpty(node.attribute) ||
      _isNil(propertyValue) ||
      _isEmpty(propertyValue)
    ) {
      ruleError[startNode] = {
        message: 'Field cannot be empty',
        code: 500,
      };
    }

    let mappedValue =
      !_isUndefined(node.sourceType) && !_isNil(dataset[node.sourceType])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[node.sourceType].attributes).reduce(
                  (acc, curr) => {
                    return {
                      ...acc,
                      [curr]:
                        dataset[node.sourceType ?? ''].attributes[`${curr}`]
                          .executedValue,
                    };
                  },
                  {}
                )
              )
            ) ?? {},
            node.attribute ?? ''
          )
        : undefined;

    // Assuming that if the sourceType is systemVar then attribute is available always.
    const attributeFound =
      node.sourceType === 'systemVar'
        ? true
        : !_isNil(node.attribute) &&
          !_isEmpty(node.attribute) &&
          !_isNil(node.sourceType) &&
          !_isEmpty(node.sourceType) &&
          !_isNil(dataset[node.sourceType]) &&
          !_isUndefined(mappedValue);

    if (!attributeFound) {
      ruleError[startNode] = {
        message: 'Unable to find the attribute',
        code: 500,
      };
    }

    const isLHSNode =
      nodes[node.parent]?.leftNode?.includes(startNode) ?? false;

    // Formatting values in case of Date and DateTime
    mappedValue = !_isNil(mappedValue)
      ? node.dataType === 'date'
        ? formatNectedDate(mappedValue, 'date')
        : node.dataType === 'dateTime'
        ? formatNectedDate(mappedValue, 'dateTime')
        : mappedValue
      : mappedValue;

    const dataTypeMatched =
      !_isNil(node.attribute) &&
      !_isEmpty(node.attribute) &&
      !_isNil(node.sourceType) &&
      !_isEmpty(node.sourceType) &&
      !_isNil(dataset[node.sourceType]) &&
      nodes[node.parent]?.dataType ===
        (!_isNil(mappedValue) ? getDataTypeNected(mappedValue) : node.dataType);

    const hasIgnoredDatatype = datatypesToIgnoreOnValidation.includes(
      node.dataType ?? ''
    );

    if (attributeFound) {
      if (isLHSNode && !dataTypeMatched && !hasIgnoredDatatype) {
        ruleError[startNode] = {
          message: 'DataType of the field has changed',
          code: 500,
        };
      } else if (
        !isLHSNode &&
        !hasIgnoredDatatype &&
        !validateRhsParamType(
          nodes[node.parent]?.dataType ?? '',
          nodes[node.parent]?.operator ?? '',
          getDataTypeNected(mappedValue),
          dataTypeMatched
        )
      ) {
        ruleError[startNode] = {
          message: 'DataType of the field has changed',
          code: 500,
        };
      }
    }

    if (!attributeFound) {
      ruleError[startNode] = {
        message: 'Unable to find the attribute',
        code: 500,
      };
    }
  } else if (node?.nodeType === 'jsCondition') {
    if (_isNil(node.query) || _isEmpty(node.query)) {
      ruleError[startNode] = {
        message: 'Field cannot be empty',
        code: 500,
      };
    } else {
      const tokenValid = checkUsedTokensAreValid(node.query, dataset);

      if (!tokenValid.isValidTokenUsed) {
        ruleError[startNode] = {
          message: 'Incorrect token used in JS Code',
          code: 500,
        };
      }
    }
  } else if (node?.nodeType === 'excelCondition') {
    if (_isNil(node.query) || _isEmpty(node.query)) {
      ruleError[startNode] = {
        message: 'Field cannot be empty',
        code: 500,
      };
    } else {
      const tokenValid = checkUsedTokensAreValid(node.query, dataset);

      if (!tokenValid.isValidTokenUsed) {
        ruleError[startNode] = {
          message: 'Incorrect token used in Formula',
          code: 500,
        };
      }
    }
  }
}

export const customParseDate = (input: string | Date): Date | any => {
  if (typeof input === 'string' && input.length > 0) {
    // Regular expressions for different date formats
    const dateFormat1 = /^(\d{2})\/(\d{2})\/(\d{4})$/; // dd/mm/yyyy
    const dateFormat2 = /^(\d{4})\/(\d{2})\/(\d{2})$/; // yyyy/mm/dd
    const dateFormat3 = /^(\d{2})-(\d{2})-(\d{4})$/; // dd-mm-yyyy
    const dateFormat4 = /^(\d{4})-(\d{2})-(\d{2})$/; // yyyy-mm-dd

    const dateTimeFormat1 =
      /^(\d{2})[/-](\d{2})[/-](\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd/MM/yyyy HH:mm:ss
    const dateTimeFormat2 =
      /^(\d{4})[/-](\d{2})[/-](\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/MM/dd HH:mm:ss
    const dateTimeFormat3 = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-MM-dd HH:mm:ss
    const dateTimeFormat4 = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd-MM-yyyy HH:mm:ss

    const dateTimeTZFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ

    const rfc3339Format =
      /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional milliseconds

    let match;

    // Check for dd/mm/yyyy format
    if ((match = input.match(dateFormat1)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10)
      );
    }

    // Check for yyyy/mm/dd format
    if ((match = input.match(dateFormat2)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }

    // Check for dd-mm-yyyy format
    if ((match = input.match(dateFormat3)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10)
      );
    }

    // Check for yyyy-mm-dd format
    if ((match = input.match(dateFormat4)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }

    // Check for dd/MM/yyyy HH:mm:ss format
    if ((match = input.match(dateTimeFormat1)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for yyyy/MM/dd HH:mm:ss format
    if ((match = input.match(dateTimeFormat2)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for yyyy-MM-dd HH:mm:ss format
    if ((match = input.match(dateTimeFormat3)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for dd-MM-yyyy HH:mm:ss format
    if ((match = input.match(dateTimeFormat4)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for yyyy-MM-ddTHH:mm:ssZ format (UTC)
    if ((match = input.match(dateTimeTZFormat)) != null) {
      return new Date(input);
    }

    // Check for RFC 3339 format
    if ((match = input.match(rfc3339Format)) != null) {
      return new Date(input);
    }

    if (isInvalidDate(input) === true) {
      return input;
    } else {
      return new Date(input);
    }
  } else if (input instanceof Date) {
    return input; // If input is already a Date object, return it as is
  } else {
    return input; // Return current date for invalid input
  }
};

export const isInvalidDate = (dateString: string | null) => {
  if (_isNil(dateString)) {
    return null;
  }
  const date = new Date(dateString);

  return !(date instanceof Date && !isNaN(date as unknown as number));
};

function validateConditionRule(
  node: SimpleRuleNodesModel,
  nodes: Record<string, SimpleRuleNodesModel>
) {
  if (
    _isNil(node.rightNode) ||
    _isNil(node.operator) ||
    !['bet', 'nbet'].includes(node.operator)
  ) {
    return null;
  }

  const inputValues = node.rightNode.map((key: string) => nodes[key].value);
  const [firstInput, secondInput] = inputValues;

  if (_isNil(firstInput) || _isNil(secondInput)) {
    return null;
  }

  if (
    _includes(['date', 'dateType'], node.dataType) &&
    _isString(firstInput) &&
    _isString(secondInput)
  ) {
    const [firstInputTime, secondInputTime] = [firstInput, secondInput].map(
      (date) => parse(date, DATE_FORMAT, new Date()).getTime()
    );

    if (firstInputTime >= secondInputTime) {
      return {
        message: 'Second input should be greater than first',
        code: 500,
      };
    }
  } else if (node.dataType === 'numeric') {
    if (firstInput >= secondInput) {
      return {
        message: 'Second input should be greater than first',
        code: 500,
      };
    }
  }

  return null;
}

export const getTitleByRule = (
  rules: Record<string, SimpleRuleNodesModel>,
  dataset: Record<string, Dataset>,
  nodeId: string
) => {
  const attribute = rules[nodeId].attribute;
  const source = rules[nodeId].sourceType;

  return !_isUndefined(source) &&
    !_isUndefined(attribute) &&
    !_isEmpty(source) &&
    !_isEmpty(attribute) &&
    !_isUndefined(dataset[source]) &&
    !_isUndefined(dataset[source].attributes[attribute])
    ? dataset[source].attributes[attribute].name
    : undefined;
};

export const validateTokensInJsonEditor = (
  value: string,
  tokens: string[],
  index: number,
  dataParams: ResultAddDataModel[]
) => {
  const outputDataTokens: string[] = dataParams
    .filter((token, paramIndex) => paramIndex < index)
    .map((token) => {
      return `outputData.${token.keyName}`;
    });

  let formattedTokens = tokens.map((token: string) =>
    token.replace('<<', '').replace('>>', '')
  );

  formattedTokens = [...formattedTokens, ...outputDataTokens];

  let matched = true;

  const matchedExpressions = value.match(TOKEN_REGEX);

  if (!_isNil(matchedExpressions)) {
    matchedExpressions.forEach((matchedExpression: string) => {
      if (!formattedTokens.includes(matchedExpression)) {
        matched = false;
      }
    });
  }

  return matched;
};

export const validateToken = async (
  dataParams: ResultAddDataModel[],
  dataset: Record<string, Dataset>,
  tokens: string[]
) => {
  const errors: Array<Record<string, any>> = [];

  const outputDataAttributes: Record<string, Attributes> = {};

  dataParams.forEach((field) => {
    outputDataAttributes[field.keyName] = {
      name: field.keyName,
      dataType: field.dataType as DataTypes,
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      executedValue: !!field.executedValue
        ? convertCaSampleValues(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            !!field.returnType ? field.returnType : field.dataType,
            field.executedValue
          )
        : convertCaSampleValues(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            !!field.returnType ? field.returnType : field.dataType,
            field.value
          ),
    };
  });

  const updatedDataset = _isNil(dataset.outputData)
    ? {
        ...dataset,
        outputData: {
          attributes: outputDataAttributes,
          name: 'Output Data Attributes',
          id: 'output_data_attributes',
        },
      }
    : dataset;

  for (let index = 0; index < dataParams.length; index++) {
    const param = dataParams[index];

    if (param.dataType === 'json' && typeof param.value === 'string') {
      const { isValid, message } = await validateTokensBasedOnEditor({
        dataset: updatedDataset,
        query: param.value,
        editorType: 'json',
      });

      if (!isValid) {
        errors.push({
          index,
          message,
        });
      }
    } else if (
      ['jsFormula', 'excelFormula'].includes(param.dataType) &&
      typeof param.value === 'string' &&
      !_isNil(param.returnType)
    ) {
      const { isValid, message } = await validateTokensBasedOnEditor({
        dataset: updatedDataset,
        query: param.value,
        editorType: 'jsFormula',
      });

      if (!isValid) {
        errors.push({
          index,
          message,
        });
      }
    } else if (
      ['jsFormula', 'excelFormula'].includes(param.dataType) &&
      _isUndefined(param.returnType)
    ) {
      errors.push({
        index,
        message: 'Invalid Return Type',
      });
    } else {
      const { isValid, message } = checkAttributeIsValid(
        updatedDataset,
        param.source,
        param.attribute
      );

      if (!isValid) {
        errors.push({
          index,
          message,
        });
      }
    }
  }

  return errors;
};

export const getDatasetWithoutReactCode = (
  dataset: Record<string, Dataset> = {}
) => {
  const copyOfDataSet: Record<string, Dataset> = _reduce(
    dataset,
    (prev: Record<string, Dataset>, val, key) => {
      const { tooltip, footer, ...rest } = val;

      return {
        ...prev,
        [key]: { ...rest },
      };
    },
    {}
  );

  return copyOfDataSet;
};

export const getOutputAddedDataset = (
  dataParams: ResultAddDataModel[],
  dataset: Record<string, Dataset>
) => {
  const copyOfDataSet = getDatasetWithoutReactCode(dataset);

  const outputDataAttributes: Record<string, Attributes> = {};

  dataParams.forEach((field) => {
    outputDataAttributes[field.keyName] = {
      name: field.keyName,
      dataType: field.dataType as DataTypes,
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      executedValue: !!field.executedValue
        ? convertCaSampleValues(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            !!field.returnType ? field.returnType : field.dataType,
            field.executedValue
          )
        : convertCaSampleValues(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            !!field.returnType ? field.returnType : field.dataType,
            field.value
          ),
    };
  });

  const updatedDataset = _isNil(copyOfDataSet.outputData)
    ? {
        ...copyOfDataSet,
        outputData: {
          attributes: outputDataAttributes,
          name: 'Output Data Attributes',
          id: 'output_data_attributes',
        },
      }
    : copyOfDataSet;

  return updatedDataset;
};

export const validateTokensBeforeSaving = async (
  data: SimpleRuleModel,
  dataset: Record<string, Dataset>,
  tokens: string[]
) => {
  const thenErrors = await validateToken(data.thenDataParams, dataset, tokens);

  const elseErrors = await validateToken(data.elseDataParams, dataset, tokens);

  return {
    thenErrors,
    elseErrors,
  };
};

export function findConflictingItems(arrays: any[]) {
  const conflicts: any[] = [];
  const keyMap: any = {};
  arrays.forEach((array: any[], idx) => {
    array.forEach((item: any, i: number) => {
      const { key, dataType } = item;
      // eslint-disable-next-line
      if (!keyMap[key]) {
        keyMap[key] = { dataType, indexes: [idx] };
      } else {
        if (keyMap[key]?.dataType !== dataType) {
          conflicts.push({ key, indexes: [keyMap[key].indexes[0], idx] });
        } else {
          keyMap[key].indexes.push(idx);
        }
      }
    });
  });

  return conflicts;
}

export const validateRuleSetBeforeTesting = (
  data: RulesetModel,
  ruleResultById: Record<string, RuleResultMappedModel>,
  customAttributesById: Record<string, CustomAttributeByRuleId[]>
) => {
  const ruleErrors: number[] = [];

  const customInputIndexWise: CustomAttributeByRuleId[][] = [];

  data.ruleList.reduce<Record<string, CustomAttributeByRuleId[]>>(
    (acc, curr) => {
      if (!_isNil(customAttributesById[curr.ruleId])) {
        customInputIndexWise.push(
          curr.isEnabled ? customAttributesById[curr.ruleId] : []
        );

        return {
          ...acc,
          [curr.ruleId]: customAttributesById[curr.ruleId],
        };
      }

      return acc;
    },
    {}
  );

  data.ruleList.forEach((rule, index) => {
    if (
      _isNil(rule.ruleId) ||
      _isNil(ruleResultById[rule.ruleId]?.name) ||
      _isEmpty(ruleResultById[rule.ruleId].name)
    ) {
      ruleErrors.push(index);
    }
  });

  return {
    ruleErrors,
    commonItems: findConflictingItems(customInputIndexWise),
  };
};

export const getSameKeyNameData = (dataParams: ResultAddDataModel[]) => {
  return dataParams.reduce(
    (acc: Record<string, number[]>, { keyName }, index) => {
      if (!_isNil(acc[keyName])) {
        acc[keyName].push(index);
      } else {
        acc[keyName] = [index];
      }

      return acc;
    },
    {}
  );
};

export const validateKeyNameBeforeSaving = (data: SimpleRuleModel) => {
  const thenKeyNameSet = getSameKeyNameData(data.thenDataParams);
  const elseKeyNameSet = getSameKeyNameData(data.elseDataParams);

  return {
    thenKeyNameSet,
    elseKeyNameSet,
  };
};

export const formatTriggersDataFromResponse = (
  data: TriggerEnvironmentModel,
  staticUrl: string,
  isClone: boolean = false,
  authType: AuthenticationDropdownModel | null
) => {
  return {
    api: '',
    staticUrl: isClone ? '' : staticUrl,
    endDate:
      !_isNil(data.endAt) && !_isEmpty(data.endAt)
        ? new Date(data.endAt)
        : null,
    order: data.salience,
    isEnabled: data.isEnabled,
    isApiEnabled: data.api.isEnabled,
    startDate:
      !_isNil(data.startAt) && !_isEmpty(data.startAt)
        ? new Date(data.startAt)
        : null,
    authType,
    // eslint-disable-next-line
    auditIO: !!data.auditIO,
    schedule: data.schedule,
  };
};

export const isFirstChild = (
  rules: Record<string, SimpleRuleNodesModel>,
  ruleId: string
) => {
  const orderedSiblings = !_isEmpty(rules[ruleId]?.parent)
    ? getNodeIdsSortedBySiblingIndex(rules, rules[ruleId]?.parent)
    : [];

  return orderedSiblings.length > 0 ? ruleId === orderedSiblings[0] : false;
};

function generateResultSetKey(
  dataParams: Array<Record<string, any>>,
  name: string
): string {
  if (
    dataParams.some((item) => item.keyName === name) ||
    dataParams.some((item) => item.name === name)
  ) {
    return generateResultSetKey(dataParams, name + '_1');
  }

  return name;
}

export function getResultKeyName(
  dataParams: Array<Record<string, any>>,
  name: string = 'key_name_'
) {
  if (_isEmpty(dataParams)) {
    return '';
  } else {
    return generateResultSetKey(dataParams, `${name}${dataParams.length + 1}`);
  }
}

export const getDataTypeByResult = (str: string) => {
  try {
    // eslint-disable-next-line
    let res = new Function(str);

    switch (typeof res) {
      case 'string':
        return 'string';
      case 'number':
      case 'bigint':
        return 'numeric';
      case 'object':
        return 'json';
      case 'boolean':
        return 'boolean';
      default:
        return '';
    }
  } catch (error) {
    return '';
  }
};

export const replaceTokenWithCorrespondingData = (
  codeString: string,
  dataSet: Record<string, Dataset>,
  resultData?: ResultAddDataModel[],
  additionalData: AdditionalActionFormData[] = []
) => {
  let newCodeString = codeString.replaceAll(
    REMOVE_JAVASCRIPT_COMMENTS_REGEX,
    ''
  );

  const matchedExpressions = codeString.match(TOKEN_REGEX);

  if (_isNil(matchedExpressions)) {
    return codeString;
  }

  matchedExpressions.forEach((text: string) => {
    const type = text.split('.')[0];
    const key = text.split('.')[1];

    const outputAttribute = !_isNil(resultData)
      ? resultData.filter((value) => key === value.keyName)
      : [];

    if (
      type !== 'outputData' &&
      !_isNil(dataSet[type]) &&
      !_isNil(dataSet[type].attributes[key])
    ) {
      if (
        dataSet[type].attributes[key].dataType === 'json' &&
        (type === 'customInput' || type === 'globalVar')
      ) {
        const key2 = text
          .split('.')
          .filter((t, i) => i > 1)
          .join('.');

        const flat = flattenKeysAndTypes(
          dataSet[type].attributes[key].executedValue
        ).find((o) => o.key === key2);

        if (!_isNil(flat)) {
          const value = getDefaultValueForTokensByDataType(flat.type);

          // eslint-disable-next-line
          newCodeString = newCodeString.replace(`{{.${text}}}`, `${value}`);
        }
      } else {
        let value;

        if (
          dataSet[type].attributes[key].dataType === 'json' ||
          dataSet[type].attributes[key].dataType === 'restAPI'
        ) {
          const jsonKey = text.split('.').slice(2);

          value = extractExecutedValueFromObject(
            dataSet[type].attributes[key].executedValue,
            jsonKey.join('.')
          );
        } else {
          value = dataSet[type].attributes[key].executedValue;
        }

        // eslint-disable-next-line
        newCodeString = newCodeString.replace(
          `{{.${text}}}`,
          Array.isArray(value)
            ? `[${JSON.stringify(value, null)}]`
            : `${value as string}`
        );
      }
    } else if (type === 'outputData' && outputAttribute.length > 0) {
      let newString = newCodeString;
      let newDT = outputAttribute[0].dataType;

      if (outputAttribute[0].dataType === 'jsFormula') {
        newString = replaceTokenWithCorrespondingData(
          outputAttribute[0].value as string,
          dataSet,
          resultData,
          additionalData
        );
        newDT = getDataTypeByResult(newString);
      }

      newCodeString = newCodeString.replace(
        `{{.${text}}}`,
        `${getDefaultValueForTokensByDataType(newDT) as unknown as string}`
      );
    } else if (type === 'outputDataList' && outputAttribute.length > 0) {
      const quotes = outputAttribute[0].dataType === 'string' ? `'` : ``;

      newCodeString = newCodeString.replace(
        `{{.${text}}}`,
        `[${quotes}${
          getDefaultValueForTokensByDataType(
            outputAttribute[0].dataType
          ) as unknown as string
        }${quotes}]`
      );
    } else if (type === 'additionalData' && additionalData?.length > 0) {
      /**
       * For now w're replacing the @token additionalData type token with a string
       */
      newCodeString = newCodeString.replace(
        `{{.${text}}}`,
        `${getDefaultValueForTokensByDataType('string') as unknown as string}`
      );
    }
  });

  return newCodeString;
};

export function openEditor(
  dataType: string,
  openJsonEditor: () => void,
  openJsEditor: () => void
) {
  if (dataType === 'json' || dataType === 'list') {
    openJsonEditor();
  } else if (dataType === 'jsFormula') {
    openJsEditor();
  }
}

export const getRequiredKey = (
  properties: Record<string, any>,
  keysToExclude: string[]
): string => {
  return Object.keys(properties ?? {}).filter(
    (key) => !keysToExclude.includes(key)
  )[0];
};

export const isValueNumeric = (value: any) => {
  if (typeof value === 'number') {
    return true;
  }

  return FLOATING_NUMBER_REGEX.test(value);
};

export const getFilteredDataSet = (
  customAttributes: Record<string, AttributeModel>,
  dataset: Record<string, Dataset>
) => {
  const valuesToExclude: string[] = [];
  const dataTypesToSelectFrom = [
    'date',
    'dateTime',
    'string',
    'numeric',
    'boolean',
    'json',
    'list',
  ];

  Object.keys(customAttributes).forEach((key) => {
    const attribute = customAttributes[key].attribute;

    if (!_isNil(attribute) && !_isEmpty(attribute)) {
      valuesToExclude.push(attribute);
    }
  });

  if (!_isNil(dataset.dataSet) && !_isNil(dataset.dataSet.attributes)) {
    const attributes = dataset.dataSet.attributes;
    const updatedAttributes: Record<string, Attributes> = {};

    Object.keys(attributes).forEach((attribute) => {
      if (
        !valuesToExclude.includes(attributes[attribute].name) &&
        dataTypesToSelectFrom.includes(attributes[attribute].dataType)
      ) {
        updatedAttributes[attribute] = attributes[attribute];
      }
    });

    return {
      dataSet: {
        name: dataset.dataSet.name,
        id: dataset.dataSet.id,
        order: 3,
        attributes: updatedAttributes,
        tooltip: dataset.dataSet.tooltip,
        footer:
          Object.keys(updatedAttributes).length === 0
            ? dataset.dataSet.footer
            : undefined,
      },
    };
  }

  return null;
};

export const validateCustomAttributesBeforeTest = (
  customAttributes: Record<string, AttributeModel>,
  dataSetFieldById: FieldsByID,
  selectedDataSet?: string
) => {
  const customInputValidationErrors: NodeErrorModel[] = [];

  Object.keys(customAttributes).forEach((key) => {
    const isCurrentAttribute = customAttributes[key].sourceType === 'dataSet';

    if (isCurrentAttribute) {
      if (
        _isNil(selectedDataSet) ||
        _isNil(dataSetFieldById[selectedDataSet])
      ) {
        customInputValidationErrors.push({
          message: 'Please select a dataset',
          code: 500,
        });
      } else {
        const datasetAttribute = dataSetFieldById[selectedDataSet].fields.find(
          (attribute) => attribute.name === customAttributes[key].attribute
        );

        if (_isNil(datasetAttribute)) {
          customInputValidationErrors.push({
            message: 'Please check the custom attributes',
            code: 500,
          });
        }
      }
    }
  });

  return customInputValidationErrors;
};

export const getDataSetUsedInRuleById = async (
  key: string,
  getDataSetById: LazyQueryExecFunction<any, OperationVariables>
) => {
  const primitiveDataTypes = [
    'date',
    'dateTime',
    'string',
    'numeric',
    'boolean',
    'json',
    'list',
  ];

  try {
    const { data } = await getDataSetById({
      variables: {
        dataSetId: key,
      },
      fetchPolicy: 'no-cache',
    });

    const mappedData = _reduce(
      data.getDataSet.data[0]?.fields,
      (result: SimpleDropDownModel[], { dataType, executedValue }, keyName) => {
        if (primitiveDataTypes.includes(dataType)) {
          return [
            ...result,
            {
              name: keyName,
              type: dataType,
              executedValue,
              id: keyName,
            },
          ];
        }

        return result;
      },
      []
    );

    if (data.getDataSet.data.length > 0) {
      return {
        [key]: {
          name: data.getDataSet.data[0].name as string,
          fields: mappedData,
          type: data.getDataSet.data[0].connector.plugin.name as string,
        },
      };
    }
  } catch {}

  return null;
};

export const filterDataSetSuggestionsBySection = (
  dataSetSuggestions: string[],
  section: ResultType
) => {
  const filteredDataSetVariables = dataSetSuggestions.filter((suggestion) => {
    if (
      suggestion.split('.')[0].includes('dataSet') &&
      section === 'elseDataParams'
    ) {
      return false;
    } else if (section === 'thenDataParams') {
      return true;
    }

    return true;
  });

  return filteredDataSetVariables;
};

export const filterDataSetSuggestionObjBySection = (
  dataSetSuggestions: NectedSuggestionModel[],
  section: ResultType
) => {
  const filteredDataSetVariables = dataSetSuggestions.filter((suggestion) => {
    if (
      suggestion.value.split('.')[0].includes('dataSet') &&
      section === 'elseDataParams'
    ) {
      return false;
    } else if (section === 'thenDataParams') {
      return true;
    }

    return true;
  });

  return filteredDataSetVariables;
};

export const updateDataSetOnChange = (
  customAttributes: Record<string, AttributeModel>,
  dataset: Record<string, Dataset>,
  dataSetSelected: string[],
  hideCustomFunction: boolean = false,
  allowDatasetMappedValues: boolean = true
) => {
  const filteredValues = allowDatasetMappedValues
    ? dataset
    : getFilteredDataSet(customAttributes, dataset);

  const updatedDataset = { ...dataset };
  let updatedDatasetFiltered: Record<string, Dataset> = {};

  if (!_isNil(filteredValues) && dataSetSelected.length > 0) {
    updatedDatasetFiltered = { ...updatedDataset, ...filteredValues };
  } else {
    Object.keys(updatedDataset).forEach((key) => {
      if (key !== 'dataSet') {
        updatedDatasetFiltered[key] = updatedDataset[key];
      }
    });
  }

  if (hideCustomFunction) {
    delete updatedDatasetFiltered.custom;
  }

  return updatedDatasetFiltered;
};

function getTypeNameofPluginById(
  id: string,
  publishConnectors: Record<string, any>
) {
  if (!_isNil(publishConnectors[id])) {
    return publishConnectors[id];
  }

  return null;
}

export async function verifyActions(
  data: ResultAction,
  publishConnectors: PublishedConnectors,
  stringWithReplacedToken: string
) {
  const plugin = getTypeNameofPluginById(data.connectorId, publishConnectors);

  const editorDetails = getEditorDetailsByPlugin(plugin);

  if (!_isNil(data)) {
    const type =
      plugin?.plugin?.name === 'postgres' ? 'pgsql' : plugin?.plugin?.name;

    const actionListToIgnoreTokens = ['restAPI', 'slack', 'gsheet'];

    if (
      stringWithReplacedToken.trim().length === 0 &&
      !actionListToIgnoreTokens.includes(type)
    ) {
      return false;
    }

    if (type === 'mongodb') {
      if (isMongoActionValid(stringWithReplacedToken)) {
        return true;
      } else {
        return false;
      }
    } else if (
      nodeSqlParserSupportedDatabases.includes(type) &&
      type !== 'restAPI'
    ) {
      try {
        const module = await nodeSqlParser(type);
        const { Parser } = module;
        const parser = new Parser();

        try {
          const ast = parser.astify(stringWithReplacedToken, {
            database: editorDetails?.databaseLabel,
          });

          if (Array.isArray(ast)) {
            return SQL_COMMANDS.includes(ast[0].type.toLocaleLowerCase());
          } else {
            return ast.type === 'select';
          }
        } catch (error) {
          return false;
        }
      } catch (error: unknown) {}
    } else if (type === 'restAPI') {
      return validateRestApiAction(data);
    }
  }

  return true;
}

export async function validateAction(
  data: ResultAction[],
  dataSetVariables: Record<string, Dataset>,
  publishedConnectors: PublishedConnectors,
  dataSetResults: ResultAddDataModel[],
  setError?: UseFormSetError<any>,
  section: ResultActionType = 'thenActionParams',
  additionalData?: AdditionalActionFormData[],
  tokens: string[] = [],
  ruleType: RuleType = 'SimpleRule',
  finalOutputRows?: any[]
) {
  const outputDataAttributes: Record<string, Attributes> = {};
  const additionalDataAttributes: Record<string, Attributes> = {};

  dataSetResults.forEach((field) => {
    outputDataAttributes[field.keyName] = {
      name: field.keyName,
      dataType: field.dataType as DataTypes,
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      executedValue: !!field.executedValue
        ? convertCaSampleValues(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            !!field.returnType ? field.returnType : field.dataType,
            field.executedValue
          )
        : convertCaSampleValues(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            !!field.returnType ? field.returnType : field.dataType,
            field.value
          ),
    };
  });

  const addData: ResultAddDataModel[] =
    additionalData?.map((item) => {
      return {
        keyName: item.name,
        dataType: item.dataType,
        value: item.value,
        returnType: item.returnType,
        executedValue: item.executedValue,
      };
    }) ?? [];

  addData?.forEach((data) => {
    additionalDataAttributes[data.keyName] = {
      name: data.keyName,
      dataType: data.returnType,
      executedValue: data.executedValue ?? undefined,
    };
  });

  const finalAttribute: Record<string, any> = !_isNil(finalOutputRows)
    ? {
        output: {
          dataType: 'list',
          name: 'output',
          executedValue: finalOutputRows,
        },
      }
    : outputDataAttributes;

  const updatedDataset = {
    ...dataSetVariables,
    outputData: {
      attributes: finalAttribute,
      name: 'Output Data Attributes',
      id: 'output_data_attributes',
    },
    additionalData: {
      attributes: additionalDataAttributes,
      name: 'Additional Data Attributes',
      id: 'additional_data_attributes',
    },
  };

  let isActionValid = true;

  const duplicateKeyNames: Record<string, number[]> = {};

  const setErrorType = typeof setError === 'function';

  for (let index = 0; index < data.length; index++) {
    const action = data[index];

    if (!_isNil(duplicateKeyNames[action.name])) {
      duplicateKeyNames[action.name] = [
        ...duplicateKeyNames[action.name],
        index,
      ];
    } else {
      duplicateKeyNames[action.name] = [index];
    }

    if (action.name.trim().length >= 50) {
      isActionValid = false;

      if (setErrorType) {
        setError(`${section}.${index}`, {
          message: 'Name cannot be greater than 50 characters',
          type: 'validate',
        });
      }
    }

    if (action.name.trim().length === 0) {
      isActionValid = false;

      if (setErrorType) {
        setError(`${section}.${index}`, {
          message: 'Please enter a title',
          type: 'validate',
        });
      }
    }

    if (!KEY_REGEX.test(action.name)) {
      isActionValid = false;

      if (setErrorType) {
        setError(`${section}.${index}`, {
          message: 'Invalid action name',
          type: 'validate',
        });
      }
    }

    const plugin = getTypeNameofPluginById(
      action.connectorId,
      publishedConnectors
    );

    const editorType =
      plugin?.plugin?.name === 'postgres' ? 'pgsql' : plugin?.plugin?.name;

    const editorConfig = getEditorDetailsByPlugin(plugin);

    if (editorType === 'restAPI') {
      const isValid = validateRestApiAction(action);

      if (!isValid && setErrorType) {
        isActionValid = false;
        setError(`${section}.${index}`, {
          message: 'Invalid Action syntax',
          type: 'validate',
        });
      }
    } else if (editorType === 'gsheet' || editorType === 'slack') {
      const tokenValidity = checkUsedTokensAreValid(
        JSON.stringify(action),
        updatedDataset
      );

      if (!tokenValidity.isValidTokenUsed && setErrorType) {
        isActionValid = false;
        setError(`${section}.${index}`, {
          message: tokenValidity.message ?? 'Invalid Action syntax',
          type: 'validate',
        });
      }
    }

    const { isValid: isQueryValid, message: queryValidationMsg } =
      await validateTokensBasedOnEditor({
        dataset: updatedDataset,
        query: action.config.query ?? '',
        editorType,
        editorConfig,
      });

    if (
      !_isNil(action.config.query) &&
      !_isEmpty(action.config.query) &&
      !isQueryValid
    ) {
      isActionValid = false;

      if (setErrorType) {
        setError(`${section}.${index}`, {
          message: queryValidationMsg,
          type: 'validate',
        });
      }
    }

    const { isValid: isBodyValid, message: bodyValidationMsg } =
      await validateTokensBasedOnEditor({
        dataset: updatedDataset,
        query: action.config.body ?? '',
        editorType: action.config?.contentType?.label === 'JSON' ? 'json' : '',
      });

    if (
      !_isNil(action.config.body) &&
      !_isEmpty(action.config.body) &&
      !isBodyValid
    ) {
      isActionValid = false;

      if (setErrorType) {
        setError(`${section}.${index}`, {
          message: bodyValidationMsg,
          type: 'validate',
        });
      }
    }

    const { isValid: isPathValid, message: pathValidationMsg } =
      await validateTokensBasedOnEditor({
        dataset: updatedDataset,
        query: action.config.path ?? '',
        editorType: '',
      });

    if (
      !_isNil(action.config.path) &&
      !_isEmpty(action.config.path) &&
      !isPathValid
    ) {
      isActionValid = false;

      if (setErrorType) {
        setError(`${section}.${index}`, {
          message: pathValidationMsg,
          type: 'validate',
        });
      }
    }
  }

  _forEach(duplicateKeyNames, (value, key) => {
    if (value.length > 1) {
      isActionValid = false;

      value.forEach((val) => {
        if (setErrorType) {
          setError(`${section}.${val}`, {
            message: 'Action name must be unique',
            type: 'validate',
          });
        }
      });
    }
  });

  return isActionValid;
}

export const getAllRuleTestActions = (
  actionObject?: RuleTestActionResponse
) => {
  if (_isNil(actionObject)) return [];

  const actionList: RuleTestActionObject[] = _reduce(
    actionObject,
    (accum: RuleTestActionObject[], value, key: string) => {
      const currActionData = {
        actionName: key,
        actionData: {
          executionId: value.executionId,
          isCollapsed: true,
          status: StatusCode.RUNNING,
          json: '',
          hasError: false,
          executionTime: '',
        },
      };

      return [...accum, currActionData];
    },
    []
  );

  return actionList;
};

export const getAllInProgressActions = (
  ruleActionList: RuleTestActionObject[]
) => {
  return ruleActionList
    .filter(
      (action: RuleTestActionObject) =>
        action.actionData.status === StatusCode.RUNNING
    )
    .map((action: RuleTestActionObject) => ({
      executionId: action.actionData.executionId,
    }));
};

export const getActionInfoForRuleTestActions = (
  actionResponse: RuleTestActionObject[]
) => {
  const statusCount = {
    inProgress: 0,
    fail: 0,
    success: 0,
  };
  actionResponse.forEach((action: RuleTestActionObject) => {
    const { status, hasError } = action.actionData;
    statusCount.inProgress += status === StatusCode.RUNNING ? 1 : 0;
    statusCount.fail += status !== StatusCode.RUNNING && hasError ? 1 : 0;
    statusCount.success += status !== StatusCode.RUNNING && !hasError ? 1 : 0;
  });
  let currActionInfo: ActionInfoObject;

  if (statusCount.inProgress > 0) {
    currActionInfo = {
      status: StatusCode.RUNNING,
      count: statusCount.inProgress,
    };
  } else if (statusCount.fail > 0) {
    currActionInfo = {
      status: StatusCode.FAILED,
      count: statusCount.fail,
    };
  } else {
    currActionInfo = {
      status: StatusCode.COMPLETED,
      count: statusCount.success,
    };
  }

  return currActionInfo;
};

export const getFormattedJsonForRuleTestActions = (json: any) => {
  if (!_isNil(json)) {
    if (typeof json === 'string') return json;

    return JSON.stringify(json, null, 2);
  }

  return '';
};

export const validateRestApiAction = (
  data: Omit<ResultAction, 'connectorId'>,
  setError?: UseFormSetError<any>,
  name?: string
) => {
  let isValid = true;

  const config = data.config;

  let duplicateKeyNames: DuplicateKeyIndexes = {};

  const canSetError = typeof setError === 'function' && !_isNil(name);

  if (_isNil(config.method)) {
    isValid = false;

    if (canSetError) {
      setError(`${name}.config.method`, {
        message: `Please select a method`,
      });
    }
  }

  config.headers?.forEach((header, index) => {
    if (_isNil(duplicateKeyNames[header.key])) {
      duplicateKeyNames[header.key] = [index];
    } else {
      isValid = false;
      duplicateKeyNames[header.key] = [...duplicateKeyNames[header.key], index];
    }

    if (_isEmpty(header.key) || !ACTION_KEY_REGEX.test(header.key)) {
      isValid = false;

      if (canSetError) {
        setError(`${name}.config.headers.${index}.key`, {
          message: `Key name must be proper`,
        });
      }
    }

    if (_isNil(header.value) || _isEmpty(header.value)) {
      isValid = false;

      if (canSetError) {
        setError(`${name}.config.headers.${index}.value`, {
          message: `Value must not be empty`,
        });
      }
    }

    const matchedExpressions = (header.value as string).match(TOKEN_REGEX);

    matchedExpressions?.forEach((expression) => {
      const splitExpression = expression.split('.');

      if (
        splitExpression[0] === 'headers' &&
        splitExpression[splitExpression.length - 1] !==
          capitalizeHeaderKey(header.key)
      ) {
        isValid = false;

        if (canSetError) {
          setError(`${name}.config.headers.${index}.value`, {
            message: `Incorrect header token used`,
          });
        }
      }
    });
  });

  _forEach(duplicateKeyNames, (val, key) => {
    if (val.length > 1) {
      val.forEach((i) => {
        if (canSetError) {
          setError(`${name}.config.headers.${i}.key`, {
            message: 'Key name must be unique',
          });
        }
      });
    }
  });

  duplicateKeyNames = {};

  config.queryParams?.forEach((queryParam, index) => {
    if (_isNil(duplicateKeyNames[queryParam.key])) {
      duplicateKeyNames[queryParam.key] = [index];
    } else {
      duplicateKeyNames[queryParam.key] = [
        ...duplicateKeyNames[queryParam.key],
        index,
      ];
    }

    if (_isEmpty(queryParam.key) || !ACTION_KEY_REGEX.test(queryParam.key)) {
      isValid = false;

      if (canSetError) {
        setError(`${name}.config.queryParams.${index}.key`, {
          message: `Key name must be proper`,
        });
      }
    }

    if (_isNil(queryParam.value) || _isEmpty(queryParam.value)) {
      isValid = false;

      if (canSetError) {
        setError(`${name}.config.queryParams.${index}.value`, {
          message: `Value must not be empty`,
        });
      }
    }
  });

  _forEach(duplicateKeyNames, (val, key) => {
    if (val.length > 1) {
      isValid = false;
      val.forEach((i) => {
        if (canSetError) {
          setError(`${name}.config.queryParams.${i}.key`, {
            message: 'Key name must be unique',
          });
        }
      });
    }
  });

  return isValid;
};

export const validateTestParameters = (
  data: TestNodesDataModel,
  setError: UseFormSetError<any>
) => {
  let isValid = true;
  data.headers.forEach((header, index) => {
    if (_isEmpty(header.key) || !ACTION_KEY_REGEX.test(header.key)) {
      isValid = false;

      setError(`headers.${index}.key`, {
        message: 'Invalid key name',
      });
    }
  });

  return isValid;
};

export const isRuleNameValid = (
  name: string,
  setError?: UseFormSetError<any>
) => {
  let isNameValid = true;

  const isSetError = typeof setError === 'function';

  if (name.trim().length === 0) {
    if (isSetError) {
      setError('ruleName', {
        message: 'Rule name cannot be empty or spaces',
      });
    }

    isNameValid = false;
  } else if (name.trim().length > 100) {
    if (isSetError) {
      setError('ruleName', {
        message: 'Rule name cannot be more than 100 characters',
      });
    }

    isNameValid = false;
  }

  return isNameValid;
};

export const getFilteredDataSetWithoutOptional = (
  tokens: string[],
  customAttributes: Record<string, AttributeModel>
) => {
  return tokens.filter((sug) => {
    let newSug = sug.replaceAll('<<', '');
    newSug = newSug.replaceAll('>>', '');
    newSug = newSug.replaceAll("'", '');

    if (
      sug.includes('customInput') &&
      !_isNil(customAttributes[newSug.split('.')[1]]) &&
      customAttributes[newSug.split('.')[1]].isOptional
    ) {
      return false;
    }

    return true;
  });
};

export const getFilteredDataSetObjWithoutOptional = (
  tokens: NectedSuggestionModel[],
  customAttributes: Record<string, AttributeModel>
) => {
  return tokens.filter((sug) => {
    let newSug = sug.value.replaceAll('<<', '');
    newSug = newSug.replaceAll('>>', '');
    newSug = newSug.replaceAll("'", '');

    if (
      sug.value.includes('customInput') &&
      !_isNil(customAttributes[newSug.split('.')[1]]) &&
      customAttributes[newSug.split('.')[1]].isOptional
    ) {
      return false;
    }

    return true;
  });
};

export const getDecisionTableBlock = (
  isLast: boolean,
  isFirst: boolean,
  length: number
) => {
  if (isLast) {
    return '0.8rem 1.6rem 0.8rem 2.4rem';
  } else if (isFirst && length > 1) {
    return '0.8rem 3.4rem 0.8rem 0.8rem';
  } else if (!isLast && length > 1) {
    return '1rem 2.6rem';
  }

  return '0.8rem';
};

export const getDataSetsQueryByName = (
  siteConstants: SiteConstantsModel | null,
  connector?: ConnectorAndPluginModel | null
) => {
  switch (connector?.plugin.name.toLowerCase()) {
    case 'mongodb':
      return getTooltipText(
        siteConstants,
        'datasets',
        'mongoDemoCode',
        'otherText'
      );
    case 'postgres':
      return getTooltipText(
        siteConstants,
        'datasets',
        'postgresDemoCode',
        'otherText'
      );
    case 'mysql':
      return getTooltipText(
        siteConstants,
        'datasets',
        'mysqlDemoCode',
        'otherText'
      );
    case 'sqlserver':
      return getTooltipText(
        siteConstants,
        'datasets',
        'sqlServerDemoCode',
        'otherText'
      );
    default:
      return '';
  }
};

export const outputKeyGenerator = (
  name: string = '',
  dataType: string = ''
) => {
  const elementsToBeString = ['string', 'date', 'datetime'];

  if (elementsToBeString.includes(dataType.toLowerCase())) {
    return `"<<outputData.${name}>>"`;
  }

  return `<<outputData.${name}>>`;
};

export const transformCronOutput = (
  data: LocalCronRuleModel,
  oldData: RuleCronScheduleModel
) => {
  const spec = {
    dayOfMonth: '',
    dayOfWeek: '',
    hour: '',
    minute: '',
    month: '',
  };

  const config: RuleCronScheduleModel = {
    ...oldData,
    timezone: getTimeZone(),
  };

  config.startAt = data.startAt.toISOString();

  if (!_isNil(data.endAt)) {
    config.endAt = data.endAt.toISOString();
  } else {
    config.endAt = null;
  }

  if (!_isNil(data.unit) && !_isEmpty(data.unit)) {
    config.unit = data.unit.value;

    if (data.unit.value === 'minute') {
      config.spec = { ...spec, minute: data.minutes.toString() };
    } else if (data.unit.value === 'hourly') {
      config.spec = { ...spec, hour: data.hours.toString() };
    } else if (data.unit.value === 'weekly') {
      config.spec = { ...spec, dayOfWeek: data.weekdays.join(',') };
    } else if (data.unit.value === 'monthly') {
      config.spec = { ...spec, dayOfMonth: data.days.join(',') };
    } else if (data.unit.value === 'daily') {
      config.spec = { ...spec };
    } else if (data.unit.value === 'cron') {
      config.spec = {
        ...spec,
        dayOfMonth: data.cron.days,
        dayOfWeek: data.cron.weekdays,
        hour: data.cron.hours,
        minute: data.cron.minutes,
        month: data.cron.month,
      };
    }
  }

  return config;
};

export const formatCronInput = (cronData?: RuleCronScheduleModel) => {
  if (!_isNil(cronData)) {
    const newDefaultValue = {
      ...structuredClone(defaultSchedulerValues),
      startAt: !_isEmpty(cronData.startAt ?? '')
        ? new Date(cronData.startAt)
        : undefined,
      endAt: !_isEmpty(cronData.endAt ?? '')
        ? new Date(cronData.endAt ?? '')
        : undefined,
      unit: CRON_UNITS.find((cron) => cron.value === cronData.unit),
    };

    if (cronData.unit === 'minute') {
      return {
        ...newDefaultValue,
        minutes: parseInt(cronData.spec.minute),
      };
    } else if (cronData.unit === 'hourly') {
      return {
        ...newDefaultValue,
        hours: parseInt(cronData.spec.hour),
      };
    } else if (cronData.unit === 'daily') {
      return {
        ...newDefaultValue,
      };
    } else if (cronData.unit === 'weekly') {
      return {
        ...newDefaultValue,
        weekdays: cronData.spec.dayOfWeek
          .split(',')
          .map((week) => parseInt(week)),
      };
    } else if (cronData.unit === 'monthly') {
      return {
        ...newDefaultValue,
        days: cronData.spec.dayOfMonth.split(',').map((week) => parseInt(week)),
      };
    } else if (cronData.unit === 'cron') {
      return {
        ...newDefaultValue,
        cron: {
          days: cronData.spec.dayOfMonth,
          hours: cronData.spec.hour,
          minutes: cronData.spec.minute,
          weekdays: cronData.spec.dayOfWeek,
          month: cronData.spec.month,
        },
      };
    }
  } else {
    return structuredClone(defaultSchedulerValues);
  }
};

export const validateScheduleBeforeSaving = (
  trigger: ProductionConfigModel,
  state: 'stagingConfig' | 'productionConfig',
  customAttributes: Record<string, AttributeModel>,
  setError?: UseFormSetError<any>
) => {
  let isValid = true;

  const isSetError = typeof setError === 'function';

  const checker = (arr: any[], target: any[]) =>
    target.every((v) => arr.includes(v));

  const newCustomInputs = Object.keys(customAttributes).filter(
    (k) =>
      !['dataSet', 'restAPI'].includes(customAttributes[k].sourceType ?? '')
  );

  if (!_isNil(trigger.schedule) && !_isEmpty(trigger.schedule)) {
    trigger.schedule.forEach((schedule, index) => {
      if (!checker(Object.keys(schedule?.inputParam ?? {}), newCustomInputs)) {
        if (isSetError) {
          setError(`${state}.schedule`, {
            message: `Please check the custom inputs filled`,
          });
        }

        isValid = false;
      }
    });
  }

  return isValid;
};

export const filterRestApiConnectorsFromPublished = (
  publishedConnectors: PublishedConnectors
) => {
  return _reduce(
    publishedConnectors,
    (res: RestAPIEditorPayload[], val, key) => {
      if (val.plugin.name.toLowerCase() === 'restapi') {
        return [
          ...res,
          {
            name: val.name,
            value: val.id,
            type: val.plugin.name,
            prodBaseURL: val.production?.conf?.baseUrl ?? '',
            stagBaseURL: val.staging?.conf?.baseUrl ?? '',
          },
        ];
      }

      return res;
    },
    []
  );
};

export const filterRestAPIConnectorsFromPublishedMap = (
  publishedConnectors: PublishedConnectors
) => {
  const filteredMap: Record<string, Attributes> = {};

  for (const key in publishedConnectors) {
    const connector = publishedConnectors[key];

    if (connector.plugin.name.toLowerCase() === 'restapi') {
      filteredMap[key] = {
        id: connector.id,
        name: connector.name,
        dataType: connector.plugin.name as DataTypes,
      };
    }
  }

  return filteredMap;
};
export const getPlaceholderByDataType = (dataType?: string) => {
  switch (dataType) {
    case 'dateTime':
      return DATE_TIME_FORMAT;
    case 'date':
      return 'yyyy-mm-dd';
    default:
      return 'Enter value';
  }
};

export const getUpdatedTokens = (
  dataSetParams: Record<string, Dataset>,
  skipJson: boolean = false
) => {
  return _reduce(
    dataSetParams,
    (result: string[], value, key) => {
      if (!_isNil(value.attributes)) {
        return [
          ...result,
          ..._reduce(
            value.attributes,
            (res: string[], attributeValue, attributeKey) => {
              const mappedValue = flattenKeysAndTypes(
                attributeValue.executedValue ?? {}
              ).map((kt) => {
                return `<<${key}.${attributeKey}.${kt.key}>>`;
              });

              if (skipJson && attributeValue.dataType === 'json') {
                return [...res, ...mappedValue];
              }

              return [...res, `<<${key}.${attributeKey}>>`, ...mappedValue];
            },
            []
          ),
        ];
      }

      return result;
    },
    []
  );
};

export const getUpdatedTokensByType = (
  dataSetParams: Record<string, Dataset>,
  skipJson: boolean = false,
  skipQuotes: boolean = false
) => {
  return _reduce(
    dataSetParams,
    (result: string[], value, key) => {
      if (!_isNil(value.attributes)) {
        return [
          ...result,
          ..._reduce(
            value.attributes,
            (res: string[], attributeValue, attributeKey) => {
              const mappedValue = flattenKeysAndTypes(
                attributeValue.executedValue ?? {}
              ).map((kt) => {
                return `<<${key}.${attributeKey}.${kt.key}>>`;
              });

              if (skipJson && attributeValue.dataType === 'json') {
                return [...res, ...mappedValue];
              }

              let tokenName = `"<<${key}.${attributeKey}>>"`;

              if (skipQuotes) {
                tokenName = `<<${key}.${attributeKey}>>`;
              }

              if (['string'].includes(attributeValue.dataType)) {
                return [...res, tokenName, ...mappedValue];
              }

              return [...res, `<<${key}.${attributeKey}>>`, ...mappedValue];
            },
            []
          ),
        ];
      }

      return result;
    },
    []
  );
};

export const getOutputPlaceholder = (dataType: string) => {
  return `Enter ${dataType} or custom tokens`;
};

const processValue = (
  ruleType: RuleTypes,
  dataTypeByKey: Record<string, any>,
  key: string,
  val: Record<string, any>,
  customAttributes: Record<string, AttributeModel>
) => {
  if (ruleType === 'ruleSet' && dataTypeByKey[key] === 'numeric') {
    return parseFloat(val.value as string);
  } else if (ruleType === 'ruleSet') {
    return val.value;
  } else if (customAttributes[key]?.dataType?.value === 'numeric') {
    return parseFloat(val.value as string);
  } else if (customAttributes[key]?.dataType?.value === 'list') {
    return convertArrayAsInput(val.value);
  } else {
    return val.value;
  }
};

export const handleSetCronValues = (
  ruleType: RuleTypes,
  cronAttributes: Record<string, CronCAObjectModel>,
  dataTypeByKey: Record<string, any> = {},
  customAttributes: Record<string, AttributeModel> = {}
) => {
  return _reduce(
    cronAttributes,
    (res: any, val, key) => {
      if (ruleType === 'ruleSet' && dataTypeByKey[key] === 'restAPI') {
        return res;
      } else if (
        ruleType !== 'ruleSet' &&
        customAttributes[key].dataType?.value === 'restAPI'
      ) {
        return res;
      }

      // eslint-disable-next-line
      if (!_isEmpty(key) && !val.isDataset) {
        return {
          ...res,
          [key]: {
            value: processValue(
              ruleType,
              dataTypeByKey,
              key,
              val,
              customAttributes
            ),
            sendNull: val.isNullable,
            notSend: val.isOptional,
          },
        };
      }

      return res;
    },
    {}
  );
};

export const getCustomAttributeData = (
  customAttributes: Record<string, AttributeModel>,
  firstAttribute: string = ''
) => {
  if (!_isEmpty(firstAttribute)) {
    const list: any[] = [];
    getCustomAttributeByPrev(customAttributes, firstAttribute, list);

    return list;
  }

  const updatedCi = Object.keys(customAttributes)
    .filter((key) => key !== 'CI0')
    .map((key) => ({
      ...customAttributes[key],
      executedValue: transformSampleValue(
        customAttributes[key].selectedType?.value ?? '',
        customAttributes[key].executedValue
      ),
      sampleValue: transformSampleValue(
        customAttributes[key].selectedType?.value ?? '',
        customAttributes[key].executedValue ?? customAttributes[key].sampleValue
      ),
      config: customAttributes[key].config ?? {},
    }));

  return updatedCi;
};

export const getCustomAttributeByPrev = (
  customAttributes: Record<string, AttributeModel>,
  key: string,
  list: any[]
) => {
  let keyName = '';

  // this else if is for the fallback in case there is a wrong key saved in firstCustomAttribute
  // then we will check it customAttributes has some keys added and re construct the keys.
  if (!_isNil(customAttributes[key])) {
    keyName = key;
  } else if (
    _isNil(customAttributes[key]) &&
    Object.keys(customAttributes).length > 0
  ) {
    return reconstructArrayFromLL(customAttributes);
  } else {
    return list;
  }

  list.push({
    ...customAttributes[keyName],
    executedValue: transformSampleValue(
      customAttributes[keyName].dataType?.value ?? '',
      customAttributes[keyName].executedValue
    ),
    sampleValue: transformSampleValue(
      customAttributes[keyName].dataType?.value ?? '',
      customAttributes[keyName].executedValue
    ),
    config: customAttributes[keyName].config ?? {},
  });

  if (!_isEmpty(customAttributes[keyName].next)) {
    getCustomAttributeByPrev(
      customAttributes,
      customAttributes[keyName].next,
      list
    );
  }
};

// This function is to convert LL into array is the starting node is not known to us
export const reconstructArrayFromLL = (
  linkedList: Record<string, AttributeModel>
): AttributeModel[] => {
  const result: AttributeModel[] = [];
  const nodeMap: Record<string, AttributeModel> = {};
  let startNodeKey: string | null = null;
  let hasStartNode = false;

  // Step 1: Create a map of all nodes and identify the start node
  for (const key in linkedList) {
    nodeMap[key] = linkedList[key];

    if (
      !_isNil(linkedList[key].previous) &&
      !_isEmpty(linkedList[key].previous)
    ) {
      startNodeKey = key;
      hasStartNode = true;
    }
  }

  // Step 2: If a start node is found, traverse from the start node and construct the ordered array
  if (hasStartNode) {
    let currentNodeKey: string | null = startNodeKey;
    while (!_isNil(currentNodeKey) && !_isNil(nodeMap[currentNodeKey])) {
      result.push({
        ...nodeMap[currentNodeKey],
        executedValue: transformSampleValue(
          nodeMap[currentNodeKey].selectedType?.value ?? '',
          nodeMap[currentNodeKey].executedValue
        ),
        sampleValue: transformSampleValue(
          nodeMap[currentNodeKey].selectedType?.value ?? '',
          nodeMap[currentNodeKey].executedValue
        ),
        config: nodeMap[currentNodeKey].config ?? {},
      });
      const nextNodeKey = nodeMap[currentNodeKey].next;
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete nodeMap[currentNodeKey];
      currentNodeKey = nextNodeKey;
    }
  }

  // Step 3: Append any remaining nodes that were not linked correctly or if no start node was found
  for (const key in nodeMap) {
    result.push({
      ...nodeMap[key],
      executedValue: transformSampleValue(
        nodeMap[key].selectedType?.value ?? '',
        nodeMap[key].executedValue
      ),
      sampleValue: transformSampleValue(
        nodeMap[key].selectedType?.value ?? '',
        nodeMap[key].executedValue
      ),
      config: nodeMap[key].config ?? {},
    });
  }

  return result;
};

export const getCustomAttributeTokensForRestInCI = (
  tokenDataSet: Record<string, Dataset>
) => {
  const tokensSet: TokensSetProps[] = [];
  Object.keys(tokenDataSet).forEach((key) => {
    Object.keys(tokenDataSet[key].attributes).forEach((attributeKey) => {
      const currentAttribute = tokenDataSet[key].attributes[attributeKey];

      if (['json', 'restAPI'].includes(currentAttribute.dataType)) {
        if (
          !_isNil(currentAttribute.sampleValue) &&
          !_isEmpty(currentAttribute.sampleValue)
        ) {
          flattenKeysAndTypes(currentAttribute.sampleValue ?? {}).forEach(
            (kt) => {
              tokensSet.push({
                token: `{{.${key}.${attributeKey}.${kt.key}}}`,
                name: `${key}.${attributeKey}.${kt.key}`,
                executedValue: kt.value,
              });

              return `<<${key}.${attributeKey}.${kt.key}>>`;
            }
          );
        }
      } else {
        tokensSet.push({
          token: `{{.${key}.${attributeKey}}}`,
          name: `${key}.${attributeKey}`,
          executedValue:
            currentAttribute.sampleValue ??
            (key === 'globalVar'
              ? currentAttribute.executedValue
              : getDefaultValueByDataType(currentAttribute.dataType)),
        });
      }
    });
  });

  return tokensSet;
};

export const extractExecutedValueFromObject = (
  data: Record<string, any>,
  key: string
) => {
  if (_isEmpty(key)) {
    return getDefaultValueForTokensByDataType('string');
  }

  const keysList = key.split('.');

  for (const currentKey of keysList) {
    if (_isNil(data[currentKey])) {
      return getDefaultValueForTokensByDataType('json');
    } else {
      data = data[currentKey];
    }
  }

  return data ?? getDefaultValueForTokensByDataType('string');
};

type ValidateTokensBasedOnEditorArgs = {
  dataset: Record<string, Dataset>;
  query: string;
  editorType: string;
  editorConfig?: EditorConfigurationDetails | null;
};

export const validateTokensBasedOnEditor = async ({
  dataset,
  query,
  editorType,
  editorConfig,
}: ValidateTokensBasedOnEditorArgs) => {
  if (_isNil(query) || _isEmpty(query)) {
    return {
      isValid: true,
      message: '',
    };
  }

  let isValid = true;

  const { isValidTokenUsed, message } = checkUsedTokensAreValid(query, dataset);

  if (!isValidTokenUsed) {
    return {
      isValid: false,
      message,
    };
  }

  const updatedQuery = sanitizedStringV2(query, dataset);

  switch (editorType) {
    case 'json': {
      isValid = isValidJson(updatedQuery);

      return {
        isValid,
        message: isValid ? '' : 'Invalid JSON Syntax',
      };
    }
    case 'jsFormula': {
      isValid = isValidJS(
        updatedQuery.replaceAll(REMOVE_JAVASCRIPT_COMMENTS_REGEX, '')
      );

      return {
        isValid,
        message: isValid ? '' : 'Invalid JS Syntax',
      };
    }
    case 'mongodb': {
      isValid = isMongoActionValid(updatedQuery);

      return {
        isValid,
        message: isValid ? '' : 'Invalid Action Syntax',
      };
    }
    case 'mysql':
    case 'snowflake':
    case 'oracle':
    case 'pgsql': {
      return {
        isValid: !_isEmpty(updatedQuery),
        message: !_isEmpty(updatedQuery) ? '' : 'Invalid Action Syntax',
      };
    }
    default: {
      return {
        isValid,
        message: isValid ? '' : 'Invalid Syntax',
      };
    }
  }
};

export const isTokenMappedInCI = (
  customInput: Record<string, AttributeModel>,
  dataSet?: string
) => {
  let isTokenMapped = true;

  if (!_isNil(dataSet) && dataSet !== '' && !_isNil(dataSet)) {
    // eslint-disable-next-line
    isTokenMapped = !!Object.keys(customInput).find(
      (k) => customInput[k].sourceType === 'dataSet'
    );
  }

  return isTokenMapped;
};

export const formatCustomAttributesRuleSet = (
  customAttributes: Record<string, AttributeModel>,
  ruleList: RuleSetNodesModel[],
  customAttributesById: Record<string, CustomAttributeByRuleId[]>
) => {
  const newCustomAttributes = { ...customAttributes };
  const newSaveCustomAttributes: any = {};

  const newCustomAttributesById = ruleList.reduce<
    Record<string, CustomAttributeByRuleId[]>
  >((acc, curr) => {
    if (!_isNil(customAttributesById[curr.ruleId]) && curr.isEnabled) {
      return {
        ...acc,
        [curr.ruleId]: customAttributesById[curr.ruleId],
      };
    }

    return acc;
  }, {});

  ruleList.forEach((rule) => {
    newCustomAttributesById[rule.ruleId]?.forEach((a) => {
      let exists = false;

      if (
        !_isNil(newSaveCustomAttributes[a.key]) &&
        newSaveCustomAttributes[a.key].dataType !== a.dataType
      ) {
        exists = true;
      }

      newSaveCustomAttributes[a.key] = {
        ...a,
        next: '',
        previous: '',
        name: a.key,
        dataType: exists ? 'unknown' : a.dataType,
        isCaseSensitive: a.isCaseSensitive ?? false,
        isList: false,
        testValue: undefined,
        sampleValue: undefined,
        executedValue: a.executedValue ?? '',
        isNullable: a.isNullable ?? false,
        isOptional: a.isOptional ?? false,
        key: undefined,
        selectedType: undefined,
      };

      if (
        !_isNil(customAttributes[a.key]) &&
        customAttributes[a.key].dataType?.value === a.dataType
      ) {
        // eslint-disable-next-line
        return;
      } else {
        newCustomAttributes[a.key] = {
          ...a,
          next: '',
          previous: '',
          name: a.key,
          selectedType: getSelectedType(a.dataType, a.attribute, a.sourceType),
          dataType: getDataTypeByValue(a.dataType),
          isCaseSensitive: a.isCaseSensitive ?? false,
          isList: false,
          testValue: a.testValue ?? '',
        };
      }
    });
  });

  return { newCustomAttributes, newSaveCustomAttributes };
};

export const removeCustomFunction = (dataset: Record<string, Dataset>) => {
  const { custom, ...rest } = dataset;

  return {
    ...rest,
  };
};

export const getRuleSetTestModel = (item: CustomAttributeByRuleId) => {
  const valType = typeof item.executedValue;

  return {
    isNullable: false,
    isOptional: false,
    value:
      typeof valType === 'string' || valType === 'boolean'
        ? item.executedValue
        : item.executedValue !== '' && !_isNil(item.executedValue)
        ? transformSampleValue(item.dataType ?? 'string', item.executedValue)
        : getDefaultValuesForTest(item.dataType ?? 'string'),
  };
};

export const isUnGroupAble = (
  rules: Record<string, SimpleRuleNodesModel>,
  ruleId: string
): boolean => {
  // eslint-disable-next-line
  return !!rules[rules[ruleId].parent].parent;
};
