import { ReactElement } from 'react';
import ReactJson from 'react-json-view';
import type { PlacesType } from 'react-tooltip';
import { PopoverPanel, Typography } from 'ui';

import {
  StyledExecutedValueContainer,
  ToolTipContent,
  TooltipHeader,
} from './ExecutedValueTooltip.styled';

export type ExecutedValueTooltipProps = {
  id: string;
  children?: ReactElement;
  attribute?: string;
  value: any;
  dataType?: string;
  placement?: PlacesType;
  isVisible?: boolean;
  showExecutedValue?: boolean;
};

export function ExecutedValueTooltip({
  id = '',
  dataType,
  attribute = '',
  value,
  children,
  placement = 'right',
  isVisible = true,
  showExecutedValue = true,
}: ExecutedValueTooltipProps) {
  value = attribute === 'NULL' ? null : value;

  return (
    <PopoverPanel launcher={children} arrow padding={0} placement={placement}>
      {isVisible && (
        <StyledExecutedValueContainer
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <TooltipHeader $showExecutedValue={showExecutedValue}>
            <Typography name="secondaryXs" fontWeight={700}>
              {attribute}
            </Typography>
            <Typography
              name="secondaryXs"
              fontWeight={700}
              className="datatype-text"
            >
              {` (${dataType ?? '-'})`}
            </Typography>
          </TooltipHeader>
          {showExecutedValue && (
            <ToolTipContent>
              {['json', 'list'].includes(dataType ?? '') && value !== null ? (
                <ReactJson src={value} collapsed={1} />
              ) : (
                `${value as string}`
              )}
            </ToolTipContent>
          )}
        </StyledExecutedValueContainer>
      )}
    </PopoverPanel>
  );
}
