import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import type {
  Control,
  UseControllerProps,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import type { NectedSuggestionModel } from 'ui';

import { useAxiosPrivate } from '../../../../hooks';
import { Form } from '../../../Form';
import type { FormFields } from '../../../Form/types';

type ActionFormProps = Omit<UseControllerProps, 'name'> & {
  sectionName: string;
  pluginName: string;
  index: number;
  formFields: FormFields[];
  watch: UseFormWatch<Record<string, any>>;
  id: string;
  mainControl?: Control<any>;
  setValue?: UseFormSetValue<any>;
  parentFormData?: Record<string, any>;
  formJson?: Record<string, any>;
  formKeyPrefix?: string;
  newCustomSuggestions?: NectedSuggestionModel[];
  handleGetExecutionValues?: (data: Record<string, any>) => void;
  execValues?: Record<string, any>;
};

export function ActionForm({
  sectionName,
  pluginName,
  index,
  formFields,
  control,
  mainControl,
  watch,
  id,
  setValue,
  parentFormData = {},
  formJson = {},
  formKeyPrefix = '',
  newCustomSuggestions = [],
  handleGetExecutionValues,
  execValues,
}: ActionFormProps) {
  const status = useWatch({ control: mainControl, name: 'status' });
  const { axiosPrivate } = useAxiosPrivate();
  const getDropdownOptions = async (obj: Record<string, any>) => {
    const { name = '' } = obj;

    if (!_isEmpty(name)) {
      const currentField = formFields.filter((field) => field.key === name);

      if (currentField.length > 0) {
        const apiSpec = currentField[0].apiSpec;

        if (!_isUndefined(apiSpec)) {
          const { endpoint, method = 'GET', queryParams = {} } = apiSpec;

          const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key] as string}`)
            .join('&');

          const url = `${
            endpoint.replace('{id}', id) as string
          }?${queryString}`;

          let response;
          try {
            if (method === 'GET') {
              response = await axiosPrivate.get(url);
            }

            if (response?.data.code === 'success') {
              return response?.data.data;
            }
          } catch (err) {}
        }
      }
    }

    return [];
  };

  return (
    <Stack>
      <Form
        name={sectionName}
        entity="rules"
        entityStatus={status}
        watch={watch}
        control={control}
        formFields={formFields}
        newCustomSuggestions={newCustomSuggestions}
        getDropdownOptions={getDropdownOptions}
        parentFormData={parentFormData}
        formJson={formJson}
        setValue={setValue}
        formKeyPrefix={formKeyPrefix}
        handleGetExecutionValues={handleGetExecutionValues}
        execValues={execValues}
      />
    </Stack>
  );
}
