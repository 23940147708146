import _isNil from 'lodash/isNil';
import { FaExclamationTriangle } from 'react-icons/fa';
import { TooltipReact, Typography } from 'ui';

import useGetThresholdElement from '../../../hooks/useGetThresholdElement';
import { UpgradeLink } from '../../sidebar/Sidebar.styled';
import type { ThresholdProps } from '../Threshold';
import { Message, MessageContainer, VariantContainer } from './Variants.styled';

type BasicProps = Omit<ThresholdProps, 'variant'>;

export function Basic({ max, current, message, entity, align }: BasicProps) {
  const { color, text, state, percentage } = useGetThresholdElement({
    max,
    current,
    entity,
  });

  return (
    <VariantContainer gutter="0.8rem">
      {!_isNil(message) && state === 'danger' && (
        <TooltipReact
          id="error-message"
          launcher={<FaExclamationTriangle fill={color} fontSize={12} />}
        >
          {message}
        </TooltipReact>
      )}
      <MessageContainer gutter={0} align={align}>
        <Message>{text}</Message>
        {percentage > 85 && (
          <Typography name="paragraph">
            {entity === 'node'
              ? 'Upgrade Plan'
              : 'Ready to go beyond this free plan?'}{' '}
            <UpgradeLink
              textDecoration={true}
              fontSize={1.4}
              target="_blank"
              href="https://www.nected.ai/pricing#pricing-container"
              id="extend-plan-link"
            >
              Upgrade Plan
            </UpgradeLink>
          </Typography>
        )}
      </MessageContainer>
    </VariantContainer>
  );
}
