import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { CiSearch } from 'react-icons/ci';
import { RxCross2 } from 'react-icons/rx';
import { TbDownload } from 'react-icons/tb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TextAreaField,
  TextField,
  TextInput,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { subscriptionPlanAtom } from '../../../../atom';
import { getUserState } from '../../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { updateWidgetState } from '../../../../pages/Home/components/sub-components/UpdateWidgetState';
import { useGenrateEntityId } from '../../../../pages/Home/components/sub-components/useGenerateEntityId';
import { useGetDemoEntity } from '../../../../pages/Home/hooks/useGetHomePageData';
import { TabsContainer } from '../../../../pages/Integrations/components/common/IntegrationSheet.styled';
import {
  decisionTableInitialData,
  decisionTableInitialNodes,
} from '../../../../pages/Rules/components/DecisionTable/fixtures/sample';
import { ruleInitialState } from '../../../../pages/Rules/fixtures/ruleInitialState';
import { RuleConfigType } from '../../../../pages/Rules/types';
import { ruleTypeConfig } from '../../../../pages/Rules/utils/constant';
import {
  checkLimitExceeded,
  isOnboardingCompleted,
} from '../../../../utils/common';
import { demoRuleCreated, envMap } from '../../../../utils/constant';
import { maxFiftyCharactersRule } from '../../../../utils/validation';
import { BannerBox } from '../../../BannerBox';
import {
  ActionBlockContainer,
  IconContainer,
} from '../../../DemoRulePopup/demoRule.styled';
import { ImportEntityModal } from '../../../Modals/ImportEntityModal/ImportEntityModal';
import { Threshold } from '../../../Threshold/Threshold';
import {
  CreateRuleBlock,
  CustomClose,
  DemoRuleBlock,
  NoDemoRules,
  RuleSearchInputContainer,
  RuleTemplateFooterContainer,
  RuleTypePill,
  SearchContainerStyled,
} from '../../listing.styled';
import { useCreateDT } from '../hooks/graphql/useCreateDT';
import { useCreateRS } from '../hooks/graphql/useCreateRS';
import { useCreateSR } from '../hooks/graphql/useCreateSR';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export type RuleTemplateProps = {
  title: string;
  type: 'RULE_TEMPLATE' | 'CREATE_TEMPLATE';
  selectedTab: number;
  subModules?: Record<string, boolean>;
};

type DemoObjProps = {
  name: string;
  id: string;
  entityId: string;
  description: string;
  type: string;
};

type StartFromTemplateProps = {
  demoRules: DemoObjProps[];
  selectedDemoObj: DemoObjProps | null;
  selectDemoRule: (obj: DemoObjProps) => void;
  searchedText: string;
  setSearchedText: (value: string) => void;
};

type CreateRuleFormProps = {
  items: RuleConfigType[];
  control: Control<any>;
  ruleType: string;
  handleSelectRuleType: (type: string) => void;
};

export const ruleNameMap: Record<string, string> = {
  decisionTable: 'Decision Table',
  simpleRule: 'Simple Rule',
  ruleSet: 'Rule Set',
};

export const RuleTemplatePopup = ({
  title,
  type,
  selectedTab,
  subModules,
}: RuleTemplateProps) => {
  const navigate = useNavigate();
  const { sendEventToGTM } = useSendEventToGTM();
  const [searchParams] = useSearchParams();

  const [searchedText, setSearchedText] = useState('');
  const [subscriptionPlan] = useAtom(subscriptionPlanAtom);
  const [demoRules, setDemoRules] = useState<DemoObjProps[]>([]);
  const [currentTab, setCurrentTab] = useState(selectedTab ?? 0);
  const [createSR] = useCreateSR();
  const [createDT] = useCreateDT();
  const [createRS] = useCreateRS();

  const createMap: Record<string, any> = {
    simpleRule: createSR,
    decisionTable: createDT,
    ruleSet: createRS,
  };

  const [ruleType, setRuleType] = useState('decisionTable');
  const [demoObj, setDemoObj] = useState<DemoObjProps | null>(null);
  const { generateEntityId } = useGenrateEntityId();
  const [getDemoEntityData, { data: demoRulesData }] = useGetDemoEntity();

  const { close: closePopup } = useCurrentLayer();

  const { openWithProps: openImportModal } = useLayer(
    <ImportEntityModal entityType="rule" from="import_list" />
  );

  const { control, getValues, setError } = useForm<Record<string, string>>({
    mode: 'onChange',
  });
  const customClosePopup = () => {
    sendEventToGTM({
      event: 'lb_popup_closed',
      action: 'click',
      type,
      title,
    });
    closePopup();
  };

  useEffect(() => {
    if (deploymentType !== 'onpremise') {
      void getDemoEntityData();
    }
  }, []);

  useEffect(() => {
    if (!_isNil(demoRulesData)) {
      setDemoRules(demoRulesData.getDemoUseCases.data);
    }
  }, [demoRulesData]);

  useEffect(() => {
    if (searchedText === '') {
      if (!_isNil(demoRulesData)) {
        setDemoRules(demoRulesData.getDemoUseCases.data);
      }
    } else {
      if (!_isNil(demoRulesData)) {
        const searchedData = demoRulesData.getDemoUseCases.data.filter((i) =>
          i.name.toLocaleLowerCase().includes(searchedText.toLocaleLowerCase())
        );
        setDemoRules(searchedData);
      }
    }
  }, [searchedText]);

  const selectDemoRule = (obj: DemoObjProps) => {
    setDemoObj(obj);
  };

  const handleCreateDemoRule = () => {
    if (!_isNil(demoObj)) {
      let ruleURL = `/rules/ID?type=edit&stage=staging&ruleType=${
        demoObj.type
      }&closeSheet=true&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;

      if (!_isNil(searchParams.get('step'))) {
        ruleURL = `${ruleURL}&step=${searchParams.get('step') as string}`;
      }

      if (!_isNil(demoObj.entityId) && !_isEmpty(demoObj.entityId)) {
        sendEventToGTM({
          event: 'lb_demo_rule_open',
          action: 'click',
          type: demoObj.type,
          title,
          entityId: demoObj.entityId,
        });
        navigate(ruleURL.replace('ID', demoObj.entityId));
      } else {
        sendEventToGTM({
          event: 'lb_demo_rule_created',
          action: 'click',
          type: demoObj.type,
          title,
          entityId: demoObj.id,
        });
        void updateUserState();
        void createEntityAndOpen(demoObj.id, ruleURL, '_self');
      }
    }
  };

  const handleFooterClose = () => {
    if (currentTab === 1) {
      handleCreateDemoRule();
    } else {
      handleCreateRule();
    }
  };

  const toggleTabs = (tabValue: number) => {
    setCurrentTab(tabValue);
  };

  const handleCreateRule = () => {
    const formValues = getValues();
    const requestPayload: Record<string, any> = {
      name: formValues.useCaseName,
      description: formValues.ruleDescripiton ?? '',
      type: ruleType,
      production: {
        isEnabled: true,
        api: {
          isEnabled: true,
        },
      },
      staging: {
        isEnabled: true,
        api: {
          isEnabled: true,
        },
      },
    };

    if (_isNil(formValues.useCaseName) || _isEmpty(formValues.useCaseName)) {
      setError('useCaseName', { message: 'Rule name is required.' });
    } else if (formValues.useCaseName.length > maxFiftyCharactersRule.value) {
      setError('useCaseName', { message: maxFiftyCharactersRule.message });
    } else {
      sendEventToGTM({
        event: 'lb_create_new_rule',
        action: 'click',
        title: formValues.useCaseName,
        type: ruleType,
      });

      if (ruleType === 'simpleRule') {
        requestPayload.conditions = {
          nodes: ruleInitialState,
          startNode: 'rule_01',
        };
        void handleCreateAPI(requestPayload);
      } else if (ruleType === 'decisionTable') {
        requestPayload.conditions = {
          nodes: decisionTableInitialNodes,
          startNode: 'rule_01',
        };
        requestPayload.decisionTable = decisionTableInitialData;
        requestPayload.policy = 'first';
        void handleCreateAPI(requestPayload);
      } else if (ruleType === 'ruleSet') {
        requestPayload.ruleSetPolicy = 'first';
        void handleCreateAPI(requestPayload);
      }
    }
  };

  const handleCreateAPI = async (payload: Record<string, any>) => {
    if (
      !_isNil(subscriptionPlan) &&
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      checkLimitExceeded('rule', subscriptionPlan)
    ) {
      toasts.warning('Rule creation limit exceded', 'limit-exceeded');

      return false;
    }
    try {
      const response = await createMap[ruleType]({
        variables: { ...payload },
      });
      let ruleURL = `/rules/ID?type=edit&stage=staging&ruleType=${ruleType}&closeSheet=true&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;
      customClosePopup();

      if (!_isNil(searchParams.get('step'))) {
        ruleURL = `${ruleURL}&step=${searchParams.get('step') as string}`;
      }

      navigate(ruleURL.replace('ID', response.data.createRule.id));
    } catch (error: any) {
      if (error instanceof Error) {
        toasts.error(error.message, 'error');
      }
    }
  };

  const handleSelectRuleType = (type: string) => {
    setRuleType(type);
  };

  const createEntityAndOpen = (id: string, url: string, target: string) => {
    generateEntityId({
      usecaseId: id,
      url,
      target,
    });
  };

  const items: RuleConfigType[] = [];

  function checkSubModuleExist(moduleName: string) {
    return (
      !_isNil(subModules) &&
      !_isUndefined(subModules[moduleName]) &&
      subModules[moduleName]
    );
  }

  if (checkSubModuleExist(ruleTypeConfig.decisionTable.value)) {
    items.push(ruleTypeConfig.decisionTable);
  }

  if (checkSubModuleExist(ruleTypeConfig.simpleRule.value)) {
    items.push(ruleTypeConfig.simpleRule);
  }

  if (checkSubModuleExist(ruleTypeConfig.ruleSet.value)) {
    items.push(ruleTypeConfig.ruleSet);
  }

  const updateUserState = () => {
    if (!isOnboardingCompleted(demoRuleCreated)) {
      updateWidgetState(demoRuleCreated)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const handleINeedAssistance = () => {
    sendEventToGTM({
      event: 'lb_pf_ina',
      action: 'click',
      from: `tab_${currentTab}`,
    });
  };

  const renderPopupTabs = () => {
    const tabs = ['Build your own', 'Start from template'];
    let updatedTabs = tabs;

    if (deploymentType === 'onpremise') {
      updatedTabs = tabs.filter((i) => i !== 'Start from template');
    }

    return updatedTabs.map((tab, index) => {
      return <Tab key={index}>{tab}</Tab>;
    });
  };

  return (
    <Modal hideCloseButton={true} size="doubleExtraLarge">
      <CustomClose>
        <Button
          onClick={() => openImportModal({})}
          trailingIcon={<TbDownload />}
          appearance="filled"
        >
          Import
        </Button>
        <IconButton onClick={customClosePopup}>
          <RxCross2 size={16} />
        </IconButton>
      </CustomClose>
      <ModalContent>
        <Stack gutter={'1.5rem'}>
          <Typography name="heading2">{title} </Typography>
          <TabsContainer>
            <Tabs onTabChange={toggleTabs} defaultOpen={currentTab}>
              <TabList>{renderPopupTabs()}</TabList>
              <TabPanels>
                <TabPanel>
                  <CreateRuleForm
                    items={items}
                    control={control}
                    ruleType={ruleType}
                    handleSelectRuleType={handleSelectRuleType}
                  />
                </TabPanel>
                <TabPanel>
                  <StartFromTemplate
                    selectDemoRule={selectDemoRule}
                    selectedDemoObj={demoObj}
                    demoRules={demoRules}
                    searchedText={searchedText}
                    setSearchedText={setSearchedText}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </TabsContainer>
        </Stack>
      </ModalContent>

      <ModalFooter justify="start">
        <RuleTemplateFooterContainer>
          {deploymentType !== 'onpremise' && (
            <Button appearance="neutral" onClick={handleINeedAssistance}>
              <img src={`${envMap.VITE_ASSETS_URL}home/assist_small.png`} />I
              need assistance
            </Button>
          )}

          <Inline align="center">
            <Threshold
              entity="rule"
              current={subscriptionPlan?.usage.rule}
              max={subscriptionPlan?.plan.ruleLimit}
            />
            <Button
              disabled={checkLimitExceeded('rule', subscriptionPlan)}
              onClick={handleFooterClose}
            >
              Next
            </Button>
          </Inline>
        </RuleTemplateFooterContainer>
      </ModalFooter>
    </Modal>
  );
};

const StartFromTemplate = ({
  demoRules,
  searchedText,
  setSearchedText,
  selectDemoRule,
  selectedDemoObj,
}: StartFromTemplateProps) => {
  return (
    <>
      <RuleSearchInputContainer>
        <SearchContainerStyled align="center" gutter={0}>
          <PadBox
            padding={{
              left: '.8rem',
            }}
          >
            <CiSearch />
          </PadBox>
          <TextInput
            placeholder="Search your use case"
            value={searchedText}
            showOutline={false}
            name="usecase_search"
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
          />
        </SearchContainerStyled>
        {searchedText !== '' && (
          <Typography>Search Result for &quot;{searchedText}&quot;</Typography>
        )}
      </RuleSearchInputContainer>
      <ActionBlockContainer gutter="1rem">
        {demoRules.map((demoObj: any, index: number) => (
          <PadBox
            key={index}
            onClick={() => {
              selectDemoRule(demoObj);
            }}
            as={DemoRuleBlock}
            padding="1.5rem"
            gutter="1.5rem"
            selected={selectedDemoObj?.id === demoObj.id}
          >
            <IconContainer>
              <img
                src={
                  !_isEmpty(demoObj.icon)
                    ? `${envMap.VITE_ASSETS_URL}website/icons/${
                        demoObj.icon as string
                      }`
                    : `${envMap.VITE_ASSETS_URL}website/icons/${
                        demoObj.id as string
                      }.png`
                }
                alt="icon"
                style={{ maxWidth: '10rem' }}
              />
            </IconContainer>

            <Stack gutter={'1rem'}>
              <Inline>
                <Typography fontWeight={700} name={'heading4'}>
                  {demoObj.name}
                </Typography>
                <RuleTypePill type={demoObj.type} name={'heading4'}>
                  {ruleNameMap[demoObj.type]}
                </RuleTypePill>
              </Inline>

              <Typography name="secondary2" fontWeight={400}>
                {demoObj.description}
              </Typography>
            </Stack>
          </PadBox>
        ))}
        {demoRules.length === 0 && searchedText !== '' && (
          <NoDemoRules>
            <BannerBox
              title="Usecase not found"
              subTitle="Get help with Solutions Expert"
              bannerImage="no_content.png"
              buttonProps={{
                buttonUrl: '',
                buttonText: '',
                buttonTarget: '',
              }}
            />
          </NoDemoRules>
        )}
      </ActionBlockContainer>
    </>
  );
};

const CreateRuleForm = ({
  items,
  control,
  ruleType,
  handleSelectRuleType,
}: CreateRuleFormProps) => {
  return (
    <>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Rule Name</Typography>
        <TextField
          size="medium"
          name="useCaseName"
          rules={{
            required: {
              value: true,
              message: 'Required Field',
            },
            maxLength: maxFiftyCharactersRule,
          }}
          showErrorIcon={false}
          control={control}
          placeholder="Enter name"
        />
      </Stack>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Rule Description (Optional)</Typography>
        <TextAreaField
          isDefaultOutlineVisible={true}
          isShowingEllipsis={true}
          control={control}
          name="ruleDescripiton"
          placeholder="Enter description"
          size="large"
        />
      </Stack>
      <Typography name="heading4">Select rule type</Typography>
      <Inline>
        {items.map((i, index) => {
          const ruleTypeObj = i;

          return (
            <PadBox
              onClick={() => handleSelectRuleType(ruleTypeObj.value)}
              key={index}
              padding="1.5rem"
              gutter="1.5rem"
              as={CreateRuleBlock}
              type={ruleTypeObj.value}
              selected={ruleType === ruleTypeObj.value}
            >
              <Stack>
                <RuleTypePill type={ruleTypeObj.value} name={'heading4'}>
                  {ruleTypeObj.label}
                </RuleTypePill>
                <Typography name="secondary2" fontWeight={400}>
                  {ruleTypeObj.description}
                </Typography>
              </Stack>
            </PadBox>
          );
        })}
      </Inline>
    </>
  );
};
