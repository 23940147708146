import { OperatorsProps } from 'ui';

export const operators: OperatorsProps = {
  generic: {
    name: 'Common / Generic',
    id: 'operator_1',
    ops: {
      any: {
        name: 'Any',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Any',
      },
      ex: {
        name: 'Exists',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Exists',
      },
      nex: {
        name: 'Does Not Exists',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Does Not Exists',
      },
      isNull: {
        name: 'Is Null',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Is Null',
      },
      notNull: {
        name: 'Not Null',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Not Null',
      },
    },
  },
  numeric: {
    name: 'Numeric Ops',
    id: 'operator_2',
    ops: {
      bet: {
        name: 'Between',
        leftOperands: 1,
        rightOperands: 2,
        symbol: 'Between',
      },
      nbet: {
        name: 'Not Between',
        leftOperands: 1,
        rightOperands: 2,
        symbol: 'Not Between',
      },
      eq: {
        name: 'Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '=',
      },
      neq: {
        name: 'Not Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '!=',
      },
      gt: {
        name: 'Greater than',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '>',
      },
      lt: {
        name: 'Less than',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '<',
      },
      gte: {
        name: 'Greater than or equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '>=',
      },
      lte: {
        name: 'Less than or equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '<=',
      },
      even: {
        name: 'Is Even',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Is Even',
      },
      odd: {
        name: 'Is Odd',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Is Odd',
      },
      in: {
        name: 'In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'In',
      },
      nin: {
        name: 'Not In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not In',
      },
    },
  },
  string: {
    name: 'String Ops',
    id: 'operator_3',
    ops: {
      eq: {
        name: 'Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '=',
      },
      neq: {
        name: 'Not Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '!=',
      },
      contains: {
        name: 'Contains',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Contains',
      },
      notContains: {
        name: 'Does Not Contains',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Does Not Contains',
      },
      containsIn: {
        name: 'Contains IN List',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Contains IN List',
      },
      notContainsIn: {
        name: 'Not Contains IN List',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not Contains IN List',
      },
      sw: {
        name: 'Starts With',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Starts With',
      },
      nsw: {
        name: 'Does Not Starts With',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Does Not Starts With',
      },
      ew: {
        name: 'Ends With',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Ends With',
      },
      new: {
        name: 'Does Not Ends With',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Does Not Ends With',
      },
      in: {
        name: 'In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'In',
      },
      nin: {
        name: 'Not In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not In',
      },
      empty: {
        name: 'Empty',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Empty',
      },
      nempty: {
        name: 'Not Empty',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Not Empty',
      },
    },
  },
  date: {
    name: 'Date Ops',
    id: 'operator_4',
    ops: {
      bet: {
        name: 'Between',
        leftOperands: 1,
        rightOperands: 2,
        symbol: 'Between',
      },
      nbet: {
        name: 'Not Between',
        leftOperands: 1,
        rightOperands: 2,
        symbol: 'Not Between',
      },
      eq: {
        name: 'Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '=',
      },
      neq: {
        name: 'Not Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '!=',
      },
      gt: {
        name: 'Greater than',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '>',
      },
      lt: {
        name: 'Less than',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '<',
      },
      gte: {
        name: 'Greater than or equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '>=',
      },
      lte: {
        name: 'Less than or equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '>=',
      },
      in: {
        name: 'In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'In',
      },
      nin: {
        name: 'Not In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not In',
      },
    },
  },
  boolean: {
    name: 'Boolean',
    id: 'operator_5',
    ops: {
      t: {
        name: 'Is True',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Is True',
      },
      f: {
        name: 'Is False',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Is False',
      },
      in: {
        name: 'In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'In',
      },
      nin: {
        name: 'Not In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not In',
      },
    },
  },
  dateTime: {
    name: 'DateTime Ops',
    id: 'operator_6',
    ops: {
      bet: {
        name: 'Between',
        leftOperands: 1,
        rightOperands: 2,
        symbol: 'Between',
      },
      nbet: {
        name: 'Not Between',
        leftOperands: 1,
        rightOperands: 2,
        symbol: 'Not Between',
      },
      eq: {
        name: 'Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '=',
      },
      neq: {
        name: 'Not Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '!=',
      },
      gt: {
        name: 'Greater than',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '>',
      },
      lt: {
        name: 'Less than',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '<',
      },
      gte: {
        name: 'Greater than or equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '>=',
      },
      lte: {
        name: 'Less than or equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: '<=',
      },
      in: {
        name: 'In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'In',
      },
      nin: {
        name: 'Not In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not In',
      },
    },
  },
  list: {
    name: 'List',
    id: 'operator_7',
    ops: {
      empty: {
        name: 'Is Empty',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Empty',
      },
      nempty: {
        name: 'Not Empty',
        leftOperands: 1,
        rightOperands: 0,
        symbol: 'Not Empty',
      },
      eq: {
        name: 'Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Equals',
      },
      neq: {
        name: 'Not Equals',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not Equals',
      },
      in: {
        name: 'In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'In',
      },
      nin: {
        name: 'Not In',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not In',
      },
      contains: {
        name: 'Contains',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Contains',
      },
      notContains: {
        name: 'Not Contains',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not Contains',
      },
      matchAll: {
        name: 'Match All',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Match All',
      },
      notMatchAll: {
        name: 'Not Match All',
        leftOperands: 1,
        rightOperands: 1,
        symbol: 'Not Match All',
      },
    },
  },
};
