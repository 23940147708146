import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import type { UseFormSetValue } from 'react-hook-form';

import type {
  RulePolicyDropdownModel,
  RuleSetNodesModel,
} from '../components/RuleSet/models';
import type { StagingConfigModel } from '../components/SimpleRule/models';
import { decisionTablePolicies } from '../utils/constant';

type UseInitializeSimpleRuleSetProps = {
  ruleList: RuleSetNodesModel[];
  setValue: UseFormSetValue<any>;
  ruleSetName: string;
  ruleSetDescription: string;
  stagingConfig?: StagingConfigModel;
  productionConfig?: StagingConfigModel;
  ruleSetPolicy: RulePolicyDropdownModel | null;
  currentRuleData?: any;
};

export function useInitializeRuleSet({
  ruleList,
  setValue,
  stagingConfig,
  productionConfig,
  ruleSetPolicy,
  ruleSetName,
  ruleSetDescription,
  currentRuleData,
}: UseInitializeSimpleRuleSetProps) {
  useEffect(() => {
    if (!_isNil(stagingConfig)) {
      setValue('stagingConfig', stagingConfig);
    }
  }, [stagingConfig]);

  useEffect(() => {
    if (!_isNil(productionConfig)) {
      setValue('productionConfig', productionConfig);
    }
  }, [productionConfig]);

  useEffect(() => {
    if (!_isNil(ruleList)) {
      setValue('ruleList', ruleList);
    }
  }, [ruleList]);

  useEffect(() => {
    setValue('rulePolicy', ruleSetPolicy ?? decisionTablePolicies[0]);
  }, [ruleSetPolicy]);

  useEffect(() => {
    setValue('ruleName', ruleSetName);
  }, [ruleSetName]);

  useEffect(() => {
    setValue('ruleDescription', ruleSetDescription);
  }, [ruleSetDescription]);

  useEffect(() => {
    if (!_isNil(currentRuleData)) {
      Object.keys(currentRuleData).forEach((key) => {
        setValue(key, currentRuleData[key]);
      });
    }
  }, [JSON.stringify(currentRuleData)]);
}
