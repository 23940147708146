import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import {
  Dataset,
  OnClickRuleArgs,
  PopoverMethods,
  PopoverPanel,
  RuleField,
  Typography,
} from 'ui';

import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../..';
import { customAttributesAtom } from '../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { isFieldReadOnly } from '../../../../utils/common';
import type { sendEventToGTMType } from '../../types';
import {
  getDataSetByType,
  getDefaultValueByDataType,
  removeCustomFunction,
  updateDataSetOnChange,
} from '../../utils/common';
import { dataSetParamsAtom } from '../CreateRuleSheet/CreateRuleSheet';
import { RhsInputContainer } from './ResultRhs.styled';
import { RhsLauncher } from './RhsLauncher';

export type ResultConditionType = 'workflowDT' | '';

type RhsOptions = {
  updateDataType?: boolean;
};

type RhsParamsPopoverProps = Omit<UseControllerProps<any>, 'name'> & {
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  dataType: string;
  setValue?: UseFormSetValue<any>;
  nodeName: string;
  keyName?: string;
  isSmall?: boolean;
  updatedDataSet?: Record<string, Dataset>;
  isFull?: boolean;
  type?: ResultConditionType;
  disabled?: boolean;
  allowList?: boolean;
  typesToAllow?: string[];
  onlyJson?: boolean;
  size?: string;
  onSelect?: () => void;
  showTooltip?: boolean;
  showExpandIconIfList?: boolean;
  sourceKey?: string;
  attributeKey?: string;
  nodeTypeName?: string;
  rightIcon?: any;
  otherKeys?: Record<string, any>;
  forceExpandListIcon?: boolean;
  readOnly?: boolean;
  header?: ReactNode;
  idsToExpand?: string[];
  idsToNotExpand?: string[];
  options?: RhsOptions;
};

export const ResultRhs = ({
  control,
  handleSendEventToGTM,
  dataType,
  setValue,
  nodeName,
  keyName,
  isSmall,
  isFull,
  updatedDataSet,
  type = '',
  disabled = false,
  allowList = false,
  typesToAllow,
  onlyJson = false,
  size = '34rem',
  showTooltip = false,
  onSelect,
  showExpandIconIfList = true,
  sourceKey = 'source',
  attributeKey = 'attribute',
  nodeTypeName,
  rightIcon,
  otherKeys,
  forceExpandListIcon = false,
  readOnly,
  header,
  idsToExpand = [],
  idsToNotExpand = [],
  options = {
    updateDataType: true,
  },
}: RhsParamsPopoverProps) => {
  const [dataset] = useAtom(dataSetParamsAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [customAttributes] = useAtom(customAttributesAtom);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [panelVisible, setPanelVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredDataSet, setFilteredDataSet] = useState(dataset);

  useEffect(() => {
    if (
      _isNil(updatedDataSet) &&
      !_isUndefined(dataType) &&
      !_isEmpty(dataType)
    ) {
      const dataSetWithSameDataType = getDataSetByType(
        dataset,
        dataType,
        dataSetSelected
      );

      setFilteredDataSet(
        updateDataSetOnChange(
          customAttributes,
          dataSetWithSameDataType,
          dataSetSelected,
          true,
          true
        )
      );
    }
  }, [dataset, dataSetSelected]);

  const source: string | null = useWatch({
    control,
    name: `${nodeName}.${sourceKey}`,
  });
  const attribute: string | null = useWatch({
    control,
    name: `${nodeName}.${attributeKey}`,
  });

  const value = useWatch({ control, name: `${nodeName}.value` });

  const title =
    !_isNil(source) &&
    !_isNil(attribute) &&
    !_isEmpty(source) &&
    !_isEmpty(attribute)
      ? `${source}.${attribute}`
      : value;

  const ref = useRef<PopoverMethods>(null);

  const onItemClick = ({
    value,
    key,
    dataType: dType,
    executedValue,
  }: OnClickRuleArgs) => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'edit',
        element: 'rhs_value',
        actionName: dType,
      });
    }

    if (typeof setValue === 'function') {
      setValue(`${nodeName}.${sourceKey}`, key);
      setValue(`${nodeName}.${attributeKey}`, value);
      setValue(`${nodeName}.value`, null);

      setValue(
        `${nodeName}.dataType`,
        // eslint-disable-next-line
        !!options?.updateDataType ? dType : dataType
      );

      setValue(
        `${nodeName}.executedValue`,
        executedValue ?? getDefaultValueByDataType(dType)
      );

      if (!_isNil(keyName)) {
        setValue(`${nodeName}.keyName`, keyName);
      }

      if (!_isNil(otherKeys)) {
        Object.keys(otherKeys).forEach((k) => {
          setValue(k, otherKeys[k]);
        });
      }

      if (!_isNil(nodeTypeName)) {
        setValue(`${nodeTypeName}`, 'params');
      }

      if (typeof onSelect === 'function') {
        onSelect();
      }
    }
    ref.current?.hide();
  };

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <RhsLauncher
          panelVisible={panelVisible}
          text={title ?? ''}
          nodeName={nodeName}
          dataType={dataType ?? ''}
          handleSendEventToGTM={handleSendEventToGTM}
          setValue={setValue}
          onlyJson={onlyJson}
          showExpandIconIfList={showExpandIconIfList}
          forceExpandListIcon={forceExpandListIcon}
          nodeType={
            !_isNil(source) &&
            !_isNil(attribute) &&
            !_isEmpty(source) &&
            !_isEmpty(attribute)
              ? 'token'
              : 'constant'
          }
          control={control}
          isSmall={isSmall}
          isFull={isFull}
          type={type}
          disabled={disabled || isRuleReadOnly}
          showTooltip={showTooltip}
          dataset={_isNil(updatedDataSet) ? dataset : updatedDataSet}
          nodeTypeName={nodeTypeName}
          rightIcon={rightIcon}
          readOnly={readOnly ?? isFieldReadOnly(dataType ?? '')}
        />
      }
      ref={ref}
      padding="8px"
      disabled={isRuleReadOnly || disabled}
    >
      <RhsInputContainer
        onMouseEnter={() => setPanelVisible(true)}
        onMouseLeave={() => setPanelVisible(false)}
        $size={size}
      >
        <RuleField
          name={nodeName}
          control={control}
          dataset={
            _isNil(updatedDataSet)
              ? removeCustomFunction(dataset)
              : removeCustomFunction(updatedDataSet)
          }
          version="v2"
          disabled={isRuleReadOnly || disabled}
          onClick={onItemClick}
          allowList={allowList}
          typesToAllow={typesToAllow}
          onlyJson={onlyJson}
          showTooltip={showTooltip}
          idsToExpand={idsToExpand}
          idsToNotExpand={idsToNotExpand}
          header={header}
          footer={
            (_isNil(updatedDataSet) || _isEmpty(updatedDataSet)) &&
            type === 'workflowDT' ? (
              <Typography name="paragraphSmall">
                Please create workflow attribute in the first step
              </Typography>
            ) : undefined
          }
        />
      </RhsInputContainer>
    </PopoverPanel>
  );
};
