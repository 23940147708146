import { gql, useLazyQuery } from '@apollo/client';

import { RuleResponseModel } from '../../components/SimpleRule/models';

const getRuleById = gql`
  query getRule($id: String, $live: Boolean, $filters: QueryOperators) {
    getRule(id: $id, live: $live, filters: $filters) {
      data {
        id
        name
        description
        version
        status
        isLive
        workspaceId
        type
        staticUrl
        checksum
        firstCustomInput
        warnings
        datasetDetail {
          connector {
            id
          }
        }
        production {
          startAt
          endAt
          isEnabled
          auditIO
          api {
            isEnabled
            isPrivate
            authId
          }
          schedule {
            startAt
            endAt
            inputParam
            unit
            spec
            timezone
            isEnabled
          }
          salience
        }
        staging {
          startAt
          endAt
          isEnabled
          auditIO
          api {
            isEnabled
            isPrivate
            authId
          }
          salience
        }
        conditions {
          startNode
          nodes
        }
        action {
          then {
            outputData
            firstOutputData
            firstActionNode
            actionNode
          }
          else {
            outputData
            firstOutputData
            firstActionNode
            actionNode
          }
        }
        customInput
        dataSetId
        createdAt
        publishedAt
        approvalInfo {
          title
          description
          approvers
        }
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
    }
  }
`;

export function useGetRuleById() {
  return useLazyQuery<RuleResponseModel>(getRuleById, {
    fetchPolicy: 'no-cache',
  });
}
