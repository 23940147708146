import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import { siteConstantsAtom, usedConnectorMappingAtom } from '../../../atom';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import type { UsedConnectorMappingInEntityType } from '../../../types';
import {
  getTooltipText,
  handleSetCheckSumByEntityName,
  unionStringArrays,
} from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import {
  firstCustomAttributeAtom,
  isRulePublishableAtom,
  isRulePublishedAtom,
} from '../components/CreateRuleSheet/CreateRuleSheet';
import type {
  ProductionConfigModel,
  ResultAddDataModel,
  RuleResponse,
  SimpleRuleNodesModel,
  StagingConfigModel,
  TriggerModelKeys,
} from '../components/SimpleRule/models';
import {
  approvalInfoRuleAtom,
  datasetDetailsInRuleAtom,
  hasConnectorErrorInCustomAttrSheetAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
} from '../components/atom/atom';
import { ruleInitialState } from '../fixtures/ruleInitialState';
import {
  dataSetFieldsByIdAtom,
  isRuleReadOnlyAtom,
  ruleWarningsAtom,
} from '../index';
import type { AttributeModel } from '../models';
import type { ResultAction } from '../types';
import {
  formatTriggersDataFromResponse,
  getDataSetUsedInRuleById,
  transformActionNode,
  transformPayloadCustomInputToTableData,
  transformPayloadToResultDataType,
} from '../utils/common';
import { useDiscardSimpleRule } from './graphql/useDiscardSimpleRule';
import { useGetDataSetById } from './graphql/useGetDataSetById';
import { useGetRuleById } from './graphql/useGetRuleById';
import { useCIWarning } from './useCIWarning';

type UsePrefillSimpleRuleProp = {
  isClone: boolean;
  isLive: boolean;
  ruleId?: string;
  ruleIdExist?: boolean;
  isLiveNodeSheetClosed?: boolean;
  commitId?: string;
};

function getAuthType(
  editRuleData: RuleResponse,
  environment: TriggerModelKeys
) {
  return editRuleData[environment].api.isPrivate
    ? { label: 'Private', value: 'private' }
    : { label: 'None', value: 'none' };
}

export function useEditSimpleRule({
  ruleId,
  isClone,
  isLive,
  ruleIdExist = false,
  isLiveNodeSheetClosed = false,
  commitId,
}: UsePrefillSimpleRuleProp) {
  const [searchParams] = useSearchParams();

  const [getRuleById, { data: editRuleData, loading: ruleLoading }] =
    useGetRuleById();

  const [discardRuleById, { data: discardRule, error: discardError }] =
    useDiscardSimpleRule();
  const [getDataSetById] = useGetDataSetById();
  const { handleSetWarning } = useCIWarning();

  const [nodesStartId, setNodesStartId] = useState('');
  const [stagingConfig, setStagingConfig] = useState<StagingConfigModel>();
  const [productionConfig, setProductionConfig] =
    useState<ProductionConfigModel>();

  const [isMounted, setIsMounted] = useState(false);

  const [selectedDataSets, setSelectedDataSet] = useState<string[]>([]);
  const [thenActionResponse, setThenActionResponse] = useState<ResultAction[]>(
    []
  );
  const [elseActionResponse, setElseActionResponse] = useState<ResultAction[]>(
    []
  );

  const [createdAt, setCreatedAt] = useState<string>();
  const [publishedAt, setPublishedAt] = useState<string>();
  const [status, setStatus] = useState('');

  const [nodeList, setNodeList] = useState<
    Record<string, SimpleRuleNodesModel>
  >(structuredClone(ruleInitialState));
  const [ruleName, setRuleName] = useState('Untitled');
  const [ruleDescription, setRuleDescription] = useState('');
  const [ruleVersion, setRuleVersion] = useState('draft');
  const [loadingData, setLoadingData] = useState(true);
  const [dataClone, setDataClone] = useState(false);

  const [thenDataParams, setThenDataParams] = useState<ResultAddDataModel[]>(
    []
  );
  const [elseDataParams, setElseDataParams] = useState<ResultAddDataModel[]>(
    []
  );
  const [customDataParams, setCustomDataParams] = useState<
    Record<string, AttributeModel>
  >({});

  const [sheetMode, setSheetMode] = useState('');

  const [, setIsRulePublishable] = useAtom(isRulePublishableAtom);
  const [, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setIsRulePublished] = useAtom(isRulePublishedAtom);
  const [, setDataSetFieldById] = useAtom(dataSetFieldsByIdAtom);
  const [, setFirstCustomAttribute] = useAtom(firstCustomAttributeAtom);
  const [, setRuleWarnings] = useAtom(ruleWarningsAtom);

  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);

  const [datasetDetailsInRule, setDatasetDetailsInRule] = useAtom(
    datasetDetailsInRuleAtom
  );
  const [usedConnectorMapping, setUsedConnectorMappingInRules] = useAtom(
    usedConnectorMappingAtom
  );
  const [, setHasConnectorError] = useAtom(
    hasConnectorErrorInCustomAttrSheetAtom
  );

  const [siteConstant] = useAtom(siteConstantsAtom);

  const { isHide: editDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
  });

  const isEditSheetDisable = sheetMode === 'view' || editDisable;

  useEffect(() => {
    setDataClone(isClone);
  }, [isClone]);

  const handleGetRuleById = async () => {
    try {
      const filters: Record<string, any> = {};

      if (!_isNil(commitId) && !_isEmpty(commitId)) {
        filters.eq = { commitId };
      }

      const response = await getRuleById({
        variables: { id: ruleId, live: isLive, filters },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  const handleGetRuleAfterStateTransition = async () => {
    try {
      const response = await getRuleById({
        variables: { id: ruleId },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  useEffect(() => {
    setSheetMode(searchParams.get('type') ?? '');
  }, []);

  useEffect(() => {
    if (_isNil(ruleId)) {
      setNodesStartId('rule_01');
    } else if (ruleIdExist) {
      void handleGetRuleById();
    }
  }, [ruleId, ruleIdExist]);

  const initialize = async (ruleData?: RuleResponse) => {
    if (!_isNil(ruleData)) {
      setLoadingData(true);
      setNodeList(ruleData.conditions.nodes);
      setNodesStartId(ruleData.conditions.startNode);
      setRuleName(`${ruleData.name}${isClone ? '_copy' : ''}`);
      setRuleDescription(ruleData.description);
      setCreatedAt(ruleData.createdAt);
      setPublishedAt(ruleData.publishedAt);
      setStatus(ruleData.status);
      setApprovalInfoRule(ruleData.approvalInfo);
      setVersionInfoRule(ruleData.versionInfo);
      setDatasetDetailsInRule(ruleData.datasetDetail);

      setIsRuleLive(ruleData.isLive ?? false);

      if (!_isNil(ruleData.version) && !dataClone) {
        setRuleVersion(ruleData.version);
      }

      const usedConnectorMapping: UsedConnectorMappingInEntityType = {};

      if (!_isNil(ruleData.datasetDetail)) {
        usedConnectorMapping[ruleData.datasetDetail.connector.id] = true;
      }

      handleSetCheckSumByEntityName('rule', ruleData.checksum);

      if (!_isNil(ruleData.action)) {
        if (!_isNil(ruleData.action.then)) {
          setThenDataParams(
            transformPayloadToResultDataType(
              ruleData.action.then.outputData,
              ruleData.action.then.firstOutputData
            )
          );
        }

        const thenActionNode = transformActionNode(
          ruleData.action.then.actionNode,
          ruleData.action.then.firstActionNode
        );

        setThenActionResponse(thenActionNode);

        thenActionNode.forEach((currActionNode) => {
          usedConnectorMapping[currActionNode.connectorId] = true;
        });

        if (!_isNil(ruleData.action.else)) {
          setElseDataParams(
            transformPayloadToResultDataType(
              ruleData.action.else.outputData,
              ruleData.action.else.firstOutputData
            )
          );
        }

        if (!_isNil(ruleData.action.else.actionNode)) {
          const elseActionNode = transformActionNode(
            ruleData.action.else.actionNode,
            ruleData.action.else.firstActionNode
          );

          setElseActionResponse(elseActionNode);

          elseActionNode.forEach((currActionNode) => {
            usedConnectorMapping[currActionNode.connectorId] = true;
          });
        }
      }

      if (!_isNil(ruleData.dataSetId) && !_isEmpty(ruleData.dataSetId)) {
        const dataSetKey = ruleData.dataSetId;

        setSelectedDataSet([dataSetKey]);

        if (!_isNil(dataSetKey)) {
          const result = await getDataSetUsedInRuleById(
            dataSetKey,
            getDataSetById
          );

          if (!_isNil(result)) {
            setDataSetFieldById(result);
          }
        }
      }

      setFirstCustomAttribute(ruleData.firstCustomInput);

      if (!_isNil(ruleData.warnings)) {
        setRuleWarnings((prev) =>
          unionStringArrays(prev, ruleData.warnings ?? [])
        );
      }

      if (!_isNil(ruleData.customInput)) {
        const customInput = transformPayloadCustomInputToTableData(
          ruleData.customInput
        );

        setCustomDataParams(customInput);

        handleSetWarning(customInput, ruleData.dataSetId);

        Object.keys(customInput).forEach((key) => {
          const currCustomInput = customInput[key];

          if (
            currCustomInput.dataType?.value === 'restAPI' &&
            !_isNil(currCustomInput.attribute)
          ) {
            usedConnectorMapping[currCustomInput.attribute] = true;
          }
        });
      }

      setUsedConnectorMappingInRules(usedConnectorMapping);

      if (!_isNil(ruleData.production)) {
        const authType = getAuthType(ruleData, 'production');

        setProductionConfig(
          formatTriggersDataFromResponse(
            ruleData.production,
            ruleData.staticUrl,
            isClone,
            authType
          )
        );
      }

      if (!_isNil(ruleData.staging)) {
        const authType = getAuthType(ruleData, 'staging');

        setStagingConfig(
          formatTriggersDataFromResponse(
            ruleData.staging,
            ruleData.staticUrl,
            isClone,
            authType
          )
        );
      }

      if (dataClone) {
        setIsRulePublishable(false);
        setIsRulePublished(false);
        setDataClone(false);
      } else {
        setIsRulePublishable(false);
        setIsRulePublished(false);
      }
    }

    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
  };

  useEffect(() => {
    if (isLiveNodeSheetClosed) {
      void initialize(editRuleData?.getRule.data[0]);
    }
  }, [editRuleData, isLive, isLiveNodeSheetClosed]);

  useEffect(() => {
    if (!_isNil(ruleId) && !_isNil(discardRule)) {
      void initialize(discardRule.discardRule);
    }
  }, [discardRule]);

  useEffect(() => {
    // check if there is any used connector not connected (in restAPI)
    if (
      !_isNil(usedConnectorMapping) &&
      !_isEmpty(usedConnectorMapping) &&
      !_isNil(customDataParams)
    ) {
      let hasError = false;

      Object.keys(customDataParams).forEach((key) => {
        const currAttribute = customDataParams[key];

        if (currAttribute.dataType?.value === 'restAPI') {
          const connectorId = currAttribute.attribute ?? '';

          if (!usedConnectorMapping[connectorId]) {
            hasError = true;
          }
        }
      });

      setHasConnectorError((prev) => ({
        ...prev,
        restAPI: hasError,
      }));
    }
  }, [JSON.stringify(customDataParams), JSON.stringify(usedConnectorMapping)]);

  useEffect(() => {
    // check if the connector used in dataset has error or not
    if (!_isNil(datasetDetailsInRule) && !_isNil(usedConnectorMapping)) {
      const id = datasetDetailsInRule.connector.id;
      const hasError: boolean =
        !_isNil(usedConnectorMapping) && !_isEmpty(usedConnectorMapping)
          ? !usedConnectorMapping[id]
          : true;

      setHasConnectorError((prev) => ({
        ...prev,
        dataset: hasError,
      }));
    }
  }, [datasetDetailsInRule, JSON.stringify(usedConnectorMapping)]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      if (isLive && isMounted && !isEditSheetDisable) {
        setIsRuleReadOnly(false);
      }
    };
  }, [isMounted, isEditSheetDisable]);

  useEffect(() => {
    if (!_isNil(discardError)) {
      toasts.error(discardError.message, 'error');
    }
  }, [discardError]);

  return {
    nodesStartId,
    nodeList,
    thenDataParams,
    elseDataParams,
    customDataParams,
    stagingConfig,
    productionConfig,
    discardRuleById,
    ruleName,
    ruleDescription,
    ruleVersion,
    ruleLoading,
    selectedDataSets,
    thenActionResponse,
    elseActionResponse,
    loadingData,
    createdAt,
    publishedAt,
    status,
    handleGetRuleAfterStateTransition,
  };
}
