import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import type { Control, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  CheckboxInput,
  DropdownInput,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { EntityUnderReview } from '../../../components/EntityUnderReview/EntityUnderReview';
import { HowToLink } from '../../../components/HowToLink/HowToLink';
import { IntegrationField } from '../../../components/IntegrationField/IntegrationField';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { StackAsItem } from '../../../components/layouts/Stack.styled';
import type { TimerId } from '../../../types';
import { getTooltipText } from '../../../utils/common';
import type { RuleEnvironment } from '../../../utils/constant';
import { ENTITY_ID, Environment } from '../../../utils/constant';
import { REMOVE_JAVASCRIPT_COMMENTS_REGEX } from '../../../utils/regex';
import type { CustomAxiosError } from '../../../utils/response/types';
import type { ConnectorAndPluginModel } from '../../Integrations/types';
import { queryOptions } from '../../Workflow/components/Sheets/ConnectorActionSheet/constant';
import type { UseGetDataSetSchemaReturn } from '../hooks/useGetDataSetSchema';
import type {
  TestDataSetFunction,
  TestDataSetOutputValues,
} from '../hooks/useTestDataSet';
import type {
  CreateDataSetFormValues,
  DatabaseName,
  UpdateDataSetStatusFunction,
} from '../types';
import {
  getEditorDetailsByPlugin,
  isMongoQueryValid,
  nodeSqlParser,
} from '../utils';
import {
  DatasetFormStyled,
  FileExplorerContainer,
  FileExplorerStyledInline,
  QueryEditorContainer,
  SchemaContainer,
  SqlEditorAndSchemaContainer,
  StyledInlineContainer,
  SyntaxErrorContainer,
} from './DataSetForm.styled';
import { DataSetOutputMemoized } from './DataSetOutput';
import { DataSetQueryEditor } from './DataSetQueryEditor';
import { DataSetSchemaMemoized } from './DataSetSchema';
import { FileExplorer } from './FileExplorer';

export const nodeSqlParserSupportedDatabases: DatabaseName[] = [
  'mysql',
  'pgsql',
  'snowflake',
  'oracle',
];

type DataSourceProps = {
  control: Control<CreateDataSetFormValues>;
  currentEnvironment: RuleEnvironment;
  dataSetId: string;
  isDataSetLoading?: boolean;
  isTestInProgress: boolean;
  isLive?: boolean;
  testDataSet: TestDataSetFunction;
  setValue: UseFormSetValue<any>;
  connector?: ConnectorAndPluginModel;
  testData?: TestDataSetOutputValues;
  testError?: AxiosError<CustomAxiosError, any>;
  updateDataSetStatus: UpdateDataSetStatusFunction;
  handleEditButton: () => void;
  from?: string;
  connectorList?: any[];
  refreshConnectors?: () => void;
  editPlugin?: () => void;
} & UseGetDataSetSchemaReturn;

export function DataSetForm({
  connector,
  control,
  currentEnvironment,
  dataSetId,
  getDataSetSchema,
  isDataSetLoading,
  isTestInProgress,
  schemas,
  testData,
  testError,
  testDataSet,
  setValue,
  from = '',
  handleEditButton,
  connectorList = [],
  refreshConnectors = () => {},
  editPlugin = () => {},
}: DataSourceProps) {
  const [searchParams] = useSearchParams();
  const [isValidQuery, setIsValidQuery] = useState(true);

  const [siteConstants] = useAtom(siteConstantsAtom);
  const outputRef = useRef<HTMLDivElement>(null);

  const query = useWatch({ control, name: 'query' });
  const params = useWatch({ control, name: 'params' });
  const isEditable = useWatch({ control, name: 'editable' });
  const status = useWatch({ control, name: 'status' });
  const isLive = useWatch({ control, name: 'isLive' });

  const hasHeader = params?.hasHeader ?? false;

  const editorDetails = getEditorDetailsByPlugin(connector?.plugin);
  const [isQueryValidNected, setIsQueryValidNected] = useState(true);

  const editorNotRequiredList = ['gsheet'];
  const hideEditor = editorNotRequiredList.includes(
    editorDetails?.databaseName ?? ''
  );

  const { isHide: testDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.test],
    entityList: [ENTITY_ID.datasets],
    entityStatus: status,
  });

  const { isHide: hideEditBtn } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.workflow],
    statusShouldBe: ['published'],
    entityStatus: status,
  });
  const checkValidSyntax = async () => {
    if (!_isNil(editorDetails)) {
      if (editorDetails.databaseName === 'mongodb') {
        if (
          isMongoQueryValid(
            query.replaceAll(REMOVE_JAVASCRIPT_COMMENTS_REGEX, '')
          )
        ) {
          setIsValidQuery(true);
        } else {
          setIsValidQuery(false);
        }
      }

      if (
        nodeSqlParserSupportedDatabases.includes(editorDetails.databaseName)
      ) {
        try {
          const module = await nodeSqlParser(editorDetails.databaseName);
          const { Parser } = module;
          const parser = new Parser();

          try {
            const ast = parser.astify(query, {
              database: editorDetails.databaseLabel,
            });

            if (Array.isArray(ast)) {
              setIsValidQuery(ast[0].type === 'select');
            } else {
              setIsValidQuery(ast.type === 'select');
            }
          } catch (error) {
            setIsValidQuery(false);
          }
        } catch (error: unknown) {}
      }
    }
  };

  useEffect(() => {
    let submitTimeout: TimerId;

    if (!_isNil(query) && !_isEmpty(query)) {
      submitTimeout = setTimeout(() => {
        void checkValidSyntax();
      }, 1000);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [query]);

  const shouldDisableExecuteNodeButton =
    isDataSetLoading ??
    _isEmpty(dataSetId) ??
    isTestInProgress ??
    _isEmpty(query.trim());

  const showEditBtn = (isLive ?? false) && !hideEditBtn;

  const isNotPreview = searchParams.get('type') !== 'view';

  const getTooltipsForDatasets = () => {
    switch (connector?.plugin.name) {
      case 'postgres':
        return (
          <span>
            {getTooltipText(siteConstants, 'datasets', 'addQueryPostgres')}{' '}
            <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'datasets',
                'addQueryPostgresHowTo',
                'howToLinks'
              )}
            />
          </span>
        );

      case 'mongodb':
        return (
          <span>
            {getTooltipText(siteConstants, 'datasets', 'addQueryMongo')}{' '}
            <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'datasets',
                'addQueryMongoHowTo',
                'howToLinks'
              )}
            />
          </span>
        );

      case 'mysql':
        return (
          <span>
            {getTooltipText(siteConstants, 'datasets', 'addQueryMySql')}{' '}
            <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'datasets',
                'addQueryMySqlHowTo',
                'howToLinks'
              )}
            />
          </span>
        );

      case 'sqlserver':
        return (
          <span>
            {getTooltipText(siteConstants, 'datasets', 'addQueryMsSQL')}{' '}
            <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'datasets',
                'addQuerySqlserverHowTo',
                'howToLinks'
              )}
            />
          </span>
        );
      case 'gsheet':
        return (
          <span>
            {getTooltipText(siteConstants, 'datasets', 'addGSheet')}{' '}
            {/* Commenting HowToLink for now as the link is not ready.  */}
            {/* <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'datasets',
                'addGSheetHowTo',
                'howToLinks'
              )}
            /> */}
          </span>
        );
      case 'oracle':
        return (
          <span>
            {getTooltipText(siteConstants, 'datasets', 'addQueryOracleDB')}
          </span>
        );
      case 'snowflake':
        return (
          <span>
            {getTooltipText(siteConstants, 'datasets', 'addQuerySnowflake')}
          </span>
        );
    }

    return getTooltipText(siteConstants, 'datasets', 'addQueryMongo');
  };

  const handleRowHeadersCheckbox = (e: any) => {
    setValue('params.hasHeader', e.target.checked);
  };

  return (
    <DatasetFormStyled gutter="1.7rem" grow={1}>
      <StackAsItem gutter=".8rem" grow={1}>
        <PadBox
          as={Stack}
          gutter={'1rem'}
          padding={'2rem'}
          className="dataset-editor-content"
        >
          <IntegrationField
            editPlugin={editPlugin}
            refreshPlugins={refreshConnectors}
            control={control}
            name="integration"
            connectorList={connectorList}
            disabled
            showRefresh={true}
          />

          <div>
            <Inline gutter={8}>
              <Typography fontWeight={700}>Action</Typography>
            </Inline>
            <DropdownInput
              options={queryOptions}
              value={queryOptions.find((query) => query.value === 0)}
              disabled
            />
          </div>

          <Inline gutter={8} align="center">
            <Typography fontWeight={700}>
              {hideEditor ? 'Select Worksheet' : 'Add Query'}
            </Typography>
            <TooltipReact id="js-condition-type">
              <Typography>{getTooltipsForDatasets()}</Typography>
            </TooltipReact>
          </Inline>

          {hideEditor ? (
            <>
              <PadBox as={FileExplorerContainer} padding={'0rem'}>
                <FileExplorerStyledInline gutter={0}>
                  <FileExplorer
                    control={control}
                    currentEnvironment={currentEnvironment}
                    connector={connector}
                    setValue={setValue}
                    readOnly={!(isEditable ?? true)}
                  />
                </FileExplorerStyledInline>
              </PadBox>

              <CheckboxInput
                useId="rowHeaders"
                onChange={handleRowHeadersCheckbox}
                value={'rowHeaders'}
                checked={hasHeader}
                label={'Does the first row of sheet have headers?'}
                disabled={!(isEditable ?? true)}
              />
            </>
          ) : (
            <SqlEditorAndSchemaContainer>
              <QueryEditorContainer>
                <DataSetQueryEditor
                  control={control}
                  plugin={connector?.plugin}
                  databaseName={editorDetails?.databaseName}
                  schemas={schemas}
                  // eslint-disable-next-line
                  isLive={!isEditable}
                  setValidity={setIsQueryValidNected}
                />
              </QueryEditorContainer>

              <SchemaContainer>
                <DataSetSchemaMemoized
                  schemas={schemas}
                  getDataSetSchema={getDataSetSchema}
                />
              </SchemaContainer>
            </SqlEditorAndSchemaContainer>
          )}

          {!isValidQuery && (
            <SyntaxErrorContainer>
              <Typography>
                The query written here is syntactically incorrect
              </Typography>
            </SyntaxErrorContainer>
          )}
        </PadBox>
        <Stack as={PadBox} padding={'2rem'} gutter="1.6rem">
          <StyledInlineContainer status={status}>
            <EntityUnderReview entityType="Dataset" entityStatus={status} />
            <>
              {showEditBtn && isNotPreview && (
                <Button onClick={handleEditButton}>Edit</Button>
              )}

              {currentEnvironment === Environment.STAGING &&
                !testDisable &&
                isNotPreview && (
                  <Inline justify="end">
                    <Button
                      disabled={shouldDisableExecuteNodeButton}
                      onClick={async () => {
                        if (!isQueryValidNected) {
                          toasts.error(
                            'You still have some errors in the editor. Please resolve to proceed',
                            'error'
                          );

                          return;
                        }

                        await testDataSet(outputRef);
                      }}
                      appearance="filled"
                      type="button"
                    >
                      Test Query
                    </Button>
                  </Inline>
                )}
            </>
          </StyledInlineContainer>
          <DataSetOutputMemoized
            data={testData}
            error={testError}
            isLoading={isTestInProgress}
            outputRef={outputRef}
          />
        </Stack>
      </StackAsItem>
    </DatasetFormStyled>
  );
}
