import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Dataset, ExecutedValueTooltip, TextInput, Typography } from 'ui';

import { getPropertyIfExists } from '../../../../../../utils/common';
import { workflowNodesAtom } from '../../../../atoms/atoms';
import { WorkflowContainer } from './WorkflowInput.styled';

type WorkflowInputProps = {
  id: string;
  inputs: Record<string, any>;
  index: number;
  updatedDataSet: Record<string, Dataset>;
};

export function WorkflowInput({
  id,
  inputs,
  index,
  updatedDataSet,
}: WorkflowInputProps) {
  const [workflowNodes] = useAtom(workflowNodesAtom);

  const currentNode = workflowNodes.find((node) => node.id === id);

  const getValue = (key: string, input: Record<string, any>) => {
    if (!_isNil(input[key].source) && !_isEmpty(input[key].source)) {
      return (
        ((input[key].source as string) ?? '') +
        '.' +
        ((input[key].attribute as string) ?? '')
      );
    }

    if (currentNode?.type === 'trigger') {
      return ['json', 'restApi'].includes(input[key].dataType)
        ? JSON.stringify(input[key].sampleValue ?? {})
        : input[key].sampleValue ?? '';
    }

    return ['json', 'restApi'].includes(input[key].dataType)
      ? JSON.stringify(input[key].value ?? {})
      : input[key].value ?? '';
  };

  const getValueForTrigger = (
    key: string,
    input: Record<string, any>,
    executedValue: Record<string, any>
  ) => {
    return ['json', 'restApi'].includes(input[key].dataType)
      ? JSON.stringify(executedValue[key] ?? {})
      : executedValue[key] ?? '';
  };

  const getExecutedValue = (key: string, nodeInput: Record<string, any>) => {
    const source = nodeInput[key].source;
    const attribute = nodeInput[key].attribute;

    if (source in updatedDataSet) {
      return (
        updatedDataSet?.[source]?.attributes?.[attribute]?.executedValue ?? null
      );
    }

    const sourceNode = workflowNodes.find((node) => node.data?.name === source);

    if (!_isNil(attribute) && !_isEmpty(attribute) && !_isNil(sourceNode)) {
      return getPropertyIfExists(sourceNode.data.executedValue, attribute);
    }

    return null;
  };

  return (
    <WorkflowContainer gutter={8}>
      {!_isNil(currentNode?.data?.input) &&
        !_isEmpty(currentNode) &&
        Object.keys(currentNode?.data?.input)?.map((k, i) => {
          const value =
            index === 0
              ? getValueForTrigger(k, currentNode?.data?.input ?? {}, inputs)
              : getValue(k, currentNode?.data?.input ?? {});

          const executedValue = getExecutedValue(
            k,
            currentNode?.data?.input ?? {}
          );

          const hasAttribute =
            !_isNil(currentNode?.data?.input[k].attribute) &&
            !_isEmpty(currentNode?.data?.input[k].attribute);

          return (
            // eslint-disable-next-line
            <Stack key={k + i}>
              <Inline stretch="start" align="end">
                <Typography fontWeight={700}>
                  {i + 1}. {k}
                </Typography>
                <Typography fontWeight={700} name="secondaryXs">
                  {currentNode?.data?.input[k].dataType}
                </Typography>
              </Inline>

              {hasAttribute ? (
                <ExecutedValueTooltip
                  id={k + i.toString()}
                  placement="bottom"
                  attribute={currentNode?.data?.input[k].attribute}
                  dataType={currentNode?.data?.input[k].dataType}
                  value={executedValue}
                >
                  <TextInput value={value} />
                </ExecutedValueTooltip>
              ) : (
                <TextInput value={value} />
              )}
            </Stack>
          );
        })}
    </WorkflowContainer>
  );
}
