import type { ReactElement } from 'react';

import { HeaderSection } from './WorkflowNodeSheetHeader.styled';

export type WorkflowNodeSheetHeaderProps = {
  children: ReactElement[];
  styleClassName?: string;
};

export function WorkflowNodeSheetHeader({
  children,
  styleClassName = '',
}: WorkflowNodeSheetHeaderProps) {
  return <HeaderSection className={styleClassName}>{children}</HeaderSection>;
}
