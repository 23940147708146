import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { memo, useEffect, useState } from 'react';
import { IoPlayOutline } from 'react-icons/io5';
import { MdOutlineRule } from 'react-icons/md';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, TooltipReact, Typography, useLayer } from 'ui';

import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import {
  isWorkflowTestOnlyAtom,
  workflowEdgesAtom,
  workflowErrorByNodeAtom,
  workflowNodesAtom,
} from '../../../../atoms/atoms';
import { useTestWorkflowRuleNode } from '../../../../hooks/useTestWorkflowRuleNode';
import { checkNodeDisabled } from '../../../../utils/common';
import { RuleSheet } from '../../../Sheets/RuleSheet/RuleSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { RuleAction } from '../RuleAction/RuleAction';
import {
  DtContainer,
  IconContainer,
  RuleActionsContainer,
  RuleInfoContainer,
} from './DtNode.styled';

export const DtNode = memo(({ data, isConnectable, id, type }: NodeProps) => {
  const { openWithProps } = useLayer(<RuleSheet />);

  const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  // eslint-disable-next-line
  const [isNodeDisabled, setIsNodeDisabled] = useState(false);

  useEffect(() => {
    const nodeDisabled = checkNodeDisabled(
      data.rootId ?? '',
      id,
      workflowNodes,
      workflowEdges
    );

    setIsNodeDisabled(nodeDisabled.isDisabled);
  }, [JSON.stringify(workflowNodes)]);

  const { testRuleData } = useTestWorkflowRuleNode({
    id,
    localData: data,
    type,
  });

  const { sendEventToGTM } = useSendEventToGTM();

  const Component = ({ data }: any) => (
    <DtContainer padding="0.5rem" $isError={!(workflowErrorByNode[id] == null)}>
      <NodeStatus status={data?.status} />

      <IconContainer
        onClick={() => {
          sendEventToGTM({
            event: 'workflow',
            source: 'listing',
            element: type,
            action: 'node_click',
            type: data?.nodeType,
          });

          if (!isNodeDisabled) {
            openWithProps({
              id,
              data,
            });
          }
        }}
      >
        <MdOutlineRule size={32} color={isNodeDisabled ? '#dcdcdc' : '#111'} />
      </IconContainer>

      <PadBox
        padding={{
          top: '6px',
          bottom: '6px',
        }}
      >
        <RuleInfoContainer>
          <Typography name="paragraphXs" fontWeight={700}>
            {data?.name ?? 'Step 1'}
          </Typography>

          <Typography name="paragraphXs">Rule</Typography>

          {!(workflowErrorByNode[id] == null) && (
            <Typography name="errorXs">
              {workflowErrorByNode[id]?.message}
            </Typography>
          )}
        </RuleInfoContainer>

        {!isNodeDisabled && (
          <RuleActionsContainer>
            <Inline
              align="center"
              style={{
                background: 'var(--color-lightGray7)',
                width: '4rem',
              }}
            >
              <IconButton
                disabled={!isWorkflowTestOnly}
                onClick={async () => await testRuleData()}
              >
                <IoPlayOutline
                  color={
                    isWorkflowTestOnly
                      ? 'var(--color-black)'
                      : 'var(--color-darkGray)'
                  }
                />
              </IconButton>

              <RuleAction data={data} id={id} />
            </Inline>
          </RuleActionsContainer>
        )}
      </PadBox>
    </DtContainer>
  );

  return (
    <>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: 'var(--color-dodgerBlue)',
          height: 8,
          width: 8,
          border: '1px solid var(--color-dodgerBlue)',
        }}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Top}
        style={{
          background: 'var(--color-dodgerBlue)',
          height: 8,
          width: 8,
          border: '1px solid var(--color-dodgerBlue)',
        }}
        isConnectable={isConnectable}
      />

      {isNodeDisabled ? (
        <TooltipReact
          id={id}
          placement="right"
          launcher={
            <span>
              <Component data={data} />
            </span>
          }
        >
          <Typography>
            This branch is disabled because the rule is running in a loop.
          </Typography>
        </TooltipReact>
      ) : (
        <Component data={data} />
      )}
    </>
  );
});

DtNode.displayName = 'DtNode';
