import { gql, useMutation } from '@apollo/client';

const updateNodesEdgesMutation = gql`
  mutation UpdateWorkflow(
    $id: String!
    $nodes: [NodeInput!]
    $edges: [EdgeInput!]
    $startNode: String
    $checksum: String!
    $trigger: TriggerInput
    $editMode: Boolean
  ) {
    updateWorkflow(
      input: {
        id: $id
        nodes: $nodes
        edges: $edges
        startNode: $startNode
        checksum: $checksum
        editMode: $editMode
        trigger: $trigger
      }
    ) {
      id
      name
      checksum
      status
      staticUrl
      isLive
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateNodesEdges() {
  return useMutation(updateNodesEdgesMutation);
}
