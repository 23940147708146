import type { ReactElement, ReactNode } from 'react';
import {
  type PlacesType,
  Tooltip as ReactTooltip,
  VariantType,
} from 'react-tooltip';

import { Launcher } from './Launcher';

export type TooltipReactProps = {
  children: ReactNode;
  id: string;
  launcher?: ReactElement;
  placement?: PlacesType;
  variant?: VariantType;
  opacity?: number;
  customStyles?: Record<string, any>;
  defaultIsOpen?: boolean;
};

export function TooltipReact({
  children,
  launcher,
  placement = 'top-start',
  id,
  variant = 'dark',
  opacity,
  customStyles = {},
  defaultIsOpen = false,
}: TooltipReactProps) {
  return (
    <span>
      {launcher != null ? (
        <span data-tooltip-id={id}>{launcher}</span>
      ) : (
        <Launcher id={id} />
      )}

      <ReactTooltip
        id={id}
        place={placement}
        opacity={opacity}
        variant={variant}
        style={{
          zIndex: 999,
          maxWidth: '30rem',
          ...customStyles,
        }}
        clickable
        defaultIsOpen={defaultIsOpen}
      >
        {children}
      </ReactTooltip>
    </span>
  );
}
