import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo, useState } from 'react';
import {
  DataTypes,
  type Dataset,
  getDataTypeNected,
  getObjectUnion,
  toasts,
} from 'ui';

import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import {
  getPropertyIfExists,
  handleSetCheckSumByEntityName,
  transformSampleValue,
} from '../../../utils/common';
import { TESTABLE_NODES } from '../../../utils/constant';
import {
  changedNodeIdsAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import { RunInLoopType } from '../models/models';
import {
  getAllPredecessorsSkippingType,
  getDirectParentsSkippingType,
  getExecutedValueAndStatus,
  getExecutedValueOfUsedAttributes,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { useGetRuleById } from './graphql/useGetRuleById';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestWorkflowRuleNodeProps = {
  localData: Record<string, any>;
  id: string;
  type: string;
};

export function useTestWorkflowRuleNode({
  localData,
  id,
  type,
}: UseTestWorkflowRuleNodeProps) {
  const [getRuleById] = useGetRuleById();
  const { testWorkflow, data: ruleData, error: ruleError } = useTestWorkflow();
  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const runInLoop: RunInLoopType = localData.runInLoop;

  const [attributes, setAttributes] = useState<any[]>([]);

  const [currentRule, setCurrentRule] = useState<{
    name: string;
    link: string;
  }>({
    name: '',
    link: '',
  });
  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);
  const [directParents, setDirectParents] = useState<WorkflowNodeType[]>([]);

  const [currentTab, setCurrentTab] = useState(0);

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowId] = useAtom(workflowIdAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const { updatedDataSet } = useGetDataset({
    parentNodes,
  });

  const finalDataSet: Record<string, Dataset> = useMemo(() => {
    if (runInLoop?.status) {
      const parentData = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            parentNodes.find((p) => p.data.name === runInLoop.source)?.data
              ?.executedValue ?? {}
          )
        ),
        runInLoop.attribute
      );

      return {
        ...updatedDataSet,
        loop: {
          name: 'Loop',
          attributes: {
            currentItem: {
              dataType: getDataTypeNected(
                getObjectUnion(parentData ?? [])
              ) as DataTypes,
              name: 'currentItem',
              executedValue: getObjectUnion(parentData ?? []),
            },
          },
          id: 'currentItem',
        },
      };
    }

    return updatedDataSet;
  }, [updatedDataSet, JSON.stringify(runInLoop), parentNodes.length]);

  const handleGetRuleProps = async (
    id: string = '',
    isSetValue: boolean = false
  ) => {
    if (_isEmpty(id)) {
      return [];
    }

    try {
      const data = await getRuleById({
        variables: {
          id,
        },
      });

      if (!_isNil(data.data.getRule?.data[0]?.customInput) && isSetValue) {
        setAttributes(
          _reduce(
            data.data.getRule?.data[0]?.customInput,
            (res: any, val, key) => {
              return [
                ...res,
                {
                  keyName: key,
                  value: transformSampleValue(
                    val.dataType,
                    localData?.input?.[key]?.value ?? null
                  ),
                  dataType: val.dataType,
                  source: localData?.input?.[key]?.source ?? null,
                  attribute: localData?.input?.[key]?.attribute ?? null,
                  isNullable: localData?.input?.[key]?.isNullable === true,
                  isOptional: localData?.input?.[key]?.isOptional === true,
                },
              ];
            },
            []
          )
        );
      }

      if (!_isNil(data.data.getRule?.data[0]?.checksum) && isSetValue) {
        handleSetCheckSumByEntityName(
          'rule',
          data.data.getRule?.data[0]?.checksum
        );
      }

      if (
        !_isNil(data.data.getRule?.data[0]?.name) &&
        !_isNil(data.data.getRule?.data[0]?.id)
      ) {
        setCurrentRule({
          name: data.data.getRule?.data[0]?.name,
          link: `rules/${
            data.data.getRule?.data[0]?.id as string
          }?type=edit&stage=staging&ruleType=${
            data.data.getRule?.data[0].type as string
          }&wsid=${sessionStorage.getItem('workspaceUUID') as string}`,
        });
      }

      return [
        data.data.getRule?.data[0]?.action?.then?.outputData,
        data.data.getRule?.data[0]?.action?.else?.outputData,
        data.data.getRule?.data[0]?.type,
      ];
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error);
    }

    return [];
  };

  // eslint-disable-next-line
  const testRuleData = async (attr?: any[], isLoop?: Record<string, any>) => {
    sendEventToGTM({
      event: 'workflow',
      source: 'listing',
      element: type,
      action: 'test_click',
      type: localData?.nodeType,
    });

    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    const attributeObj: Record<string, any> = _reduce(
      attr ?? attributes,
      (finalObj, currAttribute) => {
        return !_isNil(currAttribute) && !_isNil(currAttribute.keyName)
          ? {
              ...finalObj,
              [currAttribute.keyName]: currAttribute,
            }
          : finalObj;
      },
      {}
    );

    const params: Record<string, any> = getExecutedValueOfUsedAttributes(
      attributeObj,
      updatedDataSet,
      false
    );

    // eslint-disable-next-line
    if (isLoop?.status ?? runInLoop?.status) {
      const mappedData = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            parentNodes.find(
              (p) => p.data.name === isLoop?.source ?? runInLoop?.source
            )?.data?.executedValue ?? {}
          )
        ),
        isLoop?.attribute ?? runInLoop?.attribute ?? ''
      );

      params.loop = mappedData;

      // eslint-disable-next-line
      if ((isLoop?.source ?? runInLoop?.source) === 'globalVar') {
        const mappedData = getPropertyIfExists(
          JSON.parse(
            JSON.stringify(
              Object.keys(updatedDataSet?.globalVar?.attributes ?? {}).reduce(
                (acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      updatedDataSet.globalVar.attributes[`${curr}`]
                        .executedValue,
                  };
                },
                {}
              )
            )
          ) ?? {},
          isLoop?.attribute ?? runInLoop?.attribute ?? ''
        );

        params.loop = mappedData;
      }
    }

    try {
      await testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params,
      });
    } catch (error) {
      if (error instanceof Error) {
        toasts.error(error.message, 'node-test-fail');
      }
    }
  };

  useEffect(() => {
    setParentNodes(
      getAllPredecessorsSkippingType(
        id,
        workflowNodes,
        workflowEdges,
        'addNode'
      )
    );

    setDirectParents(
      getDirectParentsSkippingType(workflowNodes, workflowEdges, id, 'addNode')
    );
  }, [JSON.stringify(workflowNodes)]);

  useEffect(() => {
    void handleGetRuleProps(localData?.entityId, true);
  }, [JSON.stringify(localData)]);

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  useEffect(() => {
    if (!_isNil(ruleData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const entity = getExecutedValueAndStatus(ruleData);

        newWorkflowNode.data.status = entity.status;
        setCurrentStatus(entity.status);

        newWorkflowNode.data.executedValue = entity.executedValue;

        setChangedNodeIds([]);

        setTimeout(() => {
          localData.onWorkflowNodeChange(workflowNode);
        }, 100);
      }
      setCurrentTab(0);
      setCurrentTab(1);
      setIsTesting(false);
      toasts.success('Node tested successfully', 'node-test-success');
    }
  }, [ruleData]);

  return {
    parentNodes,
    updatedDataSet: finalDataSet,
    attributes,
    isTesting,
    setIsTesting,
    currentTab,
    currentRule,
    testRuleData,
    ruleData,
    currentStatus,
    ruleError,
    directParents,
  };
}
