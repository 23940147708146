import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { Navigate } from 'react-router-dom';

import { Loader } from '../components/Loader/Loader.index';
import { roleJsonAtom } from '../components/authentication/router/AuthProvider';
import { useGetRoutePermisisons } from './hooks/useGetRoutePermissions';

export const ComponentRoleWrapper = (
  Wrapper: () => JSX.Element,
  id: string,
  isEmailVerified: boolean,
  currentRoutePath: string
) => {
  const [roleJson] = useAtom(roleJsonAtom);

  useGetRoutePermisisons({ roleJson, routeId: id, currentRoutePath });

  const isEmptyJson = _isNil(roleJson) || _isEmpty(roleJson);

  const homePermissionsNotExist =
    id === 'home' && currentRoutePath.includes(id) && _isNil(roleJson.flags);

  const otherPermissionsNotExist =
    id !== 'home' &&
    (_isNil(roleJson.internals) ||
      (!_isNil(roleJson.internals) && _isUndefined(roleJson.internals[id])));

  if (isEmptyJson || otherPermissionsNotExist || homePermissionsNotExist) {
    return <Loader />;
  }

  // if (!isEmailVerified) {
  //   return <Navigate to="/email-not-verified" />;
  // }

  const props = !_isNil(roleJson.internals) ? roleJson.internals[id] : {};

  if (!_isNil(roleJson) && !_isNil(roleJson.flags) && !!roleJson.flags[id]) {
    return <Wrapper {...props} />;
  }

  return <Navigate to={'/home'} />;
};
