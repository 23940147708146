import { Node, NodeLeaf } from '../components';
import { JsonViewer } from './JsonViewer';
import { ListViewer } from './ListViewer';

type RuleNodeBuilderProps = {
  nodeKey: string;
  nodeValue: Record<string, any>;
  depth: number;
  searchInput: string;
  searchedLeaves: string[];
  expandedNodes: Record<string, boolean>;
  parentNodeClickHandler: (id: string) => void;
  onClick: (
    value: string,
    node: string,
    dataType: string,
    executedValue?: any
  ) => void;
  allowList?: boolean;
  typesToAllow?: string[];
  onlyJson?: boolean;
  showTooltip?: boolean;
  version?: 'v1' | 'v2';
};

export const RuleNodeBuilder = ({
  nodeKey,
  nodeValue,
  depth,
  searchInput,
  expandedNodes,
  parentNodeClickHandler,
  onClick,
  searchedLeaves,
  allowList = false,
  typesToAllow = [],
  onlyJson = false,
  showTooltip = false,
  version = 'v1',
}: RuleNodeBuilderProps) => {
  const keyShouldNotBe = (key: string) => !['name', 'id'].includes(key);

  if (Object.keys(nodeValue).includes('attributes')) {
    depth += 1;

    const viewerFor = ['json', 'restAPI'];

    return (
      <Node
        depth={depth - 1}
        nodeValue={nodeValue}
        title={nodeValue.name}
        searchInput={searchInput}
        expandedNodes={expandedNodes}
        tooltip={nodeValue.tooltip}
        parentNodeClickHandler={parentNodeClickHandler}
      >
        {Object.keys(nodeValue.attributes).map((item, index) => {
          if (viewerFor.includes(nodeValue.attributes[item].dataType)) {
            return (
              <JsonViewer
                onLeafClick={onClick}
                execValue={nodeValue.attributes[item].executedValue}
                kName={item}
                depth={depth}
                nodeKey={nodeKey}
                key={index}
                searchedLeaves={searchedLeaves}
                allowList={allowList}
                typesToAllow={typesToAllow}
                currentDatatype={nodeValue.attributes[item].dataType}
                showTooltip={showTooltip}
                searchInput={searchInput}
                version={version}
              />
            );
          }

          if (nodeValue.attributes[item].dataType === 'list' && allowList) {
            return (
              <ListViewer
                onLeafClick={onClick}
                exeVal={nodeValue.attributes[item].executedValue}
                kName={item}
                depth={depth}
                nodeKey={nodeKey}
                key={index}
                searchedLeaves={searchedLeaves}
                typesToAllow={typesToAllow}
                showTooltip={showTooltip}
                currentDatatype={nodeValue.attributes[item].dataType}
                searchInput={searchInput}
                version={version}
              />
            );
          }

          if (
            typesToAllow.length > 0 &&
            !typesToAllow.includes(nodeValue.attributes[item].dataType) &&
            !allowList
          ) {
            return null;
          }

          if (
            typesToAllow.length > 0 &&
            !typesToAllow.includes(nodeValue.attributes[item].dataType)
          ) {
            return null;
          }

          return (
            <NodeLeaf
              key={index}
              title={nodeValue.attributes[item].name}
              dataType={nodeValue.attributes[item].dataType}
              executedValue={nodeValue.attributes[item].executedValue}
              showTooltip={showTooltip}
              version={version}
              onClick={() =>
                onClick(
                  item,
                  nodeKey,
                  nodeValue.attributes[item].dataType,
                  nodeValue.attributes[item].executedValue
                )
              }
            />
          );
        })}
      </Node>
    );
  }

  depth += 1;

  const nodesToRender = Object.keys(nodeValue).filter((key) => {
    return keyShouldNotBe(key);
  });

  return (
    <div>
      <Node
        depth={depth - 1}
        nodeValue={nodeValue}
        title={nodeValue.name}
        expandedNodes={expandedNodes}
        searchInput={searchInput}
        parentNodeClickHandler={parentNodeClickHandler}
      >
        {nodesToRender.map((i, index) => (
          <span key={index}>
            <RuleNodeBuilder
              key={index}
              nodeKey={i}
              parentNodeClickHandler={parentNodeClickHandler}
              depth={depth}
              nodeValue={nodeValue[i]}
              onClick={onClick}
              expandedNodes={expandedNodes}
              searchInput={searchInput}
              searchedLeaves={searchedLeaves}
              showTooltip={showTooltip}
              version={version}
            />
          </span>
        ))}
      </Node>
    </div>
  );
};
