import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import { useEffect, useState } from 'react';
import { Control, UseFormSetValue, useForm, useWatch } from 'react-hook-form';
import { BsCheckCircleFill } from 'react-icons/bs';
import {
  Button,
  Dataset,
  NectedEditor,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Toast,
  Typography,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { useGetConnectorById } from '../../../../../hooks/graphql/useGetConnectorById';
import { GetConnectorsQueryResult } from '../../../../../pages/DataSets/hooks/useGetPublishedConnectors';
import { TabsContainer } from '../../../../../pages/Integrations/components/common/IntegrationSheet.styled';
import type {
  ConnectorAndPluginModel,
  KeyValuePairList,
} from '../../../../../pages/Integrations/types';
import { isRuleReadOnlyAtom } from '../../../../../pages/Rules';
import type { ResultType } from '../../../../../pages/Rules/components/SimpleRule/Results';
import { ResultAction } from '../../../../../pages/Rules/types';
import {
  getDataSetsQueryByName,
  validateRestApiAction,
} from '../../../../../pages/Rules/utils/common';
import { sanitizedStringV2 } from '../../../../../pages/Workflow/utils/common';
import { isValidTokenPresentInLeafNodesV2 } from '../../../../../utils/common';
import {
  bodyParamTypes,
  editorDomain,
  sourceTypesToIgnoreOnValueReplacement,
} from '../../../../../utils/constant';
import { stopPropagate } from '../../../../../utils/form';
import {
  ActionBody,
  ActionBodyContainer,
  ActionFooter,
} from '../../../../ActionComponents/ActionSheet.styled';
import { RestAPI } from '../../../../ActionComponents/components/RestAPI/RestAPI';
import { publishedConnectorsAtom } from '../../../../CreateAction/CreateAction';
import { IntegrationField } from '../../../../IntegrationField/IntegrationField';
import {
  JsonContainer,
  JsonOutputContainer,
  RestResponseActionFooter,
} from '../../../../jsonOutputField/components/RuleExecutionOutput.styled';
import type { TokensSetProps } from '../CustomAttributeSheet';
import { RestInCISheetWrapper } from './RestInCISheet.styled';
import { useExecuteRestAPIAction } from './useExecuteRestAPIAction';

export type RestInCISheetProps = {
  connectorId?: string;
  control?: Control<any>;
  index?: number;
  section?: ResultType;
  setOriginalValue?: UseFormSetValue<any>;
  tabIndex?: number;
  updatedLocalCI?: Record<string, any>;
  tokensSet?: TokensSetProps[];
  updatedDataset?: Record<string, Dataset>;
  publishConnectorList?: GetConnectorsQueryResult;
  refetchPublishedConnectorList?: () => Promise<void>;
};

export const RestInCISheet = ({
  connectorId = '',
  control,
  index = 0,
  setOriginalValue,
  tabIndex = 0,
  updatedLocalCI,
  tokensSet,
  updatedDataset = {},
  publishConnectorList,
  refetchPublishedConnectorList,
}: RestInCISheetProps) => {
  const {
    isLoading,
    data: executedRestAPIData,
    executeRestAPIAction,
  } = useExecuteRestAPIAction();

  const [currentTab, setCurrentTab] = useState(0);
  const [executedRestAPIResponse, setExecutedRestAPIResponse] = useState<
    any | null
  >(null);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [publishedConnectors] = useAtom(publishedConnectorsAtom);

  const [getConnectorById] = useGetConnectorById();

  const [connectorList, setConnectorList] = useState<any[]>([]);

  const [disabledHeaders, setDisabledHeaders] = useState<KeyValuePairList[]>(
    []
  );
  const [disabledQueryParams, setDisabledQueryParams] = useState<
    KeyValuePairList[]
  >([]);
  const [executionTime, setExecutionTime] = useState('');
  const [selectedConnector, setSelectedConnector] =
    useState<ConnectorAndPluginModel | null>(null);
  const { close: closeRestAPIActionSheet } = useCurrentLayer();
  const toggleTabs = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const pluginName = publishedConnectors[connectorId].plugin.name;

  const getConnectorData = async () => {
    const connectorData = await getConnectorById({
      variables: {
        connectorId,
      },
    });
    const connector = connectorData.data?.getConnector.data[0];

    if (!_isNil(connector)) {
      setSelectedConnector(connector);
    }

    if (!_isNil(connector) && !_isNil(connector.staging.conf)) {
      if (Array.isArray(connector.staging.conf.headers)) {
        setDisabledHeaders(connector.staging.conf.headers);
      } else {
        setDisabledHeaders(
          _map(connector.staging.conf.headers, (value, key) => {
            return {
              key,
              value,
            };
          })
        );
      }

      if (Array.isArray(connector.staging.conf.queryParams)) {
        setDisabledQueryParams(connector.staging.conf.queryParams);
      } else {
        setDisabledQueryParams(
          _map(connector.staging.conf.queryParams, (value, key) => {
            return {
              key,
              value,
            };
          })
        );
      }
    }
  };

  const configObj: Record<string, any> = useWatch({
    name: `attributes.${index}.config`,
    control,
  });

  const getExecutedValue: Record<string, any> = useWatch({
    name: `attributes.${index}.executedValue`,
    control,
  });

  useEffect(() => {
    void getConnectorData();
  }, []);

  useEffect(() => {
    if (!_isNil(configObj)) {
      initializeForm(configObj, 'action');
    }
  }, [JSON.stringify(configObj)]);

  useEffect(() => {
    const updatedExecutedValue = sanitizedExecutedValue(
      !_isNil(executedRestAPIResponse)
        ? executedRestAPIResponse
        : getExecutedValue
    );

    if (
      Object.keys(_isNil(updatedExecutedValue) ? {} : updatedExecutedValue)
        .length > 0
    ) {
      toggleTabs(1);
    }
    setExecutedRestAPIResponse(updatedExecutedValue);
  }, [JSON.stringify(getExecutedValue)]);

  useEffect(() => {
    if (!_isNil(publishConnectorList)) {
      const publishedConnectors = publishConnectorList.getConnector.data
        .filter((connector) => connector.plugin.name === pluginName)
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      setConnectorList(publishedConnectors);
    }
  }, [publishConnectorList, pluginName]);

  useEffect(() => {
    if (!_isNil(connectorList)) {
      setLocalValue(
        'integration',
        connectorList.find((obj) => obj.value === connectorId)
      );
    }
  }, [JSON.stringify(connectorList)]);

  const initializeForm = (data: Record<string, any>, name: string) => {
    if (!_isNil(data?.query) && !_isEmpty(data.query)) {
      setLocalValue(`${name}.config.query`, data.query);
    }

    if (!_isNil(data?.queryParams)) {
      setLocalValue(`${name}.config.queryParams`, data.queryParams);
    }

    if (!_isNil(data?.headers)) {
      setLocalValue(`${name}.config.headers`, data.headers);
    }

    if (!_isNil(data?.body)) {
      setLocalValue(`${name}.config.body`, data.body);
    }

    if (!_isNil(data?.bodyParams)) {
      setLocalValue(`${name}.config.bodyParams`, data.bodyParams);
    }

    if (!_isNil(data?.method)) {
      setLocalValue(`${name}.config.method`, {
        value: data.method,
        label: data.method,
      });
    }

    if (!_isNil(data?.path)) {
      setLocalValue(`${name}.config.path`, data.path);
    }

    if (!_isNil(data?.contentType)) {
      setLocalValue(`${name}.config.contentType`, {
        value:
          typeof data.contentType === 'object'
            ? data.contentType.value
            : data.contentType,
        label:
          typeof data.contentType === 'object'
            ? data.contentType.label
            : bodyParamTypes.find((param) => param.value === data.contentType)
                ?.label ?? '',
      });
    }
  };

  const {
    handleSubmit,
    setValue: setLocalValue,
    control: localControl,
    setError,
  } = useForm<any>({
    defaultValues: {
      action: {
        config: {
          query: getDataSetsQueryByName(siteConstants, selectedConnector),
          headers: null,
          method: null,
          queryParams: null,
          bodyParams: null,
          body: null,
          path: null,
        },
      },
    },
  });

  const onSubmit = async (data: {
    action: ResultAction;
    integration: Record<string, string>;
  }) => {
    // On Success switch tabs
    const payload: Record<string, any> = data.action;
    payload.config.method = payload.config.method?.value ?? '';
    payload.config.contentType = payload.config.contentType?.value ?? '';
    let isValid = true;
    isValid = validateRestApiAction(data.action, setError, 'action');

    const fieldName = `action.config`;

    isValid = isValidTokenPresentInLeafNodesV2(
      payload.config,
      updatedDataset,
      fieldName,
      setError
    );

    if (!_isNil(setOriginalValue) && isValid) {
      const executeRestAPIPayload = {
        id: selectedConnector?.id ?? '',
        environment: 'staging' as 'staging',
        method: 'execute' as 'execute',
        params: replaceTokensInRestConfig(payload.config),
      };
      void executeRestAPIAction(executeRestAPIPayload);
      setOriginalValue(`attributes.${index}.config`, payload.config);

      setOriginalValue(`attributes.${index}.selectedType`, {
        value: data.integration.value,
        key: 'restAPI',
        dataType: 'restAPI',
      });
    }
  };

  const replaceTokensInRestConfig = (config: any) => {
    const configString = JSON.stringify(config);

    const updatedConfigString = sanitizedStringV2(
      configString,
      updatedDataset,
      sourceTypesToIgnoreOnValueReplacement
    );

    return JSON.parse(updatedConfigString);
  };

  const sanitizedExecutedValue = (string: string) => {
    try {
      JSON.parse(string);

      return JSON.parse(string);
    } catch (err) {
      return string;
    }
  };

  useEffect(() => {
    if (
      !_isNil(executedRestAPIData?.data?.data?.result) &&
      !_isNil(setOriginalValue)
    ) {
      const executedRestAPIResponse = executedRestAPIData?.data?.data?.result;

      setOriginalValue(
        `attributes.${index}.sampleValue`,
        JSON.stringify(executedRestAPIResponse)
      );
      setOriginalValue(
        `attributes.${index}.testValue`,
        JSON.stringify(executedRestAPIResponse)
      );
      setOriginalValue(
        `attributes.${index}.executedValue`,
        JSON.stringify(executedRestAPIResponse)
      );
      setExecutedRestAPIResponse(executedRestAPIResponse);
      setExecutionTime(executedRestAPIData?.data?.data?.executionTime);
      toggleTabs(1);
    }
  }, [executedRestAPIData]);

  const checkExecutedValueType = (value: any) => {
    if (typeof value === 'object') {
      return JSON.stringify(value, null, '\t');
    } else if (!_isUndefined(value)) {
      return JSON.stringify(JSON.parse(value), null, '\t');
    }

    return JSON.stringify('{}');
  };

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${connectorId}?stage=staging&connector=${pluginName}`
    );
  };

  return (
    <Sheet size="small">
      <ActionBody as="form" onSubmit={stopPropagate(handleSubmit(onSubmit))}>
        <PadBox padding="1rem">
          <Inline stretch="start" align="center">
            <Inline gutter="1.6rem" align="center">
              <Typography name="heading2">
                {!_isNil(selectedConnector) ? selectedConnector?.name : ''}
              </Typography>
            </Inline>
          </Inline>
        </PadBox>
        <TabsContainer>
          <Tabs onTabChange={toggleTabs} defaultOpen={currentTab}>
            <TabList>
              <Tab>Configure API</Tab>
              <Tab>Input Attribute</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <ActionBodyContainer padding="1rem">
                  <Stack gutter={10}>
                    <IntegrationField
                      editPlugin={editPlugin}
                      refreshPlugins={async () => {
                        if (
                          typeof refetchPublishedConnectorList === 'function'
                        ) {
                          await refetchPublishedConnectorList();
                        }
                      }}
                      control={localControl}
                      name="integration"
                      connectorList={connectorList}
                      pluginId={selectedConnector?.plugin.id}
                      connectorId={connectorId}
                      disabled={isRuleReadOnly}
                    />

                    {!_isNil(selectedConnector) && (
                      <RestAPI
                        sectionName=""
                        index={index}
                        control={localControl}
                        mainControl={control}
                        connectorId={connectorId ?? ''}
                        disabledHeaders={disabledHeaders}
                        disabledQueryParams={disabledQueryParams}
                        connector={selectedConnector}
                        disabled={isRuleReadOnly}
                        setValue={setLocalValue}
                        updatedLocalCI={updatedLocalCI}
                        hideOutput
                      />
                    )}
                  </Stack>
                </ActionBodyContainer>
                <ActionFooter padding={[4, 8]}>
                  <Inline justify="end">
                    <Button
                      appearance="contained"
                      type="submit"
                      disabled={isLoading || isRuleReadOnly}
                    >
                      {isLoading ? (
                        <Spinner size="extraSmall" />
                      ) : (
                        'Fetch Input Attribute via API'
                      )}
                    </Button>
                  </Inline>
                </ActionFooter>
              </TabPanel>
              <TabPanel>
                <RestInCISheetWrapper>
                  {Object.keys(
                    JSON.parse(checkExecutedValueType(getExecutedValue))
                  ).length === 0 && (
                    <Stack gutter={15}>
                      <Inline>
                        <Inline>
                          <Toast
                            type="infoBlue"
                            message="Click ‘Execute and Fetch’ to see your API output here."
                          />
                        </Inline>
                      </Inline>
                    </Stack>
                  )}
                  {Object.keys(
                    JSON.parse(checkExecutedValueType(getExecutedValue))
                  ).length > 0 && (
                    <>
                      <Stack gutter={15}>
                        <Inline>
                          <Inline>
                            <Toast
                              type="infoBlue"
                              message="This JSON output will be added as custom input in the rule with below key values pairs"
                            />
                          </Inline>
                        </Inline>
                      </Stack>
                      <JsonOutputContainer padding="1.6rem">
                        <Stack gutter={8}>
                          <Inline align="center" gutter="2rem" stretch={1}>
                            <Typography name="heading3">JSON Output</Typography>
                            {!_isEmpty(executionTime) && (
                              <Inline align="center" gutter={5}>
                                <BsCheckCircleFill fill="green" />

                                <Typography name="success">
                                  Success {executionTime}
                                </Typography>
                              </Inline>
                            )}
                          </Inline>
                          <JsonContainer>
                            <NectedEditor
                              defaultValue={checkExecutedValueType(
                                getExecutedValue
                              )}
                              mode="json"
                              domain={editorDomain}
                              readOnly={true}
                            />
                          </JsonContainer>
                        </Stack>
                      </JsonOutputContainer>
                    </>
                  )}
                </RestInCISheetWrapper>
                {Object.keys(
                  JSON.parse(checkExecutedValueType(getExecutedValue))
                ).length > 0 && (
                  <RestResponseActionFooter>
                    <ActionFooter padding={[4, 8]}>
                      <Inline justify="end">
                        <Button
                          appearance="contained"
                          type="button"
                          disabled={isLoading || isRuleReadOnly}
                          onClick={closeRestAPIActionSheet}
                        >
                          Save & Close
                        </Button>
                      </Inline>
                    </ActionFooter>
                  </RestResponseActionFooter>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </TabsContainer>
      </ActionBody>
    </Sheet>
  );
};
