import { gql, useMutation } from '@apollo/client';

import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updateDecisionTableRule = gql`
  mutation updateRule(
    $id: String!
    $name: String!
    $description: String
    $customInput: Map
    $staging: ConfDetailInput
    $production: ConfDetailInput
    $action: ActionInput
    $conditions: ConditionsInput
    $decisionTable: Map
    $policy: String
    $dataSetId: String
    $checksum: String!
    $firstCustomInput: String
    $editMode: Boolean
  ) {
    updateRule(
      input: {
        id: $id
        name: $name
        description: $description
        customInput: $customInput
        staging: $staging
        production: $production
        action: $action
        conditions: $conditions
        decisionTable: $decisionTable
        policy: $policy
        dataSetId: $dataSetId
        checksum: $checksum
        firstCustomInput: $firstCustomInput
        editMode: $editMode
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateDecisionTable() {
  return useMutation<RuleUpdateResponseModel>(updateDecisionTableRule);
}
