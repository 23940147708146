import { PadBox, Split } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { Navigate, Outlet } from 'react-router-dom';

import { userProfileAtom } from '../../atom';
import { Sidebar } from '../sidebar/Sidebar';
import { PageContainerStyled } from './Dashboard.styled';
import { ResendEmailVerificationLink } from './ResendEmailVerificationLink';

export function Dashboard() {
  const [userProfile] = useAtom(userProfileAtom);
  const $showVerifyEmailUi =
    !_isNil(userProfile) &&
    !userProfile.emailVerified &&
    userProfile.linkExpCountDown > 0;

  const isSmallScreen = window.innerWidth <= 800;

  if (isSmallScreen) {
    return <Navigate to="/switch-to-desktop" />;
  }

  return (
    <>
      {$showVerifyEmailUi && (
        <ResendEmailVerificationLink
          verificationDaysLeft={userProfile?.linkExpCountDown}
        />
      )}
      <PadBox>
        <Split fraction="auto-start" minItemWidth={232}>
          <Sidebar />
          <PageContainerStyled $showVerifyEmailUi={$showVerifyEmailUi}>
            <Outlet />
          </PageContainerStyled>
        </Split>
      </PadBox>
    </>
  );
}
