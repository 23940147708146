import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { MdEdit } from 'react-icons/md';
import { Button, Typography, useLayer } from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import {
  DynamicPanelContainer,
  EditorPanelContainer,
  SidePanelContainer,
} from '../../../../../components/CommonStyles/CommonStyles.styled';
import { EntityUnderReview } from '../../../../../components/EntityUnderReview/EntityUnderReview';
import { FAQMenu } from '../../../../../components/FAQMenu';
import { Panel } from '../../../../../components/Panel/Panel';
import { VersionControl } from '../../../../../components/VersionControl/VersionControl';
import { tabList } from '../../../../../components/VersionControl/constant';
import { StackAsItem } from '../../../../../components/layouts/Stack.styled';
import { CustomAttributeSheet } from '../../../../../components/rules/forms/CustomAttributeSheet';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import {
  getTooltipText,
  handleSetCheckSumByEntityName,
} from '../../../../../utils/common';
import { ENTITY_ID } from '../../../../../utils/constant';
import { useUpdateStateOnRulePublish } from '../../../hooks/useUpdateStateOnRulePublish';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { Triggers } from '../../Triggers/Triggers';
import {
  activePanelAtom,
  approvalInfoRuleAtom,
  errorInRuleAtom,
  hasConnectorErrorInCustomAttrSheetAtom,
  vcListTabIndexRuleAtom,
} from '../../atom/atom';
import { Results } from '../Results';
import { RuleBlockBuilder } from '../RuleBlockBuilder';
import type { SimpleRuleNodesModel } from '../models';
import {
  RuleEditorContainer,
  RuleInputHeder,
  RuleNodesContainer,
  RulesContainerStyled,
} from './RuleEditor.styled';

export type RuleEditorProps = Omit<UseControllerProps, 'name'> & {
  startNodeId: string;
  discardRule: () => void;
  isReadOnly: boolean;
  rules: Record<string, SimpleRuleNodesModel>;
  isClone: boolean;
  simpleRuleId?: string;
  setValue?: UseFormSetValue<any>;
  simpleRuleName?: string;
  handleFetchRule: () => void;
};

export const RuleEditor = ({
  startNodeId,
  rules,
  control,
  simpleRuleId,
  discardRule,
  isReadOnly,
  setValue,
  simpleRuleName,
  isClone,
  handleFetchRule,
}: RuleEditorProps) => {
  const { openWithProps: openCustomAttributeSheet } = useLayer(
    <CustomAttributeSheet isReadOnly={isReadOnly} />
  );

  const [dataset] = useAtom(dataSetParamsAtom);
  const { attributes } = dataset.customInput;
  const [activePanel, setActivePanel] = useAtom(activePanelAtom);
  const [vcListTabIndex, setVcListTabIndex] = useAtom(vcListTabIndexRuleAtom);
  const [approvalInfoRule, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);

  const [errorInRule] = useAtom(errorInRuleAtom);

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [hasConnectorErrCustomAttrSheet] = useAtom(
    hasConnectorErrorInCustomAttrSheetAtom
  );

  const hasCustomAttributes =
    _filter(attributes, (value, key) => {
      return !_isNil(attributes[key].dataType);
    }).length > 0;

  const { sendEventToGTM } = useSendEventToGTM();

  const { handleAfterRuleIsPublished } = useUpdateStateOnRulePublish({
    id: simpleRuleId ?? '',
    ruleName: simpleRuleName ?? '',
    isClone,
    setValue,
  });

  const handleAddInputClick = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId: simpleRuleId,
      ruleName: simpleRuleName,
      type: 'simpleRule',
      action: 'add',
      element: 'customAttributes',
      nec_source: '',
      action_name: '',
    });
  };

  const status = useWatch({
    control,
    name: 'status',
  });

  const handleEntityUpdate = (data: Record<string, any>) => {
    if (!_isNil(setValue) && !_isNil(data)) {
      Object.keys(data).forEach((key) => {
        if (key === 'checksum') {
          handleSetCheckSumByEntityName('rule', data.checksum);
        } else if (key === 'entityPublished') {
          void handleAfterRuleIsPublished();
        } else if (key === 'discardEntity') {
          void discardRule();
        } else if (key === 'formData') {
          const reviewFormData = data.formData.requestReview;

          if (!_isNil(reviewFormData)) {
            setApprovalInfoRule(reviewFormData);
          }
        } else if (key === 'fetchEntity') {
          void handleFetchRule();
        } else {
          setValue(key, data[key]);
        }
      });
    }
  };

  const inputBtnContainer = document.querySelector(
    '.simple-rule-input-btn-container'
  ) as HTMLDivElement;

  const editorContainer = document.querySelector(
    '.simple-rule-editor-panel'
  ) as HTMLDivElement;

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', calculateTableInfoWidth);
    };
  }, []);

  useEffect(() => {
    const updatedVcListTabIndex = tabList.findIndex(
      (tabObj) => tabObj.value === status
    );
    setVcListTabIndex(updatedVcListTabIndex !== -1 ? updatedVcListTabIndex : 0);
  }, [status]);

  useEffect(() => {
    calculateTableInfoWidth();
  }, [activePanel]);

  useEffect(() => {
    if (!_isNil(editorContainer) && !_isNil(inputBtnContainer)) {
      calculateTableInfoWidth();

      window.addEventListener('resize', calculateTableInfoWidth);
    }
  }, [inputBtnContainer, editorContainer]);

  const calculateTableInfoWidth = () => {
    if (!_isNil(editorContainer) && !_isNil(inputBtnContainer)) {
      const width = editorContainer.getBoundingClientRect().width;
      inputBtnContainer.style.width = String(width - 4) + 'px';
    }
  };

  const errorMessage = useMemo(() => {
    let error = '';

    error += _reduce(
      errorInRule,
      (acc, value, key) => {
        if (value) {
          acc += key + ', ';
        }

        return acc;
      },
      ''
    );

    return error.slice(0, -2);
  }, [errorInRule]);

  return (
    <RuleEditorContainer gutter={0}>
      <EditorPanelContainer className="simple-rule-editor-panel">
        <RulesContainerStyled className="simple-rule-editor-content">
          <div>
            <RuleInputHeder
              className="simple-rule-input-btn-container"
              padding={[6, 12]}
            >
              <Inline gutter="1rem" align="center">
                <Button
                  onClick={() => {
                    handleAddInputClick();
                    openCustomAttributeSheet({ isReadOnly });
                  }}
                >
                  {hasCustomAttributes ? <MdEdit /> : <FaPlus />}
                  {`${hasCustomAttributes ? 'Edit' : 'Add'} Input Attributes`}
                </Button>
                {(hasConnectorErrCustomAttrSheet.restAPI ||
                  hasConnectorErrCustomAttrSheet.dataset) && (
                  <Typography fontWeight={700} name="error">
                    {getTooltipText(
                      siteConstants,
                      'integrations',
                      'integrationNotConnected'
                    )}
                  </Typography>
                )}
              </Inline>
            </RuleInputHeder>

            <RuleNodesContainer padding={['1rem', '1rem', '5rem', '1rem']}>
              <PadBox padding={[0, 0, 0, '1rem']}>
                {!_isEmpty(errorMessage) && (
                  <Inline align="center" gutter="0.4rem">
                    <Typography
                      name="error"
                      fontWeight={700}
                    >{`Error in`}</Typography>

                    <Typography name="error" fontWeight={700}>
                      {errorMessage}
                    </Typography>
                  </Inline>
                )}
              </PadBox>
              <StackAsItem grow={1} gutter={20}>
                <Inline gutter={5} minItemWidth={45} align="center">
                  <Inline justify="end">
                    <Typography>If</Typography>
                  </Inline>
                  <PadBox padding={10}>
                    <RuleBlockBuilder
                      ruleId={startNodeId}
                      rules={rules}
                      control={control}
                    />
                  </PadBox>
                </Inline>

                <Inline gutter={5} minItemWidth={45} align="center">
                  <Inline justify="end">
                    <Typography>Result</Typography>
                  </Inline>
                  <PadBox padding={10}>
                    <Results control={control} setValue={setValue} />
                  </PadBox>
                </Inline>
              </StackAsItem>
            </RuleNodesContainer>
          </div>

          <PadBox padding={[0, 0, '2rem', '1rem']}>
            <EntityUnderReview entityType="Rule" entityStatus={status} />
          </PadBox>
        </RulesContainerStyled>
      </EditorPanelContainer>

      {activePanel === 'settings' && (
        <DynamicPanelContainer className="simple-rule-trigger-container">
          <StackAsItem
            grow={1}
            gutter={0}
            className="simple-rule-trigger-content"
          >
            <Triggers control={control} setValue={setValue} />
          </StackAsItem>
        </DynamicPanelContainer>
      )}

      {activePanel === 'versionControl' && (
        <DynamicPanelContainer activePanel={activePanel}>
          <VersionControl
            entityInfo={{
              type: ENTITY_ID.rules,
              status,
              name: simpleRuleName ?? '',
              id: simpleRuleId ?? '',
              subType: 'simpleRule',
            }}
            handleEntityUpdate={handleEntityUpdate}
            currentTab={vcListTabIndex}
            modalFormdata={{
              requestReview: approvalInfoRule,
              publishModal: {
                title: approvalInfoRule?.title,
              },
            }}
            updateTabIndex={setVcListTabIndex}
          />
        </DynamicPanelContainer>
      )}

      {activePanel === 'faqMenu' && (
        <DynamicPanelContainer>
          <FAQMenu />
        </DynamicPanelContainer>
      )}

      <SidePanelContainer>
        <Panel
          defaultActivePanel={activePanel}
          onPanelItemClick={setActivePanel}
        />
      </SidePanelContainer>
    </RuleEditorContainer>
  );
};
