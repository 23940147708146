import type { FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';

import type { CreateDataSetFormValues, CreateDataSetResult } from '../types';
import { parseEnvironmentSetting } from '../utils';

const createDataSetQuery = gql`
  mutation createDataSet(
    $connectorId: String!
    $name: String!
    $description: String
    $query: String
    $params: Map
    $stagingSettings: Map
    $productionSettings: Map
  ) {
    createDataSet(
      input: {
        connectorId: $connectorId
        name: $name
        description: $description
        query: $query
        params: $params
        stagingSettings: $stagingSettings
        productionSettings: $productionSettings
      }
    ) {
      id
      name
      description
      query
      params
      checksum
      createdAt
      status
    }
  }
`;

type CreateDataSetVariables = CreateDataSetFormValues & {
  connectorId: string;
};

export type CreateDataSetFunction = (
  formValues: CreateDataSetFormValues
) => Promise<FetchResult<CreateDataSetResult>>;

export type CreateDataSetReturn = {
  loading: boolean;
  createDataSet: CreateDataSetFunction;
};

export function useCreateDataSet(connectorId: string): CreateDataSetReturn {
  const [createDataSetMutator, { loading }] = useMutation<
    CreateDataSetResult,
    CreateDataSetVariables
  >(createDataSetQuery);

  const createDataSet = async ({
    name,
    description,
    query,
    status,
    stagingSettings,
    productionSettings,
    params,
  }: CreateDataSetFormValues) => {
    return await createDataSetMutator({
      variables: {
        connectorId,
        name,
        description,
        query,
        params,
        status,
        stagingSettings: {
          ...parseEnvironmentSetting(stagingSettings),
        },
        productionSettings: {
          ...parseEnvironmentSetting(productionSettings),
        },
      },
    });
  };

  return {
    loading,
    createDataSet,
  };
}
