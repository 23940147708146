import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { Dispatch, useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import {
  Dataset,
  DropdownField,
  EditorLanguages,
  Image,
  NectedSuggestionModel,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import { siteConstantsAtom } from '../../../../../../atom';
import { WebGSheetPickerField } from '../../../../../../components/Form/components/WebGSheetPickerField';
import { IntegrationField } from '../../../../../../components/IntegrationField/IntegrationField';
import { useAxiosPrivate } from '../../../../../../hooks';
import {
  getTooltipText,
  isValidImageURL,
} from '../../../../../../utils/common';
import {
  GSHEET_ACTION_METHODS,
  GSHEET_HEADERS_SELECTION,
  GSHEET_MAPPING_METHODS,
  GSHEET_UPDATING_METHODS,
} from '../../../../../../utils/constant';
import { useGetPublishedConnectors } from '../../../../../DataSets/hooks/useGetPublishedConnectors';
import { PluginDropDownField } from '../../../../../Integrations/components/connector/form/fields/PluginDropdownField';
import { ResultHeader } from '../../../../../Rules/components/DecisionTable/components/ResultHeader';
import { ResultRhs } from '../../../../../Rules/components/RestltRhs/ResultRhs';
import {
  isWorkflowReadOnlyAtom,
  workflowEnvironmentAtom,
} from '../../../../atoms/atoms';
import { LinkStyled, PluginContainer } from './GSheetMapping.styled';

type GSheetMappingProps = {
  tokens: NectedSuggestionModel[];
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  connectorData?: any;
  localData: any;
  updatedDataSet?: Record<string, Dataset>;
  setColumns: Dispatch<any>;
  columns: any[];
};

export function GSheetMapping({
  tokens,
  control,
  setValue,
  watch,
  connectorData,
  localData,
  updatedDataSet = {},
  columns,
  setColumns,
}: GSheetMappingProps) {
  const { data, refetch } = useGetPublishedConnectors(false);
  const [connectorList, setConnectorList] = useState<any>();
  const [, setBodyEditorMode] = useState<EditorLanguages>('text');
  const [results, setResults] = useState<any>([]);

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [workflowEnvironment] = useAtom(workflowEnvironmentAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { axiosPrivate } = useAxiosPrivate();

  const getSheetLists = async () => {
    try {
      const response = await axiosPrivate.post(
        // eslint-disable-next-line
        `/integration/connector/execute?fileId=${selectedFile?.value}`,
        {
          id: selectedConnector?.value,
          environment: 'staging',
          method: 'getSheets',
          params: {
            cr: false,
          },
        },
        {}
      );

      // eslint-disable-next-line
      if (response?.data.code === 'success') {
        setResults(response?.data?.data.result);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'get-schema-error');
      }
    }
  };

  const getSheetColumnLists = async () => {
    try {
      const payload = {
        id: selectedConnector?.value,
        environment: 'staging',
        method: 'getColumns',
        params: {
          cr: false,
          file: selectedFile,
          sheet: selectedSheet,
        },
      };

      const response = await axiosPrivate.post(
        `/integration/connector/execute`,
        payload,
        {}
      );

      if (response?.data.code === 'success') {
        if (hasHeader?.value === 'yes') {
          setColumns(() => {
            return Object.keys(response.data?.data?.result ?? {}).map((k) => {
              return {
                label:
                  response.data?.data?.result[k].headerColumnName ??
                  response.data?.data?.result[k].columnName,
                value:
                  response.data?.data?.result[k].headerColumnName ??
                  response.data?.data?.result[k].columnName,
              };
            });
          });
        } else {
          setColumns(() => {
            return Object.keys(response.data?.data?.result ?? {}).map((k) => {
              return {
                label: k,
                value: k,
              };
            });
          });
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'get-schema-error');
      }
    }
  };

  const plugin = useWatch({
    name: 'plugin',
    control,
  });

  const selectedConnector = useWatch({
    name: 'integration',
    control,
  });

  const selectedSheet = useWatch({
    name: 'sheet',
    control,
  });

  const selectedFile = useWatch({
    name: 'file',
    control,
  });

  const hasHeader = useWatch({
    name: 'hasHeader',
    control,
  });

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${
        selectedConnector?.value as string
      }?stage=staging&connector=${connectorData.plugin.name as string}`
    );
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line
      !_isNil(selectedFile) &&
      // eslint-disable-next-line
      !!selectedFile?.value &&
      // eslint-disable-next-line
      !_isNil(selectedConnector)
    ) {
      void getSheetLists();
    }
  }, [selectedFile, selectedConnector]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!_isNil(selectedSheet) && !_isNil(selectedConnector)) {
      void getSheetColumnLists();
    }
  }, [selectedSheet, hasHeader, selectedConnector]);

  const refreshPlugins = async () => {
    await refetch();
  };

  const formValues: any = watch();

  const contentType = formValues.input?.contentType?.value ?? null;
  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data
        .filter((connector) => connector.plugin.name === 'gsheet')
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      setConnectorList(publishedConnectors);
    }
  }, [data]);

  useEffect(() => {
    const isJsonMode = ['application/json', 'application/javascript'].includes(
      contentType?.value
    );

    setBodyEditorMode(isJsonMode ? 'json' : 'text');
  }, [contentType]);

  const checkIfEntityIsToBeShown = (entity: string) => {
    // eslint-disable-next-line
    const actionMethod: boolean = !!watch('actionMethod');
    // eslint-disable-next-line
    const file: boolean = !!watch('file');
    // eslint-disable-next-line
    const sheet: boolean = !!watch('sheet');
    // eslint-disable-next-line
    const actionIsUpdate: boolean = watch('actionMethod')?.value === 'update';

    if (entity === 'file') {
      return actionMethod;
    } else if (entity === 'sheet') {
      return actionMethod && file;
    } else if (entity === 'hasHeader') {
      return actionMethod && file && sheet;
    } else if (entity === 'columnMatch') {
      return actionMethod && actionIsUpdate && file && sheet;
    } else if (entity === 'policy') {
      return actionMethod && actionIsUpdate && file && sheet;
    } else if (entity === 'mappingMethod') {
      return actionMethod && file && sheet;
    } else if (entity === 'filterList') {
      return actionMethod && file && sheet;
    }

    return false;
  };

  const suggestionsColumn = [
    ...columns.map((column: any) => ({
      meta: 'columnName',
      value: column.label,
      name: column.label,
      score: 999,
    })),
    {
      meta: 'columnName',
      value: 'ROW_NUMBER',
      name: 'ROW_NUMBER',
      score: 999,
    },
  ].filter((c) => {
    if (watch('actionMethod')?.value === 'insert' && c.value === 'ROW_NUMBER') {
      return false;
    }

    return true;
  });

  return (
    <Stack gutter="1.5rem">
      <PluginContainer justify="start" align="center" gap="0.8rem">
        <Image
          src={isValidImageURL(plugin?.imageUrl)}
          alt="minus"
          size="small"
          round
        />
        <Typography> {plugin?.displayName ?? ''}</Typography>
      </PluginContainer>

      <IntegrationField
        control={control}
        editPlugin={editPlugin}
        refreshPlugins={refreshPlugins}
        name="integration"
        connectorList={connectorList}
        pluginId={plugin?.id ?? ''}
        disabled={isWorkflowReadOnly}
        connectorId={selectedConnector?.value}
        required
      />

      {
        // eslint-disable-next-line
        (!!selectedConnector?.value as boolean) && (
          <>
            <Stack gutter={4}>
              <Typography fontWeight={700}>Action</Typography>
              <DropdownField
                name="actionMethod"
                control={control}
                options={GSHEET_ACTION_METHODS}
              />
            </Stack>
            {checkIfEntityIsToBeShown('file') && (
              <WebGSheetPickerField
                control={control}
                name="file"
                disabled={false}
                label="Spreadsheet"
                type="googlePicker"
                tooltipText="Pick a file"
                required
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                setValue={setValue}
                parentFormData={{
                  connectorId: selectedConnector?.value,
                  currentEnvironment: workflowEnvironment,
                }}
              />
            )}

            {checkIfEntityIsToBeShown('sheet') && (
              <PluginDropDownField
                control={control}
                name="sheet"
                disabled={false}
                label="Worksheet"
                type="map"
                required
                onSelect={(nValue, oldValue) => {
                  if (nValue?.value !== oldValue?.value) {
                    setValue('columnMatch', null);
                    setValue('filterList', {});
                  }
                }}
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={results}
              />
            )}

            {checkIfEntityIsToBeShown('hasHeader') && (
              <PluginDropDownField
                control={control}
                name="hasHeader"
                disabled={false}
                label="Does the first row of sheet have headers?"
                onSelect={(nValue, oValue) => {
                  if (nValue?.value !== oValue?.value) {
                    setValue('columnMatch', null);
                    setValue('filterList', {});
                    setValue('policy', null);
                  }
                }}
                type="map"
                tooltipText="First row of sheet has headers or not"
                required
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={GSHEET_HEADERS_SELECTION}
              />
            )}

            {checkIfEntityIsToBeShown('columnMatch') && (
              <PluginDropDownField
                control={control}
                name="columnMatch"
                disabled={false}
                label="Column to match on?"
                type="multi-select"
                isMulti
                required={watch('actionMethod')?.value === 'update'}
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={[
                  ...columns,
                  { label: 'ROW_NUMBER', value: 'ROW_NUMBER' },
                ]}
              />
            )}

            {checkIfEntityIsToBeShown('policy') && (
              <PluginDropDownField
                control={control}
                name="policy"
                disabled={false}
                label="Update All/First Matching"
                type="map"
                required={watch('actionMethod')?.value === 'update'}
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={GSHEET_UPDATING_METHODS}
              />
            )}

            {checkIfEntityIsToBeShown('mappingMethod') && (
              <Stack gutter={4}>
                <PluginDropDownField
                  control={control}
                  name="mappingMethod"
                  disabled
                  label="Mapping Method"
                  type="map"
                  required
                  // eslint-disable-next-line
                  regex={new RegExp('/^[-d]+$/')}
                  options={GSHEET_MAPPING_METHODS}
                />
              </Stack>
            )}

            {checkIfEntityIsToBeShown('mappingMethod') && (
              <Stack gutter={4}>
                <Inline gutter={8}>
                  <Typography fontWeight={700}>Row Values</Typography>
                  <TooltipReact
                    id="action-rest-authentication"
                    placement="bottom-start"
                  >
                    <Typography>
                      Enter a list of values or select a token{' '}
                      <LinkStyled
                        to={getTooltipText(
                          siteConstants,
                          'workflow',
                          'howToAddGSheetTokens',
                          'howToLinks'
                        )}
                        target="_blank"
                      >
                        Know more
                      </LinkStyled>
                    </Typography>
                  </TooltipReact>
                </Inline>

                <ResultRhs
                  nodeName="filterList"
                  dataType="list"
                  typesToAllow={['list']}
                  updatedDataSet={updatedDataSet}
                  control={control}
                  isFull
                  setValue={setValue}
                  header={
                    <ResultHeader
                      dataSet={updatedDataSet}
                      // eslint-disable-next-line
                      isAdd={!watch('filterList')?.value}
                      nodeName={`filterList`}
                      resIndex={0}
                      control={control}
                      setOriginalValue={setValue}
                      disabled={isWorkflowReadOnly}
                      returnTypeName={`filterList.returnType`}
                      executedValueName={`filterList.executedValue`}
                      editorType={'list'}
                      suggestionsObjs={suggestionsColumn}
                    />
                  }
                />

                <Typography name="secondarySm">
                  Keys of this token will be automatically matched with the
                  columns of this sheet, the row will be ignored if not matched
                </Typography>
              </Stack>
            )}
          </>
        )
      }
    </Stack>
  );
}
