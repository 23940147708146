import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const EntityInfoStyled = styled(Inline)`
  background-color: var(--color-whiteSmoke2);
  padding: 0.5rem;
  border-radius: 0.5rem;
  inline-size: 30rem;
  justify-content: space-between;
  align-items: center;
`;

export const EntityNameContainer = styled(Inline)`
  flex: 1;
`;

export const NameStyled = styled(Typography)`
  flex: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const StyledVersionText = styled(Typography)`
  color: var(--color-dodgerBlue);
  cursor: pointer;
  text-decoration: underline;
`;
