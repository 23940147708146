import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { UseControllerProps, useWatch } from 'react-hook-form';
import { Link, Typography } from 'ui';

import { resultByRuleAtom } from '../../RuleSetEditor/RuleSetEditor';
import { TableColumnStyled } from './TableColumns.styled';

type ResultColumnProps = Omit<UseControllerProps, 'name'> & {
  index: number;
};

export function ResultColumn({ control, index }: ResultColumnProps) {
  const [ruleResultById] = useAtom(resultByRuleAtom);
  const id: string = useWatch({
    control,
    name: `ruleList.${index}.ruleId`,
  });

  const ruleURL =
    !_isNil(ruleResultById) &&
    !_isEmpty(ruleResultById) &&
    !_isNil(ruleResultById[id])
      ? `/rules/${id}?type=edit&stage=staging&ruleType=${
          ruleResultById[id].type
        }&wsid=${sessionStorage.getItem('workspaceUUID') as string}`
      : '';

  return (
    <TableColumnStyled width="50%" padding={[6, 10]}>
      {!_isEmpty(ruleURL) ? (
        <Link to={ruleURL} target="_blank">
          <Typography>View Rule</Typography>
        </Link>
      ) : (
        <Typography>Rule Not Found</Typography>
      )}
    </TableColumnStyled>
  );
}
