import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

type ButtonContainerProps = {
  margin: string;
};

export const CreateButtonContainer = styled(Inline)<ButtonContainerProps>`
  border-color: var(--color-whiteSmoke);
  background-color: var(--color-whiteSmoke);
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  border: none;
  width: 100%;
  margin: ${({ margin = '0px' }) => margin};
`;

export const ButtonStyled = styled.button`
  color: var(--color-black);
  font-size: 1.4rem;
  border: none;
  cursor: pointer;

  &.btn-disabled-interceptor {
    color: var(--color-black) !important;
  }
`;
