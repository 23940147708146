import { gql, useMutation } from '@apollo/client';

const CreateSR = gql`
  mutation CreateRule(
    $name: String!
    $type: String!
    $description: String
    $conditions: ConditionsInput
    $staging: ConfDetailInput
    $production: ConfDetailInput
  ) {
    createRule(
      input: {
        name: $name
        type: $type
        description: $description
        conditions: $conditions
        staging: $staging
        production: $production
      }
    ) {
      id
    }
  }
`;

export function useCreateSR() {
  return useMutation(CreateSR);
}
