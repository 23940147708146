import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { memo, useEffect, useState } from 'react';
import { GoGitBranch } from 'react-icons/go';
import { IoPlayOutline } from 'react-icons/io5';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, TooltipReact, Typography, toasts, useLayer } from 'ui';

import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import {
  changedNodeIdsAtom,
  isWorkflowTestOnlyAtom,
  workflowEdgesAtom,
  workflowErrorByNodeAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../../../../atoms/atoms';
import { useTestWorkflowNode } from '../../../../hooks/useTestWorkflowNode';
import {
  checkNodeDisabled,
  getExecutedValueAndStatus,
} from '../../../../utils/common';
import { statusListForDataUpdate } from '../../../../utils/constant';
import { WorkflowNodeSheet } from '../../../Sheets/WorkflowNodeSheet/WorkflowNodeSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { WorkflowAction } from '../WorkflowAction/WorkflowAction';
import {
  DtContainer,
  IconContainer,
  RuleActionsContainer,
  RuleInfoContainer,
} from './NectedWorkflowNode.styled';

export const NectedWorkflowNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const { openWithProps } = useLayer(<WorkflowNodeSheet />);

    const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

    const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
    const [workflowNodes] = useAtom(workflowNodesAtom);
    const [workflowEdges] = useAtom(workflowEdgesAtom);
    const [workflowStatus] = useAtom(workflowStatusAtom);
    const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

    // eslint-disable-next-line
    const [isNodeDisabled, setIsNodeDisabled] = useState(false);

    useEffect(() => {
      const nodeDisabled = checkNodeDisabled(
        data.rootId ?? '',
        id,
        workflowNodes,
        workflowEdges
      );

      setIsNodeDisabled(nodeDisabled.isDisabled);
    }, [JSON.stringify(workflowNodes)]);

    const {
      setIsTesting,
      workflowNode,
      setCurrentTab,
      workflowData,
      workflowError,
      setCurrentStatus,
      testWorkflowData,
    } = useTestWorkflowNode({ localData: data, id, type: 'workflowNode' });

    const { sendEventToGTM } = useSendEventToGTM();

    const Component = ({ data }: any) => (
      <DtContainer
        padding="0.5rem"
        $isError={!(workflowErrorByNode[id] == null)}
      >
        <NodeStatus status={data?.status} />

        <IconContainer
          onClick={() => {
            sendEventToGTM({
              event: 'workflow',
              source: 'listing',
              element: type,
              action: 'node_click',
              type: data?.nodeType,
            });

            if (!isNodeDisabled) {
              openWithProps({
                id,
                data,
              });
            }
          }}
        >
          <GoGitBranch size={32} color={isNodeDisabled ? '#dcdcdc' : '#111'} />
        </IconContainer>

        <PadBox
          padding={{
            top: '6px',
            bottom: '6px',
          }}
        >
          <RuleInfoContainer>
            <Typography name="paragraphXs" fontWeight={700}>
              {data?.name ?? 'Step 1'}
            </Typography>

            <Typography name="paragraphXs">Workflow</Typography>

            {!(workflowErrorByNode[id] == null) && (
              <Typography name="errorXs">
                {workflowErrorByNode[id]?.message}
              </Typography>
            )}
          </RuleInfoContainer>

          {!isNodeDisabled && (
            <RuleActionsContainer>
              <Inline
                align="center"
                style={{
                  background: 'var(--color-lightGray7)',
                  width: '4rem',
                }}
              >
                <IconButton
                  disabled={!isWorkflowTestOnly}
                  onClick={async () => await testWorkflowData()}
                >
                  <IoPlayOutline
                    color={
                      isWorkflowTestOnly
                        ? 'var(--color-black)'
                        : 'var(--color-darkGray)'
                    }
                  />
                </IconButton>

                <WorkflowAction data={data} id={id} />
              </Inline>
            </RuleActionsContainer>
          )}
        </PadBox>
      </DtContainer>
    );

    useEffect(() => {
      if (!_isNil(workflowData)) {
        if (
          !_isNil(workflowNode) &&
          statusListForDataUpdate.includes(workflowStatus)
        ) {
          const newWorkflowNode = workflowNode;
          const entity = getExecutedValueAndStatus(workflowData);
          setCurrentStatus('success');

          newWorkflowNode.data.status = entity.status;

          newWorkflowNode.data.executedValue = entity.executedValue;

          setChangedNodeIds([]);

          setTimeout(() => {
            data.onWorkflowNodeChange(workflowNode);
          }, 100);
        }

        setCurrentStatus('success');
        setCurrentTab(0);
        setCurrentTab(1);
        setIsTesting(false);
        toasts.success('Node tested successfully', 'node-test-success');
      }
    }, [workflowData]);

    useEffect(() => {
      if (!_isNil(workflowError)) {
        setCurrentStatus('error');

        if (!_isNil(workflowNode)) {
          const newWorkflowNode = workflowNode;
          newWorkflowNode.data.status = 'error';
          newWorkflowNode.data.executedValue = null;

          setChangedNodeIds([]);

          setTimeout(() => {
            data.onWorkflowNodeChange(workflowNode);
          }, 100);
        }

        setIsTesting(false);

        toasts.error(workflowError.message, 'node-test-fail');
      }
    }, [workflowError]);

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />

        {isNodeDisabled ? (
          <TooltipReact
            id={id}
            placement="right"
            launcher={
              <span>
                <Component data={data} />
              </span>
            }
          >
            <Typography>
              This branch is disabled because the workflow is running in a loop.
            </Typography>
          </TooltipReact>
        ) : (
          <Component data={data} />
        )}
      </>
    );
  }
);

NectedWorkflowNode.displayName = 'NectedWorkflowNode';
