import { gql, useMutation } from '@apollo/client';

import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updatePolicyMutation = gql`
  mutation updateRule(
    $id: String!
    $policy: String
    $checksum: String!
    $editMode: Boolean
  ) {
    updateRule(
      input: {
        id: $id
        policy: $policy
        checksum: $checksum
        editMode: $editMode
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdatePolicy() {
  return useMutation<RuleUpdateResponseModel>(updatePolicyMutation);
}
