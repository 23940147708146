import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

const containerAppearance = (status: string) => {
  switch (status) {
    case 'upcoming':
      return css`
        border: 1px solid var(--color-aliceBlue);
        background: var(--color-aliceBlue);
      `;
    case 'paused':
      return css`
        border: 1px solid var(--color-cornSilk);
        background: var(--color-cornSilk);
      `;
    case 'failed':
      return css`
        border: 1px solid var(--color-statusRed);
        background: var(--color-statusRed);
      `;
    default:
      return css`
        border: 1px solid var(--color-whiteSmoke);
        background: var(--color-whiteSmoke);
      `;
  }
};

export const Container = styled(Inline)<{ status: string }>`
  padding: 1rem 2rem;

  ${({ status }) => containerAppearance(status)}
`;

export const TextBlueStyled = styled(Typography)`
  color: var(--color-dodgerBlue);
`;

export const TextRedStyled = styled(Typography)`
  color: var(--color-statusTextRed);
`;

export const ApiTriggerContainer = styled(Inline)`
  border: 1px solid var(--color-dodgerBlue);
  background-color: var(--color-aliceBlue);
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const cronContainerAppearance = (status: string) => {
  switch (status) {
    case 'paused':
      return css`
        border: 1px solid var(--color-supernova);
        background: var(--color-supernova);
      `;
    case 'upcoming':
      return css`
        border: 1px solid var(--color-aliceBlue);
        background: var(--color-aliceBlue);
      `;
    case 'failed':
      return css`
        border: none;
        background: none;
      `;
    default:
      return css`
        border: 1px solid var(--color-lightGray5);
        background: var(--color-lightGray5);
      `;
  }
};

export const CronContainer = styled.div<{ status: string }>`
  cursor: pointer;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;

  ${({ status }) => cronContainerAppearance(status)}
`;
