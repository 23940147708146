import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';

import { useAuth } from '../components/authentication';
import { roleJsonAtom } from '../components/authentication/router/AuthProvider';
import { currentWorkspaceDetailAtom } from '../pages/Workspace/atom';
import { updateRoleJson } from '../utils/common';
import {
  FEATURE_FLAG_API_CONFIG,
  defaultRoleJson,
  envMap,
} from '../utils/constant';
import { useAxiosVidhanPrivate } from './useAxiosVidhanPrivate';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export function useFeatureFlag({ email = '', enablePermissionApi = false }) {
  const [roleJson, setRoleJson] = useAtom(roleJsonAtom);

  const { isLoggedIn } = useAuth();
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const [apiConfig, setApiConfig] = useState(FEATURE_FLAG_API_CONFIG);
  const { axiosVidhanPrivate } = useAxiosVidhanPrivate();

  const fetchFeatureFlagJson = async () => {
    let data = [];
    let updatedRoleJson = structuredClone(roleJson);

    if (deploymentType === 'self') {
      const response = await axiosVidhanPrivate.post(
        apiConfig.url,
        apiConfig.payload
      );

      const { additionalOutput, output } = response?.data?.data;

      const entity = apiConfig.payload.params.entity ?? '';

      updatedRoleJson = {
        ...updatedRoleJson,
        flags: _isEmpty(entity)
          ? additionalOutput?.data.flags ?? output[0].data.flags
          : {
              ...output[0].roleJSON.flags,
              [entity]:
                additionalOutput?.data[entity] ?? output[0].data[entity],
            },
        internals: additionalOutput?.data.internals ?? output[0].data.internals,
      };
    } else {
      const { role, approvalFlowEnabled } = apiConfig.payload.params;

      const fallbackJsonUrl = `json/fallback/${
        approvalFlowEnabled ? 'approvalFlowRoles' : 'nonApprovalFlowRoles'
      }/${role}.json`;

      const response = await axiosVidhanPrivate.get(
        `${window.location.origin}${envMap.VITE_ASSETS_URL}${fallbackJsonUrl}`
      );

      data = response.data;
      updatedRoleJson = {
        ...updatedRoleJson,
        flags: data[0]?.data.flags,
        internals: data[0]?.data.internals,
      };
    }

    setRoleJson(updatedRoleJson);
    updateRoleJson(updatedRoleJson);
  };

  useEffect(() => {
    const environment =
      envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';

    setApiConfig({
      ...apiConfig,
      payload: {
        ...apiConfig.payload,
        environment,
      },
    });
  }, []);

  useEffect(() => {
    const { user, role } = apiConfig.payload.params;

    if (!enablePermissionApi && !_isEmpty(email)) {
      setRoleJson(defaultRoleJson);
      updateRoleJson(defaultRoleJson);
    }

    if (
      isLoggedIn &&
      !_isEmpty(user) &&
      !_isEmpty(role) &&
      enablePermissionApi
    ) {
      void fetchFeatureFlagJson();
    }
  }, [isLoggedIn, JSON.stringify(apiConfig), enablePermissionApi, email]);

  useEffect(() => {
    const role = currentWorkspace?.role ?? '';
    const wid = currentWorkspace?.uuid ?? '';
    const approvalFlowEnabled = currentWorkspace?.approvalFlowEnabled ?? false;

    if (!_isEmpty(email) || !_isEmpty(role)) {
      setApiConfig({
        ...apiConfig,
        payload: {
          ...apiConfig.payload,
          params: {
            ...apiConfig.payload.params,
            user: window.btoa(email ?? ''),
            role,
            wid,
            approvalFlowEnabled,
          },
        },
      });
    }
  }, [email, currentWorkspace]);
}
