import { useAtom } from 'jotai';
import { memo } from 'react';
import { BiCopy } from 'react-icons/bi';
import { MdApi } from 'react-icons/md';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, TooltipReact, Typography, useLayer } from 'ui';

import { TabSelectionApiHook } from '../../../../../components/TabSelectionApiHook/TabSelectionApiHook';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import {
  copyToClipboard,
  handleChangeApiToWebhookUrl,
} from '../../../../../utils/common';
import {
  workflowEnvironmentAtom,
  workflowStaticUrlAtom,
} from '../../../atoms/atoms';
import { ApiTriggerSheet } from '../../Sheets/ApiTriggerSheet/ApiTriggerSheet';
import {
  APIContainer,
  ApiWebhookInput,
  IconContainer,
  IconContainerRight,
  TriggerContainer,
  TriggerInfoContainer,
} from './TriggerNode.styled';

export const ApiTrigger = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const { openWithProps } = useLayer(<ApiTriggerSheet />);
    const [staticUrl] = useAtom(workflowStaticUrlAtom);
    const [workflowEnvironment] = useAtom(workflowEnvironmentAtom);
    const { sendEventToGTM } = useSendEventToGTM();

    const handleTriggerClick = () => {
      sendEventToGTM({
        event: 'workflow',
        source: 'listing',
        element: type,
        action: 'node_click',
        type: data.type,
      });

      openWithProps({
        data,
        id,
      });
    };

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <TriggerContainer padding="0.5rem" onClick={handleTriggerClick}>
          <TooltipReact
            launcher={
              <IconContainer>
                <MdApi size={32} />
              </IconContainer>
            }
            id={(data.name as string) + '_trigger'}
          >
            Click to add details to your trigger
          </TooltipReact>

          <TriggerInfoContainer
            padding={{
              top: '6px',
              bottom: '6px',
            }}
          >
            <Typography name="paragraphXs" fontWeight={700}>
              {data.name ?? 'Step 1'}
            </Typography>

            <TabSelectionApiHook>
              <APIContainer>
                <Typography name="paragraphXs">API</Typography>
                <ApiWebhookInput disabled value={staticUrl} />
                <IconContainerRight>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      void copyToClipboard(staticUrl, 'API URL copied');
                    }}
                  >
                    <BiCopy fontSize={14} />
                  </IconButton>
                </IconContainerRight>
              </APIContainer>

              <APIContainer>
                <Typography name="paragraphXs">Webhook</Typography>
                <ApiWebhookInput
                  disabled
                  value={handleChangeApiToWebhookUrl(
                    staticUrl,
                    workflowEnvironment
                  )}
                />

                <IconContainerRight>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      void copyToClipboard(
                        handleChangeApiToWebhookUrl(
                          staticUrl,
                          workflowEnvironment
                        ),
                        'Webhook URL copied'
                      );
                    }}
                  >
                    <BiCopy fontSize={14} />
                  </IconButton>
                </IconContainerRight>
              </APIContainer>
            </TabSelectionApiHook>
          </TriggerInfoContainer>
        </TriggerContainer>
      </>
    );
  }
);

ApiTrigger.displayName = 'ApiTrigger';
