import { ENTITY_ID } from '../../../utils/constant';

type GetChecksumMetaDataArgsType = {
  entityId: string;
  entityType: string;
  entityName: string;
  entitySubType?: string;
};

export const getChecksumMetaData = ({
  entityId,
  entityType,
  entityName,
  entitySubType,
}: GetChecksumMetaDataArgsType) => {
  switch (entityType) {
    case ENTITY_ID.rules:
      return {
        ruleId: entityId,
        ruleName: entityName,
        type: entitySubType,
      };
    case ENTITY_ID.workflow: {
      return {
        workflowId: entityId,
        workflowName: entityName,
      };
    }
    case ENTITY_ID.datasets: {
      return {
        datasetId: entityId,
        datasetName: entityName,
      };
    }
    default:
      return {};
  }
};

export const getEntityTypeForChecksum = (type: string) => {
  switch (type) {
    case 'rules':
      return 'rule';
    default:
      return type;
  }
};

export const getEntityTypeForApi = (type: string) => {
  switch (type) {
    case 'rules':
      return 'rule';
    case 'datasets':
      return 'dataSet';
    default:
      return type;
  }
};
