import { Inline } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { UseControllerProps, useFieldArray, useWatch } from 'react-hook-form';
import { Typography, toasts } from 'ui';

import { generateRandomHex } from '../../../../../utils/common';
import { isRuleReadOnlyAtom, ruleLimitsConfigAtom } from '../../../index';
import { AddRuleMenu } from '../AddRuleMenu/AddRuleMenu';
import { customAttributesByIdAtom } from '../RuleSetEditor/RuleSetEditor';
import type { CustomAttributeByRuleId, RuleSetNodesModel } from '../models';
import { RuleSetRow } from './RuleSetRow';
import {
  TableContainerStyled,
  TableFooterContainerStyled,
  TableHeaderContainerStyled,
  TableHeaderStyled,
  TableRowContainerStyled,
} from './RuleSetTable.styled';

export type RuleSetTableProps = Omit<UseControllerProps, 'name'>;

const headers = [
  {
    title: 'Rules',
    width: '30%',
  },
  {
    title: 'Result',
    width: '50%',
  },
  {
    title: '',
    width: '20%',
  },
];

export const customAttributesInFieldsAtom = atom<
  Record<string, CustomAttributeByRuleId[]>
>({});

export function RuleSetTable({ control }: RuleSetTableProps) {
  const [, setCustomAttributesInFields] = useAtom(customAttributesInFieldsAtom);
  const [customAttributesById] = useAtom(customAttributesByIdAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [limitConfig] = useAtom(ruleLimitsConfigAtom);

  const { fields, append, insert, remove } = useFieldArray({
    name: 'ruleList',
    control,
  });

  const rules: RuleSetNodesModel[] = useWatch({
    control,
    name: `ruleList`,
  });

  const handleInsertRow = (index: number) => {
    insert(index, {
      isEnabled: true,
      ruleId: `00000000${generateRandomHex(16)}`,
    });
  };

  useEffect(() => {
    setCustomAttributesInFields(
      rules.reduce<Record<string, CustomAttributeByRuleId[]>>(
        (attributeObject, field) => {
          if (!_isNil(customAttributesById[field.ruleId]) && field.isEnabled) {
            return {
              ...attributeObject,
              [field.ruleId]: customAttributesById[field.ruleId],
            };
          }

          return attributeObject;
        },
        {}
      )
    );
  }, [rules, customAttributesById]);

  const appendElements = (ruleId: string) => {
    if (!_isEmpty(limitConfig) && fields.length < limitConfig.maxSrRs.value) {
      append({
        isEnabled: true,
        ruleId,
      });
    } else if (!_isEmpty(limitConfig.maxSrRs.message)) {
      toasts.info(limitConfig.maxSrRs.message, 'info');
    }
  };

  return (
    <TableContainerStyled gutter={0}>
      <TableHeaderContainerStyled as={Inline} gutter={0}>
        {headers.map((header, index) => (
          <TableHeaderStyled
            width={header.width}
            key={`rule_${index}`}
            padding={[5, 10]}
          >
            <Typography>{header.title}</Typography>
          </TableHeaderStyled>
        ))}
      </TableHeaderContainerStyled>

      <TableRowContainerStyled>
        {fields.map((rule, index) => (
          <RuleSetRow
            handleInsertRow={handleInsertRow}
            handleDeleteRow={(index: number) => remove(index)}
            control={control}
            key={index}
            index={index}
          />
        ))}
      </TableRowContainerStyled>

      <TableFooterContainerStyled padding={[5, 10]}>
        {!isRuleReadOnly && (
          <AddRuleMenu control={control} onClick={appendElements} />
        )}
      </TableFooterContainerStyled>
    </TableContainerStyled>
  );
}
