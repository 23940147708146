import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { CheckboxField, TextField, TextInput } from 'ui';

import {
  dataSetFieldsByIdAtom,
  selectedDataSetAtom,
} from '../../../../pages/Rules';
import { JsonNodePill } from '../../../../pages/Rules/components/SimpleRule/Results/JsonNodePill';
import type { SelectedType } from '../../../../pages/Rules/types';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  formatNectedDate,
  isFieldReadOnly,
} from '../../../../utils/common';
import { CalenderIconPicker } from '../../../CalenderIconPicker/CalenderIconPicker';
import type { TokensSetProps } from './CustomAttributeSheet';

type SampleValueFieldProps = UseControllerProps<any> & {
  index: number;
  setValue: UseFormSetValue<any>;
  isDisabled: boolean;
  tokensSet?: TokensSetProps[];
  from?: 'workflow' | 'rule';
};

export function SampleValueField({
  index,
  control,
  setValue,
  isDisabled,
  name,
  tokensSet,
  from = 'rule',
}: SampleValueFieldProps) {
  const [DSSelectedPropery, setDSSelectedPropery] = useState<
    string | boolean | number | Record<string, any>
  >('string');
  const [dataSetFieldsById] = useAtom(dataSetFieldsByIdAtom);
  const [selectedDataSets] = useAtom(selectedDataSetAtom);
  const propertyName = `attributes.${index}.attribute`;
  const sourceTypeName = `attributes.${index}.sourceType`;
  const datasetProperty = useWatch({ control, name: propertyName });
  const sourceType = useWatch({ control, name: sourceTypeName });
  const selectedType: SelectedType | null = useWatch({
    name: `attributes.${index}.selectedType`,
    control,
  });

  const sampleValue = useWatch({
    control,
    name: `attributes.${index}.sampleValue`,
  });

  const dataSetOptions =
    !_isNil(selectedDataSets[0]) &&
    !_isNil(dataSetFieldsById[selectedDataSets[0]])
      ? dataSetFieldsById[selectedDataSets[0]].fields.filter(
          (field) => field.type !== 'json'
        )
      : [];

  useEffect(() => {
    if (
      !_isNil(datasetProperty) &&
      !_isEmpty(datasetProperty) &&
      sourceType === 'dataSet'
    ) {
      const filteredObj = dataSetOptions.find(
        (option) => option.name === datasetProperty
      );

      if (!_isNil(filteredObj)) {
        setDSSelectedPropery(filteredObj.type);
      }
    }
  }, [datasetProperty, sourceType]);

  useEffect(() => {
    if (from === 'rule') {
      setValue(`attributes.${index}.executedValue`, sampleValue);
    }
  }, [sampleValue, from]);

  if (_isNil(selectedType)) {
    return <TextInput placeholder="NA" disabled />;
  }

  if (selectedType.value === 'boolean' || DSSelectedPropery === 'boolean') {
    return (
      <CheckboxField
        name={name}
        useId={name}
        control={control}
        disabled={isDisabled}
        appearance="switch"
      />
    );
  }

  if (selectedType.value === 'numeric' || DSSelectedPropery === 'numeric') {
    return (
      <TextField
        name={name}
        control={control}
        placeholder="Enter Value"
        readOnly={isDisabled}
        type="number"
        showErrorIcon={false}
      />
    );
  }

  if (selectedType.value === 'json' || selectedType.value === 'list') {
    return (
      <JsonNodePill
        control={control}
        name={name}
        setOriginalValue={setValue}
        hideSuggestions
        size={'medium'}
        isJsonInCustomInput={true}
        type={selectedType.value}
        disabled={isDisabled}
      />
    );
  }

  if (selectedType.key === 'restAPI') {
    return null;
  }

  const getPlaceholder = (dataType: string) => {
    const placeholderString = 'Enter Value';

    if (dataType === 'date') {
      return DATE_FORMAT;
    }

    if (dataType === 'dateTime') {
      return DATE_TIME_FORMAT;
    }

    return placeholderString;
  };

  return (
    <TextField
      name={name}
      control={control}
      placeholder={getPlaceholder(selectedType.value ?? '')}
      readOnly={isDisabled || isFieldReadOnly(selectedType.value ?? '')}
      showExpandIcon={false}
      icon={
        ['dateTime', 'date'].includes(selectedType.value ?? '') ? (
          <CalenderIconPicker
            value={sampleValue}
            disabled={isDisabled}
            dataType={selectedType.value ?? 'date'}
            onPick={(val) => {
              if (typeof setValue === 'function') {
                setValue(
                  `attributes.${index}.sampleValue`,
                  formatNectedDate(val, selectedType.value ?? 'date')
                );

                setValue(
                  `attributes.${index}.executedValue`,
                  formatNectedDate(val, selectedType.value ?? 'date')
                );
              }
            }}
          />
        ) : undefined
      }
    />
  );
}
