import type { FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';

import type { CreateDataSetFormValues, UpdateDataSetResult } from '../types';
import { parseEnvironmentSetting } from '../utils';

const updateDataSetQuery = gql`
  mutation updateDataSet(
    $dataSetId: String!
    $name: String!
    $query: String
    $params: Map
    $stagingSettings: Map
    $productionSettings: Map
    $checksum: String!
    $editMode: Boolean
  ) {
    updateDataSet(
      input: {
        id: $dataSetId
        name: $name
        query: $query
        params: $params
        stagingSettings: $stagingSettings
        productionSettings: $productionSettings
        checksum: $checksum
        editMode: $editMode
      }
    ) {
      id
      name
      query
      params
      status
      checksum
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

type UpdateDataSetVariables = Omit<
  CreateDataSetFormValues,
  'integration' | 'editable' | 'status'
> & {
  dataSetId: string;
  checksum: string;
};

export type UpdateDataSetFunction = (
  formValues: CreateDataSetFormValues,
  dataSetId: string,
  checksum: string
) => Promise<FetchResult<UpdateDataSetResult>>;

export type UpdateDataSetReturn = {
  loading: boolean;
  updateDataSet: UpdateDataSetFunction;
};

export function useUpdateDataSet(): UpdateDataSetReturn {
  const [updateDataSetMutator, { loading }] = useMutation<
    UpdateDataSetResult,
    UpdateDataSetVariables
  >(updateDataSetQuery);

  const updateDataSet = async (
    {
      name,
      query,
      params,
      stagingSettings,
      productionSettings,
      integration,
      editMode,
    }: CreateDataSetFormValues,
    dataSetId: string,
    checksum: string
  ) => {
    return await updateDataSetMutator({
      variables: {
        dataSetId,
        name,
        query,
        params,
        checksum: checksum ?? '',
        stagingSettings: {
          ...parseEnvironmentSetting(stagingSettings),
        },
        productionSettings: {
          ...parseEnvironmentSetting(productionSettings),
        },
        editMode,
      },
    });
  };

  return {
    loading,
    updateDataSet,
  };
}
