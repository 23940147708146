import { gql, useMutation } from '@apollo/client';

const updateWorkflowSettingsMutation = gql`
  mutation UpdateWorkflow(
    $id: String!
    $checksum: String!
    $editMode: Boolean
    $staging: SettingsInput
    $production: SettingsInput
    $isEnabled: Boolean
  ) {
    updateWorkflow(
      input: {
        id: $id
        checksum: $checksum
        editMode: $editMode
        staging: $staging
        production: $production
        isEnabled: $isEnabled
      }
    ) {
      id
      name
      checksum
      status
      staticUrl
      isLive
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
    }
  }
`;

export function useUpdateSettings() {
  return useMutation(updateWorkflowSettingsMutation);
}
