import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { CSSProperties } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Button } from 'ui';

import { userProfileAtom } from '../../atom';
import { envMap } from '../../utils/constant';
import { BannerContainer, CenteredText } from './banner.styled';

type BannerComponentProps = {
  buttonProps: {
    buttonUrl?: string;
    buttonText: string;
    buttonTarget?: string;
  };
  textButtonProps?: {
    buttonUrl?: string;
    buttonText: string;
    buttonTarget?: string;
  };
  title: string;
  subTitle: string;
  bannerImage: string;
  entity?: string;
  openTemplate?: () => void;
  imageStyles?: CSSProperties;
};

export function BannerBox({
  buttonProps,
  textButtonProps,
  title,
  subTitle,
  bannerImage,
  entity = '',
  openTemplate,
  imageStyles,
}: BannerComponentProps) {
  const [userProfile] = useAtom(userProfileAtom);

  const handleButtonClick = () => {
    if (!_isNil(userProfile) && !_isEmpty(buttonProps.buttonUrl)) {
      const querystring = `?user_id=${
        window.localStorage.getItem('userUUID') as string
      }&email=${userProfile.email}&name=${userProfile.firstName} ${
        userProfile.lastName
      }&referrer_url=${window.location.href}&referrer_action=${title}`;
      window.open(
        `${buttonProps.buttonUrl as string}${querystring}`,
        buttonProps.buttonTarget
      );
    }
  };

  const onTextButtonClick = () => {
    if (!_isNil(userProfile) && !_isEmpty(textButtonProps?.buttonUrl)) {
      window.open(textButtonProps?.buttonUrl, '_blank');
    }
  };

  return (
    <BannerContainer gutter="size7">
      <img
        style={imageStyles}
        src={`${envMap.VITE_ASSETS_URL}website/icons/${bannerImage}`}
        alt={title}
      />
      <CenteredText name="heading3">{subTitle}</CenteredText>
      {!_isEmpty(buttonProps.buttonText) && (
        <Button onClick={handleButtonClick}>{buttonProps.buttonText}</Button>
      )}
      {!_isEmpty(textButtonProps?.buttonText) && (
        <Button onClick={onTextButtonClick} appearance="text">
          {textButtonProps?.buttonText}
        </Button>
      )}
      {entity === 'rules' && !_isNil(openTemplate) && (
        <Inline justify="center">
          <Button onClick={openTemplate}>
            <BiPlus size={20} />
            Create Rule
          </Button>
        </Inline>
      )}
    </BannerContainer>
  );
}
