import { Control, UseFormSetValue } from 'react-hook-form';
import { Dataset, TextInput } from 'ui';

import { ExcelPill } from './ExcelPill/ExcelPill';

type ExcelLikeFormulaProps = {
  dataSet?: Record<string, Dataset>;
  control?: Control<any>;
  disabled?: boolean;
  setValue: UseFormSetValue<any>;
  name: string;
  value: string;
};

export function ExcelLikeFormula({
  value,
  disabled,
  ...rest
}: ExcelLikeFormulaProps) {
  return (
    <TextInput
      readOnly
      placeholder="Enter values"
      value={value}
      icon={<ExcelPill isReadOnly={disabled} {...rest} />}
    />
  );
}
