import _isEmpty from 'lodash/isEmpty';
import { AiOutlineClose } from 'react-icons/ai';

import {
  AppliedFilterBox,
  AppliedFilterContainer,
  AppliedFilterLayout,
} from '../listing.styled';
import { AllFiltersProps, AppliedFiltersProps } from '../types';

export const renderAppliedFilters = (
  allFilters: AllFiltersProps = [],
  appliedFilters: AppliedFiltersProps,
  removeFilter: (filter: string, value: string) => void
) => {
  return (
    <AppliedFilterLayout>
      {allFilters
        .filter((i: any) => i.type === 'dropdown')
        .map((filterObj: any, index: number) => {
          const filter = filterObj.keyName;

          return (
            <>
              {!_isEmpty(appliedFilters[filter]) &&
                Object.keys(appliedFilters[filter]).map(
                  (key: string, subIndex: number) => {
                    const filterValueObj = filterObj.values.filter(
                      (valueObj: any) => valueObj.value === key
                    )[0];

                    return (
                      <AppliedFilterContainer
                        key={`${subIndex}_${index}`}
                        onClick={() =>
                          removeFilter(filterObj.keyName, filterValueObj.value)
                        }
                      >
                        <AppliedFilterBox>
                          {filterValueObj.label}
                          <AiOutlineClose />
                        </AppliedFilterBox>
                      </AppliedFilterContainer>
                    );
                  }
                )}
            </>
          );
        })}
    </AppliedFilterLayout>
  );
};
