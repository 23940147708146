import { gql, useLazyQuery } from '@apollo/client';

const getDataSetsQuery = gql`
  query getDataSet($dataSetId: String!) {
    getDataSet(id: $dataSetId) {
      data {
        id
        name
        fields
        version

        connector {
          id
          name
          plugin {
            imageUrl
            name
          }
        }
      }
    }
  }
`;

export function useGetDataSetById() {
  return useLazyQuery(getDataSetsQuery);
}
