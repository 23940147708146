import _isNil from 'lodash/isNil';
import { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  Toast,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { EntityDependencyView } from '../../EntityDependencyView/EntityDependencyView';
import type { FromType } from '../ImportEntityModal/ImportEntityModal';
import { Container } from '../ImportEntityModal/ImportEntityModal.styled';
import { useExportEntity } from '../hooks/restApi/useExportEntity';

type ExportEntityModalProps = {
  entityType: string;
  entityId?: string;
  from?: FromType;
  isLive?: boolean;
};

export function ExportEntityModal({
  entityId = '',
  entityType,
  from = 'export_sheet',
  isLive,
}: ExportEntityModalProps) {
  const { handleExportEntity, isLoading } = useExportEntity();

  const { close: closeModal } = useCurrentLayer();

  const { control, setValue } = useForm<any>({
    defaultValues: {
      dependencyJson: {},
    },
  });

  const dependencyJson = useWatch({
    control,
    name: 'dependencyJson',
  });

  const title = useMemo(() => {
    let modalName = `Export ${
      entityType[0].toUpperCase() + entityType.substring(1)
    }`;

    if (!_isNil(dependencyJson) && !_isNil(dependencyJson.ref)) {
      modalName = `Exporting ${
        dependencyJson.ref[dependencyJson.rootId].name as string
      }`;
    }

    return <Typography name="heading2">{modalName}</Typography>;
  }, [JSON.stringify(dependencyJson)]);

  useEffect(() => {
    void getExportSummary();
  }, []);

  const getExportSummary = async () => {
    try {
      const response = await handleExportEntity({
        entityId,
        entityType,
        preview: true,
        isLive,
      });

      if (!_isNil(response?.data?.data)) {
        const { rootId, ref } = response?.data.data;
        setValue('dependencyJson', {
          rootId,
          ref,
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'export-summary');
      }
    }
  };

  const handleExportBtnClick = async () => {
    try {
      await handleExportEntity({
        entityId,
        entityType,
        name: dependencyJson?.ref?.[dependencyJson?.rootId]?.name,
      });
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'export-entity-error');
      }
    }

    closeModal();
  };

  return (
    <Modal size="extraLarge" title={title} overflow="none">
      <ModalContent>
        <Container gutter="1rem">
          <Typography>{`While exporting the ${entityType}, all the associated dependencies will also be exported with it`}</Typography>

          {isLoading && _isNil(dependencyJson) ? (
            <Spinner />
          ) : (
            <EntityDependencyView
              rootId={dependencyJson.rootId}
              data={dependencyJson.ref}
              from={from}
              setValue={setValue}
              control={control}
            />
          )}
        </Container>
        <Toast
          type="warning"
          message="Credentials are not exported when the rule/ workflow is exported"
        />
      </ModalContent>
      <ModalFooter>
        <Button type="submit" onClick={handleExportBtnClick}>
          Export
        </Button>
      </ModalFooter>
    </Modal>
  );
}
