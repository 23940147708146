import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useController,
  useWatch,
} from 'react-hook-form';
import { FiRefreshCw } from 'react-icons/fi';
import {
  Dataset,
  PopoverMethods,
  PopoverPanel,
  TreeViewerField,
  useLayer,
} from 'ui';

import { usedConnectorMappingAtom } from '../../../../atom';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { useUpdateMappedConnectorsData } from '../../../../hooks/useUpdateMappedConnectorsData';
import { useGetPublishedConnectors } from '../../../../pages/DataSets/hooks/useGetPublishedConnectors';
import { useGetConnectors } from '../../../../pages/Integrations/components/hooks/useGetConnectors';
import { PublishedConnectors } from '../../../../pages/Rules/components/DecisionTable/types';
import { useOpenJsonEditorSheet } from '../../../../pages/Rules/hooks/useOpenJsonEditor';
import { AttributeModel } from '../../../../pages/Rules/models';
import type { FieldsByID, SelectedType } from '../../../../pages/Rules/types';
import { filterRestAPIConnectorsFromPublishedMap } from '../../../../pages/Rules/utils/common';
import {
  generateUid,
  getCustomAttributeDataTypeLabel,
  isValidImageURL,
  transformCustomInputs,
} from '../../../../utils/common';
import { publishedConnectorsAtom } from '../../../CreateAction/CreateAction';
import { CreateButton } from '../../../CreateButton/CreateButton';
import type { TokensSetProps } from './CustomAttributeSheet';
import { RefreshStyled } from './DataSourceAttribute/DataSourceLauncher.styled';
import { DataTypeSelectionLauncher } from './DataTypeSelectionLauncher';
import { RestInCISheet } from './RestInCISheet';
import { defaultDataTypes } from './fixtures/primitiveDataTypes';

type DataTypeSelectionProps = UseControllerProps<any> & {
  index: number;
  setValue: UseFormSetValue<any>;
  isDisabled: boolean;
  setTabIndex?: (index: number) => void;
  tokensSet?: TokensSetProps[];
  hideDataSet?: boolean;
  hideRestApi?: boolean;
  hideInputAttr?: boolean;
  customAttrArray?: AttributeModel[];
  localDataSetFieldsById?: FieldsByID;
  updatedDataset?: Record<string, Dataset>;
  showRefresh?: boolean;
  from?: 'workflow' | 'rule';
};

export function DataTypeSelection({
  control,
  index,
  setValue,
  name,
  isDisabled,
  tokensSet,
  setTabIndex,
  hideDataSet = false,
  hideRestApi = false,
  hideInputAttr = false,
  customAttrArray = [],
  localDataSetFieldsById = {},
  updatedDataset = {},
  showRefresh = false,
  from = 'rule',
}: DataTypeSelectionProps) {
  const [typesToSelectFrom, setTypesToSelectFrom] = useState<
    Record<string, Dataset>
  >(hideInputAttr ? {} : defaultDataTypes);

  const { data, refetch } = useGetPublishedConnectors(false);

  const { openWithProps: openRestInCISheet } = useLayer(
    <RestInCISheet tokensSet={tokensSet} updatedDataset={updatedDataset} />
  );

  const { sendEventToGTM } = useSendEventToGTM();
  const [getConnectorList, { data: allConnectors }] = useGetConnectors();
  const [publishConnectors, setPublishConnectors] = useAtom(
    publishedConnectorsAtom
  );
  const [usedConnectorMapping, setUsedConnectorMapping] = useAtom(
    usedConnectorMappingAtom
  );

  const ref = useRef<PopoverMethods>(null);
  const [updatedLocalCI, setUpdatedLocalCI] = useState<Record<string, any>>([]);

  const { fetchMappedConnectorsData } = useUpdateMappedConnectorsData();

  const selectedType: SelectedType | null = useWatch({
    name: `attributes.${index}.selectedType`,
    control,
  });

  const selectedDataSets = useWatch({
    name: 'selectedDataSets',
    control,
  });

  const dataTypeLabel = useWatch({
    name: `attributes.${index}.dataType.label`,
    control,
  });

  useEffect(() => {
    if (
      !_isNil(selectedDataSets) &&
      !_isNil(localDataSetFieldsById) &&
      !_isNil(localDataSetFieldsById[selectedDataSets[0]]) &&
      !hideDataSet
    ) {
      setTypesToSelectFrom((prev) => ({
        ...prev,
        dataSet: {
          name: 'Map with Data Source',
          id: generateUid('dataSet_'),
          attributes: {
            [localDataSetFieldsById[selectedDataSets[0]].name]: {
              name: localDataSetFieldsById[selectedDataSets[0]].name,
              dataType: 'dataSet',
            },
          },
        },
      }));
    }
  }, [localDataSetFieldsById, selectedDataSets]);

  useEffect(() => {
    if (
      !_isNil(publishConnectors) &&
      !_isEmpty(publishConnectors) &&
      !hideRestApi
    ) {
      setTypesToSelectFrom((prev) => ({
        ...prev,
        restAPI: {
          name: 'Rest API Connectors',
          id: publishConnectors[Object.keys(publishConnectors)[0]]?.plugin.id,
          attributes:
            filterRestAPIConnectorsFromPublishedMap(publishConnectors),
        },
      }));
    }

    void getConnectorList();
  }, [publishConnectors]);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data ?? [];
      const currentData: Record<string, Dataset> = {};
      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;

          const name = connector.plugin.name;

          if (!_isNil(currentData[name])) {
            currentData[connector.plugin.name] = {
              ...currentData[connector.plugin.name],
              attributes: {
                ...currentData[connector.plugin.name].attributes,
                [connector.id]: {
                  name: connector.name,
                  dataType: '',
                },
              },
            };
          } else {
            currentData[connector.plugin.name] = {
              name: connector.plugin.name,
              id: generateUid('connector_'),
              image: isValidImageURL(connector.plugin.imageUrl),
              attributes: {
                [connector.id]: {
                  name: connector.name,
                  dataType: '',
                },
              },
            };
          }
        });
      }
      setPublishConnectors(publishConnectorIdAndPlugin);
    }
  }, [data]);

  const label = getCustomAttributeDataTypeLabel(
    selectedType,
    selectedDataSets,
    localDataSetFieldsById,
    publishConnectors,
    dataTypeLabel
  );

  const { openWithProps: openJsonEditor } = useOpenJsonEditorSheet({
    name:
      from === 'rule'
        ? `attributes.${index}.sampleValue`
        : `attributes.${index}.executedValue`,
    control,
    index,
    section: 'thenDataParams',
    type: 'json',
  });

  const { fieldState } = useController({
    name: `attributes.${index}.selectedType`,
    control,
  });

  useEffect(() => {
    if (!_isNil(customAttrArray) && customAttrArray.length > 0) {
      const filteredAttributesData: AttributeModel[] = [];

      for (const attr of customAttrArray) {
        if (attr.dataType?.value !== 'restAPI') {
          filteredAttributesData.push(attr);
        }
      }

      setUpdatedLocalCI(
        transformCustomInputs({
          attributes: filteredAttributesData,
        })
      );
    }
  }, [JSON.stringify(customAttrArray)]);

  const handleCreateIntegration = (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    sendEventToGTM({
      event: 'custom-attribute-sheet',
      action: 'create_integration',
      element: 'restAPI-attributes-tab',
    });
    const restApi = allConnectors?.databasePlugins.data.find(
      (p) => p.name === 'restAPI'
    );

    window.open(`${window.location.origin}/integrations/${restApi?.id ?? ''}`);
  };

  return (
    <Inline>
      <PopoverPanel
        launcher={
          <DataTypeSelectionLauncher
            label={label}
            error={fieldState.error?.message}
            isDisabled={isDisabled}
            setTabIndex={setTabIndex}
            dataType={selectedType?.key ?? ''}
          />
        }
        trigger="click"
        ref={ref}
        disabled={isDisabled}
      >
        <TreeViewerField
          name={name}
          control={control}
          dataset={typesToSelectFrom}
          showSearchField
          searchPlaceholder="Search..."
          onClick={({ key, value, dataType }) => {
            setValue(`attributes.${index}.executedValue`, '');
            setValue(`attributes.${index}.sampleValue`, '');
            setValue(`attributes.${index}.config`, {});

            if (key === 'primitive') {
              setValue(`attributes.${index}.attribute`, '');
              setValue(`attributes.${index}.sourceType`, '');
              setValue(`attributes.${index}.dataType`, {
                label:
                  value.charAt(0).toUpperCase() + value.slice(1, value.length),
                value,
              });

              if (dataType === 'json' || dataType === 'list') {
                setValue(`attributes.${index}.isNullable`, false);
                setValue(`attributes.${index}.isCaseSensitive`, false);
                setValue(`attributes.${index}.isOptional`, false);
                openJsonEditor({
                  hideSuggestions: true,
                  setOriginalValue: setValue,
                  size: 'medium',
                  isJsonInCustomInput: true,
                  type: dataType,
                  disabled: isDisabled,
                });
              }
            } else if (
              key !== selectedType?.dataType ||
              value !== selectedType?.value
            ) {
              if (key === 'dataSet') {
                setValue(`attributes.${index}.sourceType`, 'dataSet');
                setValue(`attributes.${index}.dataType`, {
                  label: '',
                  value: '',
                });
              } else if (key === 'restAPI') {
                setValue(`attributes.${index}.sourceType`, 'restAPI');
                setValue(`attributes.${index}.executedValue`, '{}');
                setValue(`attributes.${index}.sampleValue`, '{}');
                setValue(`attributes.${index}.dataType`, {
                  label: 'restAPI',
                  value: 'restAPI',
                });

                const connectorStatus =
                  (publishConnectors[value].staging?.isTested ?? true) &&
                  (publishConnectors[value].staging?.isPublish ?? true);

                setUsedConnectorMapping({
                  ...usedConnectorMapping,
                  [value]: connectorStatus,
                });

                openRestInCISheet({
                  connectorId: value,
                  control,
                  index,
                  setOriginalValue: setValue,
                  updatedLocalCI,
                  updatedDataset,
                  publishConnectorList: data,
                  refetchPublishedConnectorList: refetch,
                });
              }
            }
            ref.current?.hide();
          }}
        />

        {!hideRestApi && (
          <PadBox padding={['0.8rem', 0, 0, 0]}>
            <Inline justify="center" align="center" gutter={8}>
              <CreateButton
                buttonLabel="Create Rest API"
                onClick={handleCreateIntegration}
              />
            </Inline>
          </PadBox>
        )}
      </PopoverPanel>

      {showRefresh && (
        <RefreshStyled
          onClick={async () => {
            await refetch();
            void fetchMappedConnectorsData();
          }}
        >
          <FiRefreshCw />
        </RefreshStyled>
      )}
    </Inline>
  );
}
