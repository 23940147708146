import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const Form = styled.form`
  block-size: 100vh;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const WorkflowEditorContainer = styled(Inline)`
  block-size: calc(100vh - 8.6rem);
`;
