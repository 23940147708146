import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import {
  allWorkspaceAtom,
  isFetchedUserStateAtom,
  siteConstantsAtom,
  subscriptionPlanAtom,
  userProfileAtom,
} from '../../../atom';
import { useAxiosPrivate } from '../../../hooks';
import { currentWorkspaceDetailAtom } from '../../../pages/Workspace/atom';
import { showErrorToast } from '../../../utils/common';
import type { CustomAxiosError } from '../../../utils/response/types';
import { useAuth } from '../../authentication';
import { roleJsonAtom } from '../../authentication/router/AuthProvider';

export function useLogout(closeModal: () => void) {
  const { axiosPrivate } = useAxiosPrivate();
  const [, setRoleJson] = useAtom(roleJsonAtom);

  const { setAuth } = useAuth();

  const [, setUserProfile] = useAtom(userProfileAtom);
  const [, setCurrWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [, setAllWorkspaceAtom] = useAtom(allWorkspaceAtom);
  const [, setIsFetchedUserStateData] = useAtom(isFetchedUserStateAtom);
  const [, setSiteConstants] = useAtom(siteConstantsAtom);
  const [, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);
  const navigate = useNavigate();

  const { mutate: logout, isLoading } = useMutation({
    mutationFn: async () => {
      return await axiosPrivate.post('/auth/logout');
    },
    onSuccess() {
      setAuth({ refreshToken: '', accessToken: '' });
      setUserProfile(null);
      setRoleJson({});
      setSiteConstants(null);

      setCurrWorkspace(null);
      setAllWorkspaceAtom(null);
      setSubscriptionPlan(null);
      setIsFetchedUserStateData(false);

      window.localStorage.clear();
      window.sessionStorage.clear();
      Cookies.remove('cc_cookie');
      closeModal();
      navigate('/signin');
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return { logout, isLoading };
}
