import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { memo, useEffect } from 'react';
import { FiDatabase } from 'react-icons/fi';
import { IoPlayOutline } from 'react-icons/io5';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, Typography, useLayer } from 'ui';

import {
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../../../../atom';
import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import { getTooltipText } from '../../../../../../utils/common';
import {
  changedNodeIdsAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../../../../atoms/atoms';
import { useTestWorkflowDbNode } from '../../../../hooks/useTestWorkflowDbNode';
import { getExecutedValueAndStatus } from '../../../../utils/common';
import { statusListForDataUpdate } from '../../../../utils/constant';
import { ConnectorActionSheet } from '../../../Sheets/ConnectorActionSheet/ConnectorActionSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { ConnectorAction } from '../ConnectorAction/ConnectorAction';
import {
  ConnectorActionsContainer,
  ConnectorInfoContainer,
  DbContainer,
  IconContainer,
} from './DbNode.styled';

export const DbNode = memo(({ data, isConnectable, id, type }: NodeProps) => {
  const { openWithProps } = useLayer(<ConnectorActionSheet />);

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);
  const [usedConnectorMapping] = useAtom(usedConnectorMappingAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { testConnectorActionData, connectorData } = useTestWorkflowDbNode({
    id,
    localData: data,
  });

  const hasConnectorError =
    !_isNil(usedConnectorMapping) &&
    !_isEmpty(usedConnectorMapping) &&
    !_isNil(data.entityId) &&
    !_isEmpty(data.entityId)
      ? !usedConnectorMapping[data.entityId]
      : false;

  const { sendEventToGTM } = useSendEventToGTM();

  useEffect(() => {
    if (!_isNil(connectorData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(connectorData);

        newWorkflowNode.data.status = exec.status;
        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);

        setTimeout(() => {
          data.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
    }
  }, [connectorData]);

  return (
    <>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: 'var(--color-dodgerBlue)',
          height: 8,
          width: 8,
          border: '1px solid var(--color-dodgerBlue)',
        }}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Top}
        style={{
          background: 'var(--color-dodgerBlue)',
          height: 8,
          width: 8,
          border: '1px solid var(--color-dodgerBlue)',
        }}
        isConnectable={isConnectable}
      />
      <DbContainer
        padding="0.5rem"
        $isError={!(workflowErrorByNode[id] == null)}
      >
        <NodeStatus
          status={data.status}
          showError={hasConnectorError}
          tooltipId="restApiNode-error"
          errorTooltipText={getTooltipText(
            siteConstants,
            'integrations',
            'integrationNotConnected'
          )}
        />

        <IconContainer
          onClick={() => {
            sendEventToGTM({
              event: 'workflow',
              source: 'listing',
              element: type,
              action: 'node_click',
              type: data.nodeType,
            });

            openWithProps({
              id,
              data,
            });
          }}
        >
          <FiDatabase size={32} />
        </IconContainer>

        <PadBox
          padding={{
            top: '6px',
            bottom: '6px',
          }}
        >
          <ConnectorInfoContainer>
            <Typography name="paragraphXs" fontWeight={700}>
              {data.name ?? 'Step 1'}
            </Typography>

            <Typography name="paragraphXs">Action</Typography>

            {!(workflowErrorByNode[id] == null) && (
              <Typography name="errorXs">
                {workflowErrorByNode[id]?.message}
              </Typography>
            )}
          </ConnectorInfoContainer>

          <ConnectorActionsContainer>
            <Inline
              align="center"
              style={{
                background: 'var(--color-lightGray7)',
                width: '4rem',
              }}
            >
              <IconButton
                disabled={!isWorkflowTestOnly}
                onClick={async () =>
                  await testConnectorActionData(
                    data.input?.query.value,
                    data.input?.action.value
                  )
                }
              >
                <IoPlayOutline
                  color={
                    isWorkflowTestOnly
                      ? 'var(--color-black)'
                      : 'var(--color-darkGray)'
                  }
                />
              </IconButton>

              <ConnectorAction data={data} id={id} />
            </Inline>
          </ConnectorActionsContainer>
        </PadBox>
      </DbContainer>
    </>
  );
});

DbNode.displayName = 'DbNode';
