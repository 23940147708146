import { gql, useLazyQuery } from '@apollo/client';

import { emptyQuery } from '../../../../utils/constant';

// eslint-disable-next-line
const getVersionsQuery = gql`
  query getVersion(
    $id: String!
    $entityType: EntityTypes!
    $status: Status!
    $page: Int
    $perPage: Int
  ) {
    getVersion(
      id: $id
      entityType: $entityType
      status: $status
      perPage: $perPage
      page: $page
    ) {
      data {
        commitId
        comment {
          title
          description
          author
        }
        timeStamp
        version
        status
        isLive
        comments {
          comment
          author
          timeStamp
          role
        }
      }
      paginationInfo {
        perPage
        currentPage
        totalPage
        totalRecord
      }
    }
  }
`;

const getRuleVersionList = gql`
  query getRule(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getRule(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        id
        commitId
        version
        status
        isLive
        approvalInfo {
          title
          description
          author
          timeStamp
        }
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

const getWorkflowVersionList = gql`
  query getWorkflow(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getWorkflow(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        id
        commitId
        version
        status
        isLive
        approvalInfo {
          title
          description
          author
          timeStamp
        }
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

const getDatasetVersionList = gql`
  query getDataSet(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getDataSet(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        id
        commitId
        version
        status
        isLive
        approvalInfo {
          title
          description
          author
          timeStamp
        }
      }
      paginationInfo {
        perPage
        currentPage
        totalRecord
        totalPage
      }
    }
  }
`;

const getQueryBasedOnEntity = (entityType: string) => {
  switch (entityType) {
    case 'rules':
      return getRuleVersionList;
    case 'datasets':
      return getDatasetVersionList;
    case 'workflow':
      return getWorkflowVersionList;
    default:
      return emptyQuery;
  }
};

export function useGetVersionList(entityType: string) {
  return useLazyQuery<Record<string, any>, Record<string, any>>(
    getQueryBasedOnEntity(entityType)
  );
}
